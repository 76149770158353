import React, { useCallback, useState, useEffect, useRef } from 'react';
import md5 from 'md5';
import { Button, Hidden, FormHelperText, Box, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface FormFileUploadValue {
  filename: string;
  content: string;
  blob?: Blob;
}

interface FormFileUploadProps {
  accept: string;
  label: string;
  name: string;
  disabled?: boolean;
  validation: object;
  defaultValue?: FormFileUploadValue;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  errorButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
  },
}));

const readFileAsBase64 = (file: Blob, callback: (content: string) => void) => {
  const reader = new FileReader();
  reader.onload = async event => {
    const result = event?.target?.result as string;
    callback(result.split('base64,')[1]);
  };
  reader.readAsDataURL(file);
};

export const FormFileUpload: React.FC<FormFileUploadProps> = ({
  accept,
  label,
  name,
  disabled,
  errorMessage,
  defaultValue = { filename: '', content: '' },
  validation,
}) => {
  const { register, unregister, setValue, triggerValidation } = useFormContext();
  const classes = useStyles();
  const formFileUploadInputId = md5(accept + label + name);
  const uploadedFile = useRef<FormFileUploadValue>(defaultValue);
  const [uploadedFilename, setUploadedFilename] = useState<string>(defaultValue.filename);

  useEffect(() => {
    register({ name, type: 'form-file-upload' }, validation);
    setValue(name, uploadedFile.current);

    return () => unregister(name);
  }, [unregister, register, name, validation, setValue]);

  const handleChange = useCallback(
    event => {
      const uploadedFiles: FileList = event.target.files;
      if (uploadedFiles && uploadedFiles[0]) {
        const fileToUpload = uploadedFiles[0];
        readFileAsBase64(fileToUpload, content => {
          setUploadedFilename(fileToUpload.name);
          uploadedFile.current.filename = fileToUpload.name;
          uploadedFile.current.content = content;
          uploadedFile.current.blob = fileToUpload;
          setValue(name, uploadedFile.current);
          triggerValidation(name);
        });
      }
    },
    [name, setValue, triggerValidation],
  );

  return (
    <Box>
      <Hidden xsUp implementation='css'>
        <input color='primary' accept={accept} type='file' id={formFileUploadInputId} onChange={handleChange} />
      </Hidden>
      <Box display='flex' alignItems='center' alignSelf='center'>
        <Box pr={2}>
          <label htmlFor={formFileUploadInputId}>
            <Button
              variant='outlined'
              component='span'
              color='primary'
              disabled={!!disabled ? disabled : false}
              className={!!errorMessage ? classes.errorButton : ''}
              startIcon={<CloudUpload />}
            >
              {label}
            </Button>
          </label>
        </Box>
        <Box flexGrow={1}>
          <Typography>{uploadedFilename}</Typography>
        </Box>
      </Box>
      {errorMessage && (
        <FormHelperText variant='outlined' error>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};
