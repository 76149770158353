import { createSelector } from 'reselect';
import {
  selectExtendedChartViewSettingsFactory,
  selectSprintStoryPointStats,
  ExtendedChartViewSettings,
  SprintPredicted,
  DataSet,
} from '../../planning-calc.selectors';
import { ChartType } from '../../planning.model';

export const selectBurnupChartData = createSelector(
  selectSprintStoryPointStats,
  selectExtendedChartViewSettingsFactory(ChartType.BurnUp),
  (sprintsWithStoryPoints: SprintPredicted[], settings?: ExtendedChartViewSettings) => {
    if (!settings) {
      return undefined;
    }
    const dataSets: DataSet[] = generateDefaultDataset();
    let accumulatedOldStoryPoints = 0;

    settings.sprintsInRange.forEach(s => {
      let dataToPush: any[];
      if (s.isPast) {
        accumulatedOldStoryPoints += s.achievedStoryPoints;
        dataToPush = [accumulatedOldStoryPoints, accumulatedOldStoryPoints, accumulatedOldStoryPoints];
      } else {
        const { minPredictedStoryPoints, medPredictedStoryPoints, maxPredictedStoryPoints } =
          sprintsWithStoryPoints.find(sp => sp.id === s.id) ?? ({} as SprintPredicted);
        dataToPush = [
          accumulatedOldStoryPoints + medPredictedStoryPoints,
          accumulatedOldStoryPoints + minPredictedStoryPoints,
          accumulatedOldStoryPoints + maxPredictedStoryPoints,
        ];
      }
      dataSets[0].data.push(dataToPush[0]);
      dataSets[1].data.push(dataToPush[1]);
      dataSets[2].data.push(dataToPush[2]);
    });

    settings.releaseCandidates.forEach(c => {
      const line = createConstantLine(c.name, c.storyPoints, settings.sprintsInRange.length);
      dataSets.push(line);
    });

    const { releaseCandidates, sprintsInRange, selectedBeginSprintId, selectedEndSprintId } = settings;

    return {
      labels: settings.sprintLabels,
      datasets: dataSets,
      releaseCandidates: releaseCandidates,
      sprintsInRange: sprintsInRange,
      selectedBeginSprintId: selectedBeginSprintId,
      selectedEndSprintId: selectedEndSprintId,
    };
  },
);

export function generateDefaultDataset() {
  return [
    {
      label: 'Forecast (med)',
      data: [],
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 180, 53, 1)', //yellow
      borderWidth: 2,
    },
    {
      label: 'Forecast (min)',
      data: [],
      backgroundColor: 'transparent',
      borderColor: 'rgba(39, 189, 160, 1)', //green
      borderWidth: 2,
    },
    {
      label: 'Forecast (max)',
      data: [],
      backgroundColor: 'transparent',
      borderColor: 'rgba(255, 87, 53, 1)', //red
      borderWidth: 2,
    },
  ];
}

function createConstantLine(label: string, value: number, length: number) {
  const data = [];
  while (length--) {
    data.push(value);
  }

  return {
    label: `${label} (${value} SP)`,
    data: data,
    backgroundColor: 'transparent',
    borderColor: 'grey',
    borderWidth: 2,
    pointRadius: 0,
    borderDash: [12, 8],
  };
}
