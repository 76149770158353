import cloneDeep from 'clone-deep';
import { BacklogItem, MAXRANK, MINRANK, sortBacklogItemsByRank } from '../..';

export function updateBacklogItemRank<T extends BacklogItem>(oldIndex: number, newIndex: number, backlogItems: T[]): T[] {
  backlogItems = cloneDeep(backlogItems);

  if (oldIndex === newIndex) {
    return backlogItems;
  }

  let rankLowerBound;
  let rankUpperBound;

  if (newIndex > oldIndex) {
    // The item was moved down.
    // The item previously at "newIndex" is now at "newIndex - 1".
    rankLowerBound = backlogItems[newIndex + 1] ? backlogItems[newIndex + 1].rank : MAXRANK;
    rankUpperBound = backlogItems[newIndex].rank;
  } else {
    // The item was moved up.
    // The item previously at "newIndex" is now at "newIndex + 1".
    rankLowerBound = backlogItems[newIndex].rank;
    rankUpperBound = backlogItems[newIndex - 1] ? backlogItems[newIndex - 1].rank : MINRANK;
  }

  if (rankLowerBound + 1 < rankUpperBound) {
    // There is at least one integer between the lower and upper bound
    // (usual case). Take the middle number for the new rank.
    backlogItems[oldIndex].rank = Math.round((rankUpperBound + rankLowerBound) / 2);
    sortBacklogItemsByRank(backlogItems);
  } else {
    // newRank will now be a floating point number.
    // It can be assigned as rank, and sorted as usual.
    backlogItems[oldIndex].rank = (rankUpperBound + rankLowerBound) / 2;
    sortBacklogItemsByRank(backlogItems);
    // We now, however, want integer ranks, so we have to re-assign all ranks:
    const distance = Math.round(MAXRANK / (backlogItems.length + 1));
    for (let j = 0; j < backlogItems.length; j++) {
      backlogItems[j].rank = (j + 1) * distance;
    }
  }
  return backlogItems;
}
