import { RoleDto, UserDto, RegisterUserDto } from './auth.model';
import { Role, User, RegisterUser } from './auth.state';

export const convertUserDtoToUser = (dto: UserDto): User => {
  const { id, name, email, roles } = dto;
  return {
    id,
    name,
    email,
    roles,
  };
};

export const convertUserToUserDto = (user: User): UserDto => {
  const { id, name, email, roles } = user;
  return {
    id,
    name,
    email,
    roles,
  };
};

export const convertRegisterUserToRegisterUserDto = (user: RegisterUser): RegisterUserDto => {
  const { name, password, email, roles } = user;
  return {
    name,
    password,
    email,
    roles,
  };
};

export const convertRoleDtoToRole = (dto: RoleDto): Role => {
  const { id, name, isDefault } = dto;
  return {
    id,
    name,
    isDefault,
  };
};
