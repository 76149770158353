import { Moment } from 'moment';
import { ResourceState } from '../shared';
import { SprintId, TeamId, ScenarioId, BacklogManagementSystem, BacklogItemId, ChartType, TimelineItemType } from './planning.model';
import { SprintPredicted } from './planning-calc.selectors';

export type ObjectMap<Key extends string, T> = { [name in Key]: T };

export interface OAuth1Configuration {
  accessToken: string;
  consumerKey: string;
  privateKeyFileName: string;
  privateKey: string;
}

export interface TeamConfig {
  approvedBacklogItemStatuses: string[];
  defaultSprintDuration: number;
  backlogManagementSystem: BacklogManagementSystem;
  backlogApiBaseLink: string;
  workItemBaseLink: string;
  backlogPbisQuery: string;
  project: string;
  domain: string;
  defaultStoryPoints: string | null;
  isOAuthEnabled: boolean;
  oAuth1Configuration: OAuth1Configuration | null;
}

// NOTE: We are holding the scenario only to keep the backend compatibility, but we will not implement it in the new UI
export interface Scenario {
  id: ScenarioId;
  name: string;
}

export interface Sprint {
  id: SprintId;
  sprintName: string;
  plannedStoryPoints: number;
  achievedStoryPoints: number;
  personDays: number;
  startDate: Moment;
  endDate: Moment;
  isRelease: boolean;
  comments?: string;
  release?: Release;
}

export interface Release {
  name: string;
  deploymentDate: Moment;
  releaseDate: Moment;
  comments: string;
  sprints: SprintPredicted[];
  minPredictedStoryPoints?: number;
  medPredictedStoryPoints?: number;
  maxPredictedStoryPoints?: number;
  personDays?: number;
}

export interface BacklogItem {
  id: BacklogItemId;
  sourceId: BacklogItemId;
  teamId: TeamId;
  summary: string;
  storyPoints: number;
  rank: number; // this is the new rank => in the future we will have only one rank property, but we still need to migrate the backend.
  sourceRank: number; // this is the rank property from the dto
  /**
   * @deprecated DON'T USE! this is only within the state because we must ensure to convert the cold state to the new rank without scenarios.
   */
  legacyRanks: LegacyScenarioBasedRank[];
  type: string;
  epicName: string;
  status: string;
  sprintName: string;
  deleted: boolean;
}

/**
 * @deprecated DON'T USE! this is only within the state because we must ensure to convert the cold state to the new rank without scenarios.
 */
export interface LegacyScenarioBasedRank {
  id: string;
  scenarioId: ScenarioId;
  rank: number;
}

export interface ChartViewSettings {
  chart: ChartType;
  selectedBeginSprintId: SprintId;
  selectedEndSprintId: SprintId;
  releaseCandidates: ReleaseCandidate[];
  selectedEpics: string[];
}

export interface ReleaseCandidate {
  name: string;
  storyPoints: number;
}

export interface IndividualChartSettings {
  [key: string]: { [key: string]: unknown };
}

export interface ViewSettings {
  deliverySprintId: SprintId;
  selectedTeamIds: TeamId[];
}

export interface StoryPointChanges {
  sourceId: BacklogItemId;
  summary: string;
  oldStoryPoints: number;
  newStoryPoints: number;
}
export interface ProductBacklogItemChanges {
  sourceId: BacklogItemId;
  summary: string;
  action: ProductBacklogItemAction;
}

export enum ProductBacklogItemAction {
  ADDED = 'Added',
  REMOVED = 'Removed',
}

export interface TimelineItem {
  id: string;
  teamId: TeamId;
  createdAt: Moment;
  userName: string;
  header: string;
  body: string;
  type: TimelineItemType;
  storyPointChanges: StoryPointChanges[];
  productBacklogItemChanges: ProductBacklogItemChanges[];
}

export interface AddTimelineItem {
  createdAt: Moment;
  header: string;
  body: string;
  type: TimelineItemType;
}

export interface Team {
  id: TeamId;
  name: string;
  config: TeamConfig;
  scenarios: ResourceState<Scenario[]>;
  activeScenario: ScenarioId | null;
  sprints: ResourceState<Sprint[]>;
  backlogItems: ResourceState<BacklogItem[]>;
  viewSettings: ViewSettings;
  timelineItems: ResourceState<TimelineItem[]>;
  minSyntheticVelocity: number;
  maxSyntheticVelocity: number;
  useSyntheticVelocity: boolean;
  creator: string;
  createdOn: Moment;
  updateBacklog?: ResourceState<{ isSuccess: boolean }>;
}

export interface PlanningState {
  teams: ResourceState<ObjectMap<TeamId, Team>>;
  activeTeam: TeamId | null;
  createTeam?: ResourceState<any>;
  copyTeam?: ResourceState<any>;
  updateTeam?: ResourceState<any>;
  deleteTeam?: ResourceState<any>;
  createTimelineItem?: ResourceState<any>;
  deleteTimelineItem?: ResourceState<any>;
  burnup?: ResourceState<ChartViewSettings | undefined>;
  burndown?: ResourceState<ChartViewSettings | undefined>;
}

export const initialPlanningState: PlanningState = {
  teams: {
    data: {},
    requestError: null,
    isPending: false,
  },
  activeTeam: null,
  burnup: {
    data: undefined,
    requestError: null,
    isPending: false,
  },
  burndown: {
    data: undefined,
    requestError: null,
    isPending: false,
  },
};
