import React, { useEffect } from 'react';
import { CardContent, Card, CardHeader, Collapse, Tooltip, IconButton, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { selectActiveTeam } from '../planning.selectors';
import { getDistinctColor } from '../../shared/distinct-colors';
import { BacklogItemPredictedSprint, SprintPredicted } from '../planning-calc.selectors';
import { useToggleState, roundStoryPoints, getItemTypeIcon } from '../../shared';
import { getPbiWarning } from '.';
import { DATEFORMAT } from '..';

interface SprintCardProps {
  sprint: SprintPredicted;
  backlog: BacklogItemPredictedSprint[];
  expandSprints: boolean;
}

export const SprintCard: React.FC<SprintCardProps> = ({ sprint, backlog, expandSprints }: SprintCardProps) => {
  const useStyles = makeStyles((theme: Theme) => ({
    card: {
      marginBottom: theme.spacing(2.5),
    },
    pbiRow: {
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(0.25),
    },
    pbiContainer: {
      backgroundColor: theme.palette.background.paper,
      alignItems: 'center',
    },
    pbiIcon: {
      paddingLeft: theme.spacing(1),
    },
    pbiPaper: {
      color: theme.palette.background.paper,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(0.625),
      margin: theme.spacing(0.625),
      justifyContent: 'center',
      boxShadow: 'none',
    },
    pbiEpicName: {
      backgroundColor: getDistinctColor(0),
    },
    storyPointPaper: {
      backgroundColor: grey[400],
    },
  }));

  const classes = useStyles();
  const [expandSingleSprint, toggleExpandSingleSprint] = useToggleState(expandSprints);
  const team = useSelector(selectActiveTeam);
  const { sprintName, startDate, endDate } = sprint;

  useEffect(() => {
    if ((expandSingleSprint && !expandSprints) || (!expandSingleSprint && expandSprints)) {
      toggleExpandSingleSprint();
    }
    // eslint-disable-next-line
  }, [expandSprints]);

  return (
    <Card className={classes.card} elevation={3}>
      <CardHeader
        title={
          <>
            <Typography variant='h5'>
              {`${sprintName} `}
              <i>
                ({moment(startDate).format(DATEFORMAT)} - {moment(endDate).format(DATEFORMAT)})
              </i>
            </Typography>
          </>
        }
        action={
          <Tooltip placement='top' title={expandSingleSprint ? 'Collapse' : 'Expand'}>
            <IconButton aria-label='expand-sprint-card' onClick={toggleExpandSingleSprint}>
              {expandSingleSprint ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Tooltip>
        }
      />
      <Collapse in={expandSingleSprint} timeout='auto' unmountOnExit>
        <CardContent>
          {backlog
            .filter(pbi => pbi.medSprintId === sprint.id)
            .map(pbi => (
              <Paper key={pbi.id} className={classes.pbiRow} variant='outlined' elevation={0}>
                <Grid container className={classes.pbiContainer}>
                  <Grid item xs={1} className={classes.pbiIcon}>
                    <Tooltip title={pbi.type}>{getItemTypeIcon(pbi.type)}</Tooltip>
                  </Grid>
                  <Grid item xs={8}>
                    {`${pbi.sourceId} - ${pbi.summary} (${pbi.status}) `}
                    <Tooltip title='The state of this pbi indicates that the pbi is not ready for implementation.'>
                      {getPbiWarning(pbi, team)}
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2}>
                    {pbi.epicName && <Paper className={`${classes.pbiPaper} ${classes.pbiEpicName}`}>{pbi.epicName}</Paper>}
                  </Grid>
                  <Grid item xs={1}>
                    <Paper className={`${classes.pbiPaper} ${classes.storyPointPaper}`}>{roundStoryPoints(pbi.storyPoints)}</Paper>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};
