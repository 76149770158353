import React from 'react';
import { useFormContext, Controller, ValidationOptions } from 'react-hook-form';
import { TextField, Box, makeStyles, Theme } from '@material-ui/core';
import { COMMENT_LIMIT } from '../planning';

interface CommentHookFormFieldProps {
  fieldName: string;
  labelText: string;
  defaultValue?: string;
  rules?: ValidationOptions;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogField: {
    margin: theme.spacing(1, 0),
  },
}));

export const CommentHookFormField: React.FC<CommentHookFormFieldProps> = ({ fieldName, labelText, defaultValue = '', rules }) => {
  const classes = useStyles();
  const { errors, control, watch } = useFormContext();
  const watchComments = watch(fieldName, defaultValue);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        as={
          <TextField
            label={labelText}
            fullWidth
            inputProps={{ maxLength: COMMENT_LIMIT }}
            className={classes.dialogField}
            variant='outlined'
            multiline
            minRows={4}
            maxRows={8}
            error={!!errors[fieldName]}
            helperText={errors[fieldName] && (errors[fieldName] as any).message}
          />
        }
      />
      <Box mt={0} display='flex' justifyContent='flex-end'>
        {watchComments.length}/{COMMENT_LIMIT}
      </Box>
    </>
  );
};
