import { Moment } from 'moment';
import { ProductBacklogItemAction } from '.';

export type TeamId = string;
export type SprintId = string;
export type ScenarioId = string;
export type BacklogItemId = string;

export enum ChartType {
  BurnUp = 'BurnUp',
  BurnDown = 'BurnDown',
}

export enum TimelineItemType {
  BacklogUpdate = 'BacklogUpdate',
  Mail = 'Mail',
  Conversation = 'Conversation',
  Meeting = 'Meeting',
}

export enum BacklogManagementSystem {
  Azure = 'Azure',
  Excel = 'Excel',
  Jira = 'Jira',
  Tfs = 'Tfs',
  FullJiraCsv = 'FullJiraCsv',
  None = 'None',
}

export interface OAuth1ConfigurationDto {
  accessToken: string;
  consumerKey: string;
  privateKeyFileName: string;
  privateKey: string | null;
}

export interface BacklogConfigDto {
  backlogManagementSystem: BacklogManagementSystem;
  backlogApiBaseLink: string;
  workItemBaseLink: string;
  backlogPbisQuery: string;
  project: string;
  domain: string;
  defaultStoryPoints: string | null;
  isOAuthEnabled: boolean;
  oAuth1Configuration: OAuth1ConfigurationDto | null;
}

export interface BacklogApiBaseLinkAndProject {
  backlogApiBaseLink: string;
  project: string;
}

export interface TeamDto {
  id: TeamId;
  name: string;
  defaultSprintDuration: number;
  approvedBacklogItemStatuses: string[];
  deliverySprintId: SprintId;
  selectedTeamIds: TeamId[];
  backlogConfig: BacklogConfigDto;
  minVelocity: number;
  maxVelocity: number;
  useManualVelocity: boolean;
  creator: string;
  createdOn: Moment;
}

export interface CopyTeamOptionsDto {
  backlogConfig: BacklogConfigDto;
  copiedTeamId: TeamId;
  defaultSprintDuration: number;
  newName: string;
  sprintStartDate: Moment;
}

export interface SprintDto {
  id: SprintId;
  sprintName: string;
  plannedStoryPoints: number;
  achievedStoryPoints: number;
  personDays: number;
  startDate: Moment;
  endDate: Moment;
  isPast: boolean;
  isCurrent: boolean;
  isFirstFuture: boolean;
  isRelease: boolean;
  relativeVelocity?: number;
  comments?: string;
  release?: SprintReleaseDto;
}

export interface SprintReleaseDto {
  name: string;
  deploymentDate: Moment;
  releaseDate: Moment;
  comments: string;
}

export interface SprintConfigurationDto {
  useSyntheticVelocity: boolean;
  minVelocity: number;
  maxVelocity: number;
  sprints: SprintDto[];
}

export interface BacklogItemDto {
  id: BacklogItemId;
  sourceId: BacklogItemId;
  teamId: TeamId;
  summary: string;
  storyPoints: number;
  rank: number;
  type: string;
  epicName: string;
  status: string;
  sprintId: SprintId;
  sprintName: string;
  ranks: BacklogItemRankDto[];
  deleted: boolean;
}

export interface BacklogItemRankDto {
  id: string;
  rank: number;
  scenarioId: ScenarioId;
}

export interface ChartViewSettingsDto {
  chart: ChartType;
  selectedBeginSprintId: SprintId;
  selectedEndSprintId: SprintId;
  releaseCandidates: ReleaseCandidateDto[];
  selectedEpics: string[];
}

export interface ReleaseCandidateDto {
  name: string;
  storyPoints: number;
}

export interface ScenarioDto {
  id: string;
  name: string;
  modificationDate: Moment;
  contingencyFactor: number;
  isActive: boolean;
}

export interface StoryPointChangesDto {
  sourceId: string;
  summary: string;
  oldStoryPoints: number;
  newStoryPoints: number;
}

export interface ProductBacklogItemChangesDto {
  sourceId: string;
  summary: string;
  action: ProductBacklogItemAction;
}

export interface TimelineItemDto {
  id: string;
  teamId: string;
  createdAt: Moment;
  userName: string;
  header: string;
  body: string;
  type: TimelineItemType;
  storyPointChanges: StoryPointChangesDto[];
  productBacklogItemChanges: ProductBacklogItemChangesDto[];
}

export interface AddTimelineItemDto {
  createdAt: Moment;
  header: string;
  body: string;
  type: TimelineItemType;
}

export interface UpdateBacklogOptions {
  username: string;
  password: string;
  keepOldBacklogItems: boolean;
  defaultStoryPoints?: number;
}
