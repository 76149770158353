import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { getContrastBlackOrWhite } from '../../../shared/distinct-colors';

interface RoadmapItemProps {
  epic: string;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1.25),
    justifyContent: 'center',
    boxShadow: 'none',
  },
}));

export const RoadmapItem: React.FC<RoadmapItemProps> = ({ epic, color }: RoadmapItemProps) => {
  const classes = useStyles();

  return (
    <Grid item xs='auto'>
      <Paper className={classes.container} style={{ backgroundColor: color }}>
        <div style={{ color: getContrastBlackOrWhite(color) }}>{epic}</div>
      </Paper>
    </Grid>
  );
};
