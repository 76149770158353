import { Minimize as MinimizeIcon } from '@material-ui/icons';
import { Card, Collapse, Select, Tooltip, IconButton, MenuItem, Theme, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { styled } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import ChartJsWrapper from '../../../shared/chartjs-wrapper/chartjs-wrapper.component';
import { updateChartViewSettingsForTeam } from '../../planning.actions';
import { Team, Sprint } from '../../planning.state';
import { DATEFORMAT } from '../../planning.constants';
import { ChartType } from '../../planning.model';
import { BurnupChartData } from '../../../shared/chartjs-wrapper/ChartDataInterface';
import { useToggleState, WidgetLoadingState } from '../../../shared';
import { Candidate } from './release-candidate-dialog.component';
import { BurnupWidgetRelease } from './burnup-widget-release.component';
import { selectSprints } from '../../planning.selectors';
import { selectBurnupChartData } from './burnup-widget.selectors';

enum UpdateSettingsEventType {
  SprintStartDate = 'selectedBeginSprintId',
  SprintEndDate = 'selectedEndSprintId',
  ReleaseCandidates = 'releaseCandidates',
  SelectedEpics = 'selectedEpics',
}

const BurnupComponent = styled(Card)({
  display: 'block',
  padding: '16px',
  minHeight: '50px',
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
  },
  configControls: {
    '& button': {
      margin: theme.spacing(0.5),
    },
  },
  dropdownControls: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    '& p, select': {
      margin: theme.spacing(2, 2),
    },
  },
  dropdownLabel: {
    fontSize: '18px',
  },
}));

interface ChartOptionsProperties {
  elements: {
    line: {
      tension: number;
    };
  };
  scales: {
    y: {
      min: number;
    };
  };
}

const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    line: {
      tension: 0, // disables bezier curves
    },
  },
  scales: {
    y: {
      scaleLabel: {
        display: true,
        labelString: 'Story Points (SP)',
      },
      min: 0, // start with 0, also when no epic is done and nothing is selected
    },
  },
};

interface BurnupWidgetProperties {
  data?: BurnupChartData;
  sprints?: Sprint[];
  team: Team;
}

const BurnupWidget = ({ team }: BurnupWidgetProperties) => {
  const data = useSelector(selectBurnupChartData);
  const sprints = useSelector(selectSprints);
  const classes = useStyles();
  const dispatch = useDispatch();
  const chartOptions = defaultChartOptions as ChartOptionsProperties;
  const [expanded, toggleExpanded] = useToggleState(true);
  const startSprintDate = data && sprints?.length ? sprints.filter(sprint => sprint.id === data.selectedBeginSprintId)[0]?.sprintName : '';
  const endSprintDate = data && sprints?.length ? sprints.filter(sprint => sprint.id === data.selectedEndSprintId)[0]?.sprintName : '';

  const currentStartDateId = sprints?.length && data ? sprints.filter(sprint => sprint.id === data?.selectedBeginSprintId)[0]?.id : 0;
  const currentEndDateId = sprints?.length && data ? sprints.filter(sprint => sprint.id === data?.selectedEndSprintId)[0]?.id : 0;

  const updateChartSettings = useCallback(
    (key: string, value: any) => {
      const settingsToUpdate = {
        [ChartType.BurnUp]: {
          [key]: value,
        },
      };
      dispatch(updateChartViewSettingsForTeam(team.id, settingsToUpdate, ChartType.BurnUp));
    },
    [dispatch, team.id],
  );

  const handleSettingsSave = (candidates: Candidate[]) => {
    const sortedCandidates = candidates?.map((candidate: Candidate) => {
      return {
        name: candidate.name,
        storyPoints: candidate.storyPoints,
      };
    });
    updateChartSettings(UpdateSettingsEventType.ReleaseCandidates, sortedCandidates);
  };

  const handleEndDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectEndSprintDate = sprints?.filter(sprint => sprint.sprintName === event.target.value)[0];
    updateChartSettings(UpdateSettingsEventType.SprintEndDate, selectEndSprintDate ? selectEndSprintDate.id : 0);
  };

  const handleStartDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectStartSprintDate = sprints?.filter(sprint => sprint.sprintName === event.target.value)[0];
    updateChartSettings(UpdateSettingsEventType.SprintStartDate, selectStartSprintDate ? selectStartSprintDate.id : 0);
  };

  const EndSprintRenderer = () => {
    if (!sprints) return null;
    return sprints.map(sprint =>
      parseInt(sprint.id) > currentStartDateId ? (
        <MenuItem key={sprint.sprintName} value={sprint.sprintName}>
          {`${sprint.sprintName} (${moment(sprint.endDate).format(DATEFORMAT)})`}
        </MenuItem>
      ) : null,
    );
  };

  const StartSprintRenderer = () => {
    if (!sprints) return null;
    return sprints.map(sprint =>
      parseInt(sprint.id) < currentEndDateId ? (
        <MenuItem key={sprint.sprintName} value={sprint.sprintName}>
          {`${sprint.sprintName} (${moment(sprint.startDate).format(DATEFORMAT)})`}
        </MenuItem>
      ) : null,
    );
  };

  if (!data) {
    return <WidgetLoadingState title={'Burnup'} />;
  }

  return (
    <BurnupComponent>
      <header className={classes.header}>
        <Typography variant='h5'>Burnup</Typography>
        <div className={classes.configControls}>
          <BurnupWidgetRelease teamid={team.id} releaseCandidates={data.releaseCandidates} handleSave={handleSettingsSave} />
          <Tooltip id='btnExpand' title={`${expanded ? 'Minimize' : 'Maximize'}`}>
            <IconButton aria-label='Minimize' onClick={() => toggleExpanded()}>
              <MinimizeIcon fontSize='small'></MinimizeIcon>
            </IconButton>
          </Tooltip>
        </div>
      </header>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <section className={classes.dropdownControls}>
          <p className={classes.dropdownLabel}>Start date</p>
          <Select labelId='start-date-dropdown' id='start-sprint-dropdown' value={startSprintDate} onChange={handleStartDateChange}>
            {StartSprintRenderer()}
          </Select>
          <p className={classes.dropdownLabel}>End date</p>
          <Select labelId='end-date=dropdown' id='start-sprint-dropdown' value={endSprintDate} onChange={handleEndDateChange}>
            {EndSprintRenderer()}
          </Select>
        </section>
        <section>
          <ChartJsWrapper id='burnup-chart' type='line' chartData={data} chartOptions={chartOptions} />
        </section>
      </Collapse>
    </BurnupComponent>
  );
};

export default BurnupWidget;
