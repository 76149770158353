import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { amber, red, lightGreen } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Prompt } from 'react-router';
import moment from 'moment';
import cloneDeep from 'clone-deep';
import { calcRelativeVelocity } from '../planning-calc.selectors';
import { PageLayout, ResourceState, SpinnerOverlay, generateNotificationKey } from '../../shared';
import { selectSprintsStateOfActiveTeam, selectActiveTeam } from '../planning.selectors';
import { SprintReleaseDialog } from './sprint-release-dialog';
import {
  Sprint,
  Team,
  getMin,
  getMax,
  median,
  round,
  SprintCommentDialog,
  SprintRow,
  VelocityInputField,
  Release,
  updateSprintConfigurationForTeam,
  useSprintReducer,
} from '..';
import { selectUnsavedChanges } from '../../app.selectors';
import { moreUnsavedChanges, noMoreUnsavedChanges } from '../../app.actions';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
  minVelocity: {
    color: `${lightGreen[500]} !important`,
  },
  maxVelocity: {
    color: `${red[500]} !important`,
  },
  medVelocity: {
    color: amber[500],
  },
  velocityInput: {
    minWidth: '120px',
    width: '5vw',
  },
  paper: {
    overflowY: 'scroll',
    height: '80vh',
  },
  tableHead: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const minSyntheticVelocityName = 'minSyntheticVelocity';
const maxSyntheticVelocityName = 'maxSyntheticVelocity';

interface DialogSprint {
  sprint: Sprint;
  index: number;
}

export const SprintManagementPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const sprintsResourceState: ResourceState<Sprint[]> = useSelector(selectSprintsStateOfActiveTeam);
  const team = useSelector(selectActiveTeam);
  const unsavedChanges = useSelector(selectUnsavedChanges);

  const [sprintsList, updateSprint, deleteSprint, addSprint, loadSprints] = useSprintReducer(
    sprintsResourceState.data.map(s => {
      return { ...s, errors: {} };
    }),
    team.config.defaultSprintDuration,
  );

  const [processFinished, setProcessFinished] = useState<boolean>(true);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [showRelease, setShowRelease] = useState<boolean>(false);
  const [sprintForDialog, setSprintForDialog] = useState<DialogSprint | undefined>(undefined);
  const [useSyntheticVelocity, setUseSyntheticVelocity] = useState<boolean>(team.useSyntheticVelocity);
  const [minSyntheticVelocity, setMinSyntheticVelocity] = useState<number>(team.minSyntheticVelocity);
  const [maxSyntheticVelocity, setMaxSyntheticVelocity] = useState<number>(team.maxSyntheticVelocity);
  const [strMinSyntheticVelocity, setStrMinSyntheticVelocity] = useState<string>(team.minSyntheticVelocity.toFixed(2));
  const [strMaxSyntheticVelocity, setStrMaxSyntheticVelocity] = useState<string>(team.maxSyntheticVelocity.toFixed(2));
  const [cascade, setCascade] = useState<boolean>(false);
  const [addedSprint, setAddedSprint] = useState<boolean>(false);

  const historicVelocities = useMemo(() => {
    return sprintsList.filter(sprint => sprint.endDate.isBefore(moment())).map(calcRelativeVelocity);
  }, [sprintsList]);
  const minVelocity = useMemo(() => getMin(historicVelocities), [historicVelocities]);
  const maxVelocity = useMemo(() => getMax(historicVelocities), [historicVelocities]);
  const medianVelocity = useMemo(
    () => (useSyntheticVelocity ? (minSyntheticVelocity + maxSyntheticVelocity) / 2 : median(historicVelocities)),
    [historicVelocities, maxSyntheticVelocity, minSyntheticVelocity, useSyntheticVelocity],
  );
  const isValid = useMemo(() => {
    return !sprintsList.map(sprintRowForm => Object.keys(sprintRowForm.errors).length > 0).reduce((p, c) => p || c, false);
  }, [sprintsList]);

  const onSave = useCallback(() => {
    const notificationKey = generateNotificationKey();
    if (isValid) {
      setProcessFinished(false);
      const updatedTeam: Team = {
        ...team,
        useSyntheticVelocity,
        minSyntheticVelocity,
        maxSyntheticVelocity,
      };
      dispatch(noMoreUnsavedChanges());
      dispatch(
        updateSprintConfigurationForTeam(
          updatedTeam,
          cloneDeep(sprintsList).map(sprintRowForm => {
            return { ...sprintRowForm, errors: {} };
          }),
          notificationKey,
        ),
      );
    }
  }, [dispatch, isValid, maxSyntheticVelocity, minSyntheticVelocity, sprintsList, team, useSyntheticVelocity]);

  const onAddSprint = useCallback(() => {
    addSprint();
    setAddedSprint(true);
    dispatch(moreUnsavedChanges());
  }, [addSprint]);

  const onDeleteSprint = useCallback(
    (index: number, sprintName: string) => {
      confirm({
        title: 'Remove Sprint: ' + sprintName,
        description: "Deletion can't be reverted. Do you want to proceed?",
        confirmationText: 'Delete',
        dialogProps: {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        },
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
      })
        .then(() => {
          deleteSprint(index);
          dispatch(moreUnsavedChanges());
        })
        .catch(() => {});
    },
    [confirm, deleteSprint],
  );

  const openCommentDialog = useCallback((index: number, sprint: Sprint) => {
    setSprintForDialog({ index, sprint });
    setShowComment(true);
  }, []);

  const commentDialogSubmit = useCallback(
    (index: number, comments?: string) => {
      updateSprint({ key: 'comments', index, value: comments, cascade: false });
      dispatch(moreUnsavedChanges());
      setShowComment(false);
    },
    [updateSprint],
  );

  const openReleaseDialog = useCallback((index: number, sprint: Sprint) => {
    setSprintForDialog({ index, sprint });
    setShowRelease(true);
  }, []);

  const releaseDialogSubmit = useCallback(
    (index: number, release?: Release) => {
      updateSprint({ key: 'release', index, value: release, cascade: false });
      dispatch(moreUnsavedChanges());
      setShowRelease(false);
    },
    [updateSprint],
  );

  const onChangeCascadeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCascade(event.currentTarget.checked);
  }, []);

  const onChangeVelocitySwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSyntheticVelocity(event.currentTarget.checked);
    dispatch(moreUnsavedChanges());
  }, []);

  const onChangeSprint = useCallback(
    (index: number, key: string, value: string | number | moment.Moment) => {
      dispatch(moreUnsavedChanges());
      updateSprint({ index, key, value, cascade });
    },
    [cascade, updateSprint],
  );

  const onChangeMinVelocity = useCallback(
    (value: number) => {
      const verifiedValue = value < 0 ? 0 : value > maxSyntheticVelocity ? maxSyntheticVelocity : value;
      setSyntheticVelocityState(verifiedValue, setMinSyntheticVelocity, setStrMinSyntheticVelocity);
    },
    [maxSyntheticVelocity],
  );

  const onChangeMaxVelocity = useCallback(
    (value: number) => {
      const verifiedValue = value < 0 ? 0 : value < minSyntheticVelocity ? minSyntheticVelocity : value;
      setSyntheticVelocityState(verifiedValue, setMaxSyntheticVelocity, setStrMaxSyntheticVelocity);
    },
    [minSyntheticVelocity],
  );

  function setSyntheticVelocityState(value: number, stateSetter: React.SetStateAction<any>, stringStateSetter: React.SetStateAction<any>) {
    stateSetter(round(value, 2));
    stringStateSetter(value.toString());
    dispatch(moreUnsavedChanges());
  }

  function setStringSyntheticVelocityState(value: number, stateSetter: React.SetStateAction<any>) {
    stateSetter(value.toFixed(2));
  }

  useEffect(() => {
    const { isPending, requestError, data: sprints } = sprintsResourceState;
    if (isPending) return;
    if (processFinished && !requestError) {
      loadSprints(
        sprints.map(sprintRowForm => {
          return { ...sprintRowForm, errors: {} };
        }),
      );
    }
  }, [processFinished, loadSprints, sprintsResourceState]);

  useEffect(() => {
    setUseSyntheticVelocity(team.useSyntheticVelocity);
    setMinSyntheticVelocity(team.minSyntheticVelocity);
    setMaxSyntheticVelocity(team.maxSyntheticVelocity);
    setStrMinSyntheticVelocity(team.minSyntheticVelocity.toFixed(2));
    setStrMaxSyntheticVelocity(team.maxSyntheticVelocity.toFixed(2));
  }, [team.maxSyntheticVelocity, team.minSyntheticVelocity, team.useSyntheticVelocity]);

  useEffect(() => {
    dispatch(noMoreUnsavedChanges());
  }, [team]);

  useEffect(() => {
    if (sprintsResourceState.isPending) {
      return;
    }
    if (sprintsResourceState.requestError) {
      dispatch(moreUnsavedChanges());
    }
    setProcessFinished(true);
  }, [sprintsResourceState]);

  useEffect(() => {
    if (addedSprint) {
      document.querySelector('tbody .MuiTableRow-root:last-child')?.scrollIntoView();
      setAddedSprint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintsList]);

  return (
    <>
      <form className={classes.form} autoComplete='off'>
        <PageLayout
          title={'Sprint Management'}
          actions={
            <Grid item>
              <SpinnerOverlay
                button={
                  <Button
                    data-testid='save-button'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      onSave();
                    }}
                    disabled={!unsavedChanges || !processFinished}
                  >
                    Save
                  </Button>
                }
                show={!processFinished}
                css={{ display: 'inline', marginRight: '5px' }}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  onAddSprint();
                }}
                disabled={!processFinished}
              >
                Add Sprint
              </Button>
            </Grid>
          }
        >
          <Paper className={classes.paper}>
            {React.useMemo(
              () => (
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                  <Box display='flex' justifyContent='flex-start' alignItems='flex-start' ml={2}>
                    <FormControlLabel
                      control={<Switch checked={cascade} onChange={onChangeCascadeSwitch} color='primary' />}
                      label='Cascade Edit'
                    />
                  </Box>
                  <Box display='flex' justifyContent='flex-end' pt={2} pr={4} alignItems='flex-start'>
                    <Box mr={2} display='inline'>
                      <FormControlLabel
                        control={<Switch color='primary' checked={useSyntheticVelocity} onChange={onChangeVelocitySwitch} />}
                        label='Synthetic Velocity:'
                        labelPlacement='start'
                      />
                    </Box>
                    <Box display={useSyntheticVelocity ? 'flex' : 'none'}>
                      <VelocityInputField
                        min={0}
                        max={maxSyntheticVelocity}
                        adornmentText={'Min'}
                        className={`${classes.minVelocity} ${classes.velocityInput}`}
                        name={minSyntheticVelocityName}
                        value={strMinSyntheticVelocity}
                        onChange={event => {
                          onChangeMinVelocity(+event.currentTarget.value);
                        }}
                        onBlur={() => {
                          setStringSyntheticVelocityState(minSyntheticVelocity, setStrMinSyntheticVelocity);
                        }}
                      />
                      <Box mx={4} mt={1} display='inline'>
                        <Typography className={classes.medVelocity}>{round(medianVelocity, 2).toFixed(2)} Med</Typography>
                      </Box>
                      <VelocityInputField
                        min={minSyntheticVelocity}
                        adornmentText={'Max'}
                        className={`${classes.maxVelocity} ${classes.velocityInput}`}
                        name={maxSyntheticVelocityName}
                        value={strMaxSyntheticVelocity}
                        onChange={event => {
                          onChangeMaxVelocity(+event.currentTarget.value);
                        }}
                        onBlur={() => {
                          setStringSyntheticVelocityState(maxSyntheticVelocity, setStrMaxSyntheticVelocity);
                        }}
                      />
                    </Box>
                    <Box display={useSyntheticVelocity ? 'none' : 'flex'}>
                      <VelocityInputField
                        adornmentText={'Min'}
                        className={`${classes.minVelocity} ${classes.velocityInput}`}
                        value={minVelocity}
                        disabled
                      />
                      <Box mx={4} mt={1} display='inline'>
                        <Typography className={classes.medVelocity}>{round(medianVelocity, 2).toFixed(2)} Med</Typography>
                      </Box>
                      <VelocityInputField
                        adornmentText={'Max'}
                        className={`${classes.maxVelocity} ${classes.velocityInput}`}
                        value={maxVelocity}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
              ),
              [
                cascade,
                onChangeCascadeSwitch,
                useSyntheticVelocity,
                onChangeVelocitySwitch,
                maxSyntheticVelocity,
                classes.minVelocity,
                classes.velocityInput,
                classes.medVelocity,
                classes.maxVelocity,
                strMinSyntheticVelocity,
                medianVelocity,
                minSyntheticVelocity,
                strMaxSyntheticVelocity,
                minVelocity,
                maxVelocity,
                onChangeMinVelocity,
                onChangeMaxVelocity,
              ],
            )}

            <Table size='small' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}></TableCell>
                  <TableCell className={classes.tableHead}>Sprint</TableCell>
                  <TableCell className={classes.tableHead}>Start Date</TableCell>
                  <TableCell className={classes.tableHead}>SP Planned</TableCell>
                  <TableCell className={classes.tableHead}>SP Forecast</TableCell>
                  <TableCell className={classes.tableHead}>SP Achieved</TableCell>
                  <TableCell className={classes.tableHead}>Capacity</TableCell>
                  <TableCell className={classes.tableHead}>Velocity</TableCell>
                  <TableCell className={classes.tableHead}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid={`sprint-table-body`}>
                {sprintsList.map((sprint: Sprint, index: number) => (
                  <SprintRow
                    key={`${sprint.id}${team.id}`}
                    index={index}
                    sprint={sprintsList[index]}
                    deleteSprint={onDeleteSprint}
                    onChangeSprint={onChangeSprint}
                    medianVelocity={medianVelocity}
                    openCommentDialog={openCommentDialog}
                    openReleaseDialog={openReleaseDialog}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>

          <SprintCommentDialog
            key={'comments:' + sprintForDialog?.sprint?.id}
            open={showComment}
            onClose={() => {
              setShowComment(false);
            }}
            onSubmit={commentDialogSubmit}
            index={sprintForDialog?.index}
            sprint={sprintForDialog?.sprint}
          />
          <>
            {sprintForDialog && (
              <SprintReleaseDialog
                key={'release:' + sprintForDialog.sprint.id}
                open={showRelease}
                onClose={() => {
                  setShowRelease(false);
                }}
                onSubmit={releaseDialogSubmit}
                index={sprintForDialog.index}
                sprint={sprintForDialog.sprint}
              />
            )}
          </>
        </PageLayout>
      </form>
      <Prompt when={unsavedChanges} message={'There are unsaved changes.'} />
    </>
  );
};
