import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { FormFileUpload, FormFileUploadValue } from '../../shared';
import { BacklogUpdateAuthProps } from './backlog-update-auth.component';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(3, 3, 3, 0),
  },
}));

export const BacklogUpdateAuthFullJiraCsv: React.FC<BacklogUpdateAuthProps> = ({ errorMessageCallback, pending }) => {
  const classes = useStyles();
  const sampleCsvFile = require('../../assets/file/CsvImportTemplate.csv');

  return (
    <>
      <Box display='flex' className={classes.formControl} key={'csvUpload'}>
        <FormFileUpload
          accept='.csv'
          label='Upload CSV File'
          name='csvSource'
          disabled={pending}
          validation={{
            validate: (file: FormFileUploadValue) => !!file.filename || 'CSV file cannot be empty',
          }}
          errorMessage={errorMessageCallback('csvSource')}
        />
      </Box>
      <Box display='flex' className={classes.formControl} key={'csvDownload'}>
        <Button
          variant='contained'
          disabled={pending}
          color='primary'
          startIcon={<CloudDownload />}
          onClick={() => window.open(sampleCsvFile)}
        >
          Download sample (.csv)
        </Button>
      </Box>
    </>
  );
};
