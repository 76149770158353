import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Img from 'react-image';
import logo from '../../assets/img/zuehlke-logo-big.jpg';
import { selectAuth } from '../auth.selectors';
import { loginWithExternalProvider, loginWithEmailPassword } from '../auth.actions';
import { VersionComponent, SpinnerOverlay, generateNotificationKey } from '../../shared';
import { LocalLogin } from './local-login.component';
import { FormData } from './local-login.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBox: {
    width: '800px',
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'row',
  },
  loginBoxLeft: {
    flex: '1 1 0px',
    '& img': {
      width: '140px',
      height: '140px',
    },
  },
  loginBoxRight: {
    flex: '1 1 0px',
  },
  logoContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  separator: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: theme.spacing(3, 3),
    '&::after': {
      marginLeft: '.25em',
    },
    '&::before': {
      marginRight: '.25em',
    },
    '&::after, &::before': {
      content: '""',
      flex: 1,
      borderBottom: '1px solid #000',
    },
  },
  version: {
    marginTop: theme.spacing(16.25),
  },
}));

function extractReturnUrl(url: string): string {
  const query = new URLSearchParams(url);
  const origReturnUrl = query.get('ReturnUrl');
  return origReturnUrl ? origReturnUrl : '';
}

export const LoginPage: React.FC = ({ ...rest }) => {
  const showInformationVersion = false;
  const dispatch = useDispatch();
  const classes = useStyles(rest);
  const auth = useSelector(selectAuth);
  const [processFinished, setProcessFinished] = useState<boolean>(true);
  const queryString = useLocation().search;

  const returnUrl = extractReturnUrl(queryString);
  const onSubmit = (values: FormData): void => {
    const notificationKey = generateNotificationKey();
    dispatch(loginWithEmailPassword(values.email, values.password, returnUrl, notificationKey));
    setProcessFinished(false);
  };

  useEffect(() => {
    const { login, isAuthenticationPending } = auth;
    if (isAuthenticationPending || processFinished) return;
    const { error } = login;
    if (error) {
      setProcessFinished(true);
    }
  }, [auth, processFinished]);

  const externalProviders = auth.metadata.data.externalProviders;
  const applicationUri = auth.oidcConfig.data ? auth.oidcConfig.data.applicationUri : '';
  const executeLoginWithExternalProvider = useCallback(
    externalProvider => () => {
      dispatch(loginWithExternalProvider(applicationUri, externalProvider, returnUrl));
      setProcessFinished(false);
    },
    [applicationUri, returnUrl, dispatch],
  );

  return (
    <div className={classes.container}>
      <Paper className={classes.loginBox}>
        <div className={classes.loginBoxLeft}>
          <Typography className={classes.title} component='h1' variant='h5'>
            Welcome to the Agile Planner
          </Typography>
          <div className={classes.logoContainer}>
            <Img src={logo} alt='zuehlke logo' />
          </div>
          <div className={classes.version}>
            <VersionComponent showInformationVersion={showInformationVersion} />
          </div>
        </div>
        <div className={classes.loginBoxLeft}>
          <Typography className={classes.title} component='h1' variant='h5'>
            Member Login
          </Typography>
          {auth.metadata.data.enableLocalLogin && (
            <LocalLogin externalError={auth.login.error} onSubmit={onSubmit} authenticationPending={!processFinished} />
          )}
          {auth.metadata.data.enableLocalLogin && externalProviders.length > 0 && <div className={classes.separator}>or</div>}
          {externalProviders.map(p => (
            <SpinnerOverlay
              key={p.displayName}
              button={
                <Button
                  id='externalLogin'
                  fullWidth
                  data-authentication-scheme={p.authenticationScheme}
                  variant='outlined'
                  color='primary'
                  key={p.authenticationScheme}
                  disabled={!processFinished}
                  onClick={executeLoginWithExternalProvider(p)}
                >
                  {p.displayName}
                </Button>
              }
              show={!processFinished}
            />
          ))}
        </div>
      </Paper>
    </div>
  );
};
