import { RootState } from './app.state';

export const selectAppVersion = (state: RootState) => {
  return state.app.version;
};

export const selectFeatureOptions = (state: RootState) => {
  return state.app.featureOptions;
};

export const selectNotifications = (state: RootState) => {
  return state.app.notifications || [];
};

export const selectUnsavedChanges = (state: RootState) => {
  return state.app.unsavedChanges;
};
