import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useForm, Controller, FormContext } from 'react-hook-form';
import { CommentHookFormField } from '../../shared';
import { DATEFORMAT } from '../planning.constants';
import { theme } from '../../theme';
import { Release, NAME_LIMIT, Sprint } from '..';
import { ignoreBackdropClick } from '../../shared/ignore-backdrop-click';

interface SprintReleaseDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (index: number, release?: Release) => void;
  index: number;
  sprint: Sprint;
}

const useStyles = makeStyles(() => ({
  dialogField: {
    margin: theme.spacing(1, 0),
  },
}));

export const SprintReleaseDialog: React.FC<SprintReleaseDialogProps> = ({ open, onClose, onSubmit, sprint, index }) => {
  const classes = useStyles();
  const formMethods = useForm<Release>();
  const { handleSubmit, errors, control } = formMethods;
  const defaultDeploymentDate = useMemo(() => {
    if (sprint.release) return moment(sprint.release.deploymentDate);
    return sprint.endDate;
  }, [sprint]);
  const defaultReleaseDate = useMemo(() => {
    if (sprint.release) return moment(sprint.release.releaseDate);
    return sprint.endDate;
  }, [sprint]);

  const onDelete = useCallback(() => {
    onSubmit(index, undefined);
  }, [index, onSubmit]);

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => ignoreBackdropClick(reason, onClose)}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      {React.useMemo(
        () => (
          <DialogTitle id='dialog-title'>
            <Box display='flex' justifyContent='space-between'>
              Release in {sprint.sprintName}
              {sprint.release ? (
                <Button variant='contained' onClick={onDelete}>
                  Delete
                </Button>
              ) : null}
            </Box>
          </DialogTitle>
        ),
        [onDelete, sprint],
      )}
      <form
        onSubmit={handleSubmit(values => {
          onSubmit(index, { ...values });
        })}
        autoComplete='off'
      >
        <DialogContent dividers>
          <DialogContentText id='dialog-description' tabIndex={-1}></DialogContentText>

          <Controller
            name='releaseDate'
            rules={{ required: 'The release date is required.' }}
            control={control}
            defaultValue={defaultReleaseDate}
            as={
              <KeyboardDatePicker
                label='Release Date *'
                autoOk
                fullWidth
                minDate={sprint.startDate}
                className={classes.dialogField}
                variant='inline'
                inputVariant='outlined'
                format={DATEFORMAT}
                error={!!errors.releaseDate}
                helperText={errors.releaseDate && (errors.releaseDate as any).message}
                onChange={() => {}}
                value={() => {}}
              />
            }
          />

          <Controller
            name='name'
            rules={{ required: 'The release name is required.' }}
            control={control}
            defaultValue={sprint.release?.name ?? ''}
            as={
              <TextField
                label='Release Name *'
                fullWidth
                inputProps={{ maxLength: NAME_LIMIT }}
                className={classes.dialogField}
                variant='outlined'
                error={!!errors.name}
                helperText={errors.name && (errors.name as any).message}
              />
            }
          />

          <Controller
            name='deploymentDate'
            rules={{ required: 'The deployment date is required.' }}
            control={control}
            defaultValue={defaultDeploymentDate}
            as={
              <KeyboardDatePicker
                label='Deployment Date *'
                autoOk
                fullWidth
                minDate={sprint.startDate}
                className={classes.dialogField}
                variant='inline'
                inputVariant='outlined'
                format={DATEFORMAT}
                error={!!errors.deploymentDate}
                helperText={errors.deploymentDate && (errors.deploymentDate as any).message}
                onChange={() => {}}
                value={() => {}}
              />
            }
          />

          <FormContext {...formMethods}>
            <CommentHookFormField fieldName={'comments'} labelText={'Release Comments'} defaultValue={sprint.release?.comments ?? ''} />
          </FormContext>
        </DialogContent>
        {React.useMemo(
          () => (
            <DialogActions>
              <Button onClick={onClose} color='default'>
                Cancel
              </Button>
              <Button color='primary' type='submit' variant='contained'>
                Done
              </Button>
            </DialogActions>
          ),
          [onClose],
        )}
      </form>
    </Dialog>
  );
};
