import { AjaxResponse } from 'rxjs/ajax';
import { Observable, of } from 'rxjs';
import { mergeMap, filter, switchMap, catchError } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { Action, AnyAction } from 'redux';
import {
  initializePublicData,
  loadVersionInformation,
  AppActionTypes,
  loadVersionInformationSucceeded,
  loadVersionInformationFailed,
  loadFeatureOptions,
  loadFeatureOptionsSucceeded,
  loadFeatureOptionsFailed,
} from './app.actions';
import { authEpics, loadLoginInfo, loadOidcConfigInfo } from './auth';
import { planningEpics } from './planning';
import { RootState } from './app.state';
import { AppDependencies } from './app.dependencies';
import { mapDefaultResponseToError } from './shared';

export const initializeDataEpic: Epic = action$ =>
  action$.pipe(
    filter(initializePublicData.match),
    mergeMap(() => [loadLoginInfo(), loadOidcConfigInfo(), loadVersionInformation(), loadFeatureOptions()]),
  );

export const loadVersionInformationEpic: Epic<AppActionTypes, AppActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { appService },
) =>
  action$.pipe(
    filter(loadVersionInformation.match),
    switchMap(() => {
      return appService.getVersion().pipe(
        mergeMap(response => [loadVersionInformationSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(loadVersionInformationFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

export const loadFeatureOptionsEpic: Epic<AppActionTypes, AppActionTypes, RootState, AppDependencies> = (action$, state$, { appService }) =>
  action$.pipe(
    filter(loadFeatureOptions.match),
    switchMap(() => {
      return appService.getFeatureOptions().pipe(
        mergeMap(response => [loadFeatureOptionsSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(loadFeatureOptionsFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

export const appEpics = combineEpics(initializeDataEpic, loadVersionInformationEpic, loadFeatureOptionsEpic);

export const rootEpic = combineEpics<any, any, any, any>(appEpics, authEpics, planningEpics);
