import { User as OidcUser } from 'oidc-client';
import { RequestError, ResourceState } from '../shared';
import { LoginInfoDto, RoleDto, UserDto, RegisterUserDto, LogoutInfoDto, LoggedOutInfoDto } from './auth.model';
import { OidcConfig } from './oidc-config';

export interface LoginInfo extends LoginInfoDto {}

export interface LogoutInfo extends LogoutInfoDto {}

export interface ErrorState extends RequestError {}

export interface LoginState {
  error?: ErrorState;
}

export interface LogoutState {
  error?: ErrorState;
  logoutInfo?: LogoutInfo | LoggedOutInfoDto;
}

export interface User extends UserDto {}
export interface RegisterUser extends RegisterUserDto {}
export interface Role extends RoleDto {}

export type StateUser = User;

export enum UserProcessEventType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export interface AuthState {
  metadata: ResourceState<LoginInfo>;
  oidcConfig: ResourceState<OidcConfig | null>;
  login: LoginState;
  logout: LogoutState;
  user?: OidcUser;
  isReady: boolean;
  isAuthenticated: boolean;
  isAuthenticationPending: boolean;
  users: ResourceState<User[]>;
  changePassword?: ResourceState<{ success: boolean }>;
  processUser?: ResourceState<{ success: boolean; eventType: UserProcessEventType; userEmail: string }>;
  roles: ResourceState<Role[]>;
}

export const initialAuthState: AuthState = {
  metadata: {
    data: {
      username: '',
      returnUrl: '',
      enableLocalLogin: false,
      allowRememberLogin: false,
      autoCreateExternalUsers: false,
      externalProviders: [],
    },
    requestError: null,
    isPending: false,
  },
  oidcConfig: {
    data: null,
    requestError: null,
    isPending: false,
  },
  login: {},
  logout: {},
  isReady: false,
  isAuthenticated: false,
  isAuthenticationPending: false,
  users: {
    data: [],
    requestError: null,
    isPending: false,
  },
  roles: {
    data: [],
    requestError: null,
    isPending: false,
  },
};
