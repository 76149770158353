import { AspectRatio as ExpandIcon, Minimize as MinimizeIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MuiCollapse from '@material-ui/core/Collapse';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Card, TextField, Grid, Tooltip, IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { updateSelectedTeamIds } from '../../planning.actions';
import { Team } from '../../planning.state';
import { useToggleState, AlertDisplay } from '../../../shared';
import { RoadmapSprint, RoadmapTeam } from './roadmap-widget.model';
import { RoadmapSprintItem } from './roadmap-sprint-item.component';
import { RoadmapFilterItem } from './roadmap-filter-item.component';
import { RoadmapFilterOption } from './roadmap-filter-option.component';
import { RoadmapExplanationPopover } from './roadmap-widget-explanation-popover.component';
import { roadmapErrorMap } from './roadmap-widget.constants';
import { selectRoadmapData } from './roadmap-widget.selectors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  marginLeftRight: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0),
  },
  iconSection: {
    textAlign: 'right',
    marginRight: theme.spacing(1.5),
  },
  selectedTeams: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  collapseHidden: {
    padding: '0 !important',
  },
}));

const collapseStyles = makeStyles({
  collapseHidden: {
    padding: '0 !important',
  },
});

const Collapse = (props: any) => {
  const classes = collapseStyles();
  return (
    <MuiCollapse
      component={(props: any) => <Grid item xs={12} {...props} />}
      classes={{
        hidden: classes.collapseHidden,
      }}
      timeout='auto'
      unmountOnExit={true}
      {...props}
    >
      <Grid container spacing={1}>
        {props.children}
      </Grid>
    </MuiCollapse>
  );
};

interface RoadmapWidgetProps {
  team: Team;
  isFullRoadmap: boolean;
}

export const RoadmapWidget: React.FC<RoadmapWidgetProps> = ({ team, isFullRoadmap }: RoadmapWidgetProps) => {
  const data = useSelector(selectRoadmapData);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expanded, toggleExpanded] = useToggleState(true);

  const EMBEDDED_SPRINT_LIMIT = 3;

  const limitSprintsForEmbeddedRoadmap = (roadmapSprints: RoadmapSprint[]): RoadmapSprint[] => {
    return roadmapSprints.filter(roadmapSprint => roadmapSprint.epics.length > 0).slice(0, EMBEDDED_SPRINT_LIMIT);
  };

  const checkForAnySprintRelease = (roadmapSprints: RoadmapSprint[]): boolean => {
    for (let sprint of roadmapSprints) {
      if (sprint.releases.length > 0) {
        return true;
      }
    }
    return false;
  };

  const updateSelected = (selectedTeams: RoadmapTeam[]) => {
    if (!selectedTeams) {
      return;
    }
    dispatch(
      updateSelectedTeamIds({
        ...team,
        viewSettings: {
          ...team.viewSettings,
          selectedTeamIds: selectedTeams.map(team => team.id),
        },
      }),
    );
  };

  const roadmapTeams = data.teams;
  const selectedTeams = roadmapTeams.filter(rmt => rmt.selected);
  const roadmapSprints = isFullRoadmap ? data.sprints : limitSprintsForEmbeddedRoadmap(data.sprints);
  const anySprintRelease = checkForAnySprintRelease(roadmapSprints);
  const roadmapError = data.error;

  return (
    <Card className={classes.container}>
      <Grid container direction='row' spacing={1}>
        {isFullRoadmap ? (
          <React.Fragment />
        ) : (
          <>
            <Grid item xs='auto' className={classes.marginLeft}>
              <Typography variant='h5' className={classes.paddingTopBottom}>
                Roadmap Forecast
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <RoadmapExplanationPopover />
            </Grid>
            <Grid item xs className={classes.iconSection}>
              <Tooltip id='btnExpand' title='Expand'>
                <Link to='/roadmap'>
                  <IconButton aria-label='Expand'>
                    <ExpandIcon fontSize='small' />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip id='btnMinimize' title={`${expanded ? 'Minimize' : 'Maximize'}`}>
                <IconButton aria-label='Minimize' onClick={() => toggleExpanded()}>
                  <MinimizeIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
        <Collapse in={expanded}>
          <Grid item xs={12} className={classes.paddingTopBottom}>
            <React.Fragment />
          </Grid>
          {roadmapError !== undefined && (
            <Grid item xs={12} className={classes.marginLeftRight}>
              <AlertDisplay content={roadmapErrorMap[roadmapError]} severity={'info'} />
            </Grid>
          )}
          <Grid container item xs={12} className={classes.marginLeftRight} spacing={1}>
            {roadmapSprints.map(sprint => (
              <RoadmapSprintItem key={sprint.id} roadmapSprint={sprint} isFullRoadmap={isFullRoadmap} anySprintRelease={anySprintRelease} />
            ))}
          </Grid>
          <Grid item xs={12} className={classes.marginLeftRight}>
            <Autocomplete
              className={classes.selectedTeams}
              multiple={true}
              value={selectedTeams}
              id='cmbSelectedTeams'
              options={roadmapTeams}
              getOptionLabel={option => option.name}
              renderOption={option => <RoadmapFilterOption key={option.id} roadmapTeam={option} />}
              renderTags={(value, getTagProps) => {
                return value.map((team, index) => (
                  <RoadmapFilterItem key={`${team.id}${index}`} roadmapTeam={team} tagProps={getTagProps} index={index} />
                ));
              }}
              filterSelectedOptions
              onChange={(_event, values) => {
                updateSelected(values);
              }}
              noOptionsText='No further team to select'
              renderInput={params => <TextField {...params} label='Selected Teams' variant='outlined' fullWidth />}
            />
          </Grid>
        </Collapse>
      </Grid>
    </Card>
  );
};
