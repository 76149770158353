import React from 'react';
import { Chip } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { getItemTypeIcon } from '../../../shared';
import { Option, OptionLabel } from './option';

interface BacklogFilterItemProps {
  option: Option;
  tagProps: AutocompleteGetTagProps;
  index: number;
}

export const BacklogFilterItem: React.FC<BacklogFilterItemProps> = ({ option, tagProps, index }: BacklogFilterItemProps) => {
  return (
    <Chip
      variant='outlined'
      icon={option.label === OptionLabel.ItemType ? getItemTypeIcon(option.name) : <></>}
      label={option.name}
      {...tagProps({ index })}
    />
  );
};
