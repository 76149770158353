import { createSelector } from 'reselect';
import { getDistinctColorArray } from '../../../shared/distinct-colors';
import { SPRINT_DATE_FORMAT } from '../../planning.constants';
import { TeamId } from '../../planning.model';
import {
  selectFutureSprintsForSelectedTeams,
  selectProductBacklogWithPredictedSprintForSelectedTeams,
  SprintExtended,
  BacklogItemPredictedSprint,
} from '../../planning-calc.selectors';
import { selectSelectedTeams, sortBacklogItemsByRank, selectAllTeams, selectActiveTeam } from '../../planning.selectors';
import { Team, ObjectMap } from '../../planning.state';
import { RoadmapData, RoadmapError, RoadmapSprint, RoadmapTeam } from './roadmap-widget.model';

export const selectRoadmapData = createSelector(
  selectAllTeams,
  selectSelectedTeams,
  selectActiveTeam,
  selectFutureSprintsForSelectedTeams,
  selectProductBacklogWithPredictedSprintForSelectedTeams,
  (
    allTeams: Team[],
    selectedTeams: Team[],
    activeTeam: Team,
    futureSprints: ObjectMap<TeamId, SprintExtended[]>,
    backlogItems: ObjectMap<TeamId, BacklogItemPredictedSprint[]>,
  ) => {
    return calcRoadmapData(allTeams, selectedTeams, activeTeam, futureSprints, backlogItems);
  },
);

function calcRoadmapData(
  allTeams: Team[],
  selectedTeams: Team[],
  activeTeam: Team,
  futureSprints: ObjectMap<TeamId, SprintExtended[]>,
  backlogItems: ObjectMap<TeamId, BacklogItemPredictedSprint[]>,
): RoadmapData {
  if (!allTeams || allTeams.length < 1) {
    return generateEmptyRoadmapData(RoadmapError.NoTeamFound);
  }
  if (!activeTeam) {
    return generateEmptyRoadmapData(RoadmapError.NoActiveTeam);
  }
  const roadmapTeams: RoadmapTeam[] = calcRoadmapTeams(allTeams, selectedTeams, activeTeam);
  if (!selectedTeams || selectedTeams.length < 1) {
    return generateRoadmapData(roadmapTeams, [], RoadmapError.NoTeamSelected);
  }
  const roadmapSprints: RoadmapSprint[] = calcRoadmapSprints(roadmapTeams, futureSprints, backlogItems);
  const sprintMismatchResult = verifyRoadmapSprintMismatch(selectedTeams, roadmapTeams, roadmapSprints);
  if (!sprintMismatchResult.mismatch) {
    const roadmapSprintsWithNoEpic = roadmapSprints.filter(roadmapSprint => roadmapSprint.epics.length === 0);
    if (roadmapSprints.length === roadmapSprintsWithNoEpic.length) {
      return generateRoadmapData(roadmapTeams, roadmapSprints, RoadmapError.MissingEpicsSprintsVelocity);
    }
  }
  return generateRoadmapData(
    roadmapTeams,
    sprintMismatchResult.verifiedRoadmapSprints,
    sprintMismatchResult.mismatch ? RoadmapError.SprintMismatch : undefined,
  );
}

function calcRoadmapTeams(allTeams: Team[], selectedTeams: Team[], activeTeam: Team): RoadmapTeam[] {
  const selectedTeamIds = selectedTeams.map(selectedTeam => selectedTeam.id);
  const teamColors = getDistinctColorArray(0, allTeams.length);
  const activeRoadmapTeam = createRoadmapTeam(activeTeam, true, isTeamSelected(activeTeam, selectedTeamIds), teamColors[0]);
  const availableRoadmapTeams: RoadmapTeam[] = [];
  let idxColor = 1;
  const allTeamsSorted = allTeams.sort((team1, team2) => team1.name.localeCompare(team2.name));
  allTeamsSorted.forEach(team => {
    const isActiveTeam: boolean = team.id === activeTeam.id;
    const roadmapTeam = isActiveTeam
      ? activeRoadmapTeam
      : createRoadmapTeam(team, false, isTeamSelected(team, selectedTeamIds), teamColors[idxColor]);
    availableRoadmapTeams.push(roadmapTeam);
    idxColor = isActiveTeam ? idxColor : idxColor + 1;
  });
  const selectedRoadmapTeams: RoadmapTeam[] = [];
  selectedTeamIds.forEach(teamId => {
    const selectedRoadmapTeam =
      teamId === activeTeam.id ? activeRoadmapTeam : (availableRoadmapTeams.find(roadmapTeam => roadmapTeam.id === teamId) as RoadmapTeam);
    selectedRoadmapTeams.push(selectedRoadmapTeam);
    availableRoadmapTeams.splice(
      availableRoadmapTeams.findIndex(team => team.id === selectedRoadmapTeam.id),
      1,
    );
  });
  return [...selectedRoadmapTeams, ...availableRoadmapTeams];
}

function isTeamSelected(team: Team, selectedTeams: string[]): boolean {
  return selectedTeams.filter(teamId => teamId === team.id).length > 0;
}

function createRoadmapTeam(team: Team, active: boolean, selected: boolean, color: string) {
  return {
    id: team.id,
    name: team.name,
    color: color,
    active: active,
    selected: selected,
    mismatch: false,
  } as RoadmapTeam;
}

function calcRoadmapSprints(
  roadmapTeams: RoadmapTeam[],
  futureSprints: ObjectMap<TeamId, SprintExtended[]>,
  backlogItems: ObjectMap<TeamId, BacklogItemPredictedSprint[]>,
): RoadmapSprint[] {
  if (!futureSprints || Object.values(futureSprints).length < 1) {
    return [];
  }
  const roadmapSprints: RoadmapSprint[] = [];
  const teamIds = Object.keys(futureSprints);
  for (let i = 0; i < teamIds.length; i++) {
    let teamId = teamIds[i];
    const roadmapTeam = roadmapTeams.find(rmt => rmt.id === teamId && rmt.selected);
    if (!roadmapTeam) {
      continue;
    }
    const lastBacklogItemsOfEpicsSorted = getLastBacklogItemsForEpicLabelsSorted(backlogItems[teamId]);
    const teamSprints = futureSprints[teamId];
    teamSprints.forEach(teamSprint => {
      const sprintBacklogItems = lastBacklogItemsOfEpicsSorted.filter(
        backlogItem => backlogItem && teamSprint.id === backlogItem.medSprintId,
      );
      const sprintTimeframe = calcSprintTimeframe(teamSprint);
      let roadmapSprint = roadmapSprints.find(sprint => sprint.name === teamSprint.sprintName && sprint.timeframe === sprintTimeframe);
      if (!roadmapSprint) {
        roadmapSprint = createInitialRoadmapSprint(teamSprint, sprintTimeframe);
        roadmapSprints.push(roadmapSprint);
      }
      roadmapSprint.teams.push(teamId);
      if (teamSprint.isRelease) {
        roadmapSprint.releases.push({ team: roadmapTeam.name, color: roadmapTeam.color });
      }
      sprintBacklogItems.forEach(spbi => {
        roadmapSprint?.epics.push({
          selectedTeamIndex: i,
          label: spbi.epicName,
          color: roadmapTeam.color,
        });
      });
    });
  }
  return sortRoadmapSprints(roadmapSprints);
}

function createInitialRoadmapSprint(sprint: SprintExtended, timeframe: string): RoadmapSprint {
  return {
    id: sprint.id,
    name: sprint.sprintName,
    startDate: sprint.startDate,
    endDate: sprint.endDate,
    timeframe: timeframe,
    releases: [],
    teams: [],
    epics: [],
  };
}

function calcSprintTimeframe(sprint: SprintExtended): string {
  return [sprint.startDate.format(SPRINT_DATE_FORMAT), sprint.endDate.format(SPRINT_DATE_FORMAT)].join(' - ');
}

function getLastBacklogItemsForEpicLabelsSorted(backlogItems: BacklogItemPredictedSprint[]): BacklogItemPredictedSprint[] {
  if (!backlogItems || backlogItems.length < 1) {
    return [];
  }
  const epicLabels: string[] = getDistinctEpicLabels(backlogItems);
  const lastFinishedBacklogItemsOfEpic = epicLabels.map(label => getLastBacklogItemWithEpicLabel(label, backlogItems));
  return sortBacklogItemsByRank(lastFinishedBacklogItemsOfEpic);
}

function getLastBacklogItemWithEpicLabel(label: string, backlogItems: BacklogItemPredictedSprint[]): BacklogItemPredictedSprint {
  const backlogItemsWithLabel = backlogItems.filter(backlogItem => backlogItem.epicName === label && backlogItem.medSprintId !== '');
  return backlogItemsWithLabel[backlogItemsWithLabel.length - 1];
}

function getDistinctEpicLabels(backlogItems: BacklogItemPredictedSprint[]): string[] {
  let backlogItemsWithLabel = backlogItems.filter(backlogItem => backlogItem.epicName);
  let distinctLabels: string[] = [];
  backlogItemsWithLabel.forEach(backlogItem => {
    if (!distinctLabels.find(epicLabel => epicLabel === backlogItem.epicName)) {
      distinctLabels.push(backlogItem.epicName);
    }
  });
  return distinctLabels.sort((label1, label2) => label1.localeCompare(label2));
}

function sortRoadmapSprints(roadmapSprints: RoadmapSprint[]): RoadmapSprint[] {
  return roadmapSprints.sort((sprint1, sprint2) => sprint1.startDate.diff(sprint2.startDate));
}

function generateRoadmapData(roadmapTeams: RoadmapTeam[], roadmapSprints: RoadmapSprint[], error?: RoadmapError): RoadmapData {
  return {
    teams: roadmapTeams,
    sprints: roadmapSprints,
    error,
  };
}

function verifyRoadmapSprintMismatch(
  selectedTeams: Team[],
  roadmapTeams: RoadmapTeam[],
  roadmapSprints: RoadmapSprint[],
): { mismatch: boolean; verifiedRoadmapSprints: RoadmapSprint[] } {
  const numberOfSelectedTeams = selectedTeams.length;
  if (numberOfSelectedTeams < 2) {
    return { mismatch: false, verifiedRoadmapSprints: roadmapSprints };
  }
  const selectedRoadmapTeams = selectedTeams
    .map(selectedTeam => roadmapTeams.find(roadmapTeam => selectedTeam.id === roadmapTeam.id))
    .filter(team => team !== undefined) as RoadmapTeam[];
  const sprintsOfFirstSelectedTeam = roadmapSprints.filter(
    rms => rms.teams.find(team => team === selectedRoadmapTeams[0].id) !== undefined,
  );
  const furtherSelectedTeams = selectedRoadmapTeams.slice(1);
  const verifiedRoadmapSprints = [];
  let mismatch = false;
  for (let sprint of sprintsOfFirstSelectedTeam) {
    verifiedRoadmapSprints.push(sprint);
    const teamsNotInSprint = furtherSelectedTeams.filter(selectedTeam => sprint.teams.find(team => team === selectedTeam.id) === undefined);
    if (teamsNotInSprint.length > 0) {
      mismatch = true;
    }
    teamsNotInSprint.forEach(team => (team.mismatch = true));
  }
  return { mismatch: mismatch, verifiedRoadmapSprints: verifiedRoadmapSprints };
}

function generateEmptyRoadmapData(error: RoadmapError): RoadmapData {
  return {
    teams: [],
    sprints: [],
    error,
  };
}
