import React, { useCallback } from 'react';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Moment } from 'moment';
import { DATEFORMAT } from '../planning.constants';

interface SprintDateFieldProps {
  onAccept?: (date: Moment) => void;
  onChange?: (date: Moment) => void;
  value?: Moment;
  keyboard?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const SprintDateField: React.FC<SprintDateFieldProps> = ({ onChange, value, onAccept, keyboard, ...props }) => {
  const onChangeDate = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date?.isValid) return;
      if (onAccept) onAccept(date);
    },
    [onAccept],
  );

  if (keyboard) {
    return <KeyboardDatePicker autoOk variant='inline' format={DATEFORMAT} onChange={onChangeDate} value={value} {...props} />;
  } else {
    return <DatePicker autoOk variant='inline' format={DATEFORMAT} onChange={onChangeDate} value={value} {...props} />;
  }
};
