import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { VersionInformationDto, FeatureOptionsDto } from './app.model';
import { environment } from './environment';

export interface IAppService {
  getVersion(): Observable<VersionInformationDto>;
  getFeatureOptions(): Observable<FeatureOptionsDto>;
}

export class AppService {
  public getVersion(): Observable<VersionInformationDto> {
    return ajax.getJSON<VersionInformationDto>(`${environment.baseUrl}/version`);
  }

  public getFeatureOptions(): Observable<FeatureOptionsDto> {
    return ajax.getJSON<FeatureOptionsDto>(`${environment.baseUrl}/config/features`);
  }
}
