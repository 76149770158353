import { makeStyles, Theme } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';
import { History } from 'history';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoutes } from './app-routes.component';
import { initializePublicData } from './app.actions';
import useMountEffect from './shared/use-mount-effect';
import { Header, SideBar } from './navigation';
import { selectIsAuthenticated, selectUserRole } from './auth';
import { Notifier, setFirstDayOfWeekISO } from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  app: {
    background: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    height: '100%',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

interface AppProps {
  history: History;
}

export default function App({ history, ...rest }: AppProps): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const classes = useStyles(rest);

  useMountEffect(() => {
    dispatch(initializePublicData());
    setFirstDayOfWeekISO();
  });

  return (
    <div className={classes.app}>
      <Router history={history}>
        <Notifier />
        {isAuthenticated && <Header />}
        {isAuthenticated && <SideBar history={history} userRole={userRole} />}
        <main className={classes.content}>
          {isAuthenticated && <div className={classes.toolbar} />}
          <AppRoutes />
        </main>
      </Router>
    </div>
  );
}
