import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Release } from '../planning.state';

interface SprintOptionsMenuProps {
  comments?: string;
  release?: Release;
  onClickComment: (comments?: string) => void;
  onClickRelease: (release?: Release) => void;
  onClickDelete: () => void;
}

export const SprintOptionsMenu: React.FC<SprintOptionsMenuProps> = ({
  comments,
  release,
  onClickComment,
  onClickRelease,
  onClickDelete,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize='small' />
      </IconButton>
      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onClickComment(comments);
          }}
        >
          {comments ? <>Edit</> : <>Add</>} Comment
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClickRelease(release);
          }}
        >
          {release ? <>Edit</> : <>Create</>} Release
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClickDelete();
          }}
        >
          Delete Sprint
        </MenuItem>
      </Menu>
    </>
  );
};
