import React from 'react';
import { Grid, Button } from '@material-ui/core';
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';
import { useSelector, useDispatch } from 'react-redux';
import { deleteTimelineItemForTeam } from '../planning.actions';
import { generateNotificationKey, generateWorkItemBaseLink, PageLayout, useToggleState } from '../../shared';
import { TimelineEventDialog } from '.';
import { TimelineItem, TimelineDay, selectTimelineItems, Team, selectActiveTeam } from '..';

export const TimelinePage: React.FC = () => {
  const timelineItems: TimelineItem[] = useSelector(selectTimelineItems);
  const activeTeam: Team = useSelector(selectActiveTeam);
  const issueBaseLink = generateWorkItemBaseLink(activeTeam.config.workItemBaseLink);

  const dispatch = useDispatch();
  const confirm = useConfirm();

  const [showNewEventDialog, toggleShowNewEventDialog] = useToggleState();

  const groupTimelineItemsByCreatedAt = (xs: TimelineItem[]): any => {
    return xs.reduce(function (object: any, x: TimelineItem) {
      (object[x['createdAt'].clone().startOf('day').toISOString()] =
        object[x['createdAt'].clone().startOf('day').toISOString()] || []).push(x);
      return object;
    }, {});
  };

  const deleteItem = (item: TimelineItem) => {
    confirm({
      title: 'Delete Timeline Item',
      description: 'Do you really want to delete this item? This action cannot be reverted.',
      confirmationText: 'Delete',
      dialogProps: {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
      },
    })
      .then(() => {
        dispatch(deleteTimelineItemForTeam(activeTeam.id, item, generateNotificationKey()));
      })
      .catch(() => {
        // Ignore
      });
  };

  const groupedItems = groupTimelineItemsByCreatedAt(timelineItems);

  return (
    <PageLayout
      title='Timeline'
      actions={
        <Grid item>
          <Button variant='contained' color='primary' onClick={() => toggleShowNewEventDialog()}>
            Add item
          </Button>
        </Grid>
      }
    >
      <>
        {Object.keys(groupedItems)
          .sort()
          .reverse()
          .map((val: string) => (
            <TimelineDay key={val} date={moment(val)} events={groupedItems[val]} onDelete={deleteItem} issueBaseLink={issueBaseLink} />
          ))}
      </>

      <TimelineEventDialog open={showNewEventDialog} onClose={toggleShowNewEventDialog} team={activeTeam} />
    </PageLayout>
  );
};
