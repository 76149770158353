import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton, Button } from '@material-ui/core';
import { useGetErrorMessageCallback, SpinnerOverlay } from '../../shared';
import { ErrorState } from '../auth.state';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    paddingBottom: theme.spacing(3),
  },
}));

export type FormData = {
  email: string;
  password: string;
};

interface Props {
  externalError?: ErrorState;
  authenticationPending: boolean;
  onSubmit: (value: FormData) => void;
}

export const LocalLogin: React.FC<Props> = ({ externalError, onSubmit, authenticationPending, ...rest }) => {
  const classes = useStyles(rest);
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, register, errors } = useForm<FormData>();
  const getErrorMessageForField = useGetErrorMessageCallback(errors);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id='email'
        name='email'
        type='text'
        label='Email'
        required
        fullWidth
        variant='outlined'
        className={classes.formControl}
        inputRef={register({
          required: 'The Email field is required.',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Invalid email address',
          },
        })}
        helperText={getErrorMessageForField('email')}
        error={!!getErrorMessageForField('email')}
        data-cy='email'
      />
      <TextField
        id='password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        label='Password'
        required
        fullWidth
        variant='outlined'
        className={classes.formControl}
        inputRef={register({
          required: 'The Password field is required.',
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={getErrorMessageForField('password')}
        error={!!getErrorMessageForField('password')}
        data-cy='password'
      />
      <SpinnerOverlay
        button={
          <Button
            id='localLogin'
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            disabled={authenticationPending}
            data-cy='localLogin'
          >
            SIGN IN
          </Button>
        }
        show={authenticationPending}
      />
    </form>
  );
};
