import { User } from 'oidc-client';
import { createAction } from '@reduxjs/toolkit';
import { CallHistoryMethodAction } from 'redux-first-history/src/actions';
import { RequestError } from '../shared';
import { InitializePrivateDataAction } from '../app.actions';
import { StateUser, RegisterUser } from './auth.state';
import { ExternalProviderDto, LoginInfoDto, RedirectDto, RoleDto, UserDto, LogoutInfoDto, LoggedOutInfoDto } from './auth.model';
import { OidcConfig } from './oidc-config';

export const setUser = createAction('[AUTH] SET_USER', (user: User) => ({
  payload: {
    user,
  },
}));
export type SetUserAction = ReturnType<typeof setUser>;

export const loadLoginInfo = createAction('[AUTH] LOAD_LOGIN_INFO');
export type LoadLoginInfoAction = ReturnType<typeof loadLoginInfo>;

export const loadLoginInfoSucceeded = createAction('[AUTH] LOAD_LOGIN_INFO_SUCCEEDED', (info: LoginInfoDto) => ({
  payload: {
    info,
  },
}));
export type LoadLoginInfoSucceededAction = ReturnType<typeof loadLoginInfoSucceeded>;

export const loadLoginInfoFailed = createAction('[AUTH] LOAD_LOGIN_INFO_FAILED', (error: RequestError) => ({
  payload: {
    error,
  },
}));
export type LoadLoginInfoFailedAction = ReturnType<typeof loadLoginInfoFailed>;

export const loadOidcConfigInfo = createAction('[AUTH] OIDC_CONFIG_INFO');
export type LoadOidcConfigAction = ReturnType<typeof loadOidcConfigInfo>;

export const loadOidcConfigSucceeded = createAction('[AUTH] OIDC_CONFIG_INFO_SUCCEEDED', (config: OidcConfig) => ({
  payload: {
    config,
  },
}));
export type LoadOidcConfigSucceededAction = ReturnType<typeof loadOidcConfigSucceeded>;

export const loadOidcConfigFailed = createAction('[AUTH] OIDC_CONFIG_INFO_FAILED', (error: RequestError) => ({
  payload: {
    error,
  },
}));
export type LoadOidcConfigFailedAction = ReturnType<typeof loadOidcConfigFailed>;

export const login = createAction('[AUTH] LOGIN');
export type LoginAction = ReturnType<typeof login>;

export const completeLogin = createAction('[AUTH] COMPLETE_LOGIN', (url: string) => ({
  payload: {
    url,
  },
}));
export type CompleteLoginAction = ReturnType<typeof completeLogin>;

export const loginWithEmailPassword = createAction(
  '[AUTH] LOGIN_WITH_EMAIL_PASSWORD',
  (email: string, password: string, returnUrl: string, notificationKey: number) => ({
    payload: {
      email,
      password,
      returnUrl,
      notificationKey,
    },
  }),
);
export type LoginWithEmailPasswordAction = ReturnType<typeof loginWithEmailPassword>;

export const loginWithEmailPasswordSucceeded = createAction('[AUTH] LOGIN_WITH_EMAIL_PASSWORD_SUCCEEDED', (redirect: RedirectDto) => ({
  payload: {
    redirect,
  },
}));
export type LoginWithEmailPasswordSucceededAction = ReturnType<typeof loginWithEmailPasswordSucceeded>;

export const loginWithEmailPasswordFailed = createAction('[AUTH] LOGIN_WITH_EMAIL_PASSWORD_FAILED', (error: RequestError) => ({
  payload: {
    error,
  },
}));
export type LoginWithEmailPasswordFailedAction = ReturnType<typeof loginWithEmailPasswordFailed>;

export const loginWithExternalProvider = createAction(
  '[AUTH] LOGIN_WITH_EXTERNAL_PROVIDER',
  (applicationUrl: string, provider: ExternalProviderDto, returnUrl: string) => ({
    payload: {
      applicationUrl,
      provider,
      returnUrl,
    },
  }),
);
export type LoginWithExternalProviderAction = ReturnType<typeof loginWithExternalProvider>;

export const loadAllUsers = createAction('[PLANNING] LOAD_ALL_USERS');
export type LoadAllUsersAction = ReturnType<typeof loadAllUsers>;

export const loadAllUsersSucceeded = createAction('[PLANNING] LOAD_ALL_USERS_SUCCEEDED', (users: UserDto[]) => ({
  payload: {
    users,
  },
}));
export type LoadAllUsersSucceededAction = ReturnType<typeof loadAllUsersSucceeded>;

export const loadAllUsersFailed = createAction('[PLANNING] LOAD_ALL_USERS_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type LoadAllUsersFailedAction = ReturnType<typeof loadAllUsersFailed>;

export const loadAllRoles = createAction('[PLANNING] LOAD_ALL_ROLES');
export type LoadAllRolesAction = ReturnType<typeof loadAllRoles>;

export const loadAllRolesSucceeded = createAction('[PLANNING] LOAD_ALL_ROLES_SUCCEEDED', (roles: RoleDto[]) => ({
  payload: {
    roles,
  },
}));
export type LoadAllRolesSucceededAction = ReturnType<typeof loadAllRolesSucceeded>;

export const loadAllRolesFailed = createAction('[PLANNING] LOAD_ALL_ROLES_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type LoadAllRolesFailedAction = ReturnType<typeof loadAllRolesFailed>;

export const logout = createAction('[AUTH] LOGOUT');
export type LogoutAction = ReturnType<typeof logout>;

export const loadLogoutInfo = createAction('[AUTH] LOAD_LOGOUT_INFO', (logoutId: string) => ({
  payload: {
    logoutId,
  },
}));
export type LoadLogoutInfo = ReturnType<typeof loadLogoutInfo>;

export const loadLogoutInfoSucceeded = createAction(
  '[AUTH] LOAD_LOGOUT_INFO_SUCCEEDED',
  (logoutInfo: LogoutInfoDto | LoggedOutInfoDto) => ({
    payload: {
      logoutInfo,
    },
  }),
);
export type LoadLogoutInfoSucceeded = ReturnType<typeof loadLogoutInfoSucceeded>;

export const loadLogoutInfoFailed = createAction('[AUTH] LOAD_LOGOUT_INFO_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type LoadLogoutInfoFailed = ReturnType<typeof loadLogoutInfoFailed>;

export const completeLogout = createAction('[AUTH] COMPLETE_LOGOUT', (logoutId: string) => ({
  payload: {
    logoutId,
  },
}));
export type completeLogout = ReturnType<typeof completeLogout>;

export const changePassword = createAction(
  '[AUTH] CHANGE_PASSWORD',
  (currentPassword: string, newPassword: string, notificationKey: number) => ({
    payload: {
      currentPassword,
      newPassword,
      notificationKey,
    },
  }),
);
export type ChangePasswordAction = ReturnType<typeof changePassword>;

export const changePasswordSucceeded = createAction('[AUTH] CHANGE_PASSWORD_SUCCEEDED', (response: any) => ({
  payload: {
    response,
  },
}));
export type ChangePasswordSucceededAction = ReturnType<typeof changePasswordSucceeded>;

export const changePasswordFailed = createAction('[AUTH] CHANGE_PASSWORD_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type ChangePasswordFailedAction = ReturnType<typeof changePasswordFailed>;

export const changePasswordCanceled = createAction('[AUTH] CHANGE_PASSWORD_CANCELED');
export type ChangePasswordCanceledAction = ReturnType<typeof changePasswordCanceled>;

export const createUser = createAction('[PLANNING] CREATE_USER', (user: RegisterUser, notificationKey: number) => ({
  payload: {
    user,
    notificationKey,
  },
}));
export type CreateUserAction = ReturnType<typeof createUser>;

export const createUserSucceeded = createAction('[PLANNING] CREATE_USER_SUCCEEDED', (user: UserDto) => ({
  payload: {
    user,
  },
}));
export type CreateUserSucceededAction = ReturnType<typeof createUserSucceeded>;

export const createUserFailed = createAction('[PLANNING] CREATE_USER_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type CreateUserFailedAction = ReturnType<typeof createUserFailed>;

export const updateUser = createAction('[PLANNING] UPDATE_USER', (user: StateUser, notificationKey: number) => ({
  payload: {
    user,
    notificationKey,
  },
}));
export type UpdateUserAction = ReturnType<typeof updateUser>;

export const updateUserSucceeded = createAction('[PLANNING] UPDATE_USER_SUCCEEDED', (response: any) => ({
  payload: {
    response,
  },
}));
export type UpdateUserSucceededAction = ReturnType<typeof updateUserSucceeded>;

export const updateUserFailed = createAction('[PLANNING] UPDATE_USER_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type UpdateUserFailedAction = ReturnType<typeof updateUserFailed>;

export const deleteUser = createAction('[PLANNING] DELETE_USER', (user: StateUser, notificationKey: number) => ({
  payload: {
    user,
    notificationKey,
  },
}));
export type DeleteUserAction = ReturnType<typeof deleteUser>;

export const deleteUserSucceeded = createAction('[PLANNING] DELETE_USER_SUCCEEDED', (response: any) => ({
  payload: {
    response,
  },
}));
export type DeleteUserSucceededAction = ReturnType<typeof deleteUserSucceeded>;

export const deleteUserFailed = createAction('[PLANNING] DELETE_USER_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type DeleteUserFailedAction = ReturnType<typeof deleteUserFailed>;

export const processUserCanceled = createAction('[PLANNING] PROCESS_USER_CANCELED');
export type ProcessUserCanceledAction = ReturnType<typeof processUserCanceled>;

export type AuthActionTypes =
  | SetUserAction
  | LoadLoginInfoAction
  | LoadLoginInfoSucceededAction
  | LoadLoginInfoFailedAction
  | LoadOidcConfigAction
  | LoadOidcConfigSucceededAction
  | LoadOidcConfigFailedAction
  | LoadAllUsersAction
  | LoadAllUsersSucceededAction
  | LoadAllUsersFailedAction
  | LoadAllRolesAction
  | LoadAllRolesSucceededAction
  | LoadAllRolesFailedAction
  | LoginAction
  | CompleteLoginAction
  | LoginWithEmailPasswordAction
  | LoginWithEmailPasswordSucceededAction
  | LoginWithEmailPasswordFailedAction
  | LoginWithExternalProviderAction
  | CallHistoryMethodAction
  | InitializePrivateDataAction
  | LogoutAction
  | LoadLogoutInfo
  | LoadLogoutInfoSucceeded
  | LoadLogoutInfoFailed
  | ChangePasswordAction
  | ChangePasswordCanceledAction
  | ChangePasswordSucceededAction
  | ChangePasswordFailedAction
  | CreateUserAction
  | CreateUserSucceededAction
  | CreateUserFailedAction
  | UpdateUserAction
  | UpdateUserSucceededAction
  | UpdateUserFailedAction
  | DeleteUserAction
  | DeleteUserSucceededAction
  | DeleteUserFailedAction
  | ProcessUserCanceledAction;
