import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { FormFileUpload, FormFileUploadValue } from '../../shared';
import { BacklogUpdateAuthProps } from './backlog-update-auth.component';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(3, 3, 3, 0),
  },
}));

export const BacklogUpdateAuthExcel: React.FC<BacklogUpdateAuthProps> = ({ errorMessageCallback, pending }) => {
  const classes = useStyles();
  const sampleExcelFile = require('../../assets/file/ExcelImportTemplate.xlsx');

  return (
    <>
      <Box display='flex' className={classes.formControl} key={'excelUpload'}>
        <FormFileUpload
          accept='.xlsx'
          label='Upload Excel File'
          name='excelSource'
          disabled={pending}
          validation={{
            validate: (file: FormFileUploadValue) => !!file.filename || 'Excel file cannot be empty',
          }}
          errorMessage={errorMessageCallback('excelSource')}
        />
      </Box>
      <Box display='flex' className={classes.formControl} key={'excelDownload'}>
        <Button
          variant='contained'
          disabled={pending}
          color='primary'
          startIcon={<CloudDownload />}
          onClick={() => window.open(sampleExcelFile)}
        >
          Download sample (.xlsx)
        </Button>
      </Box>
    </>
  );
};
