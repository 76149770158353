import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../environment';
import { IRestClient } from '../shared';
import {
  TeamDto,
  SprintDto,
  BacklogItemDto,
  ScenarioDto,
  TeamId,
  ChartViewSettingsDto,
  TimelineItemDto,
  UpdateBacklogOptions,
  SprintConfigurationDto,
} from './planning.model';
import { Team, Sprint, BacklogItem, TimelineItem, AddTimelineItem } from './planning.state';
import {
  convertTeamToTeamDto,
  convertTeamToCopyTeamOptionsDto,
  convertSprintToSprintDto,
  convertCreateTimelineItemToCreateTimelineItemDto,
} from './planning.converters';

export interface IPlanningService {
  getTeams(): Observable<TeamDto[]>;
  getSprints(teamId: TeamId): Observable<SprintDto[]>;
  getBacklogItems(teamId: TeamId): Observable<BacklogItemDto[]>;
  getChartViewSettings(teamId: TeamId): Observable<ChartViewSettingsDto[]>;
  updateChartViewSettings(teamId: TeamId, chartViewSettings: ChartViewSettingsDto, chartType: string): Observable<ChartViewSettingsDto[]>;
  getScenarios(teamId: TeamId): Observable<ScenarioDto[]>;
  getTimelineItems(teamId: TeamId): Observable<TimelineItemDto[]>;
  updateBacklog(teamId: TeamId, updateBacklogOptions: UpdateBacklogOptions): Observable<any>;
  updateBacklogOAuth(teamId: TeamId, keepOldBacklogItems: boolean, defaultStoryPoints?: number): Observable<any>;
  updateBacklogExcel(teamId: TeamId, file: any, keepOldBacklogItems: boolean, defaultStoryPoints?: number): Observable<any>;
  updateBacklogCsv(teamId: TeamId, file: any, keepOldBacklogItems: boolean, defaultStoryPoints?: number): Observable<any>;
  createTeam(team: Team, firstSprintStartDate: Moment): Observable<TeamDto>;
  copyTeam(team: Team, name: string): Observable<TeamDto>;
  updateTeam(team: Team): Observable<TeamDto>;
  deleteTeam(teamId: TeamId): Observable<TeamId>;
  updateSprintConfiguration(team: Team, sprints: Sprint[]): Observable<SprintConfigurationDto>;
  moveBacklogItem(teamId: string, backlog: BacklogItem[]): Observable<BacklogItem[]>;
  updateDeliverySprintId(team: Team): Observable<TeamDto>;
  updateSelectedTeamIds(team: Team): Observable<TeamDto>;
  createTimelineItem(teamId: TeamId, timelineItem: AddTimelineItem): Observable<TimelineItemDto>;
  deleteTimelineItem(teamId: TeamId, timelineItem: TimelineItem): Observable<TeamId>;
}

export class PlanningService implements IPlanningService {
  constructor(private ajaxService: IRestClient) {}

  public getTeams(): Observable<TeamDto[]> {
    return this.ajaxService.get<TeamDto[]>(`${environment.baseUrl}/teams`);
  }

  public getSprints(teamId: TeamId): Observable<SprintDto[]> {
    return this.ajaxService.get<SprintDto[]>(`${environment.baseUrl}/teams/${teamId}/sprints`);
  }

  public getBacklogItems(teamId: TeamId): Observable<BacklogItemDto[]> {
    return this.ajaxService.get<BacklogItemDto[]>(`${environment.baseUrl}/teams/${teamId}/backlogitem`);
  }

  public getChartViewSettings(teamId: TeamId): Observable<ChartViewSettingsDto[]> {
    return this.ajaxService.get<ChartViewSettingsDto[]>(`${environment.baseUrl}/teams/${teamId}/chartviewsettings`);
  }

  public getScenarios(teamId: TeamId): Observable<ScenarioDto[]> {
    return this.ajaxService.get<ScenarioDto[]>(`${environment.baseUrl}/teams/${teamId}/scenarios`);
  }

  public getTimelineItems(teamId: TeamId): Observable<TimelineItemDto[]> {
    return this.ajaxService.get<TimelineItemDto[]>(`${environment.baseUrl}/teams/${teamId}/timeline`);
  }

  public updateBacklog(
    teamId: TeamId,
    { username, password, keepOldBacklogItems, defaultStoryPoints }: UpdateBacklogOptions,
  ): Observable<TimelineItemDto> {
    const defaultStoryPointsParam = this.getDefaultStoryPointsParam(defaultStoryPoints);
    return this.ajaxService.put<any>(
      `${environment.baseUrl}/teams/${teamId}/Import/backlog?keepOldBacklogItems=${keepOldBacklogItems}${defaultStoryPointsParam}`,
      {
        username,
        password,
      },
    );
  }

  public updateBacklogOAuth(teamId: TeamId, keepOldBacklogItems: boolean, defaultStoryPoints?: number): Observable<TimelineItemDto> {
    const defaultStoryPointsParam = this.getDefaultStoryPointsParam(defaultStoryPoints);
    return this.ajaxService.put<any>(
      `${environment.baseUrl}/teams/${teamId}/Import/backlog/oAuth1?keepOldBacklogItems=${keepOldBacklogItems}${defaultStoryPointsParam}`,
      {},
    );
  }

  public updateBacklogExcel(
    teamId: TeamId,
    file: any,
    keepOldBacklogItems: boolean,
    defaultStoryPoints?: number,
  ): Observable<TimelineItemDto> {
    const defaultStoryPointsParam = this.getDefaultStoryPointsParam(defaultStoryPoints);
    return this.ajaxService.putForm<any>(
      `${environment.baseUrl}/teams/${teamId}/Import/excel?keepOldBacklogItems=${keepOldBacklogItems}${defaultStoryPointsParam}`,
      file,
    );
  }
  public updateBacklogCsv(
    teamId: TeamId,
    file: any,
    keepOldBacklogItems: boolean,
    defaultStoryPoints?: number,
  ): Observable<TimelineItemDto> {
    const defaultStoryPointsParam = this.getDefaultStoryPointsParam(defaultStoryPoints);
    return this.ajaxService.putForm<any>(
      `${environment.baseUrl}/teams/${teamId}/Import/jira-csv?keepOldBacklogItems=${keepOldBacklogItems}${defaultStoryPointsParam}`,
      file,
    );
  }

  public updateChartViewSettings(teamId: TeamId, chartViewSettings: ChartViewSettingsDto, chartType: string): Observable<any> {
    return this.ajaxService.put<any>(`${environment.baseUrl}/teams/${teamId}/chartviewsettings/${chartType}`, chartViewSettings);
  }

  public createTeam(team: Team, firstSprintStartDate: Moment): Observable<TeamDto> {
    return this.ajaxService.post<TeamDto>(`${environment.baseUrl}/teams/?startDate=${firstSprintStartDate}`, convertTeamToTeamDto(team));
  }

  public copyTeam(team: Team, name: string): Observable<TeamDto> {
    return this.ajaxService.post<TeamDto>(`${environment.baseUrl}/teams/copy`, convertTeamToCopyTeamOptionsDto(team, name));
  }

  public updateTeam(team: Team): Observable<TeamDto> {
    return this.ajaxService.put<TeamDto>(`${environment.baseUrl}/teams/${team.id}`, convertTeamToTeamDto(team));
  }

  public deleteTeam(teamId: TeamId): Observable<TeamId> {
    return this.ajaxService.delete<TeamId>(`${environment.baseUrl}/teams/${teamId}`);
  }

  public updateSprintConfiguration(team: Team, sprints: Sprint[]): Observable<SprintConfigurationDto> {
    return this.ajaxService.put<SprintConfigurationDto>(`${environment.baseUrl}/teams/${team.id}/sprints`, {
      useSyntheticVelocity: team.useSyntheticVelocity,
      minVelocity: team.minSyntheticVelocity,
      maxVelocity: team.maxSyntheticVelocity,
      sprints: sprints.map(s => convertSprintToSprintDto(s)),
    });
  }

  public updateDeliverySprintId(team: Team): Observable<TeamDto> {
    return this.ajaxService.put<TeamDto>(`${environment.baseUrl}/teams/${team.id}`, convertTeamToTeamDto(team));
  }

  public updateSelectedTeamIds(team: Team): Observable<TeamDto> {
    return this.ajaxService.put<TeamDto>(`${environment.baseUrl}/teams/${team.id}`, convertTeamToTeamDto(team));
  }

  public moveBacklogItem(teamId: TeamId, backlog: BacklogItem[]): Observable<BacklogItem[]> {
    return this.ajaxService.put<BacklogItem[]>(`${environment.baseUrl}/teams/${teamId}/backlogitem/ranks`, backlog);
  }

  public createTimelineItem(teamId: TeamId, timelineItem: AddTimelineItem): Observable<TimelineItemDto> {
    return this.ajaxService.post<TimelineItemDto>(
      `${environment.baseUrl}/teams/${teamId}/Timeline`,
      convertCreateTimelineItemToCreateTimelineItemDto(timelineItem),
    );
  }

  public deleteTimelineItem(teamId: TeamId, timelineItem: TimelineItem): Observable<TeamId> {
    return this.ajaxService.delete<TeamId>(`${environment.baseUrl}/teams/${teamId}/Timeline/${timelineItem.id}`);
  }

  private getDefaultStoryPointsParam(defaultStoryPoints?: number): string {
    return defaultStoryPoints !== undefined ? `&defaultStoryPoints=${defaultStoryPoints.toString()}` : '';
  }
}
