import React, { useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Box,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Forum, Mail, Group } from '@material-ui/icons';
import moment from 'moment';
import { useForm, Controller, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CommentHookFormField, generateNotificationKey, OutlinedSelect } from '../../shared';
import { DATEFORMAT } from '../planning.constants';
import { theme } from '../../theme';
import { NAME_LIMIT, AddTimelineItem, createTimelineItemForTeam, Team } from '..';
import { ignoreBackdropClick } from '../../shared/ignore-backdrop-click';

interface TimelineEventDialogProps {
  open: boolean;
  onClose: () => void;
  team: Team;
}

const useStyles = makeStyles(() => ({
  dialogField: {
    margin: theme.spacing(1, 0),
  },
}));

export const TimelineEventDialog: React.FC<TimelineEventDialogProps> = ({ open, onClose, team }) => {
  const classes = useStyles();
  const formMethods = useForm<AddTimelineItem>();
  const dispatch = useDispatch();

  const { handleSubmit, errors, control } = formMethods;

  const onSubmit = useCallback(
    timelineItem => {
      onClose();
      if (timelineItem.createdAt.isSame(moment(), 'd')) {
        timelineItem.createdAt = moment();
      }
      dispatch(createTimelineItemForTeam(team.id, timelineItem, generateNotificationKey()));
    },
    [dispatch, onClose, team],
  );

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => ignoreBackdropClick(reason, onClose)}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='dialog-title'>
        <Box display='flex' justifyContent='space-between'>
          Add Timeline Item
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(values => onSubmit(values))} autoComplete='off'>
        <DialogContent dividers>
          <DialogContentText id='dialog-description' tabIndex={-1}></DialogContentText>

          <Controller
            name='type'
            control={control}
            defaultValue={'Mail'}
            as={
              <OutlinedSelect label='Type' name='' errorMessage={''}>
                <MenuItem value={'Mail'} key='mail'>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Mail color='primary' />
                    </Grid>
                    <Grid item>Mail</Grid>
                  </Grid>
                </MenuItem>
                <MenuItem value={'Conversation'} key='conversation'>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Forum color='primary' />
                    </Grid>
                    <Grid item>Conversation</Grid>
                  </Grid>
                </MenuItem>
                <MenuItem value={'Meeting'} key='meeting'>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Group color='primary' />
                    </Grid>
                    <Grid item>Meeting</Grid>
                  </Grid>
                </MenuItem>
              </OutlinedSelect>
            }
          />

          <Controller
            name='header'
            control={control}
            defaultValue={''}
            rules={{ required: 'Please provide a subject.' }}
            as={
              <TextField
                label='Subject *'
                fullWidth
                inputProps={{ maxLength: NAME_LIMIT }}
                className={classes.dialogField}
                variant='outlined'
                error={!!errors.header}
                helperText={errors.header && (errors.header as any).message}
              />
            }
          />

          <Controller
            name='createdAt'
            control={control}
            defaultValue={moment()}
            rules={{ required: 'Please provide an entry date.' }}
            as={
              <KeyboardDatePicker
                label='Entry Date'
                autoOk
                fullWidth
                className={classes.dialogField}
                variant='inline'
                inputVariant='outlined'
                format={DATEFORMAT}
                onChange={() => {}}
                value={() => {}}
                error={!!errors.createdAt}
                helperText={errors.createdAt && (errors.createdAt as any).message}
              />
            }
          />

          <FormContext {...formMethods}>
            <CommentHookFormField fieldName={'body'} rules={{ required: 'Please provide some text.' }} labelText={'Body *'} />
          </FormContext>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color='default'
          >
            Cancel
          </Button>
          <Button variant='contained' color='primary' type='submit'>
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
