import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { selectChangePassword } from '../auth.selectors';
import { changePassword, changePasswordCanceled } from '../auth.actions';
import {
  useGetErrorMessageCallback,
  InfoPopover,
  containsLetterAndNumber,
  useToggleState,
  SpinnerOverlay,
  generateNotificationKey,
} from '../../shared';
import { removeAllNotifications } from '../../app.actions';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(0, 3, 2, 0),
  },
  dialogActions: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  restrictionItem: {
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

interface ChangePasswordProps {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordPage: React.FC<ChangePasswordProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const changePasswordState = useSelector(selectChangePassword);

  const [processFinished, setProcessFinished] = useState<boolean>(true);
  const [showCurrentPassword, setShowCurrentPassword] = useToggleState();
  const [showNewPassword, setShowNewPassword] = useToggleState();
  const [showConfirmPassword, setShowConfirmPassword] = useToggleState();

  const formOptions = {
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  };
  const formMethods = useForm(formOptions);
  const watchCurrentPassword = formMethods.watch('currentPassword');
  const watchNewPassword = formMethods.watch('newPassword');
  const getErrorMessageForField = useGetErrorMessageCallback(formMethods.errors);

  const resetPasswordFieldState = (state: boolean, toggle: () => void) => {
    if (!state) return;
    toggle();
  };

  const resetDialogState = useCallback(() => {
    setProcessFinished(true);
    resetPasswordFieldState(showCurrentPassword, setShowCurrentPassword);
    resetPasswordFieldState(showNewPassword, setShowNewPassword);
    resetPasswordFieldState(showConfirmPassword, setShowConfirmPassword);
  }, [showCurrentPassword, setShowCurrentPassword, showNewPassword, setShowNewPassword, showConfirmPassword, setShowConfirmPassword]);

  const handleSubmit = useCallback(
    (data: any) => {
      const { currentPassword, newPassword } = data;
      setProcessFinished(false);
      dispatch(changePassword(currentPassword, newPassword, generateNotificationKey()));
    },
    [dispatch],
  );

  const handleClose = useCallback(() => {
    resetDialogState();
    dispatch(removeAllNotifications());
    onClose();
  }, [resetDialogState, onClose, dispatch]);

  const onChangePasswordCanceled = useCallback(() => {
    dispatch(changePasswordCanceled());
  }, [dispatch]);

  useEffect(() => {
    if (!changePasswordState || changePasswordState.isPending) return;
    if (changePasswordState.data.success) {
      resetDialogState();
      onClose();
    }
    setProcessFinished(true);
    onChangePasswordCanceled();
  }, [changePasswordState, onChangePasswordCanceled, onClose, resetDialogState]);

  return (
    <Dialog fullWidth open={open} disableEscapeKeyDown aria-labelledby='dialog-title' aria-describedby='dialog-description'>
      {React.useMemo(
        () => (
          <DialogTitle id='dialog-title'>
            <Box display='flex' justifyContent='space-between'>
              Change Password
            </Box>
          </DialogTitle>
        ),
        [],
      )}
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <DialogContent dividers>
          <DialogContentText id='dialog-description' tabIndex={-1}></DialogContentText>
          <FormContext {...formMethods}>
            <TextField
              name='currentPassword'
              type={showCurrentPassword ? 'text' : 'password'}
              label='Current Password *'
              variant='outlined'
              className={classes.formControl}
              inputRef={formMethods.register({
                required: 'The Current Password is required.',
              })}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position='end'>
                    <>
                      <IconButton aria-label='toggle password visibility' onClick={() => setShowCurrentPassword()}>
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
              fullWidth
              helperText={getErrorMessageForField('currentPassword')}
              error={!!getErrorMessageForField('currentPassword')}
              autoComplete='off'
              data-cy='currentPassword'
            />
            <TextField
              name='newPassword'
              type={showNewPassword ? 'text' : 'password'}
              label='New Password *'
              variant='outlined'
              className={classes.formControl}
              inputRef={formMethods.register({
                required: 'The New Password is required.',
                minLength: {
                  value: 8,
                  message: 'Password should contain at least 8 characters (letters and digits).',
                },
                validate: value => {
                  if (!containsLetterAndNumber(value)) {
                    return 'Password should consist of letters and digits.';
                  }
                  if (value === watchCurrentPassword) {
                    return 'Current and New Password are the same.';
                  }
                  return true;
                },
              })}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position='end'>
                    <>
                      <InfoPopover
                        title={'Password'}
                        content={
                          <ul>
                            <li key={'Length'} className={classes.restrictionItem}>
                              <Typography>
                                <b>Length:</b>
                                <br />
                                <b>min. 8</b> / max. 100 characters
                              </Typography>
                            </li>
                            <li key={'Format'} className={classes.restrictionItem}>
                              <Typography>
                                <b>Format:</b>
                                <br />
                                must contain letters <b>and</b> digits
                              </Typography>
                            </li>
                          </ul>
                        }
                        positionEdge={false}
                      />
                      <IconButton aria-label='toggle password visibility' onClick={() => setShowNewPassword()}>
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
              fullWidth
              helperText={getErrorMessageForField('newPassword')}
              error={!!getErrorMessageForField('newPassword')}
              autoComplete='off'
              data-cy='newPassword'
            />
            <TextField
              name='confirmPassword'
              type={showConfirmPassword ? 'text' : 'password'}
              label='Confirm Password *'
              variant='outlined'
              className={classes.formControl}
              inputRef={formMethods.register({
                required: 'The Confirm Password is required.',
                validate: value => value === watchNewPassword || 'New and Confirm Password do not match.',
              })}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position='end'>
                    <>
                      <IconButton aria-label='toggle password visibility' onClick={() => setShowConfirmPassword()}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
              fullWidth
              helperText={getErrorMessageForField('confirmPassword')}
              error={!!getErrorMessageForField('confirmPassword')}
              autoComplete='off'
              data-cy='confirmPassword'
            />
          </FormContext>
        </DialogContent>
        {React.useMemo(
          () => (
            <DialogActions className={classes.dialogActions}>
              <Button onClick={handleClose} color='default'>
                {processFinished ? 'Close' : 'Cancel'}
              </Button>
              <SpinnerOverlay
                button={
                  <Button type='submit' variant='contained' color='primary' disabled={!processFinished}>
                    Change Password
                  </Button>
                }
                show={!processFinished}
              />
            </DialogActions>
          ),
          [classes, handleClose, processFinished],
        )}
      </form>
    </Dialog>
  );
};
