import { useCallback } from 'react';

export function useGetErrorMessageCallback(errors: any) {
  return useCallback(
    (fieldName: string) => {
      let errorMessage = '';
      if (errors) {
        const error = errors[fieldName];

        if (error) {
          errorMessage = error.message || '';
        }
      }
      return errorMessage;
    },
    [errors],
  );
}
