import moment from 'moment';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { ROUNDING_ACCURACY, ISO_DATEFORMAT } from './planning.constants';
import { SprintId, ChartType, TeamId } from './planning.model';
import { Team, BacklogItem, Sprint, ChartViewSettings, ObjectMap, ReleaseCandidate, Release, PlanningState } from './planning.state';
import {
  selectActiveTeam,
  selectSelectedTeams,
  selectProductBacklog,
  selectProductBacklogForSelectedTeams,
  selectSprintsForSelectedTeams,
  selectSprints,
  selectPlanning,
} from './planning.selectors';

export interface SprintExtended extends Sprint {
  isPast: boolean;
  isCurrent: boolean;
  isFirstFuture: boolean;
  relativeVelocity?: number;
}

export interface SprintPredicted extends SprintExtended {
  minPredictedStoryPoints: number;
  medPredictedStoryPoints: number;
  maxPredictedStoryPoints: number;
  [key: string]: any;
}

type VelocityStats = {
  min: number;
  med: number;
  max: number;
};

export interface BacklogItemPredictedSprint extends BacklogItem {
  minSprintId: SprintId;
  medSprintId: SprintId;
  maxSprintId: SprintId;
  [key: string]: any;
}

export interface ExtendedChartViewSettings {
  sprintsInRange: SprintExtended[];
  sprintLabels: string[];
  releaseCandidates: ReleaseCandidate[];
  selectedEpics: string[];
  selectedBeginSprintId: string;
  selectedEndSprintId: string;
}

export interface DataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth?: number;
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, (v1, v2) => {
  return JSON.stringify(v1) === JSON.stringify(v2);
});

export const selectExtendedSprints = createSelector(selectSprints, (sprints: Sprint[]) => {
  return mapSprintsToExtended(sprints);
});

export const selectExtendedSprintsForSelectedTeams = createSelector(
  selectSprintsForSelectedTeams,
  (sprintsForTeams: ObjectMap<TeamId, Sprint[]>) => {
    const extendedSprintsForTeams: ObjectMap<TeamId, SprintExtended[]> = {};
    for (let team of Object.keys(sprintsForTeams)) {
      extendedSprintsForTeams[team] = mapSprintsToExtended(sprintsForTeams[team]);
    }
    return extendedSprintsForTeams;
  },
);

function mapSprintsToExtended(sprints: Sprint[]): SprintExtended[] {
  if (!sprints || sprints.length < 1) {
    return [];
  }
  const dateNow = moment();
  const sprintsExtended: SprintExtended[] = [];
  sprints.forEach((s, i) => {
    let extendedSprint = mapSprintToExtended(s, i === 0 ? undefined : sprintsExtended[i - 1], dateNow);
    sprintsExtended.push(extendedSprint);
  });
  return sprintsExtended;
}

const mapSprintToExtended = (sprint: Sprint, previousSprint: SprintExtended | undefined, dateNow: moment.Moment) => {
  const endDate = moment(sprint.endDate, ISO_DATEFORMAT);
  const startDate = moment(sprint.startDate, ISO_DATEFORMAT);
  const isPast = endDate.isBefore(dateNow, 'day');
  const sprintExtended: SprintExtended = {
    ...sprint,
    isPast: isPast,
    isCurrent: startDate.isSameOrBefore(dateNow, 'day') && endDate.isSameOrAfter(dateNow, 'day'),
    isFirstFuture: previousSprint ? previousSprint && previousSprint.isCurrent : false,
    relativeVelocity: isPast ? calcRelativeVelocity(sprint) : undefined,
  };
  return sprintExtended;
};

export const calcRelativeVelocity = (sprint: Sprint) => {
  const capacity = sprint.personDays;
  return capacity === 0 ? 0 : sprint.achievedStoryPoints / capacity;
};

export const selectFutureSprints = createSelector(selectExtendedSprints, (sprints: SprintExtended[]) => {
  return sprints.filter(s => !s.isPast);
});

export const selectFutureReleaseSprints = createSelector(selectFutureSprints, (sprints: SprintExtended[]) => {
  return sprints?.filter(sprint => sprint.isRelease);
});

export const selectFutureSprintsForSelectedTeams = createSelector(
  selectExtendedSprintsForSelectedTeams,
  (extendedSprints: ObjectMap<TeamId, SprintExtended[]>) => {
    const futureSprints: ObjectMap<TeamId, SprintExtended[]> = {};
    for (let team of Object.keys(extendedSprints)) {
      futureSprints[team] = extendedSprints[team].filter(sprint => !sprint.isPast);
    }
    return futureSprints;
  },
);

export const selectVelocity = createSelector(selectActiveTeam, selectExtendedSprints, (team: Team, sprints: SprintExtended[]) => {
  return calcVelocityStats(team, sprints);
});

export const selectVelocityForSelectedTeams = createSelector(
  selectSelectedTeams,
  selectExtendedSprintsForSelectedTeams,
  (teams: Team[], sprintsForTeams: ObjectMap<TeamId, SprintExtended[]>) => {
    const teamVelocities: ObjectMap<TeamId, VelocityStats> = {};
    teams.forEach(team => {
      teamVelocities[team.id] = calcVelocityStats(team, sprintsForTeams[team.id]);
    });
    return teamVelocities;
  },
);

function calcVelocityStats(team: Team, sprints: SprintExtended[]): VelocityStats {
  if (!team) return { min: 0, max: 0, med: 0 } as VelocityStats;
  if (team.useSyntheticVelocity) {
    return {
      min: team.minSyntheticVelocity,
      med: (+team.minSyntheticVelocity + +team.maxSyntheticVelocity) / 2,
      max: team.maxSyntheticVelocity,
    } as VelocityStats;
  }

  const pastSprints = sprints.filter(s => s.isPast);
  if (!pastSprints || !pastSprints.length) {
    return {
      min: 0,
      med: 0,
      max: 0,
    } as VelocityStats;
  }

  const velocities = pastSprints.map(s => (s.relativeVelocity ? s.relativeVelocity : 0));

  return {
    min: Math.min(...velocities),
    med: getMedian(velocities),
    max: Math.max(...velocities),
  } as VelocityStats;
}

const getMedian = (arr: number[]): number => {
  const mid = Math.floor(arr.length / 2);
  const nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const selectSprintStoryPointStats = createSelector(
  selectFutureSprints,
  selectVelocity,
  (futureSprints: SprintExtended[], velocity: VelocityStats) => {
    return calcStoryPointStats(futureSprints, velocity);
  },
);

export const selectSprintStoryPointStatsForSelectedTeams = createSelector(
  selectFutureSprintsForSelectedTeams,
  selectVelocityForSelectedTeams,
  (futureSprints: ObjectMap<TeamId, SprintExtended[]>, velocities: ObjectMap<TeamId, VelocityStats>) => {
    const sprintStoryPointStats: ObjectMap<TeamId, SprintPredicted[]> = {};
    for (let team of Object.keys(futureSprints)) {
      sprintStoryPointStats[team] = calcStoryPointStats(futureSprints[team], velocities[team]);
    }
    return sprintStoryPointStats;
  },
);

function calcStoryPointStats(futureSprints: SprintExtended[], velocity: VelocityStats): SprintPredicted[] {
  let sprintsWithStoryPointStats = new Array<SprintPredicted>();
  let cumulatedPersonDays = 0;
  futureSprints.forEach(s => {
    cumulatedPersonDays += s.personDays;
    sprintsWithStoryPointStats.push({
      ...s,
      minPredictedStoryPoints: Math.round(velocity.min * cumulatedPersonDays * ROUNDING_ACCURACY) / ROUNDING_ACCURACY,
      medPredictedStoryPoints: Math.round(velocity.med * cumulatedPersonDays * ROUNDING_ACCURACY) / ROUNDING_ACCURACY,
      maxPredictedStoryPoints: Math.round(velocity.max * cumulatedPersonDays * ROUNDING_ACCURACY) / ROUNDING_ACCURACY,
    });
  });
  return sprintsWithStoryPointStats;
}

export const selectProductBacklogWithPredictedSprint = createSelector(
  selectProductBacklog,
  selectSprintStoryPointStats,
  (backlogItems: BacklogItem[], sprints: SprintPredicted[]): BacklogItemPredictedSprint[] => {
    return predictBacklogItemsForSprints(backlogItems, sprints);
  },
);

export const selectProductBacklogWithPredictedSprintForSelectedTeams = createSelector(
  selectProductBacklogForSelectedTeams,
  selectSprintStoryPointStatsForSelectedTeams,
  (
    backlogItems: ObjectMap<TeamId, BacklogItem[]>,
    sprints: ObjectMap<TeamId, SprintPredicted[]>,
  ): ObjectMap<TeamId, BacklogItemPredictedSprint[]> => {
    const backlogItemsPredictedSprints: ObjectMap<TeamId, BacklogItemPredictedSprint[]> = {};
    for (let team of Object.keys(backlogItems)) {
      backlogItemsPredictedSprints[team] = predictBacklogItemsForSprints(backlogItems[team], sprints[team]);
    }
    return backlogItemsPredictedSprints;
  },
);

function predictBacklogItemsForSprints(backlogItems: BacklogItem[], sprints: SprintPredicted[]): BacklogItemPredictedSprint[] {
  const backlogItemsPredictedSprints: BacklogItemPredictedSprint[] = initializeBacklogItemsToPredictedSprint(backlogItems);
  ['min', 'med', 'max'].forEach(type => {
    setPredictedSprintIdForBacklogItems(backlogItemsPredictedSprints, sprints, type);
  });
  return backlogItemsPredictedSprints;
}

function initializeBacklogItemsToPredictedSprint(backlogItems: BacklogItem[]): BacklogItemPredictedSprint[] {
  return backlogItems.map(backlogItem => {
    return {
      ...backlogItem,
      minSprintId: '',
      medSprintId: '',
      maxSprintId: '',
    } as BacklogItemPredictedSprint;
  });
}

function setPredictedSprintIdForBacklogItems(
  backlogItems: BacklogItemPredictedSprint[],
  sprints: SprintPredicted[],
  type: string,
): BacklogItemPredictedSprint[] {
  const backlogItemsPredictedSprints: BacklogItemPredictedSprint[] = [];
  let cumulatedSP = 0;
  let sprintIndex = 0;
  for (let i = 0; i < backlogItems.length; i++) {
    cumulatedSP += backlogItems[i].storyPoints;
    while (sprintIndex < sprints.length && cumulatedSP > sprints[sprintIndex][`${type}PredictedStoryPoints`]) {
      sprintIndex++;
    }
    if (sprints[sprintIndex]) {
      backlogItems[i][`${type}SprintId`] = sprints[sprintIndex].id;
    }
  }

  return backlogItemsPredictedSprints;
}

export const selectChartViewSettingsFactory = (type: ChartType) =>
  createSelector(selectPlanning, (planningState: PlanningState) => {
    if (type === ChartType.BurnUp) {
      return planningState.burnup ? planningState.burnup.data : undefined;
    } else {
      return planningState.burndown ? planningState.burndown.data : undefined;
    }
  });

export const selectExtendedChartViewSettingsFactory = (type: ChartType) => {
  return createDeepEqualSelector(
    selectExtendedSprints,
    selectChartViewSettingsFactory(type),
    (sprints: SprintExtended[], settings?: ChartViewSettings) => {
      if (!settings) {
        return undefined;
      }
      const { selectedBeginSprintId, selectedEndSprintId, releaseCandidates, selectedEpics } = settings;
      const sprintsInRange = getSprintsInRange(selectedBeginSprintId, selectedEndSprintId, sprints);

      return {
        sprintsInRange: sprintsInRange,
        sprintLabels: sprintsInRange.map(sprint => `${sprint.sprintName}${sprint.isRelease ? ' (R)' : ''}`),
        releaseCandidates,
        selectedEpics,
        selectedBeginSprintId,
        selectedEndSprintId,
      } as ExtendedChartViewSettings;
    },
  );
};

function getSprintsInRange(beginSprintId: string, endSprintId: string, sprints: SprintExtended[]) {
  let sprintsInRange: SprintExtended[] = [];

  let startSprint = sprints.find(s => s.id === beginSprintId);
  let endSprint = sprints.find(s => s.id === endSprintId);
  if (!startSprint || !endSprint) {
    return sprintsInRange;
  }

  sprintsInRange.push(startSprint);
  sprints.forEach(sprint => {
    let currentSprintStartDate = moment(sprint.startDate);
    if (currentSprintStartDate.isAfter(startSprint?.startDate) && currentSprintStartDate.isBefore(endSprint?.endDate)) {
      sprintsInRange.push(sprint);
    }
  });

  return sprintsInRange;
}

export const selectFutureReleases = createSelector(
  selectFutureReleaseSprints,
  selectFutureSprints,
  selectVelocity,
  (releaseSprints: SprintExtended[], futureSprints: SprintExtended[], velocity: VelocityStats) => {
    let releases: Release[] = [];
    releaseSprints.forEach(releaseSprint => {
      releases.push(mapSprintsToRelease(releaseSprint, futureSprints, velocity));
      futureSprints = futureSprints.filter(s => s.startDate.isAfter(releaseSprint.startDate));
    });
    return releases;
  },
);

function mapSprintsToRelease(releaseSprint: SprintExtended, futureSprints: SprintExtended[], velocity: VelocityStats): Release {
  const sprintsExtended = getSprintsInRange(futureSprints[0].id, releaseSprint.id, futureSprints);
  const sprintsPredicted = calcStoryPointStats(sprintsExtended, velocity);
  const { minPredictedStoryPoints, medPredictedStoryPoints, maxPredictedStoryPoints } = sprintsPredicted[sprintsPredicted.length - 1];
  const { name, deploymentDate, releaseDate, comments } = releaseSprint.release
    ? releaseSprint.release
    : { name: '', deploymentDate: moment(), releaseDate: moment(), comments: '' };

  return {
    name: name,
    deploymentDate: deploymentDate,
    releaseDate: releaseDate,
    comments: comments,
    sprints: sprintsPredicted,
    minPredictedStoryPoints: minPredictedStoryPoints,
    medPredictedStoryPoints: medPredictedStoryPoints,
    maxPredictedStoryPoints: maxPredictedStoryPoints,
    personDays: sprintsPredicted.reduce((sum, sprint) => sum + sprint.personDays, 0),
  };
}
