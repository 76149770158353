import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function extractUrlParameter(url: string, name: string): string {
  const query = new URLSearchParams(url);
  const origReturnUrl = query.get(name);
  return origReturnUrl ? origReturnUrl : '';
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const ExternalErrorPage: React.FC = () => {
  const queryString = useLocation().search;
  const message = extractUrlParameter(queryString, 'message');
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography component='h3' variant='h5'>
        An error occurred while login:
      </Typography>
      <Typography component='h3' variant='h5'>
        <em>{message}</em>
      </Typography>
      <p>
        Click <a href='/'>here</a> to return to the Login page.
      </p>
    </div>
  );
};
