import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InfoPopover } from '../../shared';

const useStyles = makeStyles((theme: Theme) => ({
  restrictionItem: {
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

interface UserInfoRestrictionProps {
  title: string;
  restrictions: { [type: string]: JSX.Element };
}

export const UserInfoRestriction: React.FC<UserInfoRestrictionProps> = ({ title, restrictions }) => {
  const classes = useStyles();

  return (
    <InfoPopover
      title={title}
      content={
        <ul>
          {Object.keys(restrictions).map(key => {
            return (
              <li key={key} className={classes.restrictionItem}>
                <Typography>
                  <b>{key}:</b>
                  <br />
                  {restrictions[key]}
                </Typography>
              </li>
            );
          })}
        </ul>
      }
      positionEdge={false}
    />
  );
};
