export enum SuccessNotification {
  AddTimelineItem = 'Timeline item was added successfully.',
  DeleteTimelineItem = 'Timeline item was deleted successfully.',
  BacklogUpdate = 'Backlog was updated successfully.',
  PasswordChange = 'Password was changed successfully.',
  SprintManagement = 'Sprint configuration was updated successfully.',
  CreateTeam = 'Team was created successfully.',
  CopyTeam = 'Team was copied successfully.',
  UpdateTeam = 'Team was updated successfully.',
  DeleteTeam = 'Team was deleted successfully.',
}
