import React, { useCallback, MouseEvent, useState, useEffect } from 'react';
import { Chart } from 'chart.js';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, alpha, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  ChevronLeft,
  ChevronRight,
  DashboardOutlined,
  GroupWorkOutlined,
  InfoOutlined,
  MapOutlined,
  PersonAddOutlined,
  Timeline,
  Tune,
  FilterHdrOutlined,
} from '@material-ui/icons';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';
import { History } from 'history';
import { ROLE_ADMIN } from '../auth';
import { useToggleState, Separator } from '../shared';

interface NavBarItem {
  id: string;
  label: string;
  icon: JSX.Element;
  route: string;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  selectedNavItem: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

const upperListItems: NavBarItem[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardOutlined />,
    route: '',
  },
  {
    id: 'release',
    label: 'Release Plan',
    icon: <MapOutlined />,
    route: '/release-plan',
  },
  {
    id: 'roadmap',
    label: 'Roadmap Forecast',
    icon: <FilterHdrOutlined />,
    route: '/roadmap',
  },
  {
    id: 'timeline',
    label: 'Timeline',
    icon: <Timeline />,
    route: '/timeline',
  },
  {
    id: 'sprints',
    label: 'Sprint Management',
    icon: <Tune />,
    route: '/sprint-management',
  },
];

const lowerListItems: NavBarItem[] = [
  {
    id: 'users',
    label: 'User Management',
    icon: <PersonAddOutlined />,
    route: '/user-management',
  },
  {
    id: 'teams',
    label: 'Team Management',
    icon: <GroupWorkOutlined />,
    route: '/team-management',
  },
  {
    id: 'about',
    label: 'About',
    icon: <InfoOutlined />,
    route: '/about',
  },
];

interface SideBarProps {
  history: History;
  userRole: string;
}

export const SideBar: React.FC<SideBarProps> = ({ history, userRole }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, toggleOpen] = useToggleState();
  const [selected, setSelected] = useState<string>('dashboard');

  useEffect(() => {
    history.listen(location => {
      let route = location.pathname;
      let itemId: any = undefined;
      if (route !== '/') {
        if (route.includes('team')) {
          itemId = 'teams';
        } else {
          itemId = findNavBarItemByRoute(route)?.id;
        }
      }
      setSelected(itemId || 'dashboard');
    });
  }, [history]);

  const navigateTo = useCallback(
    (route: string) => (_event: MouseEvent<HTMLElement>) => {
      dispatch(push(route));
    },
    [dispatch],
  );

  const buildListItem = useCallback(
    (navItem: NavBarItem) => {
      return (
        <ListItem
          button
          data-cy={`${navItem.id}`}
          key={`nav${navItem.id}`}
          className={`${selected === navItem.id && classes.selectedNavItem}`}
          onClick={navigateTo(navItem.route)}
        >
          <ListItemIcon children={navItem.icon} />
          <ListItemText primary={navItem.label} />
        </ListItem>
      );
    },
    [classes, selected, navigateTo],
  );

  const applyUserRoleRestrictions = useCallback(
    (navItems: NavBarItem[]) => {
      if (userRole === ROLE_ADMIN) return navItems;
      return navItems.filter(item => item.id !== 'users');
    },
    [userRole],
  );

  const mapNavBarItems = useCallback(
    (navItems: NavBarItem[]) => {
      navItems = applyUserRoleRestrictions(navItems);
      return (
        <>
          {navItems.map(item =>
            open ? (
              buildListItem(item)
            ) : (
              <Tooltip key={`tooltipnav${item.id}`} title={item.label}>
                {buildListItem(item)}
              </Tooltip>
            ),
          )}
        </>
      );
    },
    [applyUserRoleRestrictions, open, buildListItem],
  );

  const findNavBarItemByRoute = (route: string) => {
    return upperListItems.find(item => item.route === route) || lowerListItems.find(item => item.route === route) || undefined;
  };

  const handleDrawerTransition = (event: React.TransitionEvent) => {
    if (event.propertyName !== 'width') return;

    for (const key in Chart.instances) {
      Chart.instances[key].resize();
    }
  };

  const drawerStateClass = open ? classes.drawerOpen : classes.drawerClose;

  return (
    <Drawer
      variant='permanent'
      className={`${classes.drawer} ${drawerStateClass}`}
      classes={{ paper: drawerStateClass }}
      open={open}
      onTransitionEnd={e => handleDrawerTransition(e)}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem button key='drawerClose' onClick={toggleOpen}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </ListItem>
        {mapNavBarItems(upperListItems)}
      </List>
      <Separator />
      <List>{mapNavBarItems(lowerListItems)}</List>
    </Drawer>
  );
};
