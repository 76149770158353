import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Stop } from '@material-ui/icons';
import { PbiColor } from './backlog-widget.selectors';
import { getEpicColor } from '.';

interface ColorItem {
  color: PbiColor;
  text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    marginTop: theme.spacing(-2),
  },
  icon: {
    position: 'relative',
    top: theme.spacing(1.75),
    borderColor: 'FFF',
    marginLeft: theme.spacing(-1),
  },
}));

const coloringRulesList: ColorItem[] = [
  {
    color: PbiColor.MIN,
    text: 'These items will be resolved in almost any case.',
  },
  {
    color: PbiColor.MED,
    text: 'These items will be resolved quite likely.',
  },
  {
    color: PbiColor.MAX,
    text: 'These items will probably not be resolved before delivery.',
  },
  {
    color: PbiColor.NO,
    text: 'These items can not be resolved within the given time.',
  },
];

export const ColoringRulesInfoBox: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Typography>The backlog is colored respectively to the selected delivery date. The colors meaning are as follows:</Typography>
      {coloringRulesList.map((rule, index) => (
        <Typography className={classes.text} key={index}>
          <Stop fontSize='large' className={classes.icon} style={{ color: getEpicColor({ color: rule.color }) }} />
          {rule.text}
        </Typography>
      ))}
    </>
  );
};
