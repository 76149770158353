import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box } from '@material-ui/core';
import { useForm, FormContext } from 'react-hook-form';
import { CommentHookFormField } from '../../shared';
import { Sprint } from '..';
import { ignoreBackdropClick } from '../../shared/ignore-backdrop-click';

interface SprintCommentDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (index: number, comments?: string) => void;
  sprint?: Sprint;
  index?: number;
}

interface Comments {
  comment: string;
}

export const SprintCommentDialog: React.FC<SprintCommentDialogProps> = ({ open, onClose, onSubmit, sprint, index }) => {
  const formMethods = useForm<Comments>();
  const { handleSubmit } = formMethods;

  if (!sprint || (!index && index !== 0)) return null;

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => ignoreBackdropClick(reason, onClose)}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='dialog-title'>
        <Box display='flex' justifyContent='space-between'>
          Comment for {sprint.sprintName}
          {sprint.comments ? (
            <Button
              variant='outlined'
              onClick={() => {
                onSubmit(index, undefined);
              }}
            >
              Delete
            </Button>
          ) : null}
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(values => onSubmit(index, values.comment))} autoComplete='off'>
        <DialogContent dividers>
          <DialogContentText id='dialog-description' tabIndex={-1}></DialogContentText>

          <FormContext {...formMethods}>
            <CommentHookFormField fieldName={'comment'} labelText={'Sprint Comments'} defaultValue={sprint?.comments ?? ''} />
          </FormContext>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
            color='default'
          >
            Cancel
          </Button>
          <Button type='submit' color='primary' variant='contained'>
            Done
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
