import React from 'react';
import { InfoOutlined } from '@material-ui/icons';
import { Box, IconButton, Popover, Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  content: {
    '& img': {
      maxWidth: '100%',
    },
  },
});

interface InfoPopoverProps {
  title: string;
  content: JSX.Element | undefined;
  positionEdge?: boolean;
  customWidth?: number;
}

export const InfoPopover: React.FC<InfoPopoverProps> = ({ title, content, positionEdge = true, customWidth }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label='toggle tooltip' edge={positionEdge ? 'end' : undefined} onClick={handleOpen}>
        <InfoOutlined />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box onMouseLeave={handleClose}>
          <Box px={2} py={1} fontWeight={'bold'} bgcolor={colors.grey[100]}>
            <Typography>{title}</Typography>
          </Box>
          <Box p={2} width={customWidth || 500}>
            <Box fontSize={'body2.fontSize'} className={classes.content}>
              {content}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
