const REGEX_LETTER = /[a-zA-Z]+/;
const REGEX_DIGIT = /[0-9]+/;
const REGEX_EMAIL = /[^@]+@[^.]+\..+/;
export const REGEX_STORY_POINTS = /(^[1-9]+[0-9]*$)|(^0\.5$)/;

export const containsLetterAndNumber = (value: string): boolean => containsLetter(value) && containsDigit(value);

export const containsLetter = (value: string): boolean => REGEX_LETTER.test(value);

export const containsDigit = (value: string): boolean => REGEX_DIGIT.test(value);

export const checkEmailAddressFormat = (email: string): boolean => {
  if (!email || email.trim().length < 1) return false;
  return REGEX_EMAIL.test(email);
};

export const tryParseNumeric = (value: string | null | undefined): number | undefined => {
  if (value === undefined || value === null || value.trim().length < 1) return undefined;
  const trimmedValue = value.trim();
  const verifiedValue = trimmedValue.startsWith('.') ? `0${trimmedValue}` : trimmedValue;
  const parsedValue = parseFloat(verifiedValue);
  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export const parseChangePasswordErrorMessage = (message: string) => {
  return message.endsWith('An unknown error occurred.') ? message + ' Please verify if the current password is set correctly.' : message;
};
