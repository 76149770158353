import moment from 'moment';
import {
  AddTimelineItem,
  BacklogItem,
  ChartViewSettings,
  LegacyScenarioBasedRank,
  OAuth1Configuration,
  Release,
  ReleaseCandidate,
  Scenario,
  Sprint,
  Team,
  TeamConfig,
  TimelineItem,
  ViewSettings,
} from './planning.state';
import {
  AddTimelineItemDto,
  BacklogApiBaseLinkAndProject,
  BacklogItemDto,
  BacklogItemRankDto,
  BacklogManagementSystem,
  ChartViewSettingsDto,
  CopyTeamOptionsDto,
  OAuth1ConfigurationDto,
  ReleaseCandidateDto,
  ScenarioDto,
  ScenarioId,
  SprintDto,
  SprintReleaseDto,
  TeamDto,
  TimelineItemDto,
} from './planning.model';
import { MAXRANK } from './planning.constants';

export const convertOAuth1ConfigurationDtoToOAuth1Configuration = (
  oAuth1ConfigurationDto: OAuth1ConfigurationDto | null,
): OAuth1Configuration | null => {
  return oAuth1ConfigurationDto
    ? {
        ...oAuth1ConfigurationDto,
        privateKey: oAuth1ConfigurationDto.privateKey || '',
      }
    : null;
};

export const convertOAuth1ConfigurationToOAuth1ConfigurationDto = (
  oAuth1Configuration: OAuth1Configuration | null,
): OAuth1ConfigurationDto | null => {
  return oAuth1Configuration
    ? {
        ...oAuth1Configuration,
        privateKey: oAuth1Configuration.privateKey || null,
      }
    : null;
};

export const convertTeamDtoToTeam = (dto: TeamDto): Team => {
  const viewSettings: ViewSettings = {
    deliverySprintId: String(dto.deliverySprintId),
    selectedTeamIds: [...dto.selectedTeamIds],
  };

  const config: TeamConfig = {
    approvedBacklogItemStatuses: dto.approvedBacklogItemStatuses,
    defaultSprintDuration: dto.defaultSprintDuration,
    backlogManagementSystem: dto.backlogConfig.backlogManagementSystem,
    backlogApiBaseLink: dto.backlogConfig.backlogApiBaseLink,
    workItemBaseLink: dto.backlogConfig.workItemBaseLink,
    backlogPbisQuery: dto.backlogConfig.backlogPbisQuery,
    project: dto.backlogConfig.project,
    domain: dto.backlogConfig.domain,
    defaultStoryPoints: dto.backlogConfig.defaultStoryPoints,
    isOAuthEnabled: dto.backlogConfig.isOAuthEnabled,
    oAuth1Configuration: convertOAuth1ConfigurationDtoToOAuth1Configuration(dto.backlogConfig.oAuth1Configuration),
  };

  return {
    id: dto.id,
    name: dto.name,
    scenarios: { data: [], requestError: null, isPending: false },
    activeScenario: null,
    sprints: { data: [], requestError: null, isPending: false },
    backlogItems: { data: [], requestError: null, isPending: false },
    timelineItems: { data: [], requestError: null, isPending: false },
    minSyntheticVelocity: dto.minVelocity,
    maxSyntheticVelocity: dto.maxVelocity,
    useSyntheticVelocity: dto.useManualVelocity,
    config,
    viewSettings,
    creator: dto.creator,
    createdOn: dto.createdOn,
    updateBacklog: { data: { isSuccess: false }, requestError: null, isPending: false },
  };
};

export const convertTeamToTeamDto = (team: Team): TeamDto => {
  const backlogApiBaseLinkAndProject: BacklogApiBaseLinkAndProject = {
    backlogApiBaseLink: team.config.backlogApiBaseLink,
    project: team.config.project,
  };

  return {
    id: team.id,
    name: team.name,
    defaultSprintDuration: team.config.defaultSprintDuration,
    approvedBacklogItemStatuses: team.config.approvedBacklogItemStatuses,
    deliverySprintId: team.viewSettings.deliverySprintId,
    selectedTeamIds: team.viewSettings.selectedTeamIds,
    backlogConfig: {
      backlogManagementSystem: team.config.backlogManagementSystem,
      backlogApiBaseLink: team.config.backlogApiBaseLink,
      workItemBaseLink: team.config.workItemBaseLink,
      backlogPbisQuery: team.config.backlogPbisQuery,
      project: team.config.project,
      domain: team.config.domain,
      defaultStoryPoints: team.config.defaultStoryPoints,
      isOAuthEnabled: team.config.isOAuthEnabled,
      oAuth1Configuration: convertOAuth1ConfigurationToOAuth1ConfigurationDto(team.config.oAuth1Configuration),
    },
    minVelocity: team.minSyntheticVelocity,
    maxVelocity: team.maxSyntheticVelocity,
    useManualVelocity: team.useSyntheticVelocity,
    creator: team.creator,
    createdOn: team.createdOn,
  };
};

export const convertTeamToCopyTeamOptionsDto = (team: Team, newName: string): CopyTeamOptionsDto => {
  return {
    backlogConfig: {
      backlogManagementSystem: team.config.backlogManagementSystem,
      backlogApiBaseLink: team.config.backlogApiBaseLink,
      workItemBaseLink: team.config.workItemBaseLink,
      backlogPbisQuery: team.config.backlogPbisQuery,
      project: team.config.project,
      domain: team.config.domain,
      defaultStoryPoints: team.config.defaultStoryPoints,
      isOAuthEnabled: team.config.isOAuthEnabled,
      oAuth1Configuration: convertOAuth1ConfigurationToOAuth1ConfigurationDto(team.config.oAuth1Configuration),
    },
    copiedTeamId: team.id,
    defaultSprintDuration: team.config.defaultSprintDuration,
    newName: newName,
    sprintStartDate: moment(),
  };
};

export const convertSprintDtoToSprint = (dto: SprintDto): Sprint => {
  const mappedDtp = Object.assign({}, dto) as any;

  delete mappedDtp['isPast'];
  delete mappedDtp['isCurrent'];
  delete mappedDtp['isFirstFuture'];
  delete mappedDtp['relativeVelocity'];

  const converted: Sprint = {
    ...mappedDtp,
    id: dto.id.toString(),
    startDate: moment(dto.startDate),
    endDate: moment(dto.endDate),
    release: dto.release
      ? ({
          name: dto.release.name,
          comments: dto.release.comments,
          releaseDate: moment(dto.release.releaseDate),
          deploymentDate: moment(dto.release.deploymentDate),
        } as Release)
      : undefined,
  };

  return converted;
};

export const convertSprintToSprintDto = (sprint: Sprint): SprintDto => {
  return {
    ...sprint,
    startDate: sprint.startDate,
    endDate: sprint.endDate,
    isCurrent: false,
    isPast: false,
    isFirstFuture: false,
    release: sprint.release
      ? ({
          ...sprint.release,
          releaseDate: sprint.release?.releaseDate,
          deploymentDate: sprint.release?.deploymentDate,
        } as SprintReleaseDto)
      : undefined,
  };
};

export const convertBacklogItemRank = (rankDtos: BacklogItemRankDto[], activeScenario: ScenarioId | null): number => {
  const rankDto = rankDtos.find(r => r.scenarioId === activeScenario);
  return rankDto ? rankDto.rank : MAXRANK;
};

export const convertLegacyRank = (dto: BacklogItemRankDto): LegacyScenarioBasedRank => {
  return {
    id: dto.id,
    scenarioId: dto.scenarioId,
    rank: dto.rank,
  };
};

export const convertBacklogItemDtoToBacklogItem = (dto: BacklogItemDto, activeScenario: ScenarioId | null): BacklogItem => {
  return {
    id: dto.id,
    sourceId: dto.sourceId,
    summary: dto.summary,
    status: dto.status,
    storyPoints: dto.storyPoints,
    teamId: dto.teamId,
    rank: activeScenario !== null || dto.rank === 0 ? convertBacklogItemRank(dto.ranks, activeScenario) : dto.rank,
    sourceRank: dto.rank,
    legacyRanks: dto.ranks.map(rank => convertLegacyRank(rank)),
    type: dto.type,
    epicName: dto.epicName,
    deleted: dto.deleted,
    sprintName: dto.sprintName,
  };
};

export const convertScenarioDtoToScenario = (dto: ScenarioDto): Scenario => {
  return {
    id: dto.id,
    name: dto.name,
  };
};

export const convertTimelineItemDtoToTimelineItem = (dto: TimelineItemDto): TimelineItem => {
  return {
    ...dto,
    createdAt: moment(dto.createdAt),
  };
};

export const convertCreateTimelineItemToCreateTimelineItemDto = (timelineItemCreate: AddTimelineItem): AddTimelineItemDto => {
  return {
    ...timelineItemCreate,
    createdAt: timelineItemCreate.createdAt,
  };
};

export const convertReleaseCandidateDtoToReleaseCandidate = (dto: ReleaseCandidateDto): ReleaseCandidate => {
  return {
    name: dto.name,
    storyPoints: dto.storyPoints,
  };
};

export const convertChartViewSettingsDtoToChartViewSettings = (dto: ChartViewSettingsDto): ChartViewSettings => {
  return {
    chart: dto.chart,
    selectedBeginSprintId: dto.selectedBeginSprintId.toString(),
    selectedEndSprintId: dto.selectedEndSprintId.toString(),
    releaseCandidates: dto.releaseCandidates.map(rc => convertReleaseCandidateDtoToReleaseCandidate(rc)),
    selectedEpics: dto.selectedEpics,
  };
};
