import React, { useRef } from 'react';
import { Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDrop, DropTargetMonitor, XYCoord, useDrag } from 'react-dnd';
import { alpha } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { roundStoryPoints, getItemTypeIcon, IssueLink } from '../../../shared';
import { getDistinctColor } from '../../../shared/distinct-colors';
import { MovingBacklogItemMenu } from './moving-backlog-item-menu.component';
import { ColoredBacklogItem } from './backlog-widget.selectors';
import { ItemTypes } from './item-types';
import { getStoryPointColor, getEpicColor, PbiColorStyle } from '.';

interface BacklogItemProps {
  id: any;
  backlogItem: ColoredBacklogItem;
  numberOfPbis: number;
  index: number;
  issueBaseLink: string;
  dragPbi: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface ColoredGridProps {
  children: JSX.Element;
}

const useStyles = makeStyles<Theme, PbiColorStyle>((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.625),
    margin: theme.spacing(0.625),
    justifyContent: 'center',
    boxShadow: 'none',
  },
  epic: {
    backgroundColor: getDistinctColor(0),
    color: theme.palette.background.paper,
    textAlign: 'center',
  },
  storyPointColor: props => ({
    backgroundColor: getStoryPointColor(props),
    color: grey[700],
  }),
  container: props => ({
    backgroundColor: getEpicColor(props),
    cursor: 'all-scroll',
    alignItems: 'center',
  }),
  row: props => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
    cursor: 'all-scroll',
    alignItems: 'center',
  }),
  iconButton: {
    padding: theme.spacing(0),
  },
  dragHandle: {
    cursor: 'all-scroll',
  },
}));

export const ProductBacklogItem: React.FC<BacklogItemProps> = ({
  backlogItem,
  numberOfPbis,
  index,
  dragPbi,
  id,
  issueBaseLink,
}: BacklogItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.ProductBacklogItem,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Drag Item and dispatch moving action
      dragPbi(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ProductBacklogItem, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  const ColoredGrid: React.FC<ColoredGridProps> = ({ children }) => {
    return (
      <>
        {roundStoryPoints(backlogItem.storyPoints) ? (
          <Grid container className={classes.container} ref={ref} style={{ opacity }}>
            {children}
          </Grid>
        ) : (
          <Grid container className={classes.row} ref={ref} style={{ opacity }}>
            {children}
          </Grid>
        )}
      </>
    );
  };

  const classes = useStyles({ color: backlogItem.color });
  return (
    <ColoredGrid>
      <>
        <Grid item xs={1}>
          <IconButton className={`${classes.iconButton} ${classes.dragHandle}`}>
            <DragHandle />
          </IconButton>
          <MovingBacklogItemMenu numberOfPbis={numberOfPbis} index={index} dragPbi={dragPbi} />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={backlogItem.type}>{getItemTypeIcon(backlogItem.type, true)}</Tooltip>
        </Grid>
        <Grid item xs={4}>
          <IssueLink issueBaseLink={issueBaseLink} sourceId={backlogItem.sourceId} /> - {backlogItem.summary} ({backlogItem.status})
        </Grid>
        <Grid item xs={3}>
          {backlogItem.sprintName && <Paper className={`${classes.paper} ${classes.epic}`}>{backlogItem.sprintName}</Paper>}
        </Grid>
        <Grid item xs={2}>
          {backlogItem.epicName && <Paper className={`${classes.paper} ${classes.epic}`}>{backlogItem.epicName}</Paper>}
        </Grid>
        <Grid item xs={1}>
          <Paper className={`${classes.paper} ${classes.storyPointColor}`}>{roundStoryPoints(backlogItem.storyPoints)}</Paper>
        </Grid>
      </>
    </ColoredGrid>
  );
};
