import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ActionButtons } from './action-buttons.component';

interface PageLayoutProps {
  actions?: JSX.Element;
  children: JSX.Element[] | JSX.Element;
  title?: string | JSX.Element;
  showActionsAtTheEnd?: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ title, actions, children, showActionsAtTheEnd = false }) => {
  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} direction='row' justifyContent='space-between' alignItems='center'>
        {title && (typeof title === 'string' ? <Typography variant='h5'>{title}</Typography> : title)}
        {actions && <ActionButtons actions={actions} />}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
      {showActionsAtTheEnd && (
        <Grid container item xs={12} direction='row' justifyContent='flex-end' alignItems='flex-end'>
          {actions && <ActionButtons actions={actions} />}
        </Grid>
      )}
    </Grid>
  );
};
