import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography, Grid } from '@material-ui/core';
import React from 'react';
import { Team } from '../../planning.state';
import { ColoringRulesInfoBox } from './coloring-rules-info-box.component';
import { InfoPopover, AlertDisplay } from '../../../shared';
import { selectIsLoadBacklogItemsForTeamPending } from '../..';
import { selectBacklogWidgetData } from './backlog-widget.selectors';
import { BacklogDisplay } from './backlog-display.component';
import { DeliverySprintDropdown } from './delivery-sprint-dropdown.component';

export interface BacklogWidgetProps {
  team: Team;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  paddingTopBottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  deliverySprintDropdownGrid: {
    textAlign: 'right',
  },
}));

export const BacklogWidget: React.FC<BacklogWidgetProps> = ({ team }) => {
  const classes = useStyles();
  const data = useSelector(selectBacklogWidgetData);
  const { deliverySprint, backlogItems, deliverySprints, options, sumOfBacklogStoryPoints } = data;

  const ColoringRulesInfoBoxRenderer = () => <ColoringRulesInfoBox />;

  const isLoadBacklogItemsForTeamPending = useSelector(selectIsLoadBacklogItemsForTeamPending);

  return (
    <Paper className={classes.container}>
      <Grid container direction='row'>
        <Grid item xs='auto'>
          <Typography variant='h5' className={classes.paddingTopBottom}>
            Backlog
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <InfoPopover title={'Coloring Rules'} content={ColoringRulesInfoBoxRenderer()}></InfoPopover>
        </Grid>
        {backlogItems.length !== 0 && (
          <Grid item xs className={classes.deliverySprintDropdownGrid}>
            <DeliverySprintDropdown team={team} deliverySprint={deliverySprint} deliverySprints={deliverySprints} />
          </Grid>
        )}
      </Grid>
      {backlogItems.length !== 0 && (
        <BacklogDisplay team={team} backlogItems={backlogItems} options={options} sumOfBacklogStoryPoints={sumOfBacklogStoryPoints} />
      )}
      {backlogItems.length === 0 && !isLoadBacklogItemsForTeamPending && (
        <AlertDisplay content={'There are no backlog items. Have you already imported the team backlog?'} severity={'info'} />
      )}
    </Paper>
  );
};
