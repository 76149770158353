import moment from 'moment';

/**
 * Updates the provided locale so that the first day of the week is set to Monday (adhering to ISO-8601).
 *
 * Note: This function only needs to be invoked once, as it updates the given locale globally.
 *
 * @param locale The locale to be updated (default = 'en')
 */
export function setFirstDayOfWeekISO(locale: string = 'en') {
  moment.updateLocale(locale, {
    week: {
      dow: 1,
      doy: 4,
    },
  });
}
