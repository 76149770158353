import { createBrowserHistory } from 'history';
import { combineReducers, Reducer } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import { RouterState } from 'redux-first-history/src/reducer';
import { initialAppState } from './app.state';
import { authReducer } from './auth';
import { planningReducer } from './planning';
import {
  loadVersionInformationSucceeded,
  loadVersionInformationFailed,
  loadVersionInformation,
  loadFeatureOptions,
  loadFeatureOptionsSucceeded,
  loadFeatureOptionsFailed,
  enqueueNotification,
  removeNotification,
  removeAllNotifications,
  moreUnsavedChanges,
  noMoreUnsavedChanges,
} from './app.actions';

export const appReducer = createReducer(initialAppState, builder => {
  builder.addCase(loadVersionInformation, state => {
    state.version.isPending = true;
  });

  builder.addCase(loadVersionInformationSucceeded, (state, action) => {
    state.version = {
      data: action.payload.version,
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadVersionInformationFailed, (state, action) => {
    state.version = {
      data: state.version.data,
      requestError: action.payload.requestError,
      isPending: false,
    };
  });

  builder.addCase(loadFeatureOptions, state => {
    state.featureOptions = {
      data: {
        allowSetDefaultStoryPoints: false,
      },
      requestError: null,
      isPending: true,
    };
  });

  builder.addCase(loadFeatureOptionsSucceeded, (state, action) => {
    state.featureOptions = {
      data: {
        allowSetDefaultStoryPoints: action.payload.options.allowSetDefaultStoryPoints,
      },
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadFeatureOptionsFailed, (state, action) => {
    state.featureOptions = {
      data: {
        allowSetDefaultStoryPoints: false,
      },
      requestError: action.payload.requestError,
      isPending: false,
    };
  });

  builder.addCase(enqueueNotification, (state, action) => {
    state.notifications = [
      ...state.notifications,
      {
        ...action.payload.notification,
      },
    ];
  });

  builder.addCase(removeNotification, (state, action) => {
    state.notifications = state.notifications.filter(notification => notification.key !== action.payload.key);
  });

  builder.addCase(removeAllNotifications, state => {
    state.notifications.forEach(notification => (notification.dismissed = true));
  });

  builder.addCase(moreUnsavedChanges, state => {
    state.unsavedChanges = true;
  });

  builder.addCase(noMoreUnsavedChanges, state => {
    state.unsavedChanges = false;
  });
});

export const createRootReducer = (routerReducer: Reducer<RouterState>) => {
  return combineReducers({
    app: appReducer,
    auth: authReducer,
    router: routerReducer,
    planning: planningReducer,
  });
};
