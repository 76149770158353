import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { generateNotificationKey, PageLayout } from '../../shared';
import {
  selectAvailableUsers,
  deleteUser,
  User,
  createUser,
  updateUser,
  selectAvailableRoles,
  Role,
  selectUserEmail,
  selectProcessUserState,
  RegisterUser,
  UserProcessEventType,
  processUserCanceled,
  selectAvatarUrlsForUsers,
} from '../../auth';
import { UserDialog } from './user-dialog.component';
import { UserRow } from './user-row.component';

export const UserManagementPage: React.FC = () => {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const loggedInEmail = useSelector(selectUserEmail);
  const availableUsers = useSelector(selectAvailableUsers);
  const avatarUrls = useSelector(selectAvatarUrlsForUsers);
  const processUserState = useSelector(selectProcessUserState);
  const users = [...availableUsers].sort((user1, user2) => user1.email.localeCompare(user2.email));
  const availableRoles = useSelector(selectAvailableRoles);
  const roles = [...availableRoles].sort((role1, role2) => role1.name.localeCompare(role2.name));

  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [processFinished, setProcessFinished] = useState<boolean>(true);
  const [dialogUser, setDialogUser] = useState<User | undefined>(undefined);

  const showUserDialog = useCallback((user?: User) => {
    setProcessFinished(true);
    setDialogUser(user);
    setOpenUserDialog(true);
  }, []);

  const onCloseUserDialog = useCallback(() => {
    setOpenUserDialog(false);
    setDialogUser(undefined);
    setProcessFinished(true);
  }, []);

  const onSubmitUserDialog = useCallback(
    (user: User | RegisterUser, eventType: UserProcessEventType) => {
      const notificationKey = generateNotificationKey();
      setProcessFinished(false);
      dispatch(
        eventType === UserProcessEventType.Create
          ? createUser(user as RegisterUser, notificationKey)
          : updateUser(user as User, notificationKey),
      );
    },
    [dispatch],
  );

  const onDeleteUser = useCallback(
    (user: User) => {
      confirm({
        title: `Delete User: ${user.email}`,
        description: 'This process cannot be undone. Do you want to proceed?',
        cancellationText: 'Cancel',
        confirmationText: 'Delete',
        dialogProps: {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        },
        confirmationButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
      })
        .then(() => {
          dispatch(deleteUser(user, generateNotificationKey()));
        })
        .catch(() => {});
    },
    [confirm, dispatch],
  );

  const onProcessUserCanceled = useCallback(() => {
    dispatch(processUserCanceled());
  }, [dispatch]);

  const onVerifyDuplicateEmailAddress = useCallback(
    (email?: string) => {
      email = email ? email.trim() : '';
      return users.filter(user => user.email.trim() === email).length > 0;
    },
    [users],
  );

  const getRolesByPredicate = useCallback(
    (predicate: (role: Role) => boolean) => {
      return roles.filter(role => predicate(role)).map(role => role.name);
    },
    [roles],
  );

  useEffect(() => {
    if (!processUserState || processUserState.isPending) return;
    if (processUserState.data.success) {
      onCloseUserDialog();
    } else if (processUserState?.requestError) {
      setProcessFinished(true);
    }
    onProcessUserCanceled();
  }, [processUserState, onProcessUserCanceled, onCloseUserDialog]);

  return (
    <PageLayout
      title='User Management'
      actions={
        <Grid item>
          <Button onClick={() => showUserDialog()} variant='contained' color='primary'>
            Add User
          </Button>
        </Grid>
      }
    >
      <>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>E-Mail address</TableCell>
                <TableCell>Roles assigned</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <UserRow
                  key={user.id}
                  user={user}
                  loggedIn={user.email === loggedInEmail}
                  avatarUrl={avatarUrls[user.email]}
                  onEdit={showUserDialog}
                  onDelete={onDeleteUser}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
        <UserDialog
          key={`user-dialog:${dialogUser?.id}`}
          open={openUserDialog}
          user={dialogUser}
          roles={getRolesByPredicate((role: Role) => !role.isDefault)}
          processFinished={processFinished}
          onVerifyDuplicateEmailAddress={onVerifyDuplicateEmailAddress}
          onSubmit={onSubmitUserDialog}
          onClose={onCloseUserDialog}
        />
      </>
    </PageLayout>
  );
};
