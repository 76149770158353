import { Minimize as MinimizeIcon } from '@material-ui/icons';
import { Card, Tooltip, Collapse, Select, IconButton, MenuItem, Theme, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { styled } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { DATEFORMAT } from '../../planning.constants';
import { updateChartViewSettingsForTeam } from '../../planning.actions';
import { ChartType } from '../../planning.model';
import { Team, Sprint } from '../../planning.state';
import { BurndownChartData } from '../../../shared/chartjs-wrapper/ChartDataInterface';
import ChartJsWrapper from '../../../shared/chartjs-wrapper/chartjs-wrapper.component';
import { useToggleState, WidgetLoadingState } from '../../../shared';
import { BurndownEpicList } from './burndown-epic-list.component';
import { selectBurndownChartData } from './burndown-widget.selectors';
import { selectBacklogItemEpics, selectSprints } from '../../planning.selectors';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
  },
  configControls: {
    '& button': {
      margin: theme.spacing(0.5),
    },
  },
  dropdownControls: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    '& p, select': {
      margin: theme.spacing(2, 2),
    },
  },
  epicsSelector: {
    display: 'flex',
    width: '100%',
  },
  dropdownLabel: {
    fontSize: '18px',
  },
}));

const BurndownComponent = styled(Card)({
  display: 'block',
  padding: '16px',
  minHeight: '50px',
});

interface ChartOptionsProperties {
  elements: { line: { tension: number } };
}

const disableBezierCurves = {
  elements: {
    line: {
      tension: 0, // disables bezier curves
    },
  },
};

enum UpdateSettingsEventType {
  SprintStartDate = 'selectedBeginSprintId',
  SprintEndDate = 'selectedEndSprintId',
  SelectedEpics = 'selectedEpics',
}

export interface BurndownWidgetProps {
  data?: BurndownChartData;
  team: Team;
  epics?: string[];
  sprints?: Sprint[];
}

export const BurndownWidget: React.FC<BurndownWidgetProps> = ({ team }) => {
  const data = useSelector(selectBurndownChartData);
  const sprints = useSelector(selectSprints);
  const epics = useSelector(selectBacklogItemEpics);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, toggleExpanded] = useToggleState(true);
  const chartOptions = disableBezierCurves as ChartOptionsProperties;
  const startSprintDate = data && sprints?.length ? sprints.filter(sprint => sprint.id === data.selectedBeginSprintId)[0]?.sprintName : '';
  const endSprintDate = data && sprints?.length ? sprints.filter(sprint => sprint.id === data.selectedEndSprintId)[0]?.sprintName : '';

  const currentStartDateId = sprints?.length && data ? sprints.filter(sprint => sprint.id === data?.selectedBeginSprintId)[0]?.id : 0;
  const currentEndDateId = sprints?.length && data ? sprints.filter(sprint => sprint.id === data?.selectedEndSprintId)[0]?.id : 0;

  const handleEndDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectEndSprintDate = sprints?.filter(sprint => sprint.sprintName === event.target.value)[0];
    updateChartSettings(UpdateSettingsEventType.SprintEndDate, selectEndSprintDate ? selectEndSprintDate.id : 0);
  };

  const handleStartDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectStartSprintDate = sprints?.filter(sprint => sprint.sprintName === event.target.value)[0];
    updateChartSettings(UpdateSettingsEventType.SprintStartDate, selectStartSprintDate ? selectStartSprintDate.id : 0);
  };

  const handleEpicsChange = (values: string[]) => {
    updateChartSettings(UpdateSettingsEventType.SelectedEpics, values || []);
  };

  const updateChartSettings = useCallback(
    (key: string, value: any) => {
      const settingsToUpdate = {
        [ChartType.BurnDown]: {
          [key]: value,
        },
      };
      dispatch(updateChartViewSettingsForTeam(team.id, settingsToUpdate, ChartType.BurnDown));
    },
    [dispatch, team.id],
  );

  const EndSprintRenderer = () => {
    if (!sprints) return null;
    return sprints.map(sprint =>
      parseInt(sprint.id) > currentStartDateId ? (
        <MenuItem key={sprint.sprintName} value={sprint.sprintName}>
          {`${sprint.sprintName} (${moment(sprint.endDate).format(DATEFORMAT)})`}
        </MenuItem>
      ) : null,
    );
  };

  const StartSprintRenderer = () => {
    if (!sprints) return null;
    return sprints.map(sprint =>
      parseInt(sprint.id) < currentEndDateId ? (
        <MenuItem key={sprint.sprintName} value={sprint.sprintName}>
          {`${sprint.sprintName} (${moment(sprint.startDate).format(DATEFORMAT)})`}
        </MenuItem>
      ) : null,
    );
  };

  if (!data) {
    return <WidgetLoadingState title={'Burndown'} />;
  }

  return (
    <BurndownComponent>
      <header className={classes.header}>
        <Typography variant='h5'>Burndown</Typography>
        <div className={classes.configControls}>
          <Tooltip id='btnMinimize' title={`${expanded ? 'Minimize' : 'Maximize'}`}>
            <IconButton aria-label='Minimize' onClick={() => toggleExpanded()}>
              <MinimizeIcon fontSize='small'></MinimizeIcon>
            </IconButton>
          </Tooltip>
        </div>
      </header>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <section className={classes.dropdownControls}>
          <p className={classes.dropdownLabel}>Start date</p>
          <Select labelId='start-date-dropdown' id='start-sprint-dropdown' value={startSprintDate} onChange={handleStartDateChange}>
            {StartSprintRenderer()}
          </Select>
          <p className={classes.dropdownLabel}>End date</p>
          <Select labelId='end-date=dropdown' id='start-sprint-dropdown' value={endSprintDate} onChange={handleEndDateChange}>
            {EndSprintRenderer()}
          </Select>
        </section>
        <section className={classes.epicsSelector}>
          {epics ? (
            <BurndownEpicList
              options={epics}
              selectedEpics={data.datasets.map(set => ({
                label: set.label,
                color: Array.isArray(set.borderColor) ? set.borderColor[0] : set.borderColor,
              }))}
              onEpicsChanged={handleEpicsChange}
            />
          ) : null}
        </section>
        <section>
          <ChartJsWrapper id='burndown-chart' type='line' chartData={data} chartOptions={chartOptions} />
        </section>
      </Collapse>
    </BurndownComponent>
  );
};
