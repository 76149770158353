import React, { useEffect } from 'react';
import { Chart, ChartType, registerables } from 'chart.js';
import { BurnupChartData, BurndownChartData } from './ChartDataInterface';

export interface ChartJsWrapperOptions {
  id: string;
  chartOptions: any;
  chartData: BurnupChartData | BurndownChartData;
  type?: ChartType;
}

const ChartJsWrapper = ({ id, chartOptions, chartData, type }: ChartJsWrapperOptions) => {
  const { labels, datasets } = chartData || [];
  const initializeChart = () => {
    let ctx = document.getElementById(id) as HTMLCanvasElement;
    if (!ctx) return null;

    for (const key in Chart.instances) {
      const instance = Chart.instances[key];
      const canvasId = instance.canvas.id;

      if (canvasId === id) {
        instance.destroy();
      }
    }

    Chart.register(...registerables);

    new Chart(ctx, {
      type: type || 'line',
      options: chartOptions,
      data: {
        labels: labels || [],
        datasets: datasets || [],
      },
    });
  };

  useEffect(() => {
    initializeChart();
  });

  return <canvas key={id} id={id}></canvas>;
};

export default ChartJsWrapper;
