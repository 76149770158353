import Img from 'react-image';
import { Box, Checkbox, colors, Divider, FormControlLabel, TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import azureBaseLinkExampleImage from '../../assets/img/azure-baselink-example.png';
import azureQueryPathImage from '../../assets/img/azure-query-path.png';
import jiraBaseLinkExampleImage from '../../assets/img/jira-baselink-example.png';
import { useGetErrorMessageCallback, useToggleState, InfoPopover } from '../../shared';
import { BacklogManagementSystem } from '../planning.model';
import { FormFileUpload, FormFileUploadValue } from '../../shared';
import { PopoverBodyParagraph } from './popover-body-paragraph.component';

// https://docs.microsoft.com/en-us/previous-versions/msp-n-p/ff650303(v=pandp.10)#common-regular-expressions (plus whitespace and '=')
// eslint-disable-next-line no-useless-escape
const urlRegex = /^(ht|f)tp(s?):\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\s\-.?,'\/\\+&amp;%$#_=]*)*$/;

type FormInputTooltip = {
  [key in BacklogManagementSystem]?: JSX.Element;
};

type FormInputItem = {
  label: string;
  tooltip: FormInputTooltip;
  validation: object;
  visibility: BacklogManagementSystem[];
  render?: (item: FormInputItem) => JSX.Element;
};

type FormInputList = {
  [key: string]: FormInputItem;
};

export interface BacklogConfigValues {
  backlogBaseLink: string;
  backlogItemLink: string;
  project: string;
  domain: string;
  backlogPbisQuery: string;
  defaultStoryPoints: string;
  isOAuthEnabled: boolean;
  consumerKey: string;
  privateKey: FormFileUploadValue;
  accessToken: string;
}

interface TeamBacklogConfigProps {
  backlogManagementSystem: BacklogManagementSystem;
  backlogConfigValues: BacklogConfigValues;
}

export const TeamBacklogConfig: React.FC<TeamBacklogConfigProps> = ({ backlogManagementSystem, backlogConfigValues }) => {
  const { register, errors, getValues } = useFormContext();
  const getErrorMessageForField = useGetErrorMessageCallback(errors);
  const formValues = getValues();
  const [isOAuthEnabled, toggleOAuthState] = useToggleState(!!formValues.isOAuthEnabled);

  const renderIsOAuthEnabled = useCallback(
    () => (
      <FormControlLabel
        control={
          <Checkbox color='primary' name='isOAuthEnabled' onChange={toggleOAuthState} checked={isOAuthEnabled} inputRef={register({})} />
        }
        label='Enable OAuth'
      />
    ),
    [toggleOAuthState, register, isOAuthEnabled],
  );

  const renderPrivateKeyInput = useCallback(
    (inputItem: FormInputItem) => (
      <FormFileUpload
        accept='.pfx'
        label='Upload Private Key'
        name='privateKey'
        validation={inputItem.validation}
        defaultValue={backlogConfigValues.privateKey}
        errorMessage={getErrorMessageForField('privateKey')}
      />
    ),
    [getErrorMessageForField, backlogConfigValues.privateKey],
  );

  const formInputList: FormInputList = useMemo(
    () => ({
      backlogBaseLink: {
        label: 'Backlog Base Link',
        visibility: [BacklogManagementSystem.Jira, BacklogManagementSystem.Tfs, BacklogManagementSystem.Azure],
        validation: {
          required: 'Backlog Base Link cannot be empty.',
          pattern: {
            value: urlRegex,
            message: 'Backlog Base Link must be a valid URL.',
          },
        },
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <PopoverBodyParagraph>The Backlog Base Link is a URL that points to the root of the Jira webpage.</PopoverBodyParagraph>
              <Box pt={1}>
                <PopoverBodyParagraph>
                  For example the Base Link for our Jira at Zühlke would be <em>https://jira.zuehlke.com/</em>
                </PopoverBodyParagraph>
              </Box>
              <Img src={jiraBaseLinkExampleImage} alt='Jira Base Link example' />
            </>
          ),
          [BacklogManagementSystem.Tfs]: (
            <>
              <PopoverBodyParagraph>
                The Backlog Base Link is a URL which points to a TFS project. This URL can be found in the address bar of a browser if you
                are on the <b>overview page</b> of a specific project.
              </PopoverBodyParagraph>
              <Box pt={1}>
                <PopoverBodyParagraph>
                  An example would be: <em>https://agileplanner.visualstudio.com/</em>
                </PopoverBodyParagraph>
              </Box>
            </>
          ),
          [BacklogManagementSystem.Azure]: (
            <>
              <PopoverBodyParagraph>
                The Backlog Base Link is a URL which points to an Azure DevOps project. This URL can be found in the address bar of a
                browser if your on the <b>overview page</b> of a specific project.
              </PopoverBodyParagraph>
              <Box pt={1}>
                <PopoverBodyParagraph>
                  The following image shows the address bar of a project named <b>Agile Planner</b> in an organization <b>agileplanner</b>:
                </PopoverBodyParagraph>
              </Box>
              <Img src={azureBaseLinkExampleImage} alt='Azure Base Link example' />
            </>
          ),
        },
      },
      backlogItemLink: {
        label: 'Backlog Item Link',
        visibility: [BacklogManagementSystem.Jira, BacklogManagementSystem.Tfs],
        validation: {
          required: 'Backlog Item Link cannot be empty.',
          pattern: {
            value: urlRegex,
            message: 'Backlog Item Link must be a valid URL.',
          },
        },
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <PopoverBodyParagraph>The Backlog Item Link is a URL that points to:</PopoverBodyParagraph>
              <PopoverBodyParagraph>'{'{Backlog Base Link}browse/'}'</PopoverBodyParagraph>
              <Box pt={1}>
                <PopoverBodyParagraph>For example, the Item Link for our Jira at Zühlke would be:</PopoverBodyParagraph>
              </Box>
              <PopoverBodyParagraph>
                <em>https://jira.zuehlke.com/browse/</em>
              </PopoverBodyParagraph>
            </>
          ),
          [BacklogManagementSystem.Tfs]: (
            <>
              <PopoverBodyParagraph>The Backlog Item Link is a URL that points to:</PopoverBodyParagraph>
              <PopoverBodyParagraph>'{'{Backlog Base Link}/{Project Name}/_workitems?id='}'</PopoverBodyParagraph>
              <Box pt={1}>
                <PopoverBodyParagraph>An example would be:</PopoverBodyParagraph>
                <PopoverBodyParagraph>
                  <em>https://agileplanner.visualstudio.com/Agile Planner/_workitems?id=</em>
                </PopoverBodyParagraph>
              </Box>
            </>
          ),
        },
      },
      project: {
        label: 'Project',
        visibility: [BacklogManagementSystem.Tfs, BacklogManagementSystem.Azure],
        validation: {
          required: 'Project cannot be empty for TFS or AzureDevOps.',
        },
        tooltip: {
          [BacklogManagementSystem.Tfs]: <PopoverBodyParagraph>The name of the project.</PopoverBodyParagraph>,
          [BacklogManagementSystem.Azure]: <PopoverBodyParagraph>The name of the project.</PopoverBodyParagraph>,
        },
      },
      domain: {
        label: 'Domain',
        validation: {
          required: false,
        },
        visibility: [BacklogManagementSystem.Tfs, BacklogManagementSystem.Azure],
        tooltip: {
          [BacklogManagementSystem.Tfs]: (
            <PopoverBodyParagraph>The Domain of your user. If your user isn't in a domain you can leave it empty.</PopoverBodyParagraph>
          ),
          [BacklogManagementSystem.Azure]: (
            <PopoverBodyParagraph>The Domain of your user. If your user isn't in a domain you can leave it empty.</PopoverBodyParagraph>
          ),
        },
      },
      backlogPbisQuery: {
        label: 'Backlog PBIs Query',
        visibility: [BacklogManagementSystem.Jira, BacklogManagementSystem.Tfs, BacklogManagementSystem.Azure],
        validation: {
          required: 'Query cannot be empty.',
        },
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <PopoverBodyParagraph>
                The Backlog PBIs Query is a JQL Query that filters all backlog items in Jira. The Query has to meet the following
                requirements:
              </PopoverBodyParagraph>
              <ul>
                <li>
                  Epics <b>must be part</b> of the resulting PBIs.
                </li>
                <li>The query must only include future stories. Resolved stories and stories from the current Sprint must be excluded.</li>
              </ul>
              <Box py={1}>
                <PopoverBodyParagraph>For more information look at the JQL documentation.</PopoverBodyParagraph>
              </Box>
              <PopoverBodyParagraph>For example a valid JQL query for the agile-planner project would be:</PopoverBodyParagraph>
              <Box bgcolor={colors.grey[100]} px={2} py={1}>
                <PopoverBodyParagraph>
                  <em>
                    project = "Zühlke Agile Planner" AND type in (Story, Bug, Epic) AND resolution is EMPTY AND (Sprint is not EMPTY OR
                    Sprint not in openSprints()) ORDER BY Rank
                  </em>
                </PopoverBodyParagraph>
              </Box>
            </>
          ),
          [BacklogManagementSystem.Tfs]: (
            <>
              <PopoverBodyParagraph>
                The Backlog PBIs Query for a TFS Manager is the path to a query created in the TFS Project. The Query has to meet the
                following requirements:
              </PopoverBodyParagraph>
              <ul>
                <li>
                  Epics <b>must be part</b> of the resulting PBIs.
                </li>
                <li>The query must only include future stories. Resolved stories and stories from the current Sprint must be excluded.</li>
              </ul>
              <PopoverBodyParagraph>The path for the Future-Items query in the image below is</PopoverBodyParagraph>
              <PopoverBodyParagraph>
                <b>Shared Queries/Future-Items</b>
              </PopoverBodyParagraph>
              <Img src={azureQueryPathImage} alt='Azure Query Path' />
            </>
          ),
          [BacklogManagementSystem.Azure]: (
            <>
              <PopoverBodyParagraph>
                The Backlog PBIs Query for an Azure PBI Manager is the path to a query created in the Azure DevOps Project (Boards -&gt;
                Queries). The Query has to meet the following requirements:
              </PopoverBodyParagraph>
              <ul>
                <li>
                  Epics <b>must be part</b> of the resulting PBIs.
                </li>
                <li>The query must only include future stories. Resolved stories and stories from the current Sprint must be excluded.</li>
              </ul>
              <PopoverBodyParagraph>The path for the Future-Items query in the image below is</PopoverBodyParagraph>
              <PopoverBodyParagraph>
                <b>Shared Queries/Future-Items</b>:
              </PopoverBodyParagraph>
              <Img src={azureQueryPathImage} alt='Azure Query Path' />
            </>
          ),
        },
      },
      isOAuthEnabled: {
        label: 'Enable OAuth',
        visibility: [BacklogManagementSystem.Jira],
        validation: {},
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <PopoverBodyParagraph>
              There are two ways to connect to Jira either by entering username and password or using OAuth. OAuth uses a{' '}
              <b>consumer key</b>, <b>private key</b> and <b>access token</b> to connect to a Jira instance.
            </PopoverBodyParagraph>
          ),
        },
        render: renderIsOAuthEnabled,
      },
      consumerKey: {
        label: 'Consumer Key',
        validation: {
          required: 'Consumer key cannot be empty',
        },
        visibility: [BacklogManagementSystem.Jira],
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <Box pb={1}>
                <PopoverBodyParagraph>The service account which is registered in the Link application.</PopoverBodyParagraph>
              </Box>
              <PopoverBodyParagraph>For example:</PopoverBodyParagraph>
              <PopoverBodyParagraph>
                <em>svc_agile-planner-dev_jira@zuhlke.onmicrosoft.com</em>
              </PopoverBodyParagraph>
            </>
          ),
        },
      },
      privateKey: {
        label: 'Private Key',
        visibility: [BacklogManagementSystem.Jira],
        validation: {
          validate: (file: FormFileUploadValue) => !!file.filename || 'Private Key token cannot be empty',
        },
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <Box pb={1}>
                <PopoverBodyParagraph>
                  The pfx file which was generated using the registered public key and the corresponding private key. To create a pfx file
                  use this openssl command:
                </PopoverBodyParagraph>
              </Box>
              <PopoverBodyParagraph>
                <em>openssl pkcs12 -passout pass: -export -out new.pfx -inkey private.key -in certificate.cer</em>
              </PopoverBodyParagraph>
            </>
          ),
        },
        render: renderPrivateKeyInput,
      },
      accessToken: {
        label: 'Access Token',
        validation: {
          required: 'Access token cannot be empty',
        },
        visibility: [BacklogManagementSystem.Jira],
        tooltip: {
          [BacklogManagementSystem.Jira]: (
            <>
              <Box pb={1}>
                <PopoverBodyParagraph>
                  The access token which was generated by allowing the application access to the Jira instance.
                </PopoverBodyParagraph>
              </Box>
              <PopoverBodyParagraph>For example:</PopoverBodyParagraph>
              <PopoverBodyParagraph>
                <em>n45h2bXSplH330OS4HWHNv4e5a8Kgw</em>
              </PopoverBodyParagraph>
            </>
          ),
        },
      },
    }),
    [renderIsOAuthEnabled, renderPrivateKeyInput],
  );

  const getVisibleFormInputItems = useCallback(() => {
    return Object.keys(formInputList).filter(formField => {
      if (
        ['consumerKey', 'privateKey', 'accessToken'].includes(formField) &&
        formInputList[formField].visibility.includes(backlogManagementSystem)
      ) {
        return isOAuthEnabled;
      }

      return formInputList[formField].visibility.includes(backlogManagementSystem);
    });
  }, [isOAuthEnabled, formInputList, backlogManagementSystem]);

  return (
    <>
      {getVisibleFormInputItems().map(formField => {
        const renderFunction = formInputList[formField].render;
        return !!renderFunction ? (
          <Box display='flex' key={formField}>
            <Box display='flex' flexGrow={1}>
              {renderFunction(formInputList[formField])}
            </Box>
            <Box pr={1.75}>
              <InfoPopover title={formInputList[formField].label} content={formInputList[formField].tooltip[backlogManagementSystem]} />
            </Box>
          </Box>
        ) : (
          <TextField
            key={formField}
            name={formField}
            data-cy={formField}
            type='text'
            label={formInputList[formField].label}
            variant='outlined'
            inputRef={register(
              formInputList[formField].validation || {
                required: `The ${formInputList[formField].label} is required.`,
                pattern: 'Please provide a valid url.',
              },
            )}
            helperText={getErrorMessageForField(formField)}
            error={!!getErrorMessageForField(formField)}
            InputProps={{
              endAdornment: (
                <InfoPopover title={formInputList[formField].label} content={formInputList[formField].tooltip[backlogManagementSystem]} />
              ),
            }}
          />
        );
      })}
      {getVisibleFormInputItems().length > 0 && (
        <Box>
          <Divider />
        </Box>
      )}
    </>
  );
};
