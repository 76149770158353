import React, { useCallback } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import { Release } from '../planning.state';

interface SprintReleaseIconProps {
  value?: Release | null;
  onClick?: () => void;
}

export const SprintReleaseIcon: React.FC<SprintReleaseIconProps> = ({ value, onClick }) => {
  const onClickHandler = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return value ? (
    <Tooltip title={value.name}>
      <IconButton size='small' onClick={onClickHandler}>
        <DirectionsBoatIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};
