import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, ArrowUpward, ArrowDownward, Remove } from '@material-ui/icons';
import { IssueLink } from '../../shared';
import { TimelineItem, ProductBacklogItemChanges, StoryPointChanges, ProductBacklogItemAction } from '..';

interface TimelineEventBodyProps {
  item: TimelineItem;
  issueBaseLink: string;
}

const useStyles = makeStyles(() => ({
  icon: {
    verticalAlign: 'text-bottom',
  },
}));

export const TimelineEventBody: React.FC<TimelineEventBodyProps> = ({ item, issueBaseLink }) => {
  const { body, productBacklogItemChanges, storyPointChanges } = item;

  const getBacklogAdds = () => {
    return productBacklogItemChanges.filter(x => x.action === ProductBacklogItemAction.ADDED).length;
  };

  const getSumOfStoryPointsChange = (): number => {
    return storyPointChanges.reduce((a: number, b: StoryPointChanges) => a + (b.newStoryPoints - b.oldStoryPoints), 0);
  };

  const classes = useStyles();

  return (
    <>
      {body ? (
        <Box my={1} fontFamily='fontFamily'>
          {body}
        </Box>
      ) : (
        <></>
      )}

      {productBacklogItemChanges.length > 0 ? (
        <Accordion>
          <AccordionSummary>
            <Typography>
              Product backlog items added: {getBacklogAdds()}, Product backlog items removed:{' '}
              {productBacklogItemChanges.length - getBacklogAdds()}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productBacklogItemChanges.map(({ action, sourceId, summary }: ProductBacklogItemChanges) => (
                  <TableRow key={sourceId}>
                    <TableCell>
                      <Grid container direction='row' alignItems='center'>
                        <Grid item>
                          {action === ProductBacklogItemAction.ADDED ? (
                            <Add fontSize='small' color='secondary' className={classes.icon} />
                          ) : (
                            <Remove fontSize='small' color='error' className={classes.icon} />
                          )}
                        </Grid>
                        <Grid item>
                          <Typography display='inline' color={action === ProductBacklogItemAction.ADDED ? 'secondary' : 'error'}>
                            <IssueLink issueBaseLink={issueBaseLink} sourceId={sourceId} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{summary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}

      {storyPointChanges.length > 0 ? (
        <Accordion>
          <AccordionSummary>
            <Typography>
              Sum of story points in the backlog {getSumOfStoryPointsChange() >= 0 ? <>increased</> : <>decreased</>} by{' '}
              {Math.abs(getSumOfStoryPointsChange())}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>SP new</TableCell>
                  <TableCell>SP old</TableCell>
                  <TableCell>SP diff</TableCell>
                  <TableCell>Summary</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storyPointChanges.map(({ sourceId, newStoryPoints, oldStoryPoints, summary }: StoryPointChanges) => (
                  <TableRow key={sourceId}>
                    <TableCell>
                      <Grid container direction='row' alignItems='center'>
                        <Grid item>
                          {newStoryPoints - oldStoryPoints >= 0 ? (
                            <ArrowUpward fontSize='small' color='secondary' className={classes.icon} />
                          ) : (
                            <ArrowDownward fontSize='small' color='error' className={classes.icon} />
                          )}
                        </Grid>
                        <Grid item>
                          <Typography display='inline' color={newStoryPoints - oldStoryPoints >= 0 ? 'secondary' : 'error'}>
                            {sourceId}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{newStoryPoints}</TableCell>
                    <TableCell>{oldStoryPoints}</TableCell>
                    <TableCell>{newStoryPoints - oldStoryPoints}</TableCell>
                    <TableCell>{summary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  );
};
