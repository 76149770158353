import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

export const Separator: FC = () => {
  const classes = useStyles();

  return <div className={classes.grow} />;
};
