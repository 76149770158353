import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Box, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { selectFutureReleases, selectProductBacklogWithPredictedSprint } from '../planning-calc.selectors';
import { PageLayout, useToggleState } from '../../shared';
import { HowToAddARelease, ReleaseBlock } from '.';

export const ReleasePlanPage: React.FC = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      paddingTop: theme.spacing(2),
      background: theme.palette.background.default,
    },
    expandBtn: {
      minWidth: '160px',
      maxWidth: '160px',
    },
    hideBtn: {
      display: 'none',
    },
  }));

  const futureReleases = useSelector(selectFutureReleases);
  const predictedProductBacklog = useSelector(selectProductBacklogWithPredictedSprint);
  const classes = useStyles();
  const [expandBtn, toggleExpandBtn] = useToggleState(true);

  return (
    <PageLayout
      title={
        <div>
          <Typography variant='h5'>Release Plan</Typography>
          <Typography variant='subtitle1'>
            <i>(based on median velocity)</i>
          </Typography>
        </div>
      }
      actions={
        <Grid item>
          <Box display='flex' key={'expand'}>
            <Button
              className={futureReleases.length > 0 ? classes.expandBtn : classes.hideBtn}
              variant='contained'
              color='primary'
              startIcon={expandBtn ? <ExpandLess /> : <ExpandMore />}
              onClick={toggleExpandBtn}
            >
              {expandBtn ? 'Collapse All' : 'Expand All'}
            </Button>
          </Box>
        </Grid>
      }
      showActionsAtTheEnd={true}
    >
      <Paper className={classes.paper} elevation={0}>
        {futureReleases.length > 0 ? (
          futureReleases.map(release => (
            <ReleaseBlock key={release.name} release={release} expandBtn={expandBtn} backlog={predictedProductBacklog} />
          ))
        ) : (
          <HowToAddARelease />
        )}
      </Paper>
    </PageLayout>
  );
};
