import { map } from 'rxjs/operators';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { User } from 'oidc-client';
import { Observable } from 'rxjs';
import { StaticInjector, RequestError } from '../shared';
import { REDUX_STORE } from '../misc';

export type Headers = { [name: string]: string };

export function mapDefaultResponseToError(response: AjaxResponse<any>): RequestError {
  if (response.status === 401) {
    return {
      status: response.status,
      errors: {
        '': ['You are not authenticated - please log in.'],
      },
    };
  }

  if (response.status === 403) {
    return {
      status: response.status,
      errors: {
        '': ['You have no permission to that resource - please contact your Agile Planner Team Admin.'],
      },
    };
  }

  if (response.status === 404) {
    return {
      status: response.status,
      errors: {
        '': ['Not found - please check that the resource exists.'],
      },
    };
  }

  if (response.response) {
    const errorFromResponseArray = response.response[''] === undefined ? [response.response['_'][0]] : [response.response[''][0]];
    const errors = response.response.title ?? { '': errorFromResponseArray };
    return {
      status: response.status,
      errors: errors,
    };
  }

  return {
    status: response.status,
    errors: {
      '': ['An unknown error occurred.'],
    },
  };
}

export interface IRestClient {
  get<T>(url: string, headers?: Object): Observable<T>;
  post<T>(url: string, body: any, headers?: Headers): Observable<T>;
  put<T>(url: string, body: any, headers?: Headers): Observable<T>;
  putForm<T>(url: string, body: any, headers?: Headers): Observable<T>;
  delete<T>(url: string, headers?: Headers): Observable<T>;
}

export class RestClient implements IRestClient {
  private static readonly CONTENT_TYPE_JSON = 'application/json';

  constructor(private injector: StaticInjector) {}

  get<T>(url: string, headers?: Headers): Observable<T> {
    const requestHeaders = this.constructRequestHeaders(headers);

    return ajax.getJSON<T>(url, requestHeaders).pipe(map((res: T) => res));
  }

  post<T>(url: string, body: any, headers?: Headers): Observable<T> {
    const requestHeaders = this.constructRequestHeaders(headers);
    this.setContentTypeHeader(requestHeaders, RestClient.CONTENT_TYPE_JSON);

    return ajax.post(url, body, requestHeaders).pipe(map((res: AjaxResponse<any>) => res.response as T));
  }

  put<T>(url: string, body: any, headers?: Headers): Observable<T> {
    const requestHeaders = this.constructRequestHeaders(headers);
    this.setContentTypeHeader(requestHeaders, RestClient.CONTENT_TYPE_JSON);

    return ajax.put(url, body, requestHeaders).pipe(map((res: AjaxResponse<any>) => res.response as T));
  }

  putForm<T>(url: string, body: any, headers?: Headers): Observable<T> {
    const requestHeaders = this.constructRequestHeaders(headers);

    return ajax.put(url, body, requestHeaders).pipe(map((res: AjaxResponse<any>) => res.response as T));
  }

  delete<T>(url: string, headers?: Headers): Observable<T> {
    const requestHeaders = this.constructRequestHeaders(headers);
    this.setContentTypeHeader(requestHeaders, RestClient.CONTENT_TYPE_JSON);

    return ajax.delete(url, requestHeaders).pipe(map((res: AjaxResponse<any>) => res.response as T));
  }

  private constructRequestHeaders(headers?: Headers): Headers {
    const requestHeaders = Object.assign({} as Headers, headers);
    const user = this.getUser();
    if (user) {
      requestHeaders.Authorization = 'Bearer ' + user.access_token;
    }
    return requestHeaders;
  }

  private getUser(): User | undefined {
    const store = this.injector.get(REDUX_STORE);
    const state = store.getState();

    return state.auth.user;
  }

  private setContentTypeHeader(requestHeaders: Headers, contentType: string) {
    requestHeaders['Content-Type'] = contentType;
  }
}
