import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Chip, Tooltip } from '@material-ui/core';
import { orange, red } from '@material-ui/core/colors';
import { useConfirm } from 'material-ui-confirm';
import { selectLoggedInUser } from '.';
import { enqueueNotification } from '../app.actions';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0, 1),
    color: '#fff',
  },
  warn: {
    backgroundColor: orange[500],
    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      backgroundColor: orange[500],
    },
  },
  error: {
    backgroundColor: red[700],
    '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
      backgroundColor: red[700],
    },
  },
}));

export const SessionInterceptor: React.FC = () => {
  // check once per minute for session expiry
  const intervalMs = 60000;

  // thresholds (in minutes) when a warning toast should be displayed
  // the access token is valid for 60 minutes (configured in backend), so each threshold is displayed once when [x] minutes are left
  const expiryThresholdsMin = [15, 5, 1];

  const msgSessionExpired = 'Session expired. Please reload the page and (if necessary) sign in again.';

  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const user = useSelector(selectLoggedInUser);
  const interval = useRef<any>(undefined);
  const [minutesLeft, setMinutesLeft] = useState<number | undefined>(undefined);

  const reloadPage = () => window.location.reload();

  const constructWarnMessage = (minutesUntilExpiry: number) => {
    return `Session will expire in less than ${minutesUntilExpiry} minute${
      minutesUntilExpiry > 1 ? 's' : ''
    }. To renew it now, please save your work and reload the page.`;
  };

  const handleSessionRenewal = (minutesLeft: number) => {
    if (minutesLeft <= 0) {
      reloadPage();
      return;
    }
    confirm({
      title: `Renew session`,
      description: (
        <>
          In order to renew the session, the page will be reloaded.
          <br />
          <b>Before you proceed, please make sure that all your work has been saved.</b>
        </>
      ),
      cancellationText: 'Cancel',
      confirmationText: 'Renew',
      dialogProps: {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      },
      confirmationButtonProps: {
        variant: 'contained',
        color: 'primary',
      },
    })
      .then(reloadPage)
      .catch(() => {});
  };

  const enqueueSnack = (message: string, variant: 'warning' | 'error' = 'warning') => {
    dispatch(enqueueNotification({ message, variant, autoHideDuration: 8000 }));
  };

  const publishSessionExpiryStatus = (minutesUntilExpiry: number) => {
    setMinutesLeft(minutesUntilExpiry);
    if (minutesUntilExpiry > 0) {
      const threshold = expiryThresholdsMin.find(threshold => threshold === minutesUntilExpiry);
      if (threshold === undefined) return;
      const msg = constructWarnMessage(minutesUntilExpiry);
      enqueueSnack(msg);
    } else {
      enqueueSnack(msgSessionExpired, 'error');
    }
  };

  const checkForExpiry = (expiresInSeconds: number) => {
    if (expiresInSeconds <= 0) {
      publishSessionExpiryStatus(0);
      clearInterval(interval.current);
      return;
    }
    const expiryInMinutes = Math.trunc(expiresInSeconds / 60);
    if (expiryInMinutes > expiryThresholdsMin[0] || expiryInMinutes < 1) return;
    publishSessionExpiryStatus(expiryInMinutes);
  };

  const renderSessionExpiryStatus = useCallback(() => {
    return minutesLeft !== undefined ? (
      <Tooltip title='Click to renew the session' enterDelay={300}>
        <Chip
          label={`Session: ${
            minutesLeft > 0 ? `${minutesLeft} minute${minutesLeft > 1 ? 's' : ''} left` : 'Expired'
          }. Click here to renew.`}
          onClick={() => handleSessionRenewal(minutesLeft)}
          className={`${classes.container} ${minutesLeft > 0 ? classes.warn : classes.error}`}
        />
      </Tooltip>
    ) : (
      <></>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutesLeft]);

  useEffect(() => {
    if (!user) return;
    interval.current = setInterval(() => checkForExpiry(user.expires_in), intervalMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{renderSessionExpiryStatus()}</>;
};
