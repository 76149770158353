import { Moment } from 'moment';

export enum RoadmapError {
  NoTeamFound = 0,
  NoActiveTeam = 1,
  NoTeamSelected = 2,
  MissingEpicsSprintsVelocity = 3,
  SprintMismatch = 4,
}

export interface RoadmapData {
  teams: RoadmapTeam[];
  sprints: RoadmapSprint[];
  error?: RoadmapError;
}

export interface RoadmapTeam {
  id: string;
  name: string;
  color: string;
  active: boolean;
  selected: boolean;
  mismatch: boolean;
}

export interface RoadmapSprint {
  id: string;
  name: string;
  startDate: Moment;
  endDate: Moment;
  timeframe: string;
  releases: { team: string; color: string }[];
  teams: string[];
  epics: { selectedTeamIndex: number; label: string; color: string }[];
}
