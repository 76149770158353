import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { FormContextValues } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { BacklogManagementSystem } from '../planning.model';
import { Team } from '../planning.state';
import { useToggleState } from '../../shared';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(3, 3, 3, 0),
  },
}));

export interface BacklogUpdateAuthProps {
  activeTeam?: Team;
  backlogManagementSystem?: BacklogManagementSystem;
  formContextValues?: FormContextValues;
  errorMessageCallback: (fieldName: string) => string;
  pending: boolean;
}

export const BacklogUpdateAuth: React.FC<BacklogUpdateAuthProps> = ({
  activeTeam,
  backlogManagementSystem,
  formContextValues,
  errorMessageCallback,
  pending,
}) => {
  const classes = useStyles();
  const { register } = formContextValues ? formContextValues : ({} as any);
  const isAzureDevOps = backlogManagementSystem ? backlogManagementSystem === BacklogManagementSystem.Azure : false;
  const isOAuth = activeTeam ? activeTeam.config.isOAuthEnabled : false;
  const [showPassword, setShowPassword] = useToggleState(false);

  return isOAuth ? (
    <Typography className={classes.formControl}>Fetching backlog with OAuth.</Typography>
  ) : (
    <>
      <TextField
        id='username'
        name='username'
        type='text'
        label='Username'
        placeholder='Username without domain'
        required
        fullWidth
        variant='outlined'
        className={classes.formControl}
        disabled={pending}
        inputRef={register({
          required: 'The Username field is required.',
        })}
        helperText={errorMessageCallback('username')}
        error={!!errorMessageCallback('username')}
        data-cy='username'
      />
      <TextField
        id='password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        label={isAzureDevOps ? 'Personal Access Token' : 'Password'}
        required
        fullWidth
        variant='outlined'
        className={classes.formControl}
        disabled={pending}
        inputRef={register({
          required: 'The Password field is required.',
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='toggle password visibility' onClick={setShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={errorMessageCallback('password')}
        error={!!errorMessageCallback('password')}
        data-cy='password'
      />
    </>
  );
};
