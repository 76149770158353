import React, { useCallback } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

interface SprintCommentIconProps {
  value?: string;
  onClick?: () => void;
}

export const SprintCommentIcon: React.FC<SprintCommentIconProps> = ({ value, onClick }) => {
  const onClickHandler = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return value ? (
    <Tooltip title={value}>
      <IconButton data-testid={`icon-button`} size='small' onClick={onClickHandler}>
        <ChatBubbleIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};
