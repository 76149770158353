import cloneDeep from 'clone-deep';
import { createSelector } from 'reselect';
import { RootState } from '../app.state';
import { BacklogManagementSystem, TeamId } from './planning.model';
import { PlanningState, Team, BacklogItem, ObjectMap, Sprint } from './planning.state';

export const selectPlanning = (state: RootState) => state.planning;

export const selectActiveTeamId = (state: RootState) => selectPlanning(state).activeTeam;

export const selectActiveTeam = createSelector(
  selectPlanning,
  (state: PlanningState) => state.teams.data[state.activeTeam ? state.activeTeam : ''],
);

export const selectAllTeams = createSelector(selectPlanning, (planningState: PlanningState) => {
  const teamsMap = planningState.teams.data;
  return Object.keys(teamsMap)
    .map(key => teamsMap[key])
    .sort((team1, team2) => team1.name?.localeCompare(team2.name));
});

export const selectTeam = createSelector(selectAllTeams, (teams: Team[]) => (teamId: TeamId) => teams.find(team => team.id === teamId));

export const selectTeamNames = (state: RootState): string[] => {
  const teamsMap = selectPlanning(state).teams.data;
  return Object.values(teamsMap).map(team => team.name);
};

export const selectIsTeamLoadPending = (team: Team) => {
  return team.backlogItems.isPending || team.sprints.isPending || team.timelineItems.isPending;
};

export const selectIsTeamsLoadPending = (state: RootState) => {
  const teamsMap = state.planning.teams.data;
  const teamsPending = Object.values(teamsMap).map(team => selectIsTeamLoadPending(team));
  return state.planning.teams.isPending || teamsPending.some(e => e);
};

export const selectSelectedTeams = createSelector(selectActiveTeam, selectAllTeams, (activeTeam: Team, teams: Team[]) => {
  if (!activeTeam || !activeTeam.viewSettings) {
    return [];
  }
  const selectedTeams: Team[] = [];
  activeTeam.viewSettings.selectedTeamIds.forEach(teamId => {
    const teamFound = teams.find(team => team.id === teamId);
    if (teamFound) {
      selectedTeams.push(teamFound);
    }
  });
  return selectedTeams;
});

export const selectUpdateTeamState = createSelector(selectPlanning, (planningState: PlanningState) => {
  return planningState.updateTeam;
});

export const selectProductBacklog = createSelector(selectActiveTeam, (team: Team) => {
  const backlogItems = team ? [...team.backlogItems.data] : [];
  return sortBacklogItemsByRank(backlogItems);
});

export const selectProductBacklogForSelectedTeams = createSelector(selectSelectedTeams, (selectedTeams: Team[]) => {
  const teamProductBacklogs: ObjectMap<TeamId, BacklogItem[]> = {};
  selectedTeams.forEach(team => {
    const backlogItems = team ? [...team.backlogItems.data] : [];
    teamProductBacklogs[team.id] = sortBacklogItemsByRank(backlogItems);
  });
  return teamProductBacklogs;
});

export const selectSprints = createSelector(selectActiveTeam, (team: Team) => {
  return team ? [...team.sprints.data] : [];
});

export const selectTimelineItems = createSelector(selectActiveTeam, (team: Team) => {
  return team ? cloneDeep([...team.timelineItems.data]) : [];
});

export const selectSprintsForSelectedTeams = createSelector(selectSelectedTeams, (teams: Team[]) => {
  const sprintsForTeams: ObjectMap<TeamId, Sprint[]> = {};
  teams.forEach(team => {
    sprintsForTeams[team.id] = [...team.sprints.data];
  });
  return sprintsForTeams;
});

export const selectDeliverySprintId = createSelector(selectActiveTeam, (activeTeam: Team) => {
  return activeTeam ? activeTeam.viewSettings.deliverySprintId : undefined;
});

export const selectBacklogManagementSystem = createSelector(selectActiveTeam, (team: Team) => {
  return team ? team.config.backlogManagementSystem : BacklogManagementSystem.None;
});

export const selectIsBacklogUploadPending = createSelector(selectActiveTeam, (team: Team) => {
  return team?.updateBacklog?.isPending || team?.backlogItems.isPending || team?.timelineItems.isPending;
});

export const selectHasBacklogUpdateSucceeded = createSelector(selectActiveTeam, (team: Team) => {
  return !!team && !!team.updateBacklog && team.updateBacklog.data.isSuccess;
});

export const selectBacklogItemEpics = createSelector(selectProductBacklog, (backlogItems: BacklogItem[]): string[] => {
  return Array.from(
    new Set(backlogItems.filter(item => !!item.epicName && item.epicName?.length !== 0).map(item => item.epicName)).values(),
  );
});

export function sortBacklogItemsByRank<T extends BacklogItem>(backlogItems: T[]): T[] {
  if (!backlogItems || backlogItems.length < 1) {
    return [];
  }
  return backlogItems.sort((a, b) => b.rank - a.rank);
}

export const selectSprintsStateOfActiveTeam = createSelector(selectActiveTeam, (team: Team) => {
  return cloneDeep(team?.sprints);
});

export const selectCopyTeamState = createSelector(selectPlanning, (planningState: PlanningState) => {
  return planningState.copyTeam;
});

export const selectCreateTeamState = createSelector(selectPlanning, (planningState: PlanningState) => {
  return planningState.createTeam;
});

export const selectDeleteTeamState = createSelector(selectPlanning, (planningState: PlanningState) => {
  return planningState.deleteTeam;
});

export const selectIsLoadBacklogItemsForTeamPending = createSelector(selectActiveTeam, (team: Team) => {
  return !!team && !!team.backlogItems && team.backlogItems.isPending;
});
