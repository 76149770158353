import React, { useEffect } from 'react';
import { styled } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

export interface WidgetLoadingStateProps {
  title: string;
}

const SSkeletonLine = styled(Skeleton)({
  width: '100%',
  height: '100%',
  borderRadius: '5px',
  '&::before': {
    content: '\\00a0\\00a0',
  },
});

export const LoadingAnimation = () => <SSkeletonLine />;

const useStyles = makeStyles((theme: Theme) => ({
  loadingState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    minHeight: '300px',
    minWidth: '400px',
    overflow: 'hidden',
    background: 'white',
    borderRadius: '6px',
    boxShadow: '0 15px 30px 0 rgba(0, 0, 0, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.05)',
    transition: 'ease box-shadow 0.3s',
    '&:hover': {
      boxShadow: '0 15px 60px 0 rgba(0, 0, 0, 0.08), 0 5px 25px 0 rgba(0, 0, 0, 0.08)',
    },
  },
  cardTitle: {
    lineHeight: '64px',
    margin: theme.spacing(0, 2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
  },
  headerLabel: {
    fontSize: '24px',
  },
  cardImage: {
    height: '200px',
    overflow: 'hidden',
    margin: theme.spacing(1),
  },
}));

export const WidgetLoadingState: React.FC<WidgetLoadingStateProps> = ({ title }) => {
  const classes = useStyles();

  useEffect(() => {});

  return (
    <div className={classes.loadingState}>
      <header className={classes.header}>
        <p className={classes.headerLabel}>{title}</p>
      </header>
      <h3 id='cardTitle' className={classes.cardTitle}>
        <LoadingAnimation />
      </h3>
      <figure id='cardImage' className={classes.cardImage}>
        <LoadingAnimation />
      </figure>
    </div>
  );
};
