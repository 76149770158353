import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Link, Paper, Box, Button } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import Img from 'react-image';
import { PageLayout, VersionComponent } from '../shared';
import teams from '../assets/img/teams.png';
import { codeOwners, contributors, productOwners } from './community-member';
import { MembersDisplay } from './members-display.component';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  teamsImg: {
    width: '30px',
    verticalAlign: 'bottom',
  },
  section: {
    paddingTop: 'inherit',
  },
}));

export const AboutPage: React.FC = () => {
  const showInformationVersion = true;
  const classes = useStyles();

  let licenseReport: string | undefined;

  try {
    licenseReport = require('../assets/file/licenseReport.csv');
  } catch (err) {
    licenseReport = '';
  }

  return (
    <PageLayout title='About'>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item>
            <Typography>
              {' '}
              The Agile Planner is an implementation of best practices, methodology and Zühlke project experience. In particular, the value
              for you as Agile Planner user is at its center. Thus, please provide your feedback, like general impression, improvements,
              feature requests, or bugs, so that we can enhance the Agile Planner continuously.
            </Typography>
            <Typography>
              <Link
                target='_blank'
                rel='noreferrer'
                href='https://teams.microsoft.com/l/team/19%3aee4848434aae486db377bd32ea9ed4f7%40thread.skype/conversations?groupId=0bdd5e4b-102a-45d0-a1af-73156779acb5&tenantId=ccce7f5e-a35f-4bc3-8e63-b2215e7d14f9'
              >
                <Img className={classes.teamsImg} src={teams} alt='teams logo' />
                Contact us in Teams
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <MembersDisplay members={productOwners} title='Product Owners'></MembersDisplay>
        <MembersDisplay members={codeOwners} title='Code Owners'></MembersDisplay>
        <MembersDisplay members={contributors} title='Contributors'></MembersDisplay>

        <Grid item className={classes.section}>
          <VersionComponent showInformationVersion={showInformationVersion} />
        </Grid>

        <Grid item className={classes.section}>
          {licenseReport && (
            <Box display='flex' key={'licenseReportDownload'}>
              <Button variant='contained' color='primary' startIcon={<CloudDownload />} onClick={() => window.open(licenseReport)}>
                Download license report (.csv)
              </Button>
            </Box>
          )}
        </Grid>
      </Paper>
    </PageLayout>
  );
};
