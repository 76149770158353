import { useFormContext } from 'react-hook-form';
import { useEffect, useCallback, useState, ChangeEvent } from 'react';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

interface ReadyStatusesProps {
  value: string[];
  name: string;
  validation: object;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputStyle: {
    background: 'white',
  },
}));

export const ReadyStatuses: React.FC<ReadyStatusesProps> = ({ value, name, errorMessage, validation }) => {
  const classes = useStyles();
  const { register, unregister, setValue, triggerValidation } = useFormContext();
  const [currentValue, setCurrentValue] = useState<string[]>(value);

  useEffect(() => {
    register({ name }, validation);
    setValue(name, currentValue);
    return () => unregister(name);
  }, [register, name, setValue, validation, currentValue, unregister]);

  const handleStatusTagsChange = useCallback(
    (event: ChangeEvent<{}>, value: string[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string>) => {
      if (['create-option', 'remove-option'].indexOf(reason) !== -1) {
        setCurrentValue(value);
      }
    },
    [],
  );

  return (
    <>
      <Autocomplete
        multiple
        freeSolo
        onChange={handleStatusTagsChange}
        options={[]}
        value={currentValue}
        renderTags={(value, getTagProps) =>
          value.map((state, index: number) => <Chip variant='outlined' label={state} {...getTagProps({ index })} />)
        }
        renderInput={params => <TextField {...params} variant='outlined' label='Add new ready state' />}
      />
      {errorMessage && (
        <FormHelperText variant='outlined' error>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
};
