import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
}));

interface SpinnerOverlayProps {
  button: JSX.Element;
  show: boolean;
  diameter?: number;
  css?: any;
}

export const SpinnerOverlay: React.FC<SpinnerOverlayProps> = ({ button, show, diameter = 24, css }) => {
  const classes = useStyles();
  return (
    <div style={css} className={classes.wrapper}>
      {button}
      {show && <CircularProgress size={diameter} className={classes.progress} />}
    </div>
  );
};
