import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, Validate } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DATEFORMAT, ISO_DATEFORMAT } from '../planning';

interface FormDatePickerProps {
  defaultValue: string;
  label: string;
  name: string;
  validate?: Validate | Record<string, Validate>;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({ defaultValue, label, name, validate }) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const { register, setValue } = useFormContext();

  const handleDateChange = useCallback(
    date => {
      setSelectedDate(date);
      if (date) {
        setValue(name, date.format(ISO_DATEFORMAT));
      }
    },
    [name, setValue],
  );

  useEffect(() => {
    register({ name, type: 'custom' }, { validate });
  }, [name, register, validate]);

  return (
    <KeyboardDatePicker
      autoOk
      variant='inline'
      inputVariant='outlined'
      label={label}
      format={DATEFORMAT}
      value={selectedDate}
      InputAdornmentProps={{ position: 'start' }}
      onChange={handleDateChange}
    />
  );
};
