import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { generateNotificationKey, PageLayout } from '../../shared';
import { selectAllTeams } from '../planning.selectors';
import { deleteTeam } from '../planning.actions';
import { TeamCard } from './team-card.component';

export const TeamManagementPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const teams = useSelector(selectAllTeams);

  const addTeamCallback = useCallback(() => history.push(`/team/new`), [history]);
  const editTeamCallback = useCallback((teamId: string) => history.push(`/teams/edit/${teamId}`), [history]);
  const deleteTeamCallback = useCallback(
    (teamId: string) => {
      dispatch(deleteTeam(teamId, generateNotificationKey()));
    },
    [dispatch],
  );
  const copyTeamCallback = useCallback((teamId: string) => history.push(`/teams/copy/${teamId}`), [history]);

  return (
    <PageLayout
      title='Team Management'
      actions={
        <Grid item>
          <Button onClick={addTeamCallback} variant='contained' color='primary' data-cy='add-team'>
            Add Team
          </Button>
        </Grid>
      }
    >
      <Grid container spacing={3}>
        {teams.map(team => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={team.id}>
            <TeamCard team={team} onEdit={editTeamCallback} onDelete={deleteTeamCallback} onCopy={copyTeamCallback} />
          </Grid>
        ))}
      </Grid>
    </PageLayout>
  );
};
