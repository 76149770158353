import React from 'react';
import { Grid } from '@material-ui/core';

interface ActionButtonsProps {
  actions: JSX.Element;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ actions }) => {
  return (
    <Grid item>
      <Grid container spacing={2} justifyContent='flex-end'>
        {actions}
      </Grid>
    </Grid>
  );
};
