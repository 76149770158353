import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Img from 'react-image';
import { selectSortedCommunityMembers } from './about.selectors';
import { CommunityMember } from './community-member';

interface MembersDisplayProps {
  members: CommunityMember[];
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    paddingTop: 'inherit',
    paddingBottom: 'inherit',
  },
  memberDisplay: {
    textAlign: 'center',
    marginRight: theme.spacing(8),
  },
  memberImg: {
    borderRadius: '50%',
    width: '80px',
  },
}));

export const MembersDisplay: React.FC<MembersDisplayProps> = ({ members, title }) => {
  members = selectSortedCommunityMembers(members);

  const classes = useStyles();

  return (
    <>
      <Typography variant='h6' className={classes.section}>
        {title}
      </Typography>
      <Grid container spacing={3} alignItems='baseline'>
        {members.map(member => (
          <Grid item key={member.shortName} className={classes.memberDisplay} xs={1}>
            <Img
              className={classes.memberImg}
              src={[`/community-members/${member.shortName}.jpg`, `data:image/jpeg;base64,${member.imageBase64}`]}
              alt=''
            />
            <Typography>
              {member.firstName} {member.lastName}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
