import React, { useCallback } from 'react';
import { TableRow, TableCell, Box, Tooltip, IconButton, Typography, Chip, Avatar, colors, alpha } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Edit, Delete, Person } from '@material-ui/icons';
import { User } from '../../auth';

const useStyles = makeStyles((theme: Theme) => ({
  userRow: {
    height: '65px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  avatar: {
    backgroundColor: colors.grey[300],
    color: '#FFF',
    margin: 'auto',
  },
  avatarFallback: {
    fontSize: '2rem',
  },
  tableCellAvatar: {
    textAlign: 'center',
    width: '80px',
  },
  tableCellText: {
    textAlign: 'left',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  tableCellRoles: {
    width: '40%',
  },
  tableCellActions: {
    textAlign: 'center',
    width: '80px',
  },
}));

interface UserRowProps {
  user: User;
  loggedIn: boolean;
  onEdit: (user: User) => void;
  onDelete: (user: User) => void;
  avatarUrl?: string;
}

export const UserRow: React.FC<UserRowProps> = ({ user, loggedIn, avatarUrl, onEdit, onDelete }) => {
  const classes = useStyles();

  const handleEdit = useCallback(() => {
    onEdit(user);
  }, [user, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(user);
  }, [user, onDelete]);

  return (
    <TableRow className={classes.userRow} selected={loggedIn}>
      <TableCell className={classes.tableCellAvatar}>
        {React.useMemo(() => {
          return (
            <>
              <Avatar className={classes.avatar} src={avatarUrl}>
                <Person className={classes.avatarFallback} />
              </Avatar>
            </>
          );
        }, [classes, avatarUrl])}
      </TableCell>
      <TableCell className={classes.tableCellText}>
        {React.useMemo(() => {
          return <Typography>{user.email}</Typography>;
        }, [user])}
      </TableCell>
      <TableCell className={`${classes.tableCellText} ${classes.tableCellRoles}`}>
        {React.useMemo(() => {
          return user.roles.map(role => <Chip key={`${user.id}${role}`} label={role} variant='default' />);
        }, [user])}
      </TableCell>
      <TableCell className={classes.tableCellActions}>
        <Box display='flex'>
          {loggedIn ? (
            <>
              <Tooltip title='You cannot edit your own user.' placement='top'>
                <span>
                  <IconButton aria-label='update' disabled>
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title='You cannot delete your own user.' placement='top'>
                <span>
                  <IconButton aria-label='delete' disabled>
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title='Edit' placement='top'>
                <IconButton aria-label='update' onClick={handleEdit}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete' placement='top'>
                <IconButton aria-label='delete' onClick={handleDelete}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
