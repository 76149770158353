import React, { useCallback } from 'react';
import { Avatar, Card, CardContent, CardHeader, Chip, alpha, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit, FileCopyOutlined } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectActiveTeam } from '../planning.selectors';
import { ConfirmationDialog, useToggleState } from '../../shared';
import { TeamId } from '../planning.model';
import { Team } from '../planning.state';

const useStyles = makeStyles((theme: Theme) => ({
  activeTeamBorder: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  activeTeamBackground: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  cardHeaderAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

interface TeamCardProps {
  team: Team;
  onEdit: (teamId: TeamId) => void;
  onDelete: (teamId: TeamId) => void;
  onCopy: (teamId: TeamId) => void;
}

export const TeamCard: React.FC<TeamCardProps> = ({ team, onEdit, onDelete, onCopy }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useToggleState();

  const activeTeam = useSelector(selectActiveTeam);
  const activeTeamBorderClass = activeTeam.id === team.id ? classes.activeTeamBorder : '';
  const activeTeamBackgroundClass = activeTeam.id === team.id ? classes.activeTeamBackground : '';

  const onCopyHandler = useCallback(() => {
    onCopy(team.id);
  }, [onCopy, team.id]);

  const onEditHandler = useCallback(() => {
    onEdit(team.id);
  }, [onEdit, team.id]);

  const onDeleteHandler = useCallback(() => {
    toggleOpen();
    onDelete(team.id);
  }, [onDelete, team.id, toggleOpen]);

  const getFirstLetterOfTheFirstTwoWordsInSentence = useCallback((sentence: string): string => {
    return sentence
      .split(' ')
      .slice(0, 2)
      .map(word => word[0])
      .join('')
      .toUpperCase();
  }, []);

  return (
    <>
      <Card className={activeTeamBorderClass}>
        <CardHeader
          className={activeTeamBackgroundClass}
          avatar={<Avatar className={classes.cardHeaderAvatar}>{getFirstLetterOfTheFirstTwoWordsInSentence(team.name)}</Avatar>}
          title={team.name}
          subheader={team.config.project ? team.config.project : ''}
          action={
            <>
              <Tooltip title='Copy' placement='top'>
                <IconButton aria-label='copy' onClick={onCopyHandler}>
                  <FileCopyOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit' placement='top'>
                <IconButton aria-label='update' onClick={onEditHandler}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {activeTeam.id === team.id ? (
                <Tooltip
                  title='This team cannot be deleted as it is currently selected. In order to delete it, you must first switch to another team at the top.'
                  placement='top'
                >
                  <span>
                    <IconButton aria-label='delete' disabled>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title='Delete' placement='top'>
                  <IconButton aria-label='delete' onClick={toggleOpen} data-cy={`delete-team-${team.name}`}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <CardContent className={activeTeamBackgroundClass}>
          <Grid container justifyContent='flex-start' spacing={1}>
            <Grid item>
              <Chip variant='outlined' label={team.config.backlogManagementSystem.toUpperCase()} />
            </Grid>
            <Grid item>
              <Chip variant='outlined' label={team.config.defaultSprintDuration + '-Day Sprint'} />
            </Grid>
            <Grid item>
              <Chip variant='outlined' label={`Synthetic Velocity: ${team.useSyntheticVelocity}`} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConfirmationDialog
        isOpen={open}
        title={`Delete Team '${team.name}'?`}
        description={`This operation will permanently delete the team and cannot be undone.`}
        confirmBtnText={'Delete'}
        onCancel={toggleOpen}
        onConfirm={onDeleteHandler}
        identifier={team.id}
      />
    </>
  );
};
