import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { appDependencies } from './app.dependencies';
import { rootEpic } from './app.epics';
import { createRootReducer } from './app.reducer';
import { REDUX_STORE } from './misc';

const epicMiddleware = createEpicMiddleware({
  dependencies: appDependencies,
});

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = createStore(createRootReducer(routerReducer), composeWithDevTools(applyMiddleware(epicMiddleware, routerMiddleware)));

export const history = createReduxHistory(store);

appDependencies.injector.register(REDUX_STORE, store);

epicMiddleware.run(rootEpic);
