import React from 'react';
import { Input, InputAdornment, FormControl, FormHelperText } from '@material-ui/core';

interface VelocityInputFieldProps {
  adornmentText: string;
  errorMessage?: string;
  min?: number;
  max?: number;
  value?: number | string;
  name?: string;
  disabled?: boolean;
  className?: string;
  inputRef?: (instance: any) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const VelocityInputField: React.FC<VelocityInputFieldProps> = ({
  min,
  max,
  adornmentText,
  value,
  errorMessage,
  disabled,
  ...props
}) => {
  return (
    <FormControl error={!!errorMessage}>
      <Input
        inputProps={{
          step: 0.05,
          min: min !== undefined && !isNaN(min) ? min : undefined,
          max: max !== undefined && !isNaN(max) ? max : undefined,
        }}
        endAdornment={<InputAdornment position='end'>{adornmentText}</InputAdornment>}
        value={disabled ? (value as number).toFixed(2) : value}
        type='number'
        disabled={disabled}
        {...props}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};
