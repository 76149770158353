import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DirectionsBoat as ReleaseIcon } from '@material-ui/icons';
import Img from 'react-image';
import { InfoPopover } from '../../../shared';
import roadmapExplanationExampleImage from '../../../assets/img/roadmap-explanation-example.png';

const useStyles = makeStyles((theme: Theme) => ({
  explanationImageContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: theme.spacing(1, 0, 0),
    paddingRight: theme.spacing(1),
  },
}));

export const RoadmapExplanationPopover: React.FC = () => {
  const classes = useStyles();

  const explanationPopoverContent = useCallback(() => {
    return (
      <>
        <Typography>
          The <em>Roadmap Forecast</em> shows all epics / features which are likely to be resolved in the planned future.
          <br />
          The basis for the calculation is the median velocity.
          <br />
          <br />
          In the following example, the feature <em>Dashboard</em> will be finished in <em>Sprint 2</em>. In <em>Sprint 3</em> no feature
          will be finished. Finally in <em>Sprint 4</em>, the features <em>Reporting</em> and <em>User Management</em> will be completed.
          <br />
          Note that <em>Sprint 4</em> is also marked with <ReleaseIcon fontSize='small' />, indicating that this particular sprint contains
          a planned release for the listed teams.
        </Typography>
        <div className={classes.explanationImageContainer}>
          <Img src={roadmapExplanationExampleImage} width={418} height={250} alt='Roadmap explanation example' />
        </div>
      </>
    );
  }, [classes]);

  return <InfoPopover title={'Explanation'} content={explanationPopoverContent()} customWidth={570} />;
};
