export interface Option {
  label: OptionLabel;
  name: string;
}

export enum OptionLabel {
  Ids = 'Ids',
  ItemType = 'Item Type',
  Epics = 'Epics',
}
