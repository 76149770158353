import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { LoadingState } from '../shared';
import { selectIsTeamsLoadPending } from '../planning';
import { TriggerLogin } from './trigger-login.component';
import { selectIsReady, selectIsAuthenticated } from './auth.selectors';
import { removeAllNotifications } from '../app.actions';

interface AuthorizedRouteComponentProps {
  path: string;
  component: React.FC | React.ComponentClass;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export const AuthorizedRoute: React.FC<AuthorizedRouteComponentProps> = (authRouteProps: any) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isTeamsLoadPending = useSelector(selectIsTeamsLoadPending);
  const classes = useStyles(authRouteProps);
  const dispatch = useDispatch();
  const renderAllowedComponent = useCallback(
    props => {
      dispatch(removeAllNotifications());
      if (isAuthenticated) {
        const Component = authRouteProps.component;
        return <div className={classes.root}>{isTeamsLoadPending ? <LoadingState /> : <Component {...props} />}</div>;
      } else {
        return <TriggerLogin />;
      }
    },
    [dispatch, isAuthenticated, isTeamsLoadPending, authRouteProps.component, classes.root],
  );

  const isReady = useSelector(selectIsReady);
  if (!isReady) {
    return <div />;
  }

  return <Route path={authRouteProps.path} render={renderAllowedComponent} />;
};
