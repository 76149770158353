import React from 'react';
import { Moment } from 'moment';
import { Box, Typography } from '@material-ui/core';
import { TimelineItem, TimelineEvent } from '..';

interface TimelineDayProps {
  date: Moment;
  events: TimelineItem[];
  onDelete: (item: TimelineItem) => void;
  issueBaseLink: string;
}

export const TimelineDay: React.FC<TimelineDayProps> = ({ date, events, onDelete, issueBaseLink }) => {
  return (
    <>
      <Box mt={2} mb={1}>
        <Typography variant='h5'>{date.format('DD. MMMM YYYY')}</Typography>
      </Box>

      <>
        {events.sort(compareWithCreatedAt).map((timelineItem: TimelineItem) => (
          <TimelineEvent key={timelineItem.id} item={timelineItem} onDelete={onDelete} issueBaseLink={issueBaseLink} />
        ))}
      </>
    </>
  );
};

const compareWithCreatedAt = (a: { createdAt: Moment }, b: { createdAt: Moment }) => {
  if (a.createdAt > b.createdAt) {
    return -1;
  } else {
    return 1;
  }
};
