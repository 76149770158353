import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Grid, Card, CardHeader, Tooltip, IconButton, Collapse, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Release } from '../planning.state';
import { BacklogItemPredictedSprint } from '../planning-calc.selectors';
import { useToggleState } from '../../shared';
import { SprintCard } from './sprint-card.component';
import { ReleaseCardContent } from './release-card-content.component';

interface ReleaseBlockProps {
  release: Release;
  backlog: BacklogItemPredictedSprint[];
  expandBtn: boolean;
}

export const ReleaseBlock: React.FC<ReleaseBlockProps> = ({ release, backlog, expandBtn }: ReleaseBlockProps) => {
  const [expandRelease, toggleExpandRelease] = useToggleState(expandBtn);
  const [expandSprints, toggleExpandSprints] = useToggleState(expandBtn);

  useEffect(() => {
    if ((expandRelease && !expandBtn) || (!expandRelease && expandBtn)) {
      toggleExpandRelease();
    }
    // eslint-disable-next-line
  }, [expandBtn]);

  useEffect(() => {
    if (
      (expandSprints && !expandBtn) ||
      (!expandSprints && expandBtn) ||
      (expandSprints && !expandRelease) ||
      (!expandSprints && expandRelease)
    ) {
      toggleExpandSprints();
    }
    // eslint-disable-next-line
  }, [expandBtn, expandRelease]);

  return (
    <Grid container key={release.name} spacing={5}>
      <Grid item sm={3}>
        <Card elevation={3}>
          <CardHeader
            title={release.name}
            action={
              <Tooltip placement='top' title={expandRelease ? 'Collapse' : 'Expand'}>
                <IconButton aria-label='expand-release-card' onClick={toggleExpandRelease}>
                  {expandRelease ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            }
          />
          <Collapse in={expandRelease} timeout='auto' unmountOnExit>
            <CardContent>
              <ReleaseCardContent release={release} />
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
      <Grid item sm={9}>
        {release.sprints.map(sprint => (
          <SprintCard key={`${release.name}-Sprint-${sprint.id}`} sprint={sprint} backlog={backlog} expandSprints={expandSprints} />
        ))}
      </Grid>
    </Grid>
  );
};
