import { createReducer } from '@reduxjs/toolkit';
import {
  loadLoginInfoSucceeded,
  loadOidcConfigSucceeded,
  loginWithEmailPassword,
  loginWithExternalProvider,
  loginWithEmailPasswordFailed,
  setUser,
  loadLoginInfoFailed,
  loadOidcConfigFailed,
  loadAllUsersSucceeded,
  loadAllUsersFailed,
  loadAllRolesSucceeded,
  loadAllRolesFailed,
  loadLoginInfo,
  loadOidcConfigInfo,
  loadAllUsers,
  loadAllRoles,
  loadLogoutInfoSucceeded,
  loadLogoutInfoFailed,
  changePassword,
  changePasswordSucceeded,
  changePasswordFailed,
  changePasswordCanceled,
  createUser,
  createUserSucceeded,
  createUserFailed,
  updateUser,
  updateUserSucceeded,
  updateUserFailed,
  deleteUser,
  deleteUserSucceeded,
  deleteUserFailed,
  processUserCanceled,
} from './auth.actions';
import { initialAuthState, UserProcessEventType } from './auth.state';
import { convertRoleDtoToRole, convertUserDtoToUser } from './auth.converters';

export const authReducer = createReducer(initialAuthState, builder => {
  builder.addCase(setUser, (state, action) => {
    state.user = action.payload.user;
    state.isAuthenticated = !!action.payload.user;
  });

  builder.addCase(loadLoginInfo, state => {
    state.metadata.isPending = true;
  });

  builder.addCase(loadLoginInfoSucceeded, (state, action) => {
    state.metadata = {
      data: action.payload.info,
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadLoginInfoFailed, (state, action) => {
    state.metadata.requestError = action.payload.error;
    state.metadata.isPending = false;
  });

  builder.addCase(loadOidcConfigInfo, state => {
    state.oidcConfig.isPending = true;
  });

  builder.addCase(loadOidcConfigSucceeded, (state, action) => {
    state.oidcConfig.data = action.payload.config;
    state.oidcConfig.requestError = null;
    state.oidcConfig.isPending = false;
    state.isReady = !!action.payload.config;
  });

  builder.addCase(loadOidcConfigFailed, (state, action) => {
    state.oidcConfig.requestError = action.payload.error;
    state.oidcConfig.isPending = false;
  });

  builder.addCase(loginWithEmailPassword, state => {
    state.isAuthenticationPending = true;
  });

  builder.addCase(loginWithExternalProvider, state => {
    state.isAuthenticationPending = true;
  });

  builder.addCase(loginWithEmailPasswordFailed, (state, action) => {
    state.login.error = action.payload.error;
    state.isAuthenticated = false;
    state.isAuthenticationPending = false;
  });

  builder.addCase(loadAllUsers, state => {
    state.users.isPending = true;
  });

  builder.addCase(loadAllUsersSucceeded, (state, action) => {
    state.users = {
      data: action.payload.users.map(dto => convertUserDtoToUser(dto)),
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadAllUsersFailed, (state, action) => {
    state.users.requestError = action.payload.requestError;
    state.users.isPending = false;
  });

  builder.addCase(loadAllRoles, state => {
    state.roles.isPending = true;
  });

  builder.addCase(loadAllRolesSucceeded, (state, action) => {
    state.roles = {
      data: action.payload.roles.map(dto => convertRoleDtoToRole(dto)),
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadAllRolesFailed, (state, action) => {
    state.roles.requestError = action.payload.requestError;
    state.roles.isPending = false;
  });

  builder.addCase(loadLogoutInfoSucceeded, (state, action) => {
    state.logout.logoutInfo = action.payload.logoutInfo;
    state.logout.error = undefined;
  });

  builder.addCase(loadLogoutInfoFailed, (state, action) => {
    state.logout.error = action.payload.requestError;
  });

  builder.addCase(changePassword, state => {
    state.changePassword = {
      requestError: null,
      data: {
        success: false,
      },
      isPending: true,
    };
  });

  builder.addCase(changePasswordSucceeded, state => {
    state.changePassword = {
      requestError: null,
      data: {
        success: true,
      },
      isPending: false,
    };
  });

  builder.addCase(changePasswordFailed, (state, action) => {
    state.changePassword = {
      requestError: action.payload.requestError,
      data: { success: false },
      isPending: false,
    };
  });

  builder.addCase(changePasswordCanceled, state => {
    delete state.changePassword;
  });

  builder.addCase(createUser, (state, action) => {
    state.processUser = {
      requestError: null,
      data: { success: false, eventType: UserProcessEventType.Create, userEmail: action.payload.user.email },
      isPending: true,
    };
  });

  builder.addCase(createUserSucceeded, state => {
    if (state.processUser) {
      state.processUser.data.success = true;
      state.processUser.isPending = false;
      state.processUser.requestError = null;
    }
  });

  builder.addCase(createUserFailed, (state, action) => {
    if (state.processUser) {
      state.processUser.data.success = false;
      state.processUser.isPending = false;
      state.processUser.requestError = action.payload.requestError;
    }
  });

  builder.addCase(updateUser, (state, action) => {
    state.processUser = {
      requestError: null,
      data: { success: false, eventType: UserProcessEventType.Update, userEmail: action.payload.user.email },
      isPending: true,
    };
  });

  builder.addCase(updateUserSucceeded, state => {
    if (state.processUser) {
      state.processUser.data.success = true;
      state.processUser.isPending = false;
      state.processUser.requestError = null;
    }
  });

  builder.addCase(updateUserFailed, (state, action) => {
    if (state.processUser) {
      state.processUser.data.success = false;
      state.processUser.isPending = false;
      state.processUser.requestError = action.payload.requestError;
    }
  });

  builder.addCase(deleteUser, (state, action) => {
    state.processUser = {
      requestError: null,
      data: { success: false, eventType: UserProcessEventType.Delete, userEmail: action.payload.user.email },
      isPending: true,
    };
  });

  builder.addCase(deleteUserSucceeded, state => {
    if (state.processUser) {
      state.processUser.data.success = true;
      state.processUser.isPending = false;
      state.processUser.requestError = null;
    }
  });

  builder.addCase(deleteUserFailed, (state, action) => {
    if (state.processUser) {
      state.processUser.data.success = false;
      state.processUser.isPending = false;
      state.processUser.requestError = action.payload.requestError;
    }
  });

  builder.addCase(processUserCanceled, state => {
    delete state.processUser;
  });
});
