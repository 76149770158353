import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import useMountEffect from '../shared/use-mount-effect';
import { completeLogin } from './auth.actions';

export const CallbackPage: React.FC = () => {
  const urlHash = useLocation().hash;
  const dispatch = useDispatch();
  useMountEffect(() => {
    dispatch(completeLogin(urlHash));
  });

  return <div></div>;
};
