import React from 'react';
import { Chip } from '@material-ui/core';
import { PeopleOutline } from '@material-ui/icons';
import { getContrastBlackOrWhite } from '../../../shared/distinct-colors';
import { RoadmapTeam } from './roadmap-widget.model';

interface RoadmapFilterOptionProps {
  roadmapTeam: RoadmapTeam;
}

export const RoadmapFilterOption: React.FC<RoadmapFilterOptionProps> = ({ roadmapTeam }: RoadmapFilterOptionProps) => {
  const contrastColor = getContrastBlackOrWhite(roadmapTeam.color);
  return (
    <Chip
      style={{
        padding: '1px',
        color: contrastColor,
        backgroundColor: roadmapTeam.color,
        fontWeight: roadmapTeam.active ? 'bold' : 'normal',
      }}
      icon={<PeopleOutline style={{ color: contrastColor }} />}
      label={roadmapTeam.name}
    />
  );
};
