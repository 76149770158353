import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { selectIsReady } from './auth.selectors';

interface AuthorizationReadyRouteComponentProps {
  path: string;
  component: React.FC | React.ComponentClass;
}

export const AuthorizationReadyRoute: React.FC<AuthorizationReadyRouteComponentProps> = (authRouteProps: any) => {
  const renderComponent = useCallback(
    props => {
      const Component = authRouteProps.component;
      return <Component {...props} />;
    },
    [authRouteProps.component],
  );

  const isReady = useSelector(selectIsReady);
  if (!isReady) {
    return <div />;
  }

  return <Route path={authRouteProps.path} render={renderComponent} />;
};
