import React from 'react';

export const roadmapErrorMap: { [key: number]: JSX.Element } = {
  0: <>No team could be found.</>,
  1: <>No active team has been set.</>,
  2: (
    <>
      No team has been selected.
      <br />
      Please select at least one team to view the roadmap.
    </>
  ),
  3: (
    <div style={{ textAlign: 'left' }}>
      There are no epics / features to show.
      <br />
      Please make sure that
      <ul style={{ padding: '0 0 0 16px', margin: '8px 0 0 0' }}>
        <li>backlog items are assigned to epics</li>
        <li>there is at least one active or future sprint</li>
        <li>synthetic or calculated velocity is greater than 0</li>
      </ul>
    </div>
  ),
  4: <>Roadmap Forecast is not available for teams with nonmatching sprints.</>,
};
