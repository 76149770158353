import { User } from 'oidc-client';
import md5 from 'md5';
import { createSelector } from 'reselect';
import { RootState } from '../app.state';
import { AuthState, StateUser } from './auth.state';

export const selectAuth = (state: RootState) => state.auth;

export const selectIsReady = createSelector(selectAuth, auth => auth.isReady);

export const selectIsAuthenticated = createSelector(selectAuth, auth => auth.isAuthenticated);

export const selectLogoutState = createSelector(selectAuth, auth => auth.logout);

export const selectLoggedInUser = createSelector(selectAuth, (auth: AuthState) => auth.user);

export const selectUserRole = createSelector(selectLoggedInUser, (user?: User) => {
  return user && user.profile && user.profile.role ? user.profile.role : null;
});

export const selectUserEmail = createSelector(selectLoggedInUser, (user?: User) => {
  return user && user.profile ? user.profile.email : null;
});

export const selectUserIdp = createSelector(selectLoggedInUser, (user?: User) => {
  return user && user.profile ? user.profile.idp : null;
});

export const selectAvailableUsers = createSelector(selectAuth, (auth: AuthState) => {
  return auth.users.data;
});

export const selectAvailableRoles = createSelector(selectAuth, (auth: AuthState) => {
  return auth.roles.data;
});

export const selectUserAvatarUrl = createSelector(selectUserEmail, email => getAvatarUrlByEmail(email));

export const selectAvatarUrlsForUsers = createSelector(selectAvailableUsers, (users: StateUser[]) => {
  const avatarUrls: { [email: string]: string } = {};
  users.forEach(user => {
    const email = user.email;
    avatarUrls[email] = getAvatarUrlByEmail(email);
  });
  return avatarUrls;
});

export const selectProcessUserState = createSelector(selectAuth, (auth: AuthState) => {
  return auth.processUser;
});

export const selectChangePassword = createSelector(selectAuth, (auth: AuthState) => {
  return auth.changePassword;
});

const userEmailHash = (email: string = '') => md5(email.trim().toLowerCase());

const getAvatarUrlByEmail = (email?: string | null) => {
  return email ? `https://www.gravatar.com/avatar/${userEmailHash(email)}?d=mp` : '';
};
