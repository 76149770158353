import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm, FormContext, Mode } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  PageLayout,
  useGetErrorMessageCallback,
  FormDatePicker,
  OutlinedSelect,
  SpinnerOverlay,
  tryParseNumeric,
  InfoPopover,
  REGEX_STORY_POINTS,
  generateNotificationKey,
  useToggleState,
} from '../../shared';
import { selectFeatureOptions } from '../../app.selectors';
import {
  createTeam,
  BacklogManagementSystem,
  TeamBacklogConfig,
  ISO_DATEFORMAT,
  updateTeam,
  updateTeamCanceled,
  createTeamCanceled,
} from '../../planning';
import { BacklogConfigValues, ReadyStatuses, createDefaultTeam } from '../../planning/team';
import { selectAllTeams, selectCreateTeamState, selectTeam, selectTeamNames, selectUpdateTeamState } from '../planning.selectors';
import { PopoverBodyParagraph } from './popover-body-paragraph.component';
import { selectUserEmail } from '../../auth';
import { TeamCreationHintWindow } from './team-wizard-dialog.component';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    '& > *:not(:last-child)': {
      paddingBottom: theme.spacing(3),
    },
  },
}));

export const TeamPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const isNewTeam = !params.id || params.id === 'new';
  const teamSelector = useSelector(selectTeam);
  const team = teamSelector(params.id) || createDefaultTeam();
  const teamOldName = team && !isNewTeam ? team.name : '';
  const teamNames = useSelector(selectTeamNames);
  const { allowSetDefaultStoryPoints } = useSelector(selectFeatureOptions).data;
  const updateTeamState = useSelector(selectUpdateTeamState);
  const createTeamState = useSelector(selectCreateTeamState);
  const teams = useSelector(selectAllTeams);
  const currentUser = useSelector(selectUserEmail) as string;
  const hasCreatedTeam = teams.filter(team => team.creator === currentUser).length ? true : false;
  const [showWizardDialog, toggleShowWizardDialog] = useToggleState(!hasCreatedTeam && isNewTeam);

  const [processFinished, setProcessFinished] = useState<boolean>(true);

  useEffect(() => {
    if (!createTeamState || createTeamState.isPending) return;
    if (createTeamState.requestError && !processFinished) {
      setProcessFinished(true);
    }
  }, [createTeamState, processFinished]);

  useEffect(() => {
    if (!updateTeamState || updateTeamState.isPending) return;
    if (updateTeamState.requestError && !processFinished) {
      setProcessFinished(true);
    }
  }, [updateTeamState, processFinished]);

  const firstSprintStartDateDefaultValue = moment().format(ISO_DATEFORMAT);

  const firstSprintStartDateValidationCallback = useCallback((firstSprintStartDate: string) => {
    return (
      moment(firstSprintStartDate).isSameOrAfter(moment().subtract(1, 'years'), 'day') ||
      'Start date cannot be more than one year in the past.'
    );
  }, []);

  const getDefaultStoryPointsTooltip = useCallback(() => {
    return (
      <PopoverBodyParagraph>
        Default StoryPoints to set if an imported PBI is either unestimated or has 0 StoryPoints assigned.
      </PopoverBodyParagraph>
    );
  }, []);

  const backlogConfigDefaultValues: BacklogConfigValues = {
    backlogBaseLink: team.config.backlogApiBaseLink,
    backlogItemLink: team.config.workItemBaseLink,
    project: team.config.project,
    domain: team.config.domain,
    backlogPbisQuery: team.config.backlogPbisQuery,
    defaultStoryPoints: team.config.defaultStoryPoints || '',
    isOAuthEnabled: team.config.isOAuthEnabled,
    consumerKey: team.config.oAuth1Configuration?.consumerKey || '',
    privateKey: {
      filename: team.config.oAuth1Configuration?.privateKeyFileName || '',
      content: team.config.oAuth1Configuration?.privateKey || '',
    },
    accessToken: team.config.oAuth1Configuration?.accessToken || '',
  };

  const formOptions = {
    mode: 'onBlur' as Mode,
    defaultValues: {
      name: team.name,
      approvedBacklogItemStatuses: team.config.approvedBacklogItemStatuses,
      defaultSprintDuration: team.config.defaultSprintDuration,
      firstSprintStartDate: firstSprintStartDateDefaultValue,
      backlogManagementSystem: team.config.backlogManagementSystem,
      ...backlogConfigDefaultValues,
    },
  };

  const stateValidation = {
    validate: (approvedBacklogItemStatuses: string[]) =>
      approvedBacklogItemStatuses.find(s => s.length > 50) ? 'Ready state cannot be longer than 50 characters.' : true,
  };

  const formMethods = useForm(formOptions);
  const getErrorMessageForField = useGetErrorMessageCallback(formMethods.errors);

  const [backlogManagementSystem, setBacklogManagementSystem] = useState(team.config.backlogManagementSystem);

  const backlogManagementSystemCallback = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setBacklogManagementSystem(event.target.value as BacklogManagementSystem);
    },
    [setBacklogManagementSystem],
  );

  const onCancel = useCallback(() => {
    setProcessFinished(true);
    if (isNewTeam) {
      dispatch(createTeamCanceled());
    } else {
      dispatch(updateTeamCanceled());
    }
  }, [dispatch, isNewTeam]);

  const onSubmit = useCallback(
    (data: any) => {
      setProcessFinished(false);
      let defaultStoryPoints: string | null = null;
      if (allowSetDefaultStoryPoints) {
        const parsedDefaultStoryPoints = tryParseNumeric(data.defaultStoryPoints);
        defaultStoryPoints = parsedDefaultStoryPoints !== undefined ? parsedDefaultStoryPoints.toString() : '0';
      }
      const notificationKey = generateNotificationKey();
      if (isNewTeam) {
        dispatch(
          createTeam(
            {
              ...team,
              name: data.name,
              creator: currentUser,
              createdOn: moment(),
              config: {
                approvedBacklogItemStatuses: data.approvedBacklogItemStatuses,
                defaultSprintDuration: data.defaultSprintDuration,
                backlogManagementSystem: data.backlogManagementSystem,
                backlogApiBaseLink: data.backlogBaseLink,
                workItemBaseLink: data.backlogItemLink,
                backlogPbisQuery: data.backlogPbisQuery,
                project: data.project,
                domain: data.domain,
                defaultStoryPoints: defaultStoryPoints,
                isOAuthEnabled: data.isOAuthEnabled,
                ...(data.isOAuthEnabled
                  ? {
                      oAuth1Configuration: {
                        accessToken: data.accessToken,
                        consumerKey: data.consumerKey,
                        privateKeyFileName: data.privateKey.filename,
                        privateKey: data.privateKey.content,
                      },
                    }
                  : { oAuth1Configuration: null }),
              },
            },
            data.firstSprintStartDate,
            notificationKey,
          ),
        );
      } else {
        dispatch(
          updateTeam(
            {
              ...team,
              name: data.name,
              config: {
                approvedBacklogItemStatuses: data.approvedBacklogItemStatuses,
                defaultSprintDuration: data.defaultSprintDuration,
                backlogManagementSystem: data.backlogManagementSystem,
                backlogApiBaseLink: data.backlogBaseLink,
                workItemBaseLink: data.backlogItemLink,
                backlogPbisQuery: data.backlogPbisQuery,
                project: data.project,
                domain: data.domain,
                defaultStoryPoints: defaultStoryPoints,
                isOAuthEnabled: data.isOAuthEnabled,
                ...(data.isOAuthEnabled
                  ? {
                      oAuth1Configuration: {
                        accessToken: data.accessToken,
                        consumerKey: data.consumerKey,
                        privateKeyFileName: data.privateKey.filename,
                        privateKey: data.privateKey.content,
                      },
                    }
                  : { oAuth1Configuration: null }),
              },
            },
            '/team-management',
            notificationKey,
          ),
        );
      }
    },
    [allowSetDefaultStoryPoints, dispatch, isNewTeam, team],
  );

  return (
    <div className={classes.form}>
      <FormContext {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <PageLayout
            title={isNewTeam ? 'Create New Team' : `Update Team ${team.name}`}
            actions={
              <>
                {isNewTeam && (
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={() => toggleShowWizardDialog()}>
                      Help
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button onClick={onCancel}>{processFinished ? 'Close' : 'Cancel'}</Button>
                </Grid>
                <Grid item>
                  <SpinnerOverlay
                    button={
                      <Button type='submit' variant='contained' color='primary' data-cy='add-or-update-team' disabled={!processFinished}>
                        {isNewTeam ? 'Create' : 'Update'}
                      </Button>
                    }
                    show={(createTeamState ? createTeamState?.isPending : false) || (updateTeamState ? updateTeamState?.isPending : false)}
                  />
                </Grid>
              </>
            }
            showActionsAtTheEnd={true}
          >
            <Card>
              <CardContent className={classes.cardContent}>
                <TextField
                  name='name'
                  data-cy='team-name'
                  type='text'
                  label='Team Name'
                  variant='outlined'
                  inputRef={formMethods.register({
                    required: 'The Team Name is required.',
                    validate: value =>
                      !teamNames.filter(name => name !== teamOldName).includes(value) || 'A team with that name already exists.',
                    maxLength: {
                      value: 100,
                      message: 'Team name must not be longer than 100 characters.',
                    },
                  })}
                  helperText={getErrorMessageForField('name')}
                  error={!!getErrorMessageForField('name')}
                />
                <TextField
                  disabled={!isNewTeam}
                  name='defaultSprintDuration'
                  type='number'
                  label='Default Sprint Duration (Days)'
                  variant='outlined'
                  inputRef={formMethods.register({
                    required: 'The Default Sprint Duration is required.',
                    min: {
                      value: 1,
                      message: 'The Default Sprint Duration must be at least one day.',
                    },
                  })}
                  helperText={getErrorMessageForField('defaultSprintDuration')}
                  error={!!getErrorMessageForField('defaultSprintDuration')}
                />
                {allowSetDefaultStoryPoints && (
                  <TextField
                    name='defaultStoryPoints'
                    type='text'
                    label='Default StoryPoints per PBI'
                    variant='outlined'
                    placeholder='0.5'
                    inputRef={formMethods.register({
                      pattern: {
                        value: REGEX_STORY_POINTS,
                        message: 'Default StoryPoints should be either 0.5 or a positive number > 0.',
                      },
                    })}
                    InputProps={{
                      endAdornment: <InfoPopover title={'Default StoryPoints per PBI'} content={getDefaultStoryPointsTooltip()} />,
                    }}
                    helperText={getErrorMessageForField('defaultStoryPoints')}
                    error={!!getErrorMessageForField('defaultStoryPoints')}
                  />
                )}
                {isNewTeam && (
                  <FormDatePicker
                    defaultValue={firstSprintStartDateDefaultValue}
                    label='Start date of first sprint'
                    name='firstSprintStartDate'
                    validate={firstSprintStartDateValidationCallback}
                  />
                )}
                <OutlinedSelect
                  name='backlogManagementSystem'
                  inputRef={formMethods.register({ required: 'The PBI Manager is required.' })}
                  label='PBI Manager'
                  errorMessage={getErrorMessageForField('backlogManagementSystem')}
                  onChange={backlogManagementSystemCallback}
                  value={backlogManagementSystem}
                >
                  <MenuItem data-cy='jira' value={BacklogManagementSystem.Jira}>
                    Jira
                  </MenuItem>
                  <MenuItem data-cy='tfs' value={BacklogManagementSystem.Tfs}>
                    TFS
                  </MenuItem>
                  <MenuItem data-cy='azure-devops' value={BacklogManagementSystem.Azure}>
                    Azure DevOps
                  </MenuItem>
                  <MenuItem value={BacklogManagementSystem.Excel} data-cy='excel'>
                    Excel
                  </MenuItem>
                  <MenuItem value={BacklogManagementSystem.FullJiraCsv} data-cy='csv'>
                    JIRA Full CSV Export
                  </MenuItem>
                </OutlinedSelect>
                <Box>
                  <Divider />
                </Box>
                <TeamBacklogConfig backlogManagementSystem={backlogManagementSystem} backlogConfigValues={backlogConfigDefaultValues} />
                <Typography>PBI lifecycle - Definition of ready states</Typography>
                <ReadyStatuses
                  name='approvedBacklogItemStatuses'
                  value={formOptions.defaultValues.approvedBacklogItemStatuses}
                  validation={stateValidation}
                  errorMessage={getErrorMessageForField('approvedBacklogItemStatuses')}
                />
              </CardContent>
            </Card>
          </PageLayout>
        </form>
      </FormContext>
      <TeamCreationHintWindow open={showWizardDialog} onClose={toggleShowWizardDialog} />
    </div>
  );
};
