import { useEffect, EffectCallback } from 'react';

/**
 * Accepts a function that contains imperative, possibly effectful code.
 * Will only be executed upon component mount - not on rerender.
 *
 * @param effect Imperative function that can return a cleanup function.
 */
export default function useMountEffect(effect: EffectCallback): void {
  return useEffect(effect, []);
}
