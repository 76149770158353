import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import 'typeface-roboto';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import App from './App';
import { appDependencies } from './app.dependencies';
import { theme } from './theme';
import { history, store } from './store';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <DndProvider backend={Backend}>
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={3000}
            dense
            hideIconVariant
          >
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App history={history} />
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ConfirmProvider>
      </DndProvider>
    </ThemeProvider>
  </ReduxProvider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
