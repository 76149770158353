export interface CommunityMember {
  firstName: string;
  lastName: string;
  shortName: string;
  imageBase64: string;
}

const image =
  '/9j/4AAQSkZJRgABAAEAYABgAAD//gAfTEVBRCBUZWNobm9sb2dpZXMgSW5jLiBWMS4wMQD/2wCEAAUFBQgFCAwHBwwMCQkJDA0MDAwMDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0BBQgICgcKDAcHDA0MCgwNDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDf/EAaIAAAEFAQEBAQEBAAAAAAAAAAABAgMEBQYHCAkKCwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGRoQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+foRAAIBAgQEAwQHBQQEAAECdwABAgMRBAUhMQYSQVEHYXETIjKBCBRCkaGxwQkjM1LwFWJy0QoWJDThJfEXGBkaJicoKSo1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoKDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uLj5OXm5+jp6vLz9PX29/j5+v/AABEIAEYARgMBEQACEQEDEQH/2gAMAwEAAhEDEQA/APTqACgAoAKACgAoAKACgAoAKACgByqXIVRkkgADqSegFAHoul+EIo0D32Xc87ASFX2JGCx9cEDtz1oA15vDGnzLtEflnsyMwI/MkH8QaAPPda0STR3GTvif7j4x/wABb0b9COR3AAMOgAoAKACgAoA6zwhaC4vDKwyIV3D/AHicD8hkj3AoA9RoAKAM3V7Nb60khIydpK+zKMqfz4+hIoA8UoAKACgAoAKAO08FTBLiSI8F0BH/AAE9PybP4UAek0AFAENzKIInkbgIrMfoATQB4RQAUAFABQAUAWrO7exmSeLhkOfYjuD7EZB9jQB7FpuqQanGHhI3Y+ZD95T7j09D0P6UAaNAHA+KNeR0NlbMGyf3jDpgH7gPc5+8Rx25ycAHAUAFABQAUAdJoOgNqxMkhKQIcEjqx/ur2GB1POOBg9gD0WDRLG3XYkMZHcsoYn6lsn+lAHL6l4TeN/P01tp67NxUj/cb+jEY/vdqAMhtJ1q5/dSeaV6HfLlf1c5/DNAHTaR4Vhsv3l1tml7LjKL+B+8fcjHoM80AaV74esr0YMYjbs0YCn8QBg/iD+FAHmGq6XJpMxhk5B5RugZfX2PYjsfUYJAMygAoA9p0S3FrZQoBj92rH6sNx/U0AalABQAUAFABQByHjO3Elok3eJwP+AsMH9QtAHmNACgbjgdTQB71GgjUIOigD8higB1ABQAUAFABQBg+Jk36dN7bD+Tr/SgDyCgC3Yp5lxEh/ikQfmwFAHuVABQAUAFABQAUAZWurusJx/0zY/lz/SgDxegD/9k=';

export const productOwners: CommunityMember[] = [
  { firstName: 'Lukas', lastName: 'Studer', shortName: 'luk', imageBase64: image },
  { firstName: 'Markus', lastName: 'Wettstein', shortName: 'maw', imageBase64: image },
];

export const codeOwners: CommunityMember[] = [
  { firstName: 'Bruno', lastName: 'Ritz', shortName: 'bri', imageBase64: image },
  { firstName: 'Mario', lastName: 'Sabbatella', shortName: 'sab', imageBase64: image },
  { firstName: 'Bill', lastName: 'Bosshard', shortName: 'bibo', imageBase64: image },
  { firstName: 'Mathoolika', lastName: 'Vasigaran', shortName: 'matv', imageBase64: image },
  { firstName: 'Elias', lastName: 'Mehran', shortName: 'elme', imageBase64: image },
  { firstName: 'Laurin', lastName: 'Kuster', shortName: 'laku', imageBase64: image },
  { firstName: 'Thomas', lastName: 'Beeler', shortName: 'tobe', imageBase64: image },
];

export const contributors: CommunityMember[] = [
  { firstName: 'Markus', lastName: 'Karrer', shortName: 'ka', imageBase64: image },
  { firstName: 'Michael', lastName: 'Lehmann', shortName: 'leh', imageBase64: image },
  { firstName: 'Simon', lastName: 'Schaefer', shortName: 'scsi', imageBase64: image },
  { firstName: 'Guido', lastName: 'Angenendt', shortName: 'guan', imageBase64: image },
  { firstName: 'Martin', lastName: 'Indzhov', shortName: 'mind', imageBase64: image },
  { firstName: 'Yasen', lastName: 'Bonev', shortName: 'yabo', imageBase64: image },
  { firstName: 'Aleksandar', lastName: 'Popov', shortName: 'poal', imageBase64: image },
  { firstName: 'Aleksandar', lastName: 'Nikolov', shortName: 'alni', imageBase64: image },
  { firstName: 'Slobodan', lastName: 'Mirkovic', shortName: 'slmi', imageBase64: image },
  { firstName: 'Jonas', lastName: 'Gschwend', shortName: 'jogs', imageBase64: image },
  { firstName: 'Sinisa', lastName: 'Gujic', shortName: 'sigu', imageBase64: image },
  { firstName: 'Vera', lastName: 'Milojevic', shortName: 'vemi', imageBase64: image },
  { firstName: 'Branko', lastName: 'Tripkovic', shortName: 'btri', imageBase64: image },
  { firstName: 'Sandro', lastName: 'Wicki', shortName: 'wisa', imageBase64: image },
  { firstName: 'Cédric', lastName: 'Stoll', shortName: 'cest', imageBase64: image },
  { firstName: 'Stefan', lastName: 'Hirzel', shortName: 'hir', imageBase64: image },
  { firstName: 'Marcel', lastName: 'Brunschwiler', shortName: 'brm', imageBase64: image },
  { firstName: 'Fabian', lastName: 'Gübeli', shortName: 'fagu', imageBase64: image },
  { firstName: 'Luca', lastName: 'Salzani', shortName: 'lusa', imageBase64: image },
  { firstName: 'Stefan', lastName: 'Zurfluh', shortName: 'stef-zur', imageBase64: image },
  { firstName: 'Isabelle', lastName: 'Roesch', shortName: 'isro', imageBase64: image },
  { firstName: 'Silvan', lastName: 'Stich', shortName: 'sist', imageBase64: image },
  { firstName: 'Andreas', lastName: 'Kramer', shortName: 'ankr', imageBase64: image },
  { firstName: 'Simon', lastName: 'Birrer', shortName: 'sibi', imageBase64: image },
  { firstName: 'Dany', lastName: 'Löffler', shortName: 'dalo', imageBase64: image },
  { firstName: 'Manuel', lastName: 'Meisel', shortName: 'mmei', imageBase64: image },
  { firstName: 'Dorota', lastName: 'Janiszewska', shortName: 'doja', imageBase64: image },
  { firstName: 'David', lastName: 'Fiebig', shortName: 'dafi', imageBase64: image },
  { firstName: 'Mark', lastName: 'Odermatt', shortName: 'niod', imageBase64: image },
  { firstName: 'Chrysanthi', lastName: 'Lagodimou', shortName: 'chla', imageBase64: image },
  { firstName: 'Fabian', lastName: 'Meier', shortName: 'fame', imageBase64: image },
  { firstName: 'Andreas', lastName: 'Caspers', shortName: 'aca', imageBase64: image },
  { firstName: 'Cristian', lastName: 'Duta', shortName: 'crdu', imageBase64: image },
  { firstName: 'Simon', lastName: 'Stucki', shortName: 'stus', imageBase64: image },
  { firstName: 'Stefan', lastName: 'Boesch', shortName: 'steb', imageBase64: image },
  { firstName: 'Lion', lastName: 'Kunz', shortName: 'liku', imageBase64: image },
  { firstName: 'Markus', lastName: 'Kaufmann', shortName: 'mkau', imageBase64: image },
  { firstName: 'Deniss', lastName: 'Seleznevs', shortName: 'dese', imageBase64: image },
  { firstName: 'Romeo', lastName: 'Weber', shortName: 'wero', imageBase64: image },
  { firstName: 'Christian', lastName: 'Straube', shortName: 'chst', imageBase64: image },
  { firstName: 'Felix', lastName: 'Enchiparamban', shortName: 'feen', imageBase64: image },
  { firstName: 'Nick', lastName: 'Rascher', shortName: 'rani', imageBase64: image },
];
