import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { RoadmapWidget, RoadmapExplanationPopover } from '../dashboard/roadmap';
import { PageLayout } from '../../shared';
import { selectActiveTeam } from '../planning.selectors';

const useStyles = makeStyles({
  widgetWrapper: {
    display: 'flex',
  },
  roadmapWidget: {
    flex: '1 1 800px',
    maxWidth: '100%',
    minWidth: '800px',
  },
});

export const RoadmapPage: React.FC = () => {
  const classes = useStyles();
  const team = useSelector(selectActiveTeam);

  const pageTitle = useCallback(() => {
    return (
      <>
        <Grid item xs='auto'>
          <Typography variant='h5' style={{ paddingRight: '10px' }}>
            Roadmap Forecast
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <RoadmapExplanationPopover />
        </Grid>
        <Grid item xs>
          <React.Fragment />
        </Grid>
      </>
    );
  }, []);

  return (
    <PageLayout title={pageTitle()}>
      <Grid container className={classes.widgetWrapper} spacing={1}>
        <Grid item className={classes.roadmapWidget}>
          <RoadmapWidget team={team} isFullRoadmap={true} />
        </Grid>
      </Grid>
    </PageLayout>
  );
};
