import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SharedProps, useSnackbar } from 'notistack';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { selectNotifications } from '../app.selectors';
import { removeNotification } from '../app.actions';

let displayed: any[] = [];

export const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeDisplayed = (id: React.ReactText) => {
    displayed = displayed.filter(key => id !== key);
  };

  useEffect(() => {
    notifications.forEach(({ key, message, variant, dismissed = false, autoHideDuration }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key: key,
        variant: variant as SharedProps['variant'],
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: variant === 'error' ? null : autoHideDuration || 3000,
        action: key => {
          if (variant === 'error') {
            return (
              <Tooltip title='Close'>
                <IconButton aria-label='close snackbar' onClick={() => closeSnackbar(key)}>
                  <Close style={{ color: 'white' }} fontSize='small' />
                </IconButton>
              </Tooltip>
            );
          }
        },
        onExited: (event, key) => {
          dispatch(removeNotification(key));
          removeDisplayed(key);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, dispatch, enqueueSnackbar, closeSnackbar]);

  return null;
};
