import React from 'react';
import { Link } from '@material-ui/core';

export interface IssueLinkProps {
  issueBaseLink: string;
  sourceId: string;
}

export const IssueLink: React.FC<IssueLinkProps> = ({ issueBaseLink, sourceId }) => {
  return (
    <>
      {issueBaseLink === undefined ? (
        <>{sourceId}</>
      ) : (
        <Link target='_blank' rel='noreferrer' href={`${issueBaseLink}${sourceId}`}>
          {sourceId}
        </Link>
      )}
    </>
  );
};
