import { Moment } from 'moment';
import { Sprint } from '..';

export const getMin = (numbers: number[]): number => (numbers.length ? Math.min(...numbers) : 0);

export const getMax = (numbers: number[]): number => (numbers.length ? Math.max(...numbers) : 0);

export const median = (array: number[]) => {
  if (!array.length) {
    return 0;
  }
  const mid = Math.floor(array.length / 2),
    numbers = [...array].sort((a, b) => a - b);
  return array.length % 2 !== 0 ? numbers[mid] : (numbers[mid - 1] + numbers[mid]) / 2;
};

export const calculateForecast = (sprint: Sprint, medianVelocity: number) => {
  return medianVelocity * sprint.personDays;
};

export const round = (number: number, decimal: number) => {
  const factor: number = Math.pow(10, decimal);
  return Math.round(number * factor) / factor;
};

export const compareWithStartDate = (a: { startDate: Moment }, b: { startDate: Moment }) => {
  if (a.startDate < b.startDate) {
    return -1;
  } else {
    return 1;
  }
};
