import { Redirect, Route, Switch } from 'react-router';
import React, { useCallback } from 'react';
import { AuthorizationReadyRoute, AuthorizedRoute, CallbackPage, LoginPage, LogoutPage, ExternalErrorPage } from './auth';
import {
  TeamManagementPage,
  DashboardPage,
  SprintManagementPage,
  TeamPage,
  CopyTeamPage,
  TimelinePage,
  UserManagementPage,
} from './planning';
import { RoadmapPage } from './planning/roadmap';
import { ReleasePlanPage } from './planning/release-plan';
import { AboutPage } from './about';

const AppRoutesComponent: React.FC = () => {
  const fallback = useCallback(({ staticContext }) => {
    if (staticContext) {
      staticContext.statusCode = 404;
    }
    return <div />;
  }, []);

  return (
    <Switch>
      <Redirect exact from='/' to='/dashboard' />
      <Route path='/login'>
        <LoginPage />
      </Route>
      <Route path='/logout'>
        <LogoutPage />
      </Route>
      <Route path='/error'>
        <ExternalErrorPage />
      </Route>
      <AuthorizationReadyRoute path='/callback' component={CallbackPage} />
      <AuthorizedRoute path='/dashboard' component={DashboardPage} />
      <AuthorizedRoute path='/roadmap' component={RoadmapPage} />
      <AuthorizedRoute path='/team-management' component={TeamManagementPage} />
      <AuthorizedRoute path='/team/new' component={TeamPage} />
      <AuthorizedRoute path='/teams/edit/:id' component={TeamPage} />
      <AuthorizedRoute path='/teams/copy/:id' component={CopyTeamPage} />
      <AuthorizedRoute path='/sprint-management' component={SprintManagementPage} />
      <AuthorizedRoute path='/timeline' component={TimelinePage} />
      <AuthorizedRoute path='/release-plan' component={ReleasePlanPage} />
      <AuthorizedRoute path='/about' component={AboutPage} />
      <AuthorizedRoute path='/user-management' component={UserManagementPage} />
      <Route path='*' render={fallback} />
    </Switch>
  );
};

export const AppRoutes = AppRoutesComponent;
