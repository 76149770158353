import React, { useState, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Menu, Fade, MenuItem, Tooltip, Button } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateDeliverySprintId } from '../../planning.actions';
import { DATEFORMAT } from '../..';
import { Sprint, Team } from '../../planning.state';

export interface DeliverySprintDropdownProps {
  team: Team;
  deliverySprint: Sprint | undefined;
  deliverySprints: Sprint[];
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1, 1, 1, 0),
    minWidth: '210px',
    float: 'right',
  },
  menuItem: {
    minWidth: '210px',
  },
}));

export const DeliverySprintDropdown: React.FC<DeliverySprintDropdownProps> = ({ team, deliverySprint, deliverySprints }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleSelectDeliveryDateButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelectDeliveryDateMenu = () => {
    setAnchorEl(null);
  };

  const updateDeliverySprintCallback = useCallback(
    (sprint: Sprint) => {
      setAnchorEl(null);
      dispatch(
        updateDeliverySprintId({
          ...team,
          viewSettings: {
            ...team.viewSettings,
            deliverySprintId: sprint.id,
          },
        }),
      );
    },
    [team, dispatch],
  );

  return (
    <>
      <Tooltip title='Delivery Sprint'>
        <Button
          className={classes.formControl}
          onClick={handleSelectDeliveryDateButtonClick}
          endIcon={<ExpandMore />}
          variant='outlined'
          color='primary'
        >
          {deliverySprint ? `${deliverySprint?.sprintName} (${moment(deliverySprint?.endDate).format(DATEFORMAT)})` : 'Delivery Sprint'}
        </Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseSelectDeliveryDateMenu} TransitionComponent={Fade}>
        {deliverySprints.map(sprint => (
          <MenuItem
            key={sprint.id}
            selected={deliverySprint ? deliverySprint?.id === sprint.id : false}
            onClick={() => {
              updateDeliverySprintCallback(sprint);
              setAnchorEl(null);
            }}
            className={classes.menuItem}
          >
            {`${sprint.sprintName} (${moment(sprint.endDate).format(DATEFORMAT)})`}
          </MenuItem>
        ))}
      </Menu>{' '}
    </>
  );
};
