import React from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { Release } from '../planning.state';
import { DATEFORMAT } from '..';

interface ReleaseCardContentProps {
  release: Release;
}

export const ReleaseCardContent: React.FC<ReleaseCardContentProps> = ({ release }: ReleaseCardContentProps) => {
  const { releaseDate, deploymentDate, personDays, minPredictedStoryPoints, medPredictedStoryPoints, maxPredictedStoryPoints } = release;

  return (
    <>
      <Typography>Release date: {moment(releaseDate).format(DATEFORMAT)}</Typography>
      <Typography>Deployment date: {moment(deploymentDate).format(DATEFORMAT)}</Typography>
      <Typography>Total capacity: {personDays} PDs</Typography>
      <Typography align='right'>min {minPredictedStoryPoints} SPs</Typography>
      <Typography align='right'>med {medPredictedStoryPoints} SPs</Typography>
      <Typography align='right'>max {maxPredictedStoryPoints} SPs</Typography>
    </>
  );
};
