import { createAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { RequestError } from '../shared';
import { InitializePrivateDataAction } from '../app.actions';
import {
  TeamDto,
  SprintDto,
  BacklogItemDto,
  ChartViewSettingsDto,
  ScenarioDto,
  TimelineItemDto,
  UpdateBacklogOptions,
  TeamId,
  ChartType,
  SprintConfigurationDto,
} from './planning.model';
import { Team, Sprint, BacklogItem, AddTimelineItem, TimelineItem, IndividualChartSettings } from './planning.state';

export const loadAllTeamsAndActiveTeam = createAction('[PLANNING] LOAD_ALL_TEAMS_AND_ACTIVE_TEAM');
export type LoadAllTeamsAndActiveTeamAction = ReturnType<typeof loadAllTeamsAndActiveTeam>;

export const loadAllTeamsAndActiveTeamSucceeded = createAction(
  '[PLANNING] LOAD_ALL_TEAMS__AND_ACTIVE_TEAM_SUCCEEDED',
  (teams: TeamDto[]) => ({
    payload: {
      teams,
    },
  }),
);
export type LoadAllTeamsAndActiveTeamSucceededAction = ReturnType<typeof loadAllTeamsAndActiveTeamSucceeded>;

export const loadAllTeamsAndActiveTeamFailed = createAction(
  '[PLANNING] LOAD_ALL_TEAMS_AND_ACTIVE_TEAM_FAILED',
  (requestError: RequestError) => ({
    payload: {
      requestError,
    },
  }),
);
export type LoadAllTeamsAndActiveTeamFailedAction = ReturnType<typeof loadAllTeamsAndActiveTeamFailed>;

export const loadDataForTeam = createAction('[PLANNING] LOAD_DATA_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type LoadDataForTeamAction = ReturnType<typeof loadDataForTeam>;

export const loadSprintsForTeam = createAction('[PLANNING] LOAD_SPRINTS_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type LoadSprintsForTeamAction = ReturnType<typeof loadSprintsForTeam>;

export const loadSprintsForTeamSucceeded = createAction(
  '[PLANNING] LOAD_SPRINTS_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, sprints: SprintDto[]) => ({
    payload: {
      teamId,
      sprints,
    },
  }),
);
export type LoadSprintsForTeamSucceededAction = ReturnType<typeof loadSprintsForTeamSucceeded>;

export const loadSprintsForTeamFailed = createAction(
  '[PLANNING] LOAD_SPRINTS_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type LoadSprintsForTeamFailedAction = ReturnType<typeof loadSprintsForTeamFailed>;

export const updateSprintConfigurationForTeam = createAction(
  '[PLANNING] UPDATE_SPRINT_CONFIGURATION_FOR_TEAM',
  (team: Team, sprints: Sprint[], notificationKey: number) => ({
    payload: {
      team,
      sprints,
      notificationKey,
    },
  }),
);
export type UpdateSprintConfigurationForTeamAction = ReturnType<typeof updateSprintConfigurationForTeam>;

export const updateSprintConfigurationForTeamSucceeded = createAction(
  '[PLANNING] UPDATE_SPRINT_CONFIGURATION_FOR_TEAM_SUCCEEDED',
  (teamId: string, sprintConfiguration: SprintConfigurationDto) => ({
    payload: {
      teamId,
      sprintConfiguration,
    },
  }),
);
export type UpdateSprintConfigurationForTeamSucceededAction = ReturnType<typeof updateSprintConfigurationForTeamSucceeded>;

export const updateSprintConfigurationForTeamFailed = createAction(
  '[PLANNING] UPDATE_SPRINT_CONFIGURATION_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type UpdateSprintConfigurationForTeamFailedAction = ReturnType<typeof updateSprintConfigurationForTeamFailed>;

export const loadBacklogItemsForTeam = createAction('[PLANNING] LOAD_BACKLOG_ITEMS_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type LoadBacklogItemsForTeamAction = ReturnType<typeof loadBacklogItemsForTeam>;

export const loadBacklogItemsForTeamSucceeded = createAction(
  '[PLANNING] LOAD_BACKLOG_ITEMS_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, backlogItems: BacklogItemDto[]) => ({
    payload: {
      teamId,
      backlogItems,
    },
  }),
);
export type LoadBacklogItemsForTeamSucceededAction = ReturnType<typeof loadBacklogItemsForTeamSucceeded>;

export const loadBacklogItemsForTeamFailed = createAction(
  '[PLANNING] LOAD_BACKLOG_ITEMS_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type LoadBacklogItemsForTeamFailedAction = ReturnType<typeof loadBacklogItemsForTeamFailed>;

export const loadScenariosForTeam = createAction('[PLANNING] LOAD_SCENARIOS_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));

export type LoadScenariosForTeamAction = ReturnType<typeof loadScenariosForTeam>;

export const loadScenariosForTeamSucceeded = createAction(
  '[PLANNING] LOAD_SCENARIOS_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, scenarios: ScenarioDto[]) => ({
    payload: {
      teamId,
      scenarios,
    },
  }),
);

export type LoadScenariosForTeamSucceededAction = ReturnType<typeof loadScenariosForTeamSucceeded>;

export const loadScenariosForTeamFailed = createAction(
  '[PLANNING] LOAD_SCENARIOS_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type LoadScenariosForTeamFailedAction = ReturnType<typeof loadScenariosForTeamFailed>;

export const loadTimelineItemsForTeam = createAction('[PLANNING] LOAD_TIMELINE_ITEMS_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));

export type LoadTimelineItemsForTeamAction = ReturnType<typeof loadTimelineItemsForTeam>;

export const loadTimelineItemsForTeamSucceeded = createAction(
  '[PLANNING] LOAD_TIMELINE_ITEMS_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, timelineItems: TimelineItemDto[]) => ({
    payload: {
      teamId,
      timelineItems,
    },
  }),
);

export type LoadTimelineItemsForTeamSucceededAction = ReturnType<typeof loadTimelineItemsForTeamSucceeded>;

export const loadTimelineItemsForTeamFailed = createAction(
  '[PLANNING] LOAD_TIMELINE_ITEMS_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type LoadTimelineItemsForTeamFailedAction = ReturnType<typeof loadTimelineItemsForTeamFailed>;

export const loadChartViewSettingsForTeam = createAction('[PLANNING] LOAD_CHART_VIEW_SETTINGS_FOR_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type LoadChartViewSettingsForTeamAction = ReturnType<typeof loadChartViewSettingsForTeam>;

export const loadChartViewSettingsForTeamSucceeded = createAction(
  '[PLANNING] LOAD_CHART_VIEW_SETTINGS_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, chartViewSettings: ChartViewSettingsDto[]) => ({
    payload: {
      teamId,
      chartViewSettings,
    },
  }),
);
export type LoadChartViewSettingsForTeamSucceededAction = ReturnType<typeof loadChartViewSettingsForTeamSucceeded>;

export const loadChartViewSettingsForTeamFailed = createAction(
  '[PLANNING] LOAD_CHART_VIEW_SETTINGS_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type LoadChartViewSettingsForTeamFailedAction = ReturnType<typeof loadChartViewSettingsForTeamFailed>;

export const updateChartViewSettingsForTeam = createAction(
  '[PLANNING] UPDATE_CHART_VIEW_SETTINGS_FOR_TEAM',
  (teamId: string, chartViewSettings: IndividualChartSettings, chartType: ChartType) => ({
    payload: {
      teamId,
      chartViewSettings,
      chartType,
    },
  }),
);
export type UpdateChartViewSettingsForTeamAction = ReturnType<typeof updateChartViewSettingsForTeam>;

export const updateChartViewSettingsForTeamSucceeded = createAction(
  '[PLANNING] UPDATE_CHART_VIEW_SETTINGS_FOR_TEAM_SUCCEEDED',
  (teamId: string) => ({
    payload: {
      teamId,
    },
  }),
);
export type UpdateChartViewSettingsForTeamSucceededAction = ReturnType<typeof updateChartViewSettingsForTeamSucceeded>;

export const updateChartViewSettingsForTeamFailed = createAction(
  '[PLANNING] UPDATE_CHART_VIEW_SETTINGS_FOR_TEAM_FAILED',
  (teamId: string, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type UpdateChartViewSettingsForTeamFailedAction = ReturnType<typeof updateChartViewSettingsForTeamFailed>;

export const setActiveTeam = createAction('[PLANNING] SET_ACTIVE_TEAM', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type SetActiveTeamAction = ReturnType<typeof setActiveTeam>;

export const updateBacklogForTeam = createAction(
  '[PLANNING] UPDATE_BACKLOG_FOR_TEAM',
  (teamId: TeamId, notificationKey: number, updateBacklogOptions: UpdateBacklogOptions) => ({
    payload: {
      teamId,
      notificationKey,
      updateBacklogOptions,
    },
  }),
);
export type UpdateBacklogForTeamAction = ReturnType<typeof updateBacklogForTeam>;

export const updateBacklogForTeamOAuth = createAction(
  '[PLANNING] UPDATE_BACKLOG_FOR_TEAM_OAUTH',
  (teamId: TeamId, notificationKey: number, keepOldBacklogItems: boolean, defaultStoryPoints?: number) => ({
    payload: {
      teamId,
      notificationKey,
      keepOldBacklogItems,
      defaultStoryPoints,
    },
  }),
);
export type UpdateBacklogForTeamOAuthAction = ReturnType<typeof updateBacklogForTeamOAuth>;

export const updateBacklogForTeamExcel = createAction(
  '[PLANNING] UPDATE_BACKLOG_FOR_TEAM_EXCEL',
  (teamId: TeamId, notificationKey: number, file: any, keepOldBacklogItems: boolean, defaultStoryPoints?: number) => ({
    payload: {
      teamId,
      notificationKey,
      file,
      keepOldBacklogItems,
      defaultStoryPoints,
    },
  }),
);
export type UpdateBacklogForTeamExcelAction = ReturnType<typeof updateBacklogForTeamExcel>;

export const updateBacklogForTeamFullJiraCsv = createAction(
  '[PLANNING] UPDATE_BACKLOG_FOR_TEAM_CSV',
  (teamId: TeamId, notificationKey: number, file: any, keepOldBacklogItems: boolean, defaultStoryPoints?: number) => ({
    payload: {
      teamId,
      notificationKey,
      file,
      keepOldBacklogItems,
      defaultStoryPoints,
    },
  }),
);
export type UpdateBacklogForTeamCsvAction = ReturnType<typeof updateBacklogForTeamFullJiraCsv>;

export const updateBacklogForTeamSucceeded = createAction('[PLANNING] UPDATE_BACKLOG_FOR_TEAM_SUCCEEDED', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type UpdateBacklogForTeamSucceededAction = ReturnType<typeof updateBacklogForTeamSucceeded>;

export const updateBacklogForTeamFailed = createAction(
  '[PLANNING] UPDATE_BACKLOG_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type UpdateBacklogForTeamFailedAction = ReturnType<typeof updateBacklogForTeamFailed>;

export const updateBacklogForTeamCanceled = createAction('[PLANNING] UPDATE_BACKLOG_FOR_TEAM_CANCELED', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type UpdateBacklogForTeamCanceledAction = ReturnType<typeof updateBacklogForTeamCanceled>;

export const createTeam = createAction('[PLANNING] CREATE_TEAM', (team: Team, firstSprintStartDate: Moment, notificationKey: number) => ({
  payload: {
    team,
    firstSprintStartDate,
    notificationKey,
  },
}));
export type CreateTeamAction = ReturnType<typeof createTeam>;

export const createTeamSucceeded = createAction('[PLANNING] CREATE_TEAM_SUCCEEDED', (team: TeamDto) => ({
  payload: {
    team,
  },
}));
export type CreateTeamSucceededAction = ReturnType<typeof createTeamSucceeded>;

export const createTeamFailed = createAction('[PLANNING] CREATE_TEAM_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type CreateTeamFailedAction = ReturnType<typeof createTeamFailed>;

export const createTeamCanceled = createAction('[PLANNING] CREATE_TEAM_CANCELED');
export type CreateTeamCanceledAction = ReturnType<typeof createTeamCanceled>;

export const copyTeam = createAction('[PLANNING] COPY_TEAM', (team: Team, name: string, notificationKey: number) => ({
  payload: {
    team,
    name,
    notificationKey,
  },
}));
export type CopyTeamAction = ReturnType<typeof copyTeam>;

export const copyTeamSucceeded = createAction('[PLANNING] COPY_TEAM_SUCCEEDED', (teamDto: TeamDto) => ({
  payload: {
    teamDto,
  },
}));
export type CopyTeamSucceededAction = ReturnType<typeof copyTeamSucceeded>;

export const copyTeamFailed = createAction('[PLANNING] COPY_TEAM_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type CopyTeamFailedAction = ReturnType<typeof copyTeamFailed>;

export const copyTeamCanceled = createAction('[PLANNING] COPY_TEAM_CANCELED');
export type CopyTeamCanceledAction = ReturnType<typeof copyTeamCanceled>;

export const updateTeam = createAction('[PLANNING] UPDATE_TEAM', (team: Team, redirectLocation?: string, notificationKey?: number) => ({
  payload: {
    team,
    redirectLocation,
    notificationKey,
  },
}));
export type UpdateTeamAction = ReturnType<typeof updateTeam>;

export const updateTeamSucceeded = createAction('[PLANNING] UPDATE_TEAM_SUCCEEDED', (teamDto: TeamDto) => ({
  payload: {
    teamDto,
  },
}));
export type UpdateTeamSucceededAction = ReturnType<typeof updateTeamSucceeded>;

export const updateTeamFailed = createAction('[PLANNING] UPDATE_TEAM_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type UpdateTeamFailedAction = ReturnType<typeof updateTeamFailed>;

export const updateTeamCanceled = createAction('[PLANNING] UPDATE_TEAM_CANCELED');
export type UpdateTeamCanceledAction = ReturnType<typeof updateTeamCanceled>;

export const deleteTeam = createAction('[PLANNING] DELETE_TEAM', (teamId: TeamId, notificationKey: number) => ({
  payload: {
    teamId,
    notificationKey,
  },
}));
export type DeleteTeamAction = ReturnType<typeof deleteTeam>;

export const deleteTeamSucceeded = createAction('[PLANNING] DELETE_TEAM_SUCCEEDED', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type DeleteTeamSucceededAction = ReturnType<typeof deleteTeamSucceeded>;

export const deleteTeamFailed = createAction('[PLANNING] DELETE_TEAM_FAILED', (teamId: TeamId, requestError: RequestError) => ({
  payload: {
    teamId,
    requestError,
  },
}));
export type DeleteTeamFailedAction = ReturnType<typeof deleteTeamFailed>;

export const updateDeliverySprintId = createAction('[PLANNING] UPDATE_DELIVERY_SPRINT_ID', (team: Team) => ({
  payload: {
    team,
  },
}));
export type UpdateDeliverySprintIdAction = ReturnType<typeof updateDeliverySprintId>;

export const updateDeliverySprintIdSucceeded = createAction('[PLANNING] UPDATE_DELIVERY_SPRINT_ID_SUCCEEDED', (teamDto: TeamDto) => ({
  payload: {
    teamDto,
  },
}));
export type UpdateDeliverySprintIdSucceededAction = ReturnType<typeof updateDeliverySprintIdSucceeded>;

export const updateDeliverySprintIdFailed = createAction('[PLANNING] UPDATE_DELIVERY_SPRINT_ID_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type UpdateDeliverySprintIdFailedAction = ReturnType<typeof updateDeliverySprintIdFailed>;

export const updateSelectedTeamIds = createAction('[PLANNING] UPDATE_SELECTED_TEAM_IDS', (team: Team) => ({
  payload: {
    team,
  },
}));
export type UpdateSelectedTeamIdsAction = ReturnType<typeof updateSelectedTeamIds>;

export const updateSelectedTeamIdsSucceeded = createAction('[PLANNING] UPDATE_SELECTED_TEAM_IDS_SUCCEEDED', (teamDto: TeamDto) => ({
  payload: {
    teamDto,
  },
}));
export type UpdateSelectedTeamIdsSucceededAction = ReturnType<typeof updateSelectedTeamIdsSucceeded>;

export const updateSelectedTeamIdsFailed = createAction('[PLANNING] UPDATE_SELECTED_TEAM_IDS_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type UpdateSelectedTeamIdsFailedAction = ReturnType<typeof updateSelectedTeamIdsFailed>;

export const moveBacklogItem = createAction('[PLANNING] MOVE_BACKLOG_ITEM', (teamId: string, backlog: BacklogItem[]) => ({
  payload: {
    teamId,
    backlog,
  },
}));
export type MoveBacklogItemAction = ReturnType<typeof moveBacklogItem>;

export const moveBacklogItemSucceeded = createAction(
  '[PLANNING] MOVE_BACKLOG_ITEM_SUCCEEDED',
  (teamId: string, backlog: BacklogItem[]) => ({
    payload: {
      teamId,
      backlog,
    },
  }),
);
export type MoveBacklogItemSucceededAction = ReturnType<typeof moveBacklogItemSucceeded>;

export const moveBacklogItemFailed = createAction('[PLANNING] MOVE_BACKLOG_ITEM_FAILED', (teamId: string, requestError: RequestError) => ({
  payload: {
    teamId,
    requestError,
  },
}));
export type MoveBacklogItemFailedAction = ReturnType<typeof moveBacklogItemFailed>;

export const createTimelineItemForTeam = createAction(
  '[PLANNING] CREATE_TIMELINE_ITEM_FOR_TEAM',
  (teamId: TeamId, timelineItem: AddTimelineItem, notificationKey: number) => ({
    payload: {
      teamId,
      timelineItem,
      notificationKey,
    },
  }),
);
export type CreateTimelineItemForTeamAction = ReturnType<typeof createTimelineItemForTeam>;

export const createTimelineItemForTeamSucceeded = createAction(
  '[PLANNING] CREATE_TIMELINE_ITEM_FOR_TEAM_SUCCEEDED',
  (teamId: TeamId, timelineItem: TimelineItemDto) => ({
    payload: {
      teamId,
      timelineItem,
    },
  }),
);
export type CreateTimelineItemForTeamSucceededAction = ReturnType<typeof createTimelineItemForTeamSucceeded>;

export const createTimelineItemForTeamFailed = createAction(
  '[PLANNING] CREATE_TIMELINE_ITEM_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type CreateTimelineItemForTeamFailedAction = ReturnType<typeof createTimelineItemForTeamFailed>;

export const deleteTimelineItemForTeam = createAction(
  '[PLANNING] DELETE_TIMELINE_ITEM_FOR_TEAM',
  (teamId: TeamId, timelineItem: TimelineItem, notificationKey: number) => ({
    payload: {
      teamId,
      timelineItem,
      notificationKey,
    },
  }),
);
export type DeleteTimelineItemForTeamAction = ReturnType<typeof deleteTimelineItemForTeam>;

export const deleteTimelineItemForTeamSucceeded = createAction('[PLANNING] DELETE_TIMELINE_ITEM_FOR_TEAM_SUCCEEDED', (teamId: TeamId) => ({
  payload: {
    teamId,
  },
}));
export type DeleteTimelineItemForTeamSucceededAction = ReturnType<typeof deleteTimelineItemForTeamSucceeded>;

export const deleteTimelineItemForTeamFailed = createAction(
  '[PLANNING] DELETE_TIMELINE_ITEM_FOR_TEAM_FAILED',
  (teamId: TeamId, requestError: RequestError) => ({
    payload: {
      teamId,
      requestError,
    },
  }),
);
export type DeleteTimelineItemForTeamFailedAction = ReturnType<typeof deleteTimelineItemForTeamFailed>;

export type PlanningActionTypes =
  | InitializePrivateDataAction
  | LoadAllTeamsAndActiveTeamAction
  | LoadAllTeamsAndActiveTeamSucceededAction
  | LoadAllTeamsAndActiveTeamFailedAction
  | LoadDataForTeamAction
  | LoadSprintsForTeamAction
  | LoadSprintsForTeamSucceededAction
  | LoadSprintsForTeamFailedAction
  | LoadScenariosForTeamAction
  | LoadScenariosForTeamSucceededAction
  | LoadScenariosForTeamFailedAction
  | LoadTimelineItemsForTeamAction
  | LoadTimelineItemsForTeamSucceededAction
  | LoadTimelineItemsForTeamFailedAction
  | LoadBacklogItemsForTeamAction
  | LoadBacklogItemsForTeamSucceededAction
  | LoadBacklogItemsForTeamFailedAction
  | LoadChartViewSettingsForTeamAction
  | LoadChartViewSettingsForTeamSucceededAction
  | LoadChartViewSettingsForTeamFailedAction
  | UpdateChartViewSettingsForTeamAction
  | UpdateChartViewSettingsForTeamSucceededAction
  | UpdateChartViewSettingsForTeamFailedAction
  | UpdateBacklogForTeamAction
  | UpdateBacklogForTeamOAuthAction
  | UpdateBacklogForTeamExcelAction
  | UpdateBacklogForTeamCsvAction
  | UpdateBacklogForTeamSucceededAction
  | UpdateBacklogForTeamFailedAction
  | UpdateBacklogForTeamCanceledAction
  | CreateTeamAction
  | CreateTeamSucceededAction
  | CreateTeamFailedAction
  | CreateTeamCanceledAction
  | CopyTeamAction
  | CopyTeamSucceededAction
  | CopyTeamFailedAction
  | CopyTeamCanceledAction
  | UpdateTeamAction
  | UpdateTeamSucceededAction
  | UpdateTeamFailedAction
  | UpdateTeamCanceledAction
  | DeleteTeamAction
  | DeleteTeamSucceededAction
  | DeleteTeamFailedAction
  | SetActiveTeamAction
  | UpdateDeliverySprintIdAction
  | UpdateDeliverySprintIdSucceededAction
  | UpdateDeliverySprintIdFailedAction
  | UpdateSelectedTeamIdsAction
  | UpdateSelectedTeamIdsSucceededAction
  | UpdateSelectedTeamIdsFailedAction
  | MoveBacklogItemAction
  | MoveBacklogItemSucceededAction
  | MoveBacklogItemFailedAction
  | UpdateSprintConfigurationForTeamAction
  | UpdateSprintConfigurationForTeamSucceededAction
  | UpdateSprintConfigurationForTeamFailedAction
  | CreateTimelineItemForTeamAction
  | CreateTimelineItemForTeamSucceededAction
  | CreateTimelineItemForTeamFailedAction
  | DeleteTimelineItemForTeamAction
  | DeleteTimelineItemForTeamSucceededAction
  | DeleteTimelineItemForTeamFailedAction;
