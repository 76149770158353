const colors = [
  '#27bda0',
  '#366ec1',
  '#ffb435',
  '#ff8535',
  '#a81c0f',
  '#a39275',
  '#f947ab',
  '#21d577',
  '#d083f5',
  '#c9e74c',
  '#0e91f7',
  '#e0a228',
  '#3da8eb',
  '#f37455',
  '#44e9ec',
  '#e57eab',
  '#86a836',
  '#f1b2f2',
  '#5cbe85',
  '#f1d5ea',
  '#429fa0',
  '#f5cb9f',
  '#5dd2f8',
  '#9f92a7',
  '#cae6da',
];

export function getDistinctColor(index: number): string {
  return colors[index % colors.length];
}

export function getDistinctColorArray(start: number, size: number): string[] {
  return colors.slice(start, size);
}

export function getContrastBlackOrWhite(hexColor: string): string {
  if (hexColor.indexOf('#') === 0) {
    hexColor = hexColor.slice(1);
  }
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
}
