import { AjaxResponse } from 'rxjs/ajax';
import { RequestError } from '../shared';
import { ExternalProviderDto } from './auth.model';

export function createExternalLoginUrl(applicationUrl: string, provider: ExternalProviderDto, returnUrl: string): string {
  return `${applicationUrl}/api/account/externallogin?provider=${provider.authenticationScheme}&returnUrl=${encodeURIComponent(returnUrl)}`;
}
export function mapLoginResponseToError(response: AjaxResponse<any>): RequestError {
  if (response.status === 401) {
    return {
      status: response.status,
      errors: {
        '': ['Your email and password are incorrect - please try again.'],
      },
    };
  }

  if (response.response) {
    return {
      status: response.status,
      errors: response.response,
    };
  }

  return {
    status: response.status,
    errors: {
      '': ['An unknown error occurred.'],
    },
  };
}
