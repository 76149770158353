import { createSelector } from 'reselect';
import { getDistinctColorArray } from '../../../shared/distinct-colors';
import { ChartType } from '../../planning.model';
import {
  selectExtendedChartViewSettingsFactory,
  selectProductBacklogWithPredictedSprint,
  BacklogItemPredictedSprint,
  ExtendedChartViewSettings,
  SprintExtended,
} from '../../planning-calc.selectors';

export const selectBurndownChartData = createSelector(
  selectProductBacklogWithPredictedSprint,
  selectExtendedChartViewSettingsFactory(ChartType.BurnDown),
  (backlogItems: BacklogItemPredictedSprint[], settings?: ExtendedChartViewSettings) => {
    if (!settings) {
      return undefined;
    }
    let datasets = [];
    const colors = getDistinctColorArray(0, settings.selectedEpics.length);
    for (let i = 0; i < settings.selectedEpics.length; i++) {
      const backlogItemsForEpic = backlogItems.filter(b => b.epicName === settings.selectedEpics[i]);

      datasets.push({
        label: settings.selectedEpics[i],
        data: getChartDataForEpic(settings.sprintsInRange, backlogItemsForEpic),
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: colors[i],
      });
    }

    const { selectedBeginSprintId, selectedEndSprintId, selectedEpics } = settings;

    return {
      labels: settings.sprintLabels,
      datasets,
      selectedBeginSprintId,
      selectedEndSprintId,
      selectedEpics,
    };
  },
);

function getChartDataForEpic(sprints: SprintExtended[], backlogItems: BacklogItemPredictedSprint[]): number[] {
  let data: number[] = [];

  let spsOfEpic = backlogItems.map(p => p.storyPoints).reduce((prev, curr) => prev + curr, 0);

  for (let i = 0; i < sprints.length; i++) {
    let spsOfCurrentSprintForEpic = backlogItems
      .filter(p => p.medSprintId === sprints[i].id)
      .map(p => p.storyPoints)
      .reduce((prev, curr) => prev + curr, 0);
    spsOfEpic -= spsOfCurrentSprintForEpic;

    if (spsOfEpic < 0) {
      return data;
    }

    data.push(spsOfEpic);
  }

  return data;
}
