import React from 'react';
import Chip from '@material-ui/core/Chip';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getContrastBlackOrWhite } from '../../../shared/distinct-colors';

interface BurndownEpicListProps {
  options: string[];
  onEpicsChanged: (values: string[]) => void;
  selectedEpics: { label: string; color: string }[];
}

export const BurndownEpicList: React.FC<BurndownEpicListProps> = ({ options, onEpicsChanged, selectedEpics }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      inputStyle: {
        background: 'white',
      },
      root: {
        width: '100%',
        margin: theme.spacing(1, 2),
        '&:hover': {
          background: 'white',
        },
      },
    }),
  );
  const classes = useStyles();

  const updateSelected = (values: string[]) => {
    onEpicsChanged(values);
  };

  interface InputPillProps {
    epic: { label: string; color: string };
    tagProps: AutocompleteGetTagProps;
    index: number;
  }

  const InputPillRenderer = ({ epic, tagProps, index }: InputPillProps) => {
    const contrastColor = getContrastBlackOrWhite(epic.color);
    return (
      <Chip
        variant='outlined'
        style={{
          color: contrastColor,
          backgroundColor: epic.color,
          fontWeight: 'normal',
        }}
        label={epic.label}
        {...tagProps({ index })}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{ inputRoot: classes.inputStyle }}
        multiple
        id='burndown-epics-list'
        value={selectedEpics.map(epic => epic.label)}
        options={options}
        filterSelectedOptions
        getOptionLabel={option => option}
        onChange={(_event, values) => updateSelected(values)}
        renderTags={(value, getTagProps) =>
          selectedEpics.map((epic, index) => (
            <InputPillRenderer key={`${value}-${index}`} index={index} tagProps={getTagProps} epic={epic} />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            variant='outlined'
            label='Select epics'
            placeholder={`${selectedEpics.length ? 'Add...' : 'No epics selected'}`}
          />
        )}
      />
    </div>
  );
};
