import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Tooltip, Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { PostAddOutlined, ExpandMore, CloudUploadOutlined } from '@material-ui/icons';
import { setActiveTeam, TeamId, selectActiveTeam, selectAllTeams, TimelineEventDialog } from '../planning';
import { useToggleState } from '../shared';
import { selectUserEmail, selectUserAvatarUrl, selectAuth } from '../auth';
import { BacklogUpdateDialog } from '../planning/backlog';
import { UserAvatar } from './user-avatar.component';
import { SessionInterceptor } from '../auth/session-interceptor.component';
import { selectUnsavedChanges } from '../app.selectors';
import { selectTimestampOfLastBacklogUpdate } from '../planning/dashboard/backlog/backlog-widget.selectors';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  formControl: {
    margin: theme.spacing(1, 1, 1, 8),
    minWidth: '160px',
  },
  menuItem: {
    minWidth: '160px',
  },
  grow: {
    flexGrow: 1,
  },
  txt: {
    color: 'black',
  },
  demoText: {
    color: 'red',
  },
}));

export const Header: React.FC = props => {
  const classes = useStyles(props);
  const [showBacklogUpdate, toggleShowBacklogUpdate] = useToggleState();
  const [showAddTimelineItem, toggleShowAddTimelineItem] = useToggleState();
  const userEmail = useSelector(selectUserEmail);
  const avatarUrl = useSelector(selectUserAvatarUrl);
  const activeTeam = useSelector(selectActiveTeam);
  const teams = useSelector(selectAllTeams);
  const lastBacklogUpdate = useSelector(selectTimestampOfLastBacklogUpdate);
  const unsavedChanges = useSelector(selectUnsavedChanges);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleSelectTeamButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelectTeamMenu = () => {
    setAnchorEl(null);
  };

  const setActiveTeamCallback = useCallback(
    (teamId: TeamId) => {
      setAnchorEl(null);
      dispatch(setActiveTeam(teamId));
    },
    [dispatch],
  );

  const auth = useSelector(selectAuth);

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar>
        <Tooltip title='Select Team' enterDelay={300}>
          <Button
            aria-label='select team'
            className={classes.formControl}
            onClick={handleSelectTeamButtonClick}
            variant='contained'
            color='primary'
            endIcon={<ExpandMore />}
          >
            {activeTeam ? activeTeam.name : 'Select Team'}
          </Button>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseSelectTeamMenu} TransitionComponent={Fade}>
          {teams.map(team => (
            <MenuItem
              key={team.id}
              selected={activeTeam ? activeTeam.id === team.id : false}
              onClick={() =>
                unsavedChanges ? confirm('There are unsaved changes.') && setActiveTeamCallback(team.id) : setActiveTeamCallback(team.id)
              }
              className={classes.menuItem}
            >
              {team.name}
            </MenuItem>
          ))}
        </Menu>
        <Tooltip title='Update Backlog' enterDelay={300}>
          <IconButton aria-label='update backlog' onClick={toggleShowBacklogUpdate}>
            <CloudUploadOutlined />
          </IconButton>
        </Tooltip>
        <div className={classes.txt}>Last import: {lastBacklogUpdate}</div>
        <div className={classes.grow} />
        {auth.metadata.data.autoCreateExternalUsers && <h5 className={classes.demoText}>All Zühlke employees have access</h5>}
        <div>
          <SessionInterceptor />
          <Tooltip title='Add Timeline Item' enterDelay={300}>
            <IconButton aria-label='add timeline item' onClick={toggleShowAddTimelineItem}>
              <PostAddOutlined />
            </IconButton>
          </Tooltip>
          <IconButton aria-label='user dropdown' disableFocusRipple disableTouchRipple>
            <UserAvatar userEmail={userEmail as string} avatarUrl={avatarUrl} />
          </IconButton>
        </div>
        <BacklogUpdateDialog onClose={toggleShowBacklogUpdate} open={showBacklogUpdate} />
        <TimelineEventDialog open={showAddTimelineItem} onClose={toggleShowAddTimelineItem} team={activeTeam} />
      </Toolbar>
    </AppBar>
  );
};
