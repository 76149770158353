import { createSelector } from 'reselect';
import moment from 'moment';
import { ISO_DATEFORMAT } from '../../planning.constants';
import { BacklogItem, Sprint, TimelineItem } from '../../planning.state';
import { SprintId, TimelineItemType } from '../../planning.model';
import {
  selectProductBacklogWithPredictedSprint,
  BacklogItemPredictedSprint,
  selectFutureSprints,
  SprintExtended,
} from '../../planning-calc.selectors';
import { selectDeliverySprintId, selectProductBacklog, selectBacklogItemEpics, selectTimelineItems } from '../../planning.selectors';
import { Option, OptionLabel } from './option';

export interface BacklogWidgetData {
  deliverySprint: Sprint | undefined;
  backlogItems: ColoredBacklogItem[];
  deliverySprints: SprintExtended[];
  options: Option[];
  sumOfBacklogStoryPoints: number;
}

export enum PbiColor {
  MIN,
  MED,
  MAX,
  NO,
}

export interface ColoredBacklogItem extends BacklogItem {
  color: PbiColor;
}

export const selectDeliverySprint = createSelector(
  selectDeliverySprintId,
  selectFutureSprints,
  (sprintId: SprintId | undefined, sprints: Sprint[]) => {
    return sprintId ? sprints.find(s => s.id === sprintId) : undefined;
  },
);

export const selectColoredProductBacklog = createSelector(
  selectDeliverySprintId,
  selectProductBacklogWithPredictedSprint,
  selectFutureSprints,
  (deliverySprintId: SprintId | undefined, predictedBacklogItems: BacklogItemPredictedSprint[], sprints: Sprint[]) => {
    return predictedBacklogItems.map(backlogItem => {
      const coloredBacklogItem: ColoredBacklogItem = {
        ...backlogItem,
        color: PbiColor.NO,
      };
      if (!deliverySprintId) {
        return coloredBacklogItem;
      }

      const deliverySprint = sprints.find(s => s.id === deliverySprintId);
      const pbiMinSprint = sprints.find(s => s.id === backlogItem.minSprintId);
      const pbiMedSprint = sprints.find(s => s.id === backlogItem.medSprintId);
      const pbiMaxSprint = sprints.find(s => s.id === backlogItem.maxSprintId);

      if (deliverySprint && pbiMinSprint ? pbiMinSprint?.endDate <= deliverySprint?.endDate : false) {
        coloredBacklogItem.color = PbiColor.MIN;
      } else if (deliverySprint && pbiMedSprint ? pbiMedSprint?.endDate <= deliverySprint?.endDate : false) {
        coloredBacklogItem.color = PbiColor.MED;
      } else if (deliverySprint && pbiMaxSprint ? pbiMaxSprint?.endDate <= deliverySprint?.endDate : false) {
        coloredBacklogItem.color = PbiColor.MAX;
      }
      return coloredBacklogItem;
    });
  },
);

export const selectBacklogItemTypes = createSelector(selectProductBacklog, (backlogItems: BacklogItem[]) => {
  return Array.from(new Set(backlogItems.filter(item => !!item.type && item.type.length !== 0).map(item => item.type)).values());
});

export const selectBacklogItemSourceIds = createSelector(selectProductBacklog, (backlogItems: BacklogItem[]) => {
  return Object.values(backlogItems)
    .filter(item => !!item.sourceId && item.sourceId.length !== 0)
    .map(item => item.sourceId);
});

export const selectSumOfBacklogStoryPoints = createSelector(selectProductBacklog, (backlogItems: BacklogItem[]) => {
  return backlogItems.reduce((spSum, backlogItem) => {
    return spSum + backlogItem.storyPoints;
  }, 0);
});

export const selectBacklogWidgetData = createSelector(
  selectDeliverySprint,
  selectColoredProductBacklog,
  selectFutureSprints,
  selectBacklogItemTypes,
  selectBacklogItemEpics,
  selectBacklogItemSourceIds,
  selectSumOfBacklogStoryPoints,
  (
    deliverySprint: Sprint | undefined,
    backlogItems: ColoredBacklogItem[],
    deliverySprints: SprintExtended[],
    backlogItemTypes: string[],
    backlogItemEpics: string[],
    backlogItemSourceIds: string[],
    sumOfBacklogStoryPoints: number,
  ): BacklogWidgetData => {
    const backlogItemTypesOptions: Option[] = backlogItemTypes.map((backlogItemType: any) => {
      return {
        label: OptionLabel.ItemType,
        name: backlogItemType,
      };
    });

    const backlogItemEpicsOptions: Option[] = backlogItemEpics.map((backlogItemEpic: any) => {
      return {
        label: OptionLabel.Epics,
        name: backlogItemEpic,
      };
    });

    const backlogItemSourceIdsOptions: Option[] = backlogItemSourceIds.map((backlogItemSourceId: any) => {
      return {
        label: OptionLabel.Ids,
        name: backlogItemSourceId,
      };
    });

    return {
      deliverySprint,
      backlogItems,
      deliverySprints,
      options: [...backlogItemTypesOptions, ...backlogItemEpicsOptions, ...backlogItemSourceIdsOptions],
      sumOfBacklogStoryPoints,
    };
  },
);

export const selectTimestampOfLastBacklogUpdate = createSelector(selectTimelineItems, (timelineItems: TimelineItem[]): string => {
  const dates = timelineItems.filter(timelineItem => timelineItem.type === TimelineItemType.BacklogUpdate).map(item => item.createdAt);
  if (dates.length) return moment.max(dates).format(ISO_DATEFORMAT);
  return 'never';
});
