import React, { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

interface TeamWizardDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dialogText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const TeamCreationHintWindow: React.FC<TeamWizardDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <div className={classes.dialogTitle}>
          Create Team
          {onClose ? (
            <IconButton aria-label='close' onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText className={classes.dialogText}>
          If you need help creating a team, press the &nbsp; <InfoOutlined />
          icon in the respective field to get example data of the field.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
