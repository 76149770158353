import React from 'react';
import { useDispatch } from 'react-redux';
import useMountEffect from '../shared/use-mount-effect';
import { login } from './auth.actions';

export const TriggerLogin: React.FC = () => {
  const dispatch = useDispatch();
  useMountEffect(() => {
    dispatch(login());
  });

  return <div>Trigger Login</div>;
};
