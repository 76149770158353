import moment from 'moment/moment';
import { BacklogManagementSystem } from '../planning.model';
import { ResourceState } from '../../shared';
import { Team } from '..';

export const createDefaultTeam = (): Team => {
  const createEmptyResourceState = (): ResourceState<any> => ({ requestError: null, data: null, isPending: false });

  return {
    id: '',
    name: '',
    config: {
      approvedBacklogItemStatuses: [],
      defaultSprintDuration: 14,
      backlogManagementSystem: BacklogManagementSystem.Jira,
      backlogApiBaseLink: '',
      workItemBaseLink: '',
      backlogPbisQuery: '',
      project: '',
      domain: '',
      defaultStoryPoints: '',
      isOAuthEnabled: false,
      oAuth1Configuration: {
        accessToken: '',
        consumerKey: '',
        privateKeyFileName: '',
        privateKey: '',
      },
    },
    scenarios: createEmptyResourceState(),
    activeScenario: null,
    sprints: createEmptyResourceState(),
    backlogItems: createEmptyResourceState(),
    viewSettings: {
      deliverySprintId: '',
      selectedTeamIds: [''],
    },
    timelineItems: createEmptyResourceState(),
    minSyntheticVelocity: 0,
    maxSyntheticVelocity: 0,
    useSyntheticVelocity: false,
    creator: '',
    createdOn: moment(),
  };
};
