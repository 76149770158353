import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { Observable, defer } from 'rxjs';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { environment } from '../environment';

import { IRestClient, StaticInjector } from '../shared';
import { REDUX_STORE } from '../misc';
import { StateUser, RegisterUser } from './auth.state';
import {
  LoginInfoDto,
  LoginRequestDto,
  RedirectDto,
  RoleDto,
  UserDto,
  UserId,
  LogoutRequestDto,
  LogoutInfoDto,
  LoggedOutInfoDto,
  ChangePasswordRequestDto,
} from './auth.model';
import { OidcConfig } from './oidc-config';
import { convertUserToUserDto, convertRegisterUserToRegisterUserDto } from './auth.converters';
import { STORAGE_KEY_ACTIVE_TEAM } from '../shared/constants';

export interface IAuthService {
  getLoginInfo(): Observable<LoginInfoDto>;

  getOidcConfig(): Observable<OidcConfig>;

  signin(): Observable<any>;

  signout(): Observable<void>;

  completeSignin(url: string): Observable<User>;

  login(email: string, password: string, returnUrl: string): Observable<RedirectDto>;

  logout(logoutId: string): Observable<LoggedOutInfoDto>;

  getLogoutInfo(logoutId: string): Observable<LogoutInfoDto | LoggedOutInfoDto>;

  changePassword(currentPassword: string, newPassword: string): Observable<any>;

  getUsers(): Observable<UserDto[]>;

  getRoles(): Observable<RoleDto[]>;

  createUser(user: RegisterUser): Observable<UserDto>;

  updateUser(user: StateUser): Observable<any>;

  deleteUser(userId: UserId): Observable<any>;
}

export class AuthService implements IAuthService {
  private userManager: UserManager = undefined as any;

  constructor(private injector: StaticInjector, private ajaxService: IRestClient) {}

  public getLoginInfo(): Observable<LoginInfoDto> {
    return ajax.getJSON<LoginInfoDto>(`${environment.baseUrl}/account/login`);
  }

  public getOidcConfig(): Observable<OidcConfig> {
    return ajax.getJSON<OidcConfig>(`${environment.baseUrl}/config/oidc`);
  }

  public signin(): Observable<any> {
    return defer(async () => {
      await this.ensureUserManager();
      await this.userManager.signinRedirect();
    });
  }

  public completeSignin(url: string): Observable<User> {
    return defer(async () => {
      await this.ensureUserManager();
      return await this.userManager.signinCallback(url);
    });
  }

  public login(email: string, password: string, returnUrl: string): Observable<RedirectDto> {
    const request: LoginRequestDto = {
      email,
      password,
      returnUrl,
    };

    return ajax.post(`${environment.baseUrl}/account/login`, request, { 'Content-Type': 'application/json' }).pipe(
      map(response => {
        return response.response as RedirectDto;
      }),
    );
  }

  public signout(): Observable<void> {
    return defer(async () => {
      this.clearAuthStorage();
      await this.userManager.signoutRedirect();
    });
  }

  public logout(logoutId: string): Observable<LoggedOutInfoDto> {
    const request: LogoutRequestDto = {
      logoutId,
    };

    return this.ajaxService.post<LoggedOutInfoDto>(`${environment.baseUrl}/account/logout`, request);
  }

  public getLogoutInfo(logoutId: string): Observable<LogoutInfoDto | LoggedOutInfoDto> {
    return this.ajaxService.get<LogoutInfoDto>(`${environment.baseUrl}/account/logout?logoutId=${logoutId}`);
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<any> {
    const request: ChangePasswordRequestDto = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    return this.ajaxService.post<any>(`${environment.baseUrl}/accountsettings/changepassword`, request);
  }

  public getUsers(): Observable<UserDto[]> {
    return this.ajaxService.get<UserDto[]>(`${environment.baseUrl}/users`);
  }

  public getRoles(): Observable<RoleDto[]> {
    return this.ajaxService.get<RoleDto[]>(`${environment.baseUrl}/users/roles`);
  }

  public createUser(user: RegisterUser): Observable<UserDto> {
    return this.ajaxService.post<UserDto>(`${environment.baseUrl}/users`, convertRegisterUserToRegisterUserDto(user));
  }

  public updateUser(user: StateUser): Observable<any> {
    return this.ajaxService.put<any>(`${environment.baseUrl}/users/${user.id}`, convertUserToUserDto(user));
  }

  public deleteUser(userId: UserId): Observable<any> {
    return this.ajaxService.delete<any>(`${environment.baseUrl}/users/${userId}`);
  }

  private ensureUserManager(): Promise<UserManager> {
    const store = this.injector.get(REDUX_STORE);
    let state = store.getState();

    if (this.userManager) {
      return Promise.resolve(this.userManager);
    }

    if (state.auth.oidcConfig.data) {
      this.initializeUserManager(state.auth.oidcConfig.data);
      return Promise.resolve(this.userManager);
    }

    return new Promise(resolve => {
      store.subscribe(() => {
        if (state.auth.oidcConfig.data) {
          this.initializeUserManager(state.auth.oidcConfig.data);
          resolve(this.userManager);
        }
      });
    });
  }

  private initializeUserManager(oidcConfig: OidcConfig): void {
    if (this.userManager !== undefined) {
      return;
    }

    const config: UserManagerSettings = {
      authority: oidcConfig.applicationUri,
      client_id: 'zap-ui',
      redirect_uri: oidcConfig.applicationUri + '/callback',
      response_type: 'id_token token',
      scope: 'profile openid IdentityServerApi roles email',
      post_logout_redirect_uri: oidcConfig.applicationUri,
    };

    this.userManager = new UserManager(config);
  }

  private clearAuthStorage(): void {
    // retain active team
    const activeTeam = localStorage.getItem(STORAGE_KEY_ACTIVE_TEAM);
    localStorage.clear();
    activeTeam && activeTeam.trim().length > 0 && localStorage.setItem(STORAGE_KEY_ACTIVE_TEAM, activeTeam);
  }
}
