import React from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

interface OutlinedSelectProps {
  children: JSX.Element[];
  errorMessage?: string;
  label: string;
  name?: string;
  value?: any;
  inputRef?: (ref: any) => void;
  onChange?: (event: React.ChangeEvent<any>) => void;
}

export const OutlinedSelect: React.FC<OutlinedSelectProps> = props => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel && inputLabel.current && inputLabel.current.offsetWidth) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <FormControl fullWidth variant='outlined' error={!!props.errorMessage}>
      <InputLabel ref={inputLabel} htmlFor={props.name}>
        {props.label}
      </InputLabel>
      <Select
        variant='outlined'
        labelWidth={labelWidth}
        inputProps={{
          id: props.name,
          name: props.name,
        }}
        onChange={props.onChange}
        inputRef={ref => (props.inputRef && ref ? props.inputRef(ref.node) : null)}
        value={props.value}
        data-cy='outlined-select'
      >
        {props.children}
      </Select>
      {props.errorMessage && <FormHelperText>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
};
