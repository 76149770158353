import { createReducer } from '@reduxjs/toolkit';
import {
  copyTeam,
  copyTeamCanceled,
  copyTeamFailed,
  copyTeamSucceeded,
  createTeam,
  createTeamCanceled,
  createTeamFailed,
  createTeamSucceeded,
  createTimelineItemForTeam,
  createTimelineItemForTeamFailed,
  createTimelineItemForTeamSucceeded,
  deleteTeam,
  deleteTeamFailed,
  deleteTeamSucceeded,
  deleteTimelineItemForTeam,
  deleteTimelineItemForTeamFailed,
  deleteTimelineItemForTeamSucceeded,
  loadAllTeamsAndActiveTeam,
  loadAllTeamsAndActiveTeamFailed,
  loadAllTeamsAndActiveTeamSucceeded,
  loadBacklogItemsForTeam,
  loadBacklogItemsForTeamFailed,
  loadBacklogItemsForTeamSucceeded,
  loadChartViewSettingsForTeam,
  loadChartViewSettingsForTeamFailed,
  loadChartViewSettingsForTeamSucceeded,
  loadScenariosForTeam,
  loadScenariosForTeamFailed,
  loadScenariosForTeamSucceeded,
  loadSprintsForTeam,
  loadSprintsForTeamFailed,
  loadSprintsForTeamSucceeded,
  loadTimelineItemsForTeam,
  loadTimelineItemsForTeamFailed,
  loadTimelineItemsForTeamSucceeded,
  moveBacklogItem,
  moveBacklogItemFailed,
  moveBacklogItemSucceeded,
  setActiveTeam,
  updateBacklogForTeam,
  updateBacklogForTeamCanceled,
  updateBacklogForTeamExcel,
  updateBacklogForTeamFullJiraCsv,
  updateBacklogForTeamFailed,
  updateBacklogForTeamOAuth,
  updateBacklogForTeamSucceeded,
  updateChartViewSettingsForTeam,
  updateChartViewSettingsForTeamFailed,
  updateChartViewSettingsForTeamSucceeded,
  updateDeliverySprintId,
  updateDeliverySprintIdFailed,
  updateDeliverySprintIdSucceeded,
  updateSelectedTeamIds,
  updateSelectedTeamIdsFailed,
  updateSelectedTeamIdsSucceeded,
  updateSprintConfigurationForTeam,
  updateSprintConfigurationForTeamFailed,
  updateSprintConfigurationForTeamSucceeded,
  updateTeam,
  updateTeamCanceled,
  updateTeamFailed,
  updateTeamSucceeded,
} from './planning.actions';
import { ChartViewSettings, IndividualChartSettings, initialPlanningState, ObjectMap, Team } from './planning.state';
import {
  convertBacklogItemDtoToBacklogItem,
  convertBacklogItemRank,
  convertChartViewSettingsDtoToChartViewSettings,
  convertScenarioDtoToScenario,
  convertSprintDtoToSprint,
  convertTeamDtoToTeam,
  convertTimelineItemDtoToTimelineItem,
} from './planning.converters';
import { ChartType, TeamId } from './planning.model';
import { STORAGE_KEY_ACTIVE_TEAM } from '../shared/constants';

export const planningReducer = createReducer(initialPlanningState, builder => {
  builder.addCase(loadAllTeamsAndActiveTeam, state => {
    state.teams.isPending = true;
  });

  builder.addCase(loadAllTeamsAndActiveTeamSucceeded, (state, action) => {
    const teamsLoaded = action.payload.teams;
    if (!state.activeTeam) {
      const teamIdFromStorage = localStorage.getItem(STORAGE_KEY_ACTIVE_TEAM);
      const teamFound = teamIdFromStorage ? teamsLoaded.find(team => team.id === teamIdFromStorage) : undefined;
      state.activeTeam = teamFound ? teamFound.id : teamsLoaded.length ? teamsLoaded[0].id : null;
      if (state.activeTeam) {
        localStorage.setItem(STORAGE_KEY_ACTIVE_TEAM, state.activeTeam);
      } else {
        localStorage.removeItem(STORAGE_KEY_ACTIVE_TEAM);
      }
    }
    state.teams = {
      data: teamsLoaded.reduce((agg, teamDto) => {
        agg[teamDto.id] = convertTeamDtoToTeam(teamDto);
        return agg;
      }, {} as ObjectMap<TeamId, Team>),
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadAllTeamsAndActiveTeamFailed, (state, action) => {
    state.teams.requestError = action.payload.requestError;
    state.teams.isPending = false;
  });

  builder.addCase(loadSprintsForTeam, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.sprints.isPending = true;
    }
  });

  builder.addCase(loadSprintsForTeamSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.sprints = {
        data: action.payload.sprints.map(dto => convertSprintDtoToSprint(dto)),
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(loadSprintsForTeamFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.sprints.requestError = action.payload.requestError;
      team.sprints.isPending = false;
    }
  });

  builder.addCase(loadBacklogItemsForTeam, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems.isPending = true;
    }
  });

  builder.addCase(loadBacklogItemsForTeamSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems = {
        data: action.payload.backlogItems.map(dto => convertBacklogItemDtoToBacklogItem(dto, team.activeScenario)),
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(loadBacklogItemsForTeamFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems.requestError = action.payload.requestError;
      team.backlogItems.isPending = false;
    }
  });

  builder.addCase(loadScenariosForTeam, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.scenarios.isPending = true;
    }
  });

  builder.addCase(loadScenariosForTeamSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.scenarios = {
        data: action.payload.scenarios.map(dto => convertScenarioDtoToScenario(dto)),
        requestError: null,
        isPending: false,
      };
      const activeScenario = action.payload.scenarios.find(dto => dto.isActive);
      team.activeScenario = activeScenario ? activeScenario.id : null;

      // make sure the new rank without scenario gets updated in case the pbis are getting loaded first
      if (activeScenario) {
        team.backlogItems.data.forEach(item => (item.rank = convertBacklogItemRank(item.legacyRanks, team.activeScenario)));
      }
    }
  });

  builder.addCase(loadScenariosForTeamFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.scenarios.requestError = action.payload.requestError;
      team.scenarios.isPending = false;
    }
  });

  builder.addCase(loadTimelineItemsForTeam, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.timelineItems.isPending = true;
    }
  });

  builder.addCase(loadTimelineItemsForTeamSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.timelineItems = {
        data: action.payload.timelineItems.map(dto => convertTimelineItemDtoToTimelineItem(dto)),
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(loadTimelineItemsForTeamFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.timelineItems.requestError = action.payload.requestError;
      team.timelineItems.isPending = false;
    }
  });

  builder.addCase(loadChartViewSettingsForTeam, (state, action) => {
    if (state.burnup) {
      state.burnup.isPending = true;
    }
    if (state.burndown) {
      state.burndown.isPending = true;
    }
  });

  builder.addCase(loadChartViewSettingsForTeamSucceeded, (state, action) => {
    state.burnup = {
      data: convertChartViewSettingsDtoToChartViewSettings(action.payload.chartViewSettings[0]),
      requestError: null,
      isPending: false,
    };
    state.burndown = {
      data: convertChartViewSettingsDtoToChartViewSettings(action.payload.chartViewSettings[1]),
      requestError: null,
      isPending: false,
    };
  });

  builder.addCase(loadChartViewSettingsForTeamFailed, (state, action) => {
    if (state.burnup) {
      state.burnup.requestError = action.payload.requestError;
      state.burnup.isPending = false;
    }
    if (state.burndown) {
      state.burndown.requestError = action.payload.requestError;
      state.burndown.isPending = false;
    }
  });

  builder.addCase(updateChartViewSettingsForTeam, (state, action) => {
    const { chartType } = action.payload;
    const chartPropsToUpdate = action.payload.chartViewSettings as { [key: string]: IndividualChartSettings };
    if (chartType === ChartType.BurnUp) {
      if (state.burnup) {
        const newChart = { ...state.burnup.data, ...chartPropsToUpdate[chartType] } as ChartViewSettings;
        state.burnup = {
          data: newChart,
          requestError: null,
          isPending: true,
        };
      }
    } else {
      if (state.burndown) {
        const newChart = { ...state.burndown.data, ...chartPropsToUpdate[chartType] } as ChartViewSettings;
        state.burndown = {
          data: newChart,
          requestError: null,
          isPending: true,
        };
      }
    }
  });

  builder.addCase(updateChartViewSettingsForTeamSucceeded, (state, action) => {
    if (state.burnup) {
      state.burnup = {
        data: state.burnup.data,
        requestError: null,
        isPending: false,
      };
    }
    if (state.burndown) {
      state.burndown = {
        data: state.burndown.data,
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(updateChartViewSettingsForTeamFailed, (state, action) => {
    if (state.burnup) {
      state.burnup = {
        data: state.burnup.data,
        requestError: action.payload.requestError,
        isPending: false,
      };
    }
    if (state.burndown) {
      state.burndown = {
        data: state.burndown.data,
        requestError: action.payload.requestError,
        isPending: false,
      };
    }
  });

  builder.addCase(setActiveTeam, (state, action) => {
    const newActiveTeam = action.payload.teamId;
    state.activeTeam = newActiveTeam;
    localStorage.setItem(STORAGE_KEY_ACTIVE_TEAM, newActiveTeam);
  });

  builder.addCase(updateBacklogForTeam, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: null,
        data: { isSuccess: false },
        isPending: true,
      };
    }
  });

  builder.addCase(updateBacklogForTeamOAuth, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: null,
        data: { isSuccess: false },
        isPending: true,
      };
    }
  });

  builder.addCase(updateBacklogForTeamExcel, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: null,
        data: { isSuccess: false },
        isPending: true,
      };
    }
  });

  builder.addCase(updateBacklogForTeamFullJiraCsv, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: null,
        data: { isSuccess: false },
        isPending: true,
      };
    }
  });

  builder.addCase(updateBacklogForTeamSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: null,
        data: { isSuccess: true },
        isPending: false,
      };
    }
  });

  builder.addCase(updateBacklogForTeamFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.updateBacklog = {
        requestError: action.payload.requestError,
        data: { isSuccess: false },
        isPending: false,
      };
    }
  });

  builder.addCase(updateBacklogForTeamCanceled, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      delete team.updateBacklog;
    }
  });

  builder.addCase(createTeam, state => {
    state.createTeam = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(createTeamSucceeded, (state, action) => {
    const teamDto = action.payload.team;
    if (teamDto) {
      const team = convertTeamDtoToTeam(teamDto);
      if (team.id) {
        state.teams.data[team.id] = team;
        state.createTeam = {
          requestError: null,
          isPending: false,
          data: { isSuccess: true },
        };
      }
    }
  });

  builder.addCase(createTeamFailed, (state, action) => {
    state.createTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(createTeamCanceled, state => {
    delete state.createTeam;
  });

  builder.addCase(copyTeam, state => {
    state.copyTeam = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(copyTeamSucceeded, (state, action) => {
    const teamDto = action.payload.teamDto;
    if (teamDto) {
      const team = convertTeamDtoToTeam(teamDto);
      if (team.id) {
        state.teams.data[team.id] = team;
        state.copyTeam = {
          requestError: null,
          isPending: false,
          data: { isSuccess: true },
        };
      }
    }
  });

  builder.addCase(copyTeamFailed, (state, action) => {
    state.copyTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(copyTeamCanceled, state => {
    delete state.copyTeam;
  });

  builder.addCase(updateTeam, state => {
    state.updateTeam = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(updateTeamSucceeded, (state, action) => {
    const teamDto = action.payload.teamDto;
    if (teamDto) {
      const team = convertTeamDtoToTeam(teamDto);
      team.sprints = state.teams.data[team.id]?.sprints;
      team.scenarios = state.teams.data[team.id]?.scenarios;
      team.activeScenario = state.teams.data[team.id]?.activeScenario;
      team.viewSettings = state.teams.data[team.id]?.viewSettings;
      team.backlogItems = state.teams.data[team.id]?.backlogItems;
      team.timelineItems = state.teams.data[team.id]?.timelineItems;

      if (team.id) {
        state.teams.data[team.id] = team;
        state.updateTeam = {
          requestError: null,
          isPending: false,
          data: { isSuccess: true },
        };
      }
    }
  });

  builder.addCase(updateTeamFailed, (state, action) => {
    state.updateTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(updateTeamCanceled, state => {
    delete state.updateTeam;
  });

  builder.addCase(deleteTeam, state => {
    state.deleteTeam = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(deleteTeamSucceeded, (state, action) => {
    const teamId = action.payload.teamId;
    delete state.teams.data[teamId];
    state.deleteTeam = {
      requestError: null,
      isPending: false,
      data: { isSuccess: true },
    };
    Object.keys(state.teams.data).forEach(team => {
      const selectedTeamIds = state.teams.data[team].viewSettings.selectedTeamIds;
      const idxDeletedTeam = selectedTeamIds.findIndex(selectedTeam => selectedTeam === teamId);
      if (idxDeletedTeam > -1) {
        selectedTeamIds.splice(idxDeletedTeam, 1);
      }
    });
  });

  builder.addCase(deleteTeamFailed, (state, action) => {
    state.deleteTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(updateSprintConfigurationForTeam, (state, action) => {
    state.updateTeam = {
      requestError: null,
      isPending: true,
      data: { isSuccess: false, source: 'updateSprintConfigurationForTeam' },
    };
    const team = state.teams.data[action.payload.team.id];
    if (team) {
      team.sprints.isPending = true;
      team.sprints.requestError = null;
    }
  });

  builder.addCase(updateSprintConfigurationForTeamSucceeded, (state, action) => {
    state.updateTeam = {
      requestError: null,
      isPending: false,
      data: { isSuccess: true, source: 'updateSprintConfigurationForTeam' },
    };
    const { useSyntheticVelocity, minVelocity, maxVelocity, sprints } = action.payload.sprintConfiguration;
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.useSyntheticVelocity = useSyntheticVelocity;
      team.minSyntheticVelocity = minVelocity;
      team.maxSyntheticVelocity = maxVelocity;
      team.sprints = {
        data: sprints.map(dto => convertSprintDtoToSprint(dto)),
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(updateSprintConfigurationForTeamFailed, (state, action) => {
    state.updateTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false, source: 'updateSprintConfigurationForTeam' },
    };
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.sprints.requestError = action.payload.requestError;
      team.sprints.isPending = false;
    }
  });

  builder.addCase(moveBacklogItem, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems = {
        data: action.payload.backlog,
        isPending: false,
        requestError: null,
      };
    }
  });

  builder.addCase(moveBacklogItemSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems = {
        data: action.payload.backlog,
        requestError: null,
        isPending: false,
      };
    }
  });

  builder.addCase(moveBacklogItemFailed, (state, action) => {
    const team = state.teams.data[action.payload.teamId];
    if (team) {
      team.backlogItems.isPending = false;
      team.backlogItems.requestError = action.payload.requestError;
    }
  });

  builder.addCase(updateDeliverySprintId, state => {
    state.updateTeam = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(updateDeliverySprintIdSucceeded, (state, action) => {
    const team = state.teams.data[action.payload.teamDto.id];
    if (team) {
      state.updateTeam = {
        isPending: false,
        requestError: null,
        data: { isSuccess: true },
      };
      team.viewSettings.deliverySprintId = action.payload.teamDto.deliverySprintId.toString();
    }
  });

  builder.addCase(updateDeliverySprintIdFailed, (state, action) => {
    state.updateTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(updateSelectedTeamIds, (state, action) => {
    state.updateTeam = {
      requestError: null,
      isPending: true,
      data: { isSuccess: false, source: 'updateSelectedTeamIds' },
    };
    const team = state.teams.data[action.payload.team.id];
    if (team) {
      team.viewSettings.selectedTeamIds = action.payload.team.viewSettings.selectedTeamIds;
    }
  });

  builder.addCase(updateSelectedTeamIdsSucceeded, state => {
    state.updateTeam = {
      requestError: null,
      isPending: false,
      data: { isSuccess: true, source: 'updateSelectedTeamIds' },
    };
  });

  builder.addCase(updateSelectedTeamIdsFailed, (state, action) => {
    state.updateTeam = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false, source: 'updateSelectedTeamIds' },
    };
  });

  builder.addCase(deleteTimelineItemForTeam, state => {
    state.deleteTimelineItem = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(deleteTimelineItemForTeamSucceeded, state => {
    state.deleteTimelineItem = {
      requestError: null,
      isPending: false,
      data: { isSuccess: true },
    };
  });

  builder.addCase(deleteTimelineItemForTeamFailed, (state, action) => {
    state.deleteTimelineItem = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });

  builder.addCase(createTimelineItemForTeam, state => {
    state.createTimelineItem = {
      requestError: null,
      isPending: true,
      data: null,
    };
  });

  builder.addCase(createTimelineItemForTeamSucceeded, (state, action) => {
    const timelineDto = action.payload.timelineItem;

    const team: Team = state.teams.data[action.payload.teamId];

    const timelineItem = convertTimelineItemDtoToTimelineItem(timelineDto);

    team.timelineItems.data.push(timelineItem);
    state.createTimelineItem = {
      requestError: null,
      isPending: false,
      data: { isSuccess: true },
    };
  });

  builder.addCase(createTimelineItemForTeamFailed, (state, action) => {
    state.createTimelineItem = {
      requestError: action.payload.requestError,
      isPending: false,
      data: { isSuccess: false },
    };
  });
});
