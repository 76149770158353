import React from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  colors,
  MenuProps,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Person, ExitToApp, Lock, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordPage } from '../auth/credentials/change-password.page';
import { useToggleState } from '../shared';
import { logout, selectUserIdp } from '../auth';

const StyledMenu = withStyles({
  paper: {
    width: '350px',
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: colors.grey[300],
    color: '#FFF',
  },
  avatarFallback: {
    fontSize: '2rem',
  },
  userBox: {
    padding: theme.spacing(0, 1, 3, 2),
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
  closeIcon: {
    flex: '0 0 50px',
  },
  account: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  accountEmail: {
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    '&.MuiListItemIcon-root': {
      paddingLeft: theme.spacing(1),
    },
  },
}));

interface UserAvatarProps {
  userEmail: string;
  avatarUrl: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ userEmail, avatarUrl, ...rest }: UserAvatarProps) => {
  const classes = useStyles(rest);
  const dispatch = useDispatch();
  const [showChangePassword, toggleShowChangePassword] = useToggleState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userIdp = useSelector(selectUserIdp);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowChangePassword = () => {
    handleClose();
    toggleShowChangePassword();
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };

  return (
    <div>
      <Avatar className={classes.avatar} src={avatarUrl} onClick={handleClick}>
        <Person className={classes.avatarFallback} />
      </Avatar>
      <StyledMenu id='customized-menu' anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
        <Box className={classes.userBox}>
          <div className={`${classes.header} ${classes.account}`}>
            <Typography className={classes.title} variant='h5'>
              My Account
            </Typography>
            <Tooltip title='Close' enterDelay={300}>
              <IconButton className={classes.closeIcon} onClick={handleClose}>
                <Close />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.account}>
            <Avatar className={classes.avatar} src={avatarUrl}>
              <Person fontSize='small' />
            </Avatar>
            <Typography className={classes.accountEmail}>{userEmail}</Typography>
          </div>
        </Box>
        {userIdp == 'local' && (
          <MenuItem onClick={handleShowChangePassword}>
            <ListItemIcon className={classes.listItem}>
              <Lock />
            </ListItemIcon>
            <ListItemText primary='Change Password' />
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.listItem}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary='Log out' />
        </MenuItem>
      </StyledMenu>
      <ChangePasswordPage open={showChangePassword} onClose={toggleShowChangePassword} />
    </div>
  );
};
