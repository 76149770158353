import { Grid, List, ListItem, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateWorkItemBaseLink, getItemTypeIcon, roundStoryPoints } from '../../../shared';
import { moveBacklogItem } from '../../planning.actions';
import { BacklogItem, Team } from '../../planning.state';
import { BacklogFilterItem } from './backlog-filter-item.component';
import { ColoredBacklogItem, selectTimestampOfLastBacklogUpdate } from './backlog-widget.selectors';
import { Option, OptionLabel } from './option';
import { ProductBacklogItem } from './product-backlog-item.component';
import { updateBacklogItemRank } from './update-backlog-item-rank';

export interface BacklogDisplayProps {
  team: Team;
  backlogItems: ColoredBacklogItem[];
  options: Option[];
  sumOfBacklogStoryPoints: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    margin: theme.spacing(0, -2),
  },
  iconOptionText: {
    paddingLeft: theme.spacing(1),
  },
}));

export const BacklogDisplay: React.FC<BacklogDisplayProps> = ({ team, backlogItems, options, sumOfBacklogStoryPoints }) => {
  const classes = useStyles();
  const numberOfPbis = backlogItems.length;
  const issueBaseLink = generateWorkItemBaseLink(team.config?.workItemBaseLink);

  const timestampOfLastBacklogUpdate = useSelector(selectTimestampOfLastBacklogUpdate);

  const [selectedFilters, setSelectedFilters] = useState<Option[]>([]);

  const filterBacklogItems = useMemo(() => {
    const filters = selectedFilters.map((item: any) => Object.values(item)[1]);
    const filterTypes = new Set(selectedFilters?.map((item: any) => Object.values(item)[0]));
    return backlogItems.filter(
      pbi =>
        (filterTypes?.has(OptionLabel.Ids) && !filters?.includes(pbi.sourceId)) ||
        (filterTypes?.has(OptionLabel.ItemType) && !filters?.includes(pbi.type)) ||
        (filterTypes?.has(OptionLabel.Epics) && !filters?.includes(pbi.epicName)),
    );
  }, [backlogItems, selectedFilters]);

  const filterOptions = useMemo(() => {
    return options.filter(option => selectedFilters?.some(v => v.name === option.name));
  }, [options, selectedFilters]);

  const itemSum = useMemo(() => {
    return numberOfPbis - filterBacklogItems.length;
  }, [numberOfPbis, filterBacklogItems]);

  const storyPoints = useMemo(() => {
    const filteredPbis = filterBacklogItems ? filterBacklogItems : backlogItems;
    const storyPointsOfFilteredPbis = filteredPbis.reduce((spSum, backlogItem) => {
      return spSum + backlogItem.storyPoints;
    }, 0);
    return sumOfBacklogStoryPoints - storyPointsOfFilteredPbis;
  }, [filterBacklogItems, backlogItems, sumOfBacklogStoryPoints]);

  const dispatch = useDispatch();

  const dragPbi = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedRanksBacklog: BacklogItem[] = updateBacklogItemRank(dragIndex, hoverIndex, backlogItems);
      dispatch(moveBacklogItem(team?.id, updatedRanksBacklog));
    },
    [backlogItems, dispatch, team],
  );

  const renderDropdownOption = useCallback(
    (option: Option) => {
      const isItemType = option.label === OptionLabel.ItemType;
      return (
        <>
          {isItemType && getItemTypeIcon(option.name)}
          <span className={isItemType ? classes.iconOptionText : ''}>{option.name}</span>
        </>
      );
    },
    [classes],
  );

  return (
    <>
      <Grid container direction='row'>
        <Grid item>
          <Typography variant='body2'>
            {filterBacklogItems?.length !== 0
              ? `${itemSum} of ${numberOfPbis} items (${roundStoryPoints(storyPoints)} of ${roundStoryPoints(sumOfBacklogStoryPoints)} SPs)`
              : `${numberOfPbis} items (${roundStoryPoints(sumOfBacklogStoryPoints)} SPs)`}
            {` - Last backlog update: ${timestampOfLastBacklogUpdate}`}
          </Typography>
        </Grid>
      </Grid>
      <Autocomplete
        options={options}
        getOptionLabel={option => option.name}
        renderOption={renderDropdownOption}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => <BacklogFilterItem key={option.name} option={option} tagProps={getTagProps} index={index} />);
        }}
        getOptionDisabled={option => filterOptions?.includes(option)}
        groupBy={option => option.label.toUpperCase()}
        id='pbis-filter'
        multiple
        value={selectedFilters}
        onChange={(_event, values) => {
          setSelectedFilters(values);
        }}
        renderInput={params => (
          <TextField {...params} variant='outlined' label='Filter backlog items' style={{ marginTop: '8px' }} fullWidth />
        )}
      />
      <List dense className={classes.list}>
        {backlogItems.map((pbi, i) => (
          <ListItem key={pbi.id} disabled={filterBacklogItems?.includes(pbi)}>
            <ProductBacklogItem
              backlogItem={pbi}
              numberOfPbis={numberOfPbis}
              index={i}
              dragPbi={dragPbi}
              id={pbi.id}
              issueBaseLink={issueBaseLink}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
