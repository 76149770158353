import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import moment, { Moment } from 'moment';
import { TableRow, TableCell, InputAdornment, FormControl, Box, Tooltip, TextField, alpha } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { Sprint } from '../planning.state';
import { calcRelativeVelocity } from '../planning-calc.selectors';
import { SprintReleaseIcon } from './sprint-release-icon';
import { SprintCommentIcon } from './sprint-comment-icon';
import { round, calculateForecast } from './sprint-functions';
import { SprintOptionsMenu } from './sprint-options-menu';
import { SprintDateField } from './sprint-date-field';
import { SprintRowForm } from './sprint-store-hook';

interface SprintRowProps {
  index: number;
  sprint: SprintRowForm;
  medianVelocity: number;
  openCommentDialog: (index: number, sprint: Sprint) => void;
  openReleaseDialog: (index: number, sprint: Sprint) => void;
  deleteSprint: (index: number, sprint: string) => void;
  onChangeSprint: (index: number, key: string, value: string | number | moment.Moment) => void;
}

export interface FocusValue<T> {
  focused: boolean;
  value: T;
}

const useStyles = makeStyles((theme: Theme) => ({
  sprintRow: {
    height: '65px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  icons: {
    width: '90px',
  },
  idField: {
    display: 'none',
  },
  velocityCellWidth: {
    width: '70px',
  },
  velocityFieldFontColor: {
    color: 'black',
    fontSize: '0.875rem',
  },
}));

export const SprintRow = React.memo<SprintRowProps>(
  ({ index, sprint, medianVelocity, openCommentDialog, openReleaseDialog, deleteSprint, onChangeSprint }) => {
    const classes = useStyles();

    const isPast = useMemo(() => {
      return moment(sprint.endDate).isBefore(moment(), 'day');
    }, [sprint.endDate]);

    const isCurrent = useMemo(() => moment().isBetween(sprint.startDate, sprint.endDate, 'day', '[]'), [sprint.endDate, sprint.startDate]);

    const onStartDateChange = useCallback(
      (date: Moment) => {
        onChangeSprint(index, 'startDate', date);
      },
      [index, onChangeSprint],
    );

    const onDeleteSprint = useCallback(() => {
      deleteSprint(index, sprint.sprintName);
    }, [deleteSprint, index, sprint.sprintName]);

    const clickComment = useCallback(() => {
      openCommentDialog(index, sprint);
    }, [index, openCommentDialog, sprint]);

    const clickRelease = useCallback(() => {
      openReleaseDialog(index, sprint);
    }, [index, openReleaseDialog, sprint]);

    const onChangeField = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const key: keyof Sprint = event.currentTarget.name as keyof Sprint;
        if (event.currentTarget.type === 'number') {
          const numericValue = parseFloat(event.currentTarget.value);
          if (isNaN(numericValue) || numericValue < 0) {
            event.currentTarget.value = '0';
          } else {
            event.currentTarget.value = numericValue.toString();
          }
        }
        const value: string | number = event.currentTarget.value;
        onChangeSprint(index, key, value);
      },
      [index, onChangeSprint],
    );

    return (
      <TableRow className={classes.sprintRow} selected={isCurrent}>
        <TableCell component='th'>
          {React.useMemo(
            () => (
              <Box display='flex' className={classes.icons}>
                {isPast && (
                  <Tooltip title='Past Sprint'>
                    <CheckIcon />
                  </Tooltip>
                )}
                <SprintCommentIcon onClick={clickComment} value={sprint.comments} />
                <SprintReleaseIcon onClick={clickRelease} value={sprint.release} />
              </Box>
            ),
            [classes.icons, clickComment, clickRelease, isPast, sprint.comments, sprint.release],
          )}
        </TableCell>

        <TableCell>
          {React.useMemo(() => {
            return (
              <TextField
                type='text'
                error={!!sprint.errors.sprintName}
                value={sprint.sprintName}
                name='sprintName'
                onChange={onChangeField}
                inputProps={{ 'data-testid': `sprint-name-input${index}` }}
                helperText={sprint.errors.sprintName}
              />
            );
          }, [index, onChangeField, sprint.sprintName, sprint.errors.sprintName])}
        </TableCell>

        <TableCell>
          {React.useMemo(
            () => (
              <SprintDateField
                value={sprint.startDate}
                onAccept={onStartDateChange}
                keyboard
                error={!!sprint.errors.startDate}
                helperText={sprint.errors.startDate}
              />
            ),
            [sprint.startDate, onStartDateChange, sprint.errors.startDate],
          )}
        </TableCell>

        <TableCell>
          {React.useMemo(
            () => (
              <FormControl>
                <TextField
                  type='number'
                  inputProps={{ min: 0, step: 0.5 }}
                  value={sprint.plannedStoryPoints}
                  name='plannedStoryPoints'
                  onChange={onChangeField}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>SP</InputAdornment>,
                  }}
                />
              </FormControl>
            ),
            [onChangeField, sprint.plannedStoryPoints],
          )}
        </TableCell>

        <TableCell>
          {isPast ? <></> : round(calculateForecast(sprint, medianVelocity), 0)} {isPast ? <></> : <>SP</>}
        </TableCell>

        <TableCell>
          {React.useMemo(
            () => (
              <Box display={isPast || isCurrent ? 'flex' : 'none'}>
                <FormControl>
                  <TextField
                    disabled={!(isPast || isCurrent)}
                    type='number'
                    value={sprint.achievedStoryPoints}
                    name='achievedStoryPoints'
                    inputProps={{ min: 0, step: 0.5 }}
                    onChange={onChangeField}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>SP</InputAdornment>,
                    }}
                  />
                </FormControl>
              </Box>
            ),
            [isCurrent, isPast, onChangeField, sprint.achievedStoryPoints],
          )}
        </TableCell>

        <TableCell>
          {React.useMemo(
            () => (
              <FormControl>
                <TextField
                  type='number'
                  value={sprint.personDays}
                  name='personDays'
                  inputProps={{ min: 0, step: 0.5 }}
                  onChange={onChangeField}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>PD</InputAdornment>,
                  }}
                />
              </FormControl>
            ),
            [onChangeField, sprint.personDays],
          )}
        </TableCell>

        <TableCell className={classes.velocityCellWidth}>
          <Box display={isPast || isCurrent ? 'flex' : 'none'}>{round(calcRelativeVelocity(sprint), 2)}</Box>
        </TableCell>

        <TableCell>
          {React.useMemo(
            () => (
              <SprintOptionsMenu
                release={sprint.release}
                comments={sprint.comments}
                onClickComment={clickComment}
                onClickRelease={clickRelease}
                onClickDelete={onDeleteSprint}
              />
            ),
            [clickComment, clickRelease, onDeleteSprint, sprint.comments, sprint.release],
          )}
        </TableCell>
      </TableRow>
    );
  },
  isEqual,
);

function isEqual(objA: any, objB: any) {
  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  for (var i = 0; i < keysA.length; i++) {
    if (typeof objA[keysA[i]] === 'object' && typeof objB[keysB[i]] === 'object') {
      if (JSON.stringify(objA[keysA[i]]) !== JSON.stringify(objB[keysB[i]])) {
        return false;
      }
    } else {
      if (!Object.is(objA[keysA[i]], objB[keysA[i]])) {
        return false;
      }
    }
  }

  return true;
}
