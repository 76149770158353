import React from 'react';
import { green, red, blue } from '@material-ui/core/colors';
import { BugReportOutlined, InsertDriveFileOutlined, AssignmentOutlined } from '@material-ui/icons';

const itemTypeIconMap: { [key: string]: { factory: (props: any) => JSX.Element; color: string } } = {
  bug: { factory: props => <BugReportOutlined {...props} />, color: red[300] },
  task: { factory: props => <AssignmentOutlined {...props} />, color: blue[300] },
  default: { factory: props => <InsertDriveFileOutlined {...props} />, color: green[300] },
};

export const getItemTypeIcon = (backlogItemType: string, verticalAlignBottom: boolean = false): JSX.Element => {
  const { factory, color } = itemTypeIconMap[backlogItemType.toLowerCase()] ?? itemTypeIconMap.default;
  const style = { color, verticalAlign: verticalAlignBottom ? 'bottom' : 'middle' };
  return factory({ style, fontSize: 'small' });
};
