import { OptionsObject } from 'notistack';
import { RouterState } from 'redux-first-history';
import { AuthState } from './auth';
import { PlanningState } from './planning';
import { ResourceState } from './shared';

export interface Version {
  name: string;
  versionInformation: string;
  version: string;
}

export interface FeatureOptions {
  allowSetDefaultStoryPoints: boolean;
}

export interface Notification {
  key?: number;
  message: string;
  variant: string;
  dismissed?: boolean;
  autoHideDuration?: number | null;
}

export interface AppState {
  version: ResourceState<Version>;
  featureOptions: ResourceState<FeatureOptions>;
  notifications: Notification[];
  unsavedChanges: boolean;
}

export interface RootState {
  app: AppState;
  auth: AuthState;
  router: RouterState;
  planning: PlanningState;
}

export const initialAppState: AppState = {
  version: {
    data: {
      name: '',
      versionInformation: '',
      version: '',
    },
    requestError: null,
    isPending: false,
  },
  featureOptions: {
    data: {
      allowSetDefaultStoryPoints: false,
    },
    requestError: null,
    isPending: false,
  },
  notifications: [],
  unsavedChanges: false,
};
