import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ignoreBackdropClick } from './ignore-backdrop-click';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  confirmBtnText: string;
  onCancel: () => void;
  onConfirm: () => void;
  identifier: any;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  description,
  confirmBtnText,
  onCancel,
  onConfirm,
  identifier,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      onClose={(event, reason) => ignoreBackdropClick(reason, handleCancel)}
      aria-labelledby={`confirmation-dialog-title-${identifier}`}
      aria-describedby={`confirmation-dialog-description-${identifier}`}
    >
      <DialogTitle id={`confirmation-dialog-title-${identifier}`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`confirmation-dialog-description-${identifier}`}>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='default'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary' variant='contained' autoFocus data-cy={confirmBtnText}>
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
