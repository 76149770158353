import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { completeLogout, loadLogoutInfo } from '../auth.actions';
import { selectLogoutState } from '../auth.selectors';
import { LogoutInfo } from '../auth.state';
import { LoggedOutInfoDto } from '../auth.model';
import useMountEffect from '../../shared/use-mount-effect';

const useStyles = makeStyles((theme: Theme) => ({
  iframe: {
    width: 0,
    height: 0,
    border: 'none',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  promptHeader: {
    marginBottom: theme.spacing(2),
  },
}));

function extractUrlParameter(url: string, name: string): string {
  const query = new URLSearchParams(url);
  const origReturnUrl = query.get(name);
  return origReturnUrl ? origReturnUrl : '';
}

export const LogoutPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryString = useLocation().search;
  const logoutId = extractUrlParameter(queryString, 'logoutId');

  useMountEffect(() => {
    dispatch(loadLogoutInfo(logoutId));
  });

  const handleLogout = useCallback(() => dispatch(completeLogout(logoutId)), [dispatch, logoutId]);

  const logoutState = useSelector(selectLogoutState);
  const logoutInfo = logoutState.logoutInfo;

  if (!logoutState.logoutInfo && !logoutState.error) return <div>Logout pending</div>;

  return (
    <div className={classes.container}>
      {!(logoutInfo as LogoutInfo).showLogoutPrompt && (
        <Typography component='h3' variant='h5'>
          You are now logged out.
        </Typography>
      )}

      {(logoutInfo as LogoutInfo).showLogoutPrompt && (
        <>
          <Typography component='h3' variant='h5' className={classes.promptHeader}>
            Would you like to logout of IdentityServer?
          </Typography>

          <Button variant='contained' color='primary' onClick={handleLogout}>
            Yes
          </Button>
        </>
      )}

      {(logoutInfo as LoggedOutInfoDto).postLogoutRedirectUri && (logoutInfo as LoggedOutInfoDto).postLogoutRedirectUri && (
        <p>
          Click <a href={(logoutInfo as LoggedOutInfoDto).postLogoutRedirectUri}>here</a> to return to the{' '}
          <span>{(logoutInfo as LoggedOutInfoDto).clientName}</span> application.
        </p>
      )}

      <br />

      {logoutState.error && (
        <div>
          {logoutState.error.errors[''].map(message => (
            <small className='error'>{message}</small>
          ))}
        </div>
      )}

      {(logoutInfo as LoggedOutInfoDto).postLogoutRedirectUri && (logoutInfo as LoggedOutInfoDto).signOutIframeUrl && (
        <iframe className={classes.iframe} title='logout frame' src={(logoutInfo as LoggedOutInfoDto).signOutIframeUrl}></iframe>
      )}
    </div>
  );
};
