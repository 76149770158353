import { History } from 'history';
import { AuthService, IAuthService } from './auth';
import { IPlanningService, PlanningService } from './planning';
import { StaticInjector, INJECTOR, IRestClient, RestClient } from './shared';
import { AppService, IAppService } from './app.service';

export interface AppDependencies {
  injector: StaticInjector;
  authAjaxService: IRestClient;
  appService: IAppService;
  authService: IAuthService;
  planningService: IPlanningService;
}

const authAjaxService = new RestClient(INJECTOR);

export const appDependencies: AppDependencies = {
  injector: INJECTOR,
  authService: new AuthService(INJECTOR, authAjaxService),
  appService: new AppService(),
  authAjaxService: authAjaxService,
  planningService: new PlanningService(authAjaxService),
};
