import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { selectAppVersion } from '../app.selectors';

export const VersionComponent: React.FC<{ showInformationVersion: boolean }> = props => {
  const version = useSelector(selectAppVersion);

  if (props.showInformationVersion) {
    return <Typography>{!version?.requestError && `${version?.data.name}: ${version?.data.versionInformation}`}</Typography>;
  }
  return <Typography>{!version?.requestError && `Version: ${version?.data.version}`}</Typography>;
};
