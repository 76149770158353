import { catchError, filter, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { of } from 'rxjs';
import { combineEpics, Epic } from 'redux-observable';
import { push } from 'redux-first-history';
import { SuccessNotification } from '../shared';
import { removeAllNotifications } from '../app.actions';
import { AppDependencies } from '../app.dependencies';
import { RootState } from '../app.state';
import { mapDefaultResponseToError, parseChangePasswordErrorMessage } from '../shared';
import { enqueueNotification, initializePrivateData } from '../app.actions';
import {
  AuthActionTypes,
  completeLogin,
  loadLoginInfoFailed,
  loadLoginInfoSucceeded,
  loadOidcConfigFailed,
  loadOidcConfigSucceeded,
  loginWithEmailPassword,
  loginWithEmailPasswordFailed,
  loginWithEmailPasswordSucceeded,
  loginWithExternalProvider,
  login,
  setUser,
  loadLoginInfo,
  loadOidcConfigInfo,
  loadAllUsers,
  loadAllUsersSucceeded,
  loadAllUsersFailed,
  loadAllRoles,
  loadAllRolesSucceeded,
  loadAllRolesFailed,
  logout,
  loadLogoutInfoFailed,
  loadLogoutInfoSucceeded,
  loadLogoutInfo,
  completeLogout,
  changePassword,
  changePasswordSucceeded,
  changePasswordFailed,
  createUser,
  createUserSucceeded,
  createUserFailed,
  updateUser,
  updateUserSucceeded,
  updateUserFailed,
  deleteUser,
  deleteUserSucceeded,
  deleteUserFailed,
} from './auth.actions';
import { LoggedOutInfoDto } from './auth.model';
import { selectUserRole } from './auth.selectors';
import { ROLE_ADMIN } from './auth.constants';
import { mapLoginResponseToError, createExternalLoginUrl } from './auth-helpers';

export const loadLoginInfoEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loadLoginInfo.match),
    switchMap(() =>
      authService.getLoginInfo().pipe(
        retry(3),
        mergeMap(info => [loadLoginInfoSucceeded(info)]),
        catchError((response: AjaxResponse<any>) => of(loadLoginInfoFailed(mapDefaultResponseToError(response)))),
      ),
    ),
  );

export const loadOidcConfigEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loadOidcConfigInfo.match),
    switchMap(() =>
      authService.getOidcConfig().pipe(
        retry(3),
        mergeMap(config => [loadOidcConfigSucceeded(config)]),
        catchError((response: AjaxResponse<any>) => of(loadOidcConfigFailed(mapDefaultResponseToError(response)))),
      ),
    ),
  );

export const loginEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(login.match),
    switchMap(() => authService.signin().pipe(mergeMap(() => []))),
  );

export const completeLoginEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(completeLogin.match),
    switchMap(a =>
      authService
        .completeSignin(a.payload.url)
        .pipe(
          mergeMap(user => [
            setUser(user),
            initializePrivateData(),
            document.referrer.includes('login') ? push('/') : push(document.referrer.replace(location.origin, '')),
          ]),
        ),
    ),
  );

export const loginWithEmailPasswordEpic: Epic<AuthActionTypes, any, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loginWithEmailPassword.match),
    switchMap(action =>
      authService.login(action.payload.email, action.payload.password, action.payload.returnUrl).pipe(
        mergeMap(response => [loginWithEmailPasswordSucceeded(response), removeAllNotifications()]),
        catchError((response: AjaxResponse<any>) => {
          const mappedLoginResponseToError = mapLoginResponseToError(response);
          if (Object.keys(mappedLoginResponseToError.errors).includes('')) {
            return of(
              loginWithEmailPasswordFailed(mappedLoginResponseToError),
              enqueueNotification({
                key: action.payload.notificationKey,
                message: mappedLoginResponseToError.errors[''][0],
                variant: 'error',
              }),
            );
          } else {
            return of(loginWithEmailPasswordFailed(mappedLoginResponseToError));
          }
        }),
      ),
    ),
  );

export const redirectAfterLoginEpic: Epic<AuthActionTypes> = action$ =>
  action$.pipe(
    filter(loginWithEmailPasswordSucceeded.match),
    tap(a => {
      window.location.href = a.payload.redirect.redirectUrl;
    }),
    mergeMap(() => []),
  );

export const loginWithExternalProviderEpic: Epic<AuthActionTypes> = action$ =>
  action$.pipe(
    filter(loginWithExternalProvider.match),
    tap(a => {
      window.location.href = createExternalLoginUrl(a.payload.applicationUrl, a.payload.provider, a.payload.returnUrl);
    }),
    mergeMap(() => []),
  );

const initializeUserDataEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$) =>
  action$.pipe(
    filter(initializePrivateData.match),
    mergeMap(() => (selectUserRole(state$.value) === ROLE_ADMIN ? [loadAllUsers(), loadAllRoles()] : [])),
  );

const loadAllUsersEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loadAllUsers.match),
    switchMap(() => {
      return authService.getUsers().pipe(
        mergeMap(response => [loadAllUsersSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(loadAllUsersFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

const loadAllRolesEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loadAllRoles.match),
    switchMap(() => {
      return authService.getRoles().pipe(
        mergeMap(response => [loadAllRolesSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(loadAllRolesFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

export const logoutEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(logout.match),
    switchMap(() => authService.signout().pipe(mergeMap(() => []))),
  );

export const loadLogoutInfoEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(loadLogoutInfo.match),
    switchMap(a => {
      return authService.getLogoutInfo(a.payload.logoutId).pipe(
        mergeMap(response => {
          return handleLogoutResponse(response as LoggedOutInfoDto);
        }),
        catchError((error: AjaxResponse<any>) => of(loadLogoutInfoFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

export const completeLogoutEpic: Epic<AuthActionTypes, AuthActionTypes, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(completeLogout.match),
    switchMap(a => {
      return authService.logout(a.payload.logoutId).pipe(
        mergeMap(response => {
          return handleLogoutResponse(response);
        }),
        catchError((error: AjaxResponse<any>) => of(loadLogoutInfoFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

function handleLogoutResponse(loggedOutInfo: LoggedOutInfoDto) {
  if (loggedOutInfo.automaticRedirectAfterSignOut) {
    window.location.href = loggedOutInfo.postLogoutRedirectUri;
    return [];
  }
  return [loadLogoutInfoSucceeded(loggedOutInfo)];
}

export const changePasswordEpic: Epic<AuthActionTypes, any, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(changePassword.match),
    switchMap(action => {
      return authService.changePassword(action.payload.currentPassword, action.payload.newPassword).pipe(
        mergeMap(response => [
          changePasswordSucceeded(response),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: SuccessNotification.PasswordChange,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            changePasswordFailed(mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: parseChangePasswordErrorMessage(`${Object.values(mapDefaultResponseToError(error).errors)[0]}`),
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const createUserEpic: Epic<AuthActionTypes, any, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(createUser.match),
    switchMap(action => {
      return authService.createUser(action.payload.user).pipe(
        mergeMap(response => [
          createUserSucceeded(response),
          loadAllUsers(),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: `User ${action.payload.user.email} was created successfully.`,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            createUserFailed(mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: `Error while creating user ${action.payload.user.email}: ${
                Object.values(mapDefaultResponseToError(error).errors)[0]
              }`,
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const updateUserEpic: Epic<AuthActionTypes, any, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(updateUser.match),
    switchMap(action => {
      return authService.updateUser(action.payload.user).pipe(
        mergeMap(response => [
          updateUserSucceeded(response),
          loadAllUsers(),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: `User ${action.payload.user.email} was updated successfully.`,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            updateUserFailed(mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: `Error while updating user ${action.payload.user.email}: ${
                Object.values(mapDefaultResponseToError(error).errors)[0]
              }`,
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const deleteUserEpic: Epic<AuthActionTypes, any, RootState, AppDependencies> = (action$, state$, { authService }) =>
  action$.pipe(
    filter(deleteUser.match),
    switchMap(action => {
      return authService.deleteUser(action.payload.user.id).pipe(
        mergeMap(response => [
          deleteUserSucceeded(response),
          loadAllUsers(),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: `User ${action.payload.user.email} was deleted successfully.`,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            deleteUserFailed(mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: `Error while deleting user ${action.payload.user.email}: ${
                Object.values(mapDefaultResponseToError(error).errors)[0]
              }`,
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

export const authEpics = combineEpics(
  loadLoginInfoEpic,
  loadOidcConfigEpic,
  loginEpic,
  completeLoginEpic,
  loginWithEmailPasswordEpic,
  redirectAfterLoginEpic,
  loginWithExternalProviderEpic,
  initializeUserDataEpic,
  loadAllUsersEpic,
  loadAllRolesEpic,
  logoutEpic,
  loadLogoutInfoEpic,
  completeLogoutEpic,
  changePasswordEpic,
  createUserEpic,
  updateUserEpic,
  deleteUserEpic,
);
