import React from 'react';
import { IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { ReleaseCandidate } from '../../planning.state';
import { useToggleState } from '../../../shared';
import ReleaseCandidateModal, { Candidate } from './release-candidate-dialog.component';

interface BurnupWidgetReleaseProps {
  teamid: string;
  releaseCandidates: ReleaseCandidate[];
  handleSave: (candidatesList: Candidate[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  settingsIcon: {
    marginRight: '0 !important',
    paddingRight: theme.spacing(1),
  },
}));

export const BurnupWidgetRelease: React.FC<BurnupWidgetReleaseProps> = ({ teamid, releaseCandidates, handleSave }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useToggleState();

  const onSave = (candidatesList: Candidate[]) => {
    handleSave(candidatesList);
    toggleOpen();
  };

  return (
    <>
      <Tooltip id='btnReleaseCandidates' title='Release candidates'>
        <IconButton aria-label='Settings' onClick={() => toggleOpen()} className={classes.settingsIcon}>
          <SettingsIcon fontSize='small'></SettingsIcon>
        </IconButton>
      </Tooltip>
      <ReleaseCandidateModal
        key={teamid}
        isOpen={open}
        handleClose={() => toggleOpen()}
        releaseCandidatesData={releaseCandidates}
        handleSave={onSave}
      />
    </>
  );
};
