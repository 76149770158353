import cloneDeep from 'clone-deep';
import { useCallback, useReducer } from 'react';
import moment, { Moment } from 'moment';
import { Sprint } from '../planning.state';

interface Action {
  type: string;
  payload: any;
}

export type DeleteAction = (index: number) => void;
export type UpdateAction = (payload: any) => void;
export type AddAction = () => void;
export type LoadAction = (sprints: SprintRowForm[]) => void;
export interface SprintRowForm extends Sprint {
  errors: {
    [key: string]: string;
  };
}

function reducer(state: SprintRowForm[], action: Action) {
  const stateClone = cloneDeep(state);
  switch (action.type) {
    case 'ADD': {
      const { defaultDuration } = action.payload;
      stateClone.push(getNewSprint(stateClone, defaultDuration));
      validateSprintNames(stateClone);
      break;
    }
    case 'UPDATE': {
      updateSprint(action, stateClone);
      break;
    }
    case 'DELETE':
      const { index } = action.payload;
      if (index > 0) {
        const sprintBefore = stateClone[index - 1];
        sprintBefore.endDate = stateClone[index].endDate.clone();
      }
      stateClone.splice(action.payload.index, 1);
      validateSprintNames(stateClone);
      break;
    case 'LOAD':
      return action.payload.sprints;
  }
  return stateClone;
}

export const useSprintReducer = (
  initialSprints: SprintRowForm[],
  defaultDuration: number,
): [SprintRowForm[], UpdateAction, DeleteAction, AddAction, LoadAction] => {
  const [sprints, dispatch] = useReducer(reducer, initialSprints);

  const updateSprint = useCallback(
    (payload: any) => {
      dispatch({ type: 'UPDATE', payload: { ...payload, defaultDuration } });
    },
    [dispatch, defaultDuration],
  );

  const deleteSprint = useCallback(
    (index: number) => {
      dispatch({ type: 'DELETE', payload: { index } });
    },
    [dispatch],
  );

  const addSprint = useCallback(() => {
    dispatch({ type: 'ADD', payload: { defaultDuration } });
  }, [dispatch, defaultDuration]);

  const loadSprints = useCallback(
    (sprints: SprintRowForm[]) => {
      dispatch({ type: 'LOAD', payload: { sprints } });
    },
    [dispatch],
  );

  return [sprints, updateSprint, deleteSprint, addSprint, loadSprints];
};

function updateSprint(action: Action, state: SprintRowForm[]) {
  const { key, index, cascade, defaultDuration, value } = action.payload;
  state[index] = { ...state[index], [key]: value };

  if (key === 'startDate') {
    adjustDate(index, state, cascade, defaultDuration);
    validateStartDates(state);
  } else if (key === 'sprintName') {
    if (cascade) {
      cascadeSprintName(state, index, value);
    }
    validateSprintNames(state);
  } else if ((key === 'personDays' || key === 'plannedStoryPoints') && cascade) {
    cascadeNumericValue(state, index, key, value);
  } else if (key === 'achievedStoryPoints' && cascade) {
    const idxFirstFutureSprint = getIndexOfFirstFutureSprint(state);
    cascadeNumericValue(state, index, key, value, idxFirstFutureSprint);
  } else if (key === 'release') {
    state[index].isRelease = !!action.payload.value;
  }
}

function getCorrectSprintIndex(sprints: Sprint[], index: number): number {
  const startDate = moment(sprints[index].startDate);
  for (let i = 0; i < sprints.length; i++) {
    if (startDate.isSameOrBefore(sprints[i].startDate) && i !== index) {
      return index >= i ? i : i - 1;
    }
  }
  return sprints.length - 1;
}

function getNewSprint(sprints: SprintRowForm[], defaultSprint: number): SprintRowForm {
  const lastSprint = sprints[sprints.length - 1];
  const startDate: Moment = lastSprint ? lastSprint.endDate.clone().add(1, 'days') : moment();
  const endDate: Moment = startDate.clone().add(defaultSprint, 'days');
  const maxSprintId = Math.max(...sprints.map(s => +s.id));
  return {
    id: Number.isFinite(maxSprintId) ? (maxSprintId + 1).toString() : '1',
    sprintName: `Sprint ${sprints.length + 1}`,
    plannedStoryPoints: lastSprint ? lastSprint.plannedStoryPoints : 0,
    achievedStoryPoints: 0,
    personDays: lastSprint ? lastSprint.personDays : 0,
    startDate: startDate,
    endDate: endDate,
    isRelease: false,
    errors: {},
  };
}

function fixSprintEndDates(sprints: SprintRowForm[], defaultDuration: number, sort: boolean = true, cascade: boolean = false) {
  sort && sprints.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
  for (let i = 1; i < sprints.length; i++) {
    const sprint = sprints[i];
    sprints[i - 1].endDate = sprint.startDate.clone().add(-1, 'days');
    if (!cascade && i === sprints.length - 1) {
      sprint.endDate = sprint.startDate.clone().add(defaultDuration - 1, 'days');
    }
  }
}

function cascadeDate(_index: number, sprints: SprintRowForm[], defaultDuration: number) {
  const date = sprints[_index].startDate;
  for (let index = _index; index < sprints.length; index++) {
    sprints[index].startDate = date.clone();
    sprints[index].endDate = date.add(defaultDuration - 1, 'days').clone();
    date.add(1, 'days');
  }
}

function adjustDate(index: number, sprints: SprintRowForm[], cascade: boolean, defaultDuration: number) {
  if (cascade) {
    const newIndex = getCorrectSprintIndex(sprints, index);
    const moveUp = newIndex < index;
    fixSprintEndDates(sprints, defaultDuration, moveUp, true);
    cascadeDate(moveUp ? newIndex : index, sprints, defaultDuration);
  } else {
    fixSprintEndDates(sprints, defaultDuration);
  }
  resetAchievedStoryPointsForFutureSprints(sprints);
}

function validateStartDates(sprints: SprintRowForm[]) {
  sprints.forEach(sprint => {
    const sprintId = sprint.id;
    const startDate = sprint.startDate;
    if (startDate.isAfter(sprint.endDate)) {
      sprint.errors.startDate = 'Start date must be before start of next sprint.';
      return;
    }
    const sprintWithSameStartDate = sprints.find(s => startDate.isSame(s.startDate) && s.id !== sprintId);
    if (sprintWithSameStartDate) {
      sprint.errors.startDate = 'Start date cannot be equal.';
    } else {
      delete sprint.errors.startDate;
    }
  });
}

function validateSprintNames(sprints: SprintRowForm[]) {
  sprints.forEach(sprint => {
    const sprintId = sprint.id;
    const sprintName = sprint.sprintName.trim();
    if (!sprintName.length) {
      sprint.errors.sprintName = 'Name cannot be empty.';
      return;
    }
    const sprintWithSameName = sprints.find(s => s.sprintName.trim() === sprintName && s.id !== sprintId);
    if (sprintWithSameName) {
      sprint.errors.sprintName = 'Name must be unique.';
    } else {
      delete sprint.errors.sprintName;
    }
  });
}

function cascadeSprintName(sprints: SprintRowForm[], index: number, sprintName: string) {
  const trimmedSprintName = sprintName.trim();
  const isSprintNameEmpty = !trimmedSprintName.length;
  const sprintNameAsNumber = parseInt(trimmedSprintName);
  let sprintNumber = 0;
  let sprintNamePrefix = '';
  if (isNaN(sprintNameAsNumber)) {
    const idxLastWhitespace = trimmedSprintName.lastIndexOf(' ');
    const parsedSprintNumber = idxLastWhitespace > -1 ? parseInt(trimmedSprintName.substring(idxLastWhitespace)) : NaN;
    const idxSprintNamePrefix = isNaN(parsedSprintNumber) ? trimmedSprintName.length : idxLastWhitespace;
    sprintNamePrefix = trimmedSprintName.substring(0, idxSprintNamePrefix);
    sprintNumber = isNaN(parsedSprintNumber) ? 1 : parsedSprintNumber + 1;
  } else {
    sprintNumber = sprintNameAsNumber + 1;
  }
  sprintNamePrefix += sprintNamePrefix.length ? ' ' : '';
  for (let i = index + 1; i < sprints.length; i++) {
    sprints[i].sprintName = isSprintNameEmpty ? '' : `${sprintNamePrefix}${sprintNumber}`;
    sprintNumber++;
  }
}

function cascadeNumericValue(
  sprints: SprintRowForm[],
  index: number,
  key: 'personDays' | 'plannedStoryPoints' | 'achievedStoryPoints',
  value: number,
  stopAtIndex: number = -1,
) {
  const upperBound = stopAtIndex >= 0 ? stopAtIndex : sprints.length;
  for (let i = index; i < upperBound; i++) {
    sprints[i][key] = value;
  }
}

function getIndexOfFirstFutureSprint(sprints: SprintRowForm[]) {
  const now = moment();
  return sprints.findIndex(sprint => sprint.startDate.isAfter(now));
}

function resetAchievedStoryPointsForFutureSprints(sprints: SprintRowForm[]) {
  const idxFirstFutureSprint = getIndexOfFirstFutureSprint(sprints);
  if (idxFirstFutureSprint < 0) {
    return;
  }
  for (let i = idxFirstFutureSprint; i < sprints.length; i++) {
    sprints[i].achievedStoryPoints = 0;
  }
}
