import { useForm, FormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  Typography,
  Toolbar,
  TextField,
  Box,
  Divider,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { selectFeatureOptions } from '../../app.selectors';
import { ignoreBackdropClick } from '../../shared/ignore-backdrop-click';
import { BacklogManagementSystem } from '../planning.model';
import {
  selectActiveTeam,
  selectBacklogManagementSystem,
  selectHasBacklogUpdateSucceeded,
  selectIsBacklogUploadPending,
} from '../planning.selectors';
import {
  useGetErrorMessageCallback,
  FormFileUploadValue,
  SpinnerOverlay,
  tryParseNumeric,
  REGEX_STORY_POINTS,
  generateNotificationKey,
} from '../../shared';
import { BacklogUpdateAuth } from './backlog-update-auth.component';
import { BacklogUpdateAuthExcel } from './backlog-update-auth-excel.component';
import { BacklogUpdateAuthFullJiraCsv } from './backlog-update-auth-csv.component';
import { removeAllNotifications } from '../../app.actions';
import {
  updateBacklogForTeam,
  updateBacklogForTeamOAuth,
  updateBacklogForTeamExcel,
  updateBacklogForTeamFullJiraCsv,
  updateBacklogForTeamCanceled,
} from '..';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    padding: theme.spacing(0),
  },
  dialogActions: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dialogContextText: {
    textAlign: 'justify',
  },
  formControl: {
    margin: theme.spacing(3, 3, 3, 0),
  },
  formControlNoMarginBottom: {
    margin: theme.spacing(3, 3, 0, 0),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

interface BackLogUpdateDialogProps {
  onClose: () => void;
  open: boolean;
}

export const BacklogUpdateDialog: React.FC<BackLogUpdateDialogProps> = ({ onClose, open, ...rest }) => {
  const classes = useStyles(rest);
  const { allowSetDefaultStoryPoints } = useSelector(selectFeatureOptions).data;
  const backlogManagementSystem = useSelector(selectBacklogManagementSystem);
  const activeTeam = useSelector(selectActiveTeam);
  const isExcel = backlogManagementSystem === BacklogManagementSystem.Excel;
  const isFullJiraCsv = backlogManagementSystem === BacklogManagementSystem.FullJiraCsv;
  const isOAuth = activeTeam ? activeTeam.config.isOAuthEnabled : false;
  const defaultStoryPoints = activeTeam ? activeTeam.config.defaultStoryPoints : '0';
  const backlogUpdatePending = useSelector(selectIsBacklogUploadPending);
  const hasSucceeded = useSelector(selectHasBacklogUpdateSucceeded);
  const formContextValues = useForm();
  const { handleSubmit, register, errors } = formContextValues;
  const getErrorMessageForField = useGetErrorMessageCallback(errors);
  const dispatch = useDispatch();
  const [overrideDefaultSP, setOverrideDefaultSP] = useState<boolean>(false);

  const [processFinished, setProcessFinished] = useState<boolean>(true);

  const onCancel = useCallback(() => {
    if (activeTeam) {
      dispatch(updateBacklogForTeamCanceled(activeTeam.id));
    }
  }, [activeTeam, dispatch]);

  const onCloseDialog = useCallback(() => {
    onCancel();
    setOverrideDefaultSP(false);
    dispatch(removeAllNotifications());
    onClose();
  }, [dispatch, onCancel, onClose]);

  useEffect(() => {
    if (!processFinished && !backlogUpdatePending) {
      if (hasSucceeded) {
        onClose();
      }
      setProcessFinished(true);
    }
  }, [processFinished, backlogUpdatePending, hasSucceeded, onClose]);

  const onSubmit = useCallback(
    values => {
      if (activeTeam) {
        const activeTeamId = activeTeam.id;
        const keepOldBacklogItems = values.keepOldBacklogItems || false;
        const parsedDefaultStoryPoints = allowSetDefaultStoryPoints ? tryParseNumeric(values.defaultStoryPoints) : undefined;
        const notificationKey = generateNotificationKey();
        if (isExcel) {
          dispatch(
            updateBacklogForTeamExcel(
              activeTeamId,
              notificationKey,
              wrapFileObjectIntoFormDataExcel(values.excelSource),
              keepOldBacklogItems,
              parsedDefaultStoryPoints,
            ),
          );
        } else if (isFullJiraCsv) {
          dispatch(
            updateBacklogForTeamFullJiraCsv(
              activeTeamId,
              notificationKey,
              wrapFileObjectIntoFormDataCsv(values.csvSource),
              keepOldBacklogItems,
              parsedDefaultStoryPoints,
            ),
          );
        } else if (isOAuth) {
          dispatch(updateBacklogForTeamOAuth(activeTeamId, notificationKey, keepOldBacklogItems, parsedDefaultStoryPoints));
        } else {
          dispatch(
            updateBacklogForTeam(activeTeamId, notificationKey, {
              username: values.username,
              password: values.password,
              keepOldBacklogItems: keepOldBacklogItems,
              defaultStoryPoints: parsedDefaultStoryPoints,
            }),
          );
        }
        setProcessFinished(false);
      }
    },
    [activeTeam, allowSetDefaultStoryPoints, dispatch, isExcel, isFullJiraCsv, isOAuth],
  );

  const renderAuthFormFragment = useCallback(() => {
    switch (backlogManagementSystem) {
      case BacklogManagementSystem.None:
        return <Typography className={classes.formControl}>No valid backlog management system has been defined.</Typography>;
      case BacklogManagementSystem.Excel:
        return (
          <BacklogUpdateAuthExcel
            errorMessageCallback={getErrorMessageForField}
            activeTeam={activeTeam}
            pending={!processFinished || backlogUpdatePending}
          />
        );
      case BacklogManagementSystem.FullJiraCsv:
        return (
          <BacklogUpdateAuthFullJiraCsv
            errorMessageCallback={getErrorMessageForField}
            activeTeam={activeTeam}
            pending={!processFinished || backlogUpdatePending}
          />
        );
      default:
        return (
          <BacklogUpdateAuth
            activeTeam={activeTeam}
            backlogManagementSystem={backlogManagementSystem}
            formContextValues={formContextValues}
            errorMessageCallback={getErrorMessageForField}
            pending={!processFinished || backlogUpdatePending}
          />
        );
    }
  }, [classes, activeTeam, backlogManagementSystem, formContextValues, getErrorMessageForField, processFinished, backlogUpdatePending]);

  const renderDefaultStoryPointsFragment = useCallback(() => {
    return (
      <>
        <Box className={classes.divider}>
          <Divider />
        </Box>
        <Typography>
          If an imported PBI is either unestimated or has 0 StoryPoints assigned, its StoryPoints will currently be set to{' '}
          <b>{defaultStoryPoints ?? '0'}</b>. This default value can be adjusted in the <i>team management</i> page.
          <br />
          To override the default value for this backlog import, activate the checkbox below.
          <br />
          <b>Note: </b> This will not change the default value set in the team settings.
        </Typography>
        <Box className={classes.divider}>
          <FormControlLabel
            id='setDefaultStoryPointsLabel'
            name='setDefaultStoryPointsLabel'
            control={
              <Checkbox
                id='setDefaultStoryPoints'
                name='setDefaultStoryPoints'
                color='primary'
                checked={overrideDefaultSP}
                inputRef={register}
                onChange={event => setOverrideDefaultSP(event.target.checked)}
                disabled={!processFinished || backlogUpdatePending}
              />
            }
            label='Override default StoryPoints for unestimated / 0 StoryPoints PBI'
          />
          {overrideDefaultSP && (
            <TextField
              id='defaultStoryPoints'
              name='defaultStoryPoints'
              type='text'
              label='Default StoryPoints per PBI *'
              fullWidth
              variant='outlined'
              className={classes.formControlNoMarginBottom}
              disabled={!processFinished || backlogUpdatePending}
              inputRef={register({
                required: 'This field is required.',
                pattern: {
                  value: REGEX_STORY_POINTS,
                  message: 'Default StoryPoints should be a numeric value >= 0.',
                },
              })}
              helperText={getErrorMessageForField('defaultStoryPoints')}
              error={!!getErrorMessageForField('defaultStoryPoints')}
              data-cy='defaultStoryPoints'
            />
          )}
        </Box>
        <Box className={classes.marginBottom}>
          <Divider />
        </Box>
      </>
    );
  }, [
    defaultStoryPoints,
    classes.divider,
    classes.formControlNoMarginBottom,
    classes.marginBottom,
    overrideDefaultSP,
    register,
    processFinished,
    backlogUpdatePending,
    getErrorMessageForField,
    setOverrideDefaultSP,
  ]);

  const wrapFileObjectIntoFormDataExcel = (formFieldUploadValue: FormFileUploadValue) => {
    const formData = new FormData();
    if (formFieldUploadValue) {
      formData.append('excelFile', formFieldUploadValue.blob ? formFieldUploadValue.blob : '', formFieldUploadValue.filename);
    }
    return formData;
  };

  const wrapFileObjectIntoFormDataCsv = (formFieldUploadValue: FormFileUploadValue) => {
    const formData = new FormData();
    if (formFieldUploadValue) {
      formData.append('csvFile', formFieldUploadValue.blob ? formFieldUploadValue.blob : '', formFieldUploadValue.filename);
    }
    return formData;
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => ignoreBackdropClick(reason, onCloseDialog)}
      disableEscapeKeyDown
      aria-labelledby='update-backlog-dialog-title'
      maxWidth='md'
    >
      <Toolbar>
        <DialogTitle id='update-backlog-dialog-title' className={classes.dialogTitle}>
          Update Backlog
        </DialogTitle>
      </Toolbar>
      <FormContext {...formContextValues}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {activeTeam && (
              <DialogContentText className={classes.dialogContextText}>
                Updates the current state of the backlog from{' '}
                {isExcel ? 'an Excel file' : isFullJiraCsv ? 'a CSV file' : backlogManagementSystem} and stores it in the Agile Planner
                database. The backlog items including story points and associated epics are updated.
                {isFullJiraCsv && ' Note that epics should be included in the query when exporting a CSV file from Jira'}
              </DialogContentText>
            )}
            {renderAuthFormFragment()}
            {allowSetDefaultStoryPoints && renderDefaultStoryPointsFragment()}
            {(isExcel || isFullJiraCsv) && (
              <FormControlLabel
                id='keepOldBacklogItemsLabel'
                name='keepOldBacklogItemsLabel'
                control={
                  <Checkbox
                    id='keepOldBacklogItems'
                    name='keepOldBacklogItems'
                    color='primary'
                    inputRef={register}
                    disabled={!processFinished || backlogUpdatePending}
                  />
                }
                label='Add only additional backlog items to bottom.'
              />
            )}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={onCloseDialog}>{processFinished ? 'Close' : 'Cancel'}</Button>
            <SpinnerOverlay
              button={
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!processFinished || backlogUpdatePending}
                  data-cy='update-backlog'
                >
                  Update
                </Button>
              }
              show={!processFinished || backlogUpdatePending}
            />
          </DialogActions>
        </form>
      </FormContext>
    </Dialog>
  );
};
