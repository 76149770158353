import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Grid, Divider, Tooltip } from '@material-ui/core';
import { FiberManualRecord, DirectionsBoat } from '@material-ui/icons';
import { RoadmapSprint } from './roadmap-widget.model';
import { RoadmapItem } from './roadmap-item.component';

interface RoadmapSprintItemProps {
  roadmapSprint: RoadmapSprint;
  isFullRoadmap: boolean;
  anySprintRelease: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  paddingTop: {
    paddingTop: theme.spacing(1),
  },
  paddingTopFull: {
    paddingTop: theme.spacing(3),
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end',
  },
  sprintRelease: {
    minHeight: '40px',
  },
  sprintName: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
  },
  divider: {
    height: '5px',
  },
}));

export const RoadmapSprintItem: React.FC<RoadmapSprintItemProps> = ({
  roadmapSprint,
  isFullRoadmap,
  anySprintRelease,
}: RoadmapSprintItemProps) => {
  const classes = useStyles();

  const FULL_GRID_XS = 3;
  const EMBEDDED_GRID_XS = 4;

  const groupEpicsByTeam = (
    epics: { selectedTeamIndex: number; label: string; color: string }[],
  ): { selectedTeamIndex: number; label: string; color: string }[] => {
    return epics.sort((epic1, epic2) => epic2.selectedTeamIndex - epic1.selectedTeamIndex || epic1.label.localeCompare(epic2.label));
  };

  const groupedEpics = groupEpicsByTeam(roadmapSprint.epics);

  return (
    <Grid container item xs={isFullRoadmap ? FULL_GRID_XS : EMBEDDED_GRID_XS}>
      <Grid
        container
        direction='column'
        className={`${isFullRoadmap ? classes.paddingTopFull : classes.paddingTop} ${classes.justifyFlexEnd}`}
        spacing={1}
      >
        {groupedEpics.map(sprintEpic => {
          return (
            <RoadmapItem
              key={'rmi-' + roadmapSprint.id + sprintEpic.label + sprintEpic.color}
              epic={sprintEpic.label}
              color={sprintEpic.color}
            />
          );
        })}
        <Grid item xs='auto'>
          <Grid item xs='auto'>
            <Divider className={classes.divider} orientation='horizontal' light={false} variant='fullWidth'></Divider>
          </Grid>
          <Grid item xs='auto'>
            <Paper className={`${classes.container} ${classes.sprintName}`} elevation={0}>
              {roadmapSprint.name}
            </Paper>
          </Grid>
          <Grid item xs='auto'>
            <Paper className={classes.container} elevation={0}>
              ({roadmapSprint.timeframe})
            </Paper>
          </Grid>
          <Grid item xs='auto'>
            {anySprintRelease && (
              <Paper className={`${classes.container} ${classes.sprintRelease}`} elevation={0}>
                {roadmapSprint.releases.length > 0 ? (
                  <Tooltip title='Release Sprint'>
                    <DirectionsBoat />
                  </Tooltip>
                ) : (
                  <React.Fragment />
                )}
                {roadmapSprint.releases.length > 0 ? (
                  roadmapSprint.releases.map(release => (
                    <Tooltip key={'tooltip-' + roadmapSprint.id + release.color} title={release.team}>
                      <FiberManualRecord key={'fmr-' + roadmapSprint.id + release.color} style={{ color: release.color }} />
                    </Tooltip>
                  ))
                ) : (
                  <React.Fragment />
                )}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
