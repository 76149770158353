import { createAction } from '@reduxjs/toolkit';
import { Notification } from './app.state';
import { VersionInformationDto, FeatureOptionsDto } from './app.model';
import { generateNotificationKey, RequestError } from './shared';

export const initializePublicData = createAction('[APP] INITIALIZE_PUBLIC_DATA');
export type InitializePublicDataAction = ReturnType<typeof initializePublicData>;

export const initializePrivateData = createAction('[APP] INITIALIZE_PRIVATE_DATA');
export type InitializePrivateDataAction = ReturnType<typeof initializePrivateData>;

export const loadVersionInformation = createAction('[APP] LOAD_VERSION_INFORMATION');
export type LoadVersionInformationAction = ReturnType<typeof loadVersionInformation>;

export const loadVersionInformationSucceeded = createAction(
  '[APP] LOAD_VERSION_INFORMATION_SUCCEEDED',
  (versionInfo: VersionInformationDto) => ({
    payload: {
      version: versionInfo,
    },
  }),
);
export type LoadVersionInformationSucceededAction = ReturnType<typeof loadVersionInformationSucceeded>;

export const loadVersionInformationFailed = createAction('[APP] LOAD_VERSION_INFORMATION_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type LoadVersionInformationFailedAction = ReturnType<typeof loadVersionInformationFailed>;

export const loadFeatureOptions = createAction('[APP] LOAD_FEATURE_OPTIONS');
export type LoadFeatureOptionsAction = ReturnType<typeof loadFeatureOptions>;

export const loadFeatureOptionsSucceeded = createAction('[APP] LOAD_FEATURE_OPTIONS_SUCCEEDED', (options: FeatureOptionsDto) => ({
  payload: {
    options,
  },
}));
export type LoadFeatureOptionsSucceededAction = ReturnType<typeof loadFeatureOptionsSucceeded>;

export const loadFeatureOptionsFailed = createAction('[APP] LOAD_FEATURE_OPTIONS_FAILED', (requestError: RequestError) => ({
  payload: {
    requestError,
  },
}));
export type LoadFeatureOptionsFailedAction = ReturnType<typeof loadFeatureOptionsFailed>;

export const enqueueNotification = createAction('[APP] ENQUEUE_NOTIFICATION', (notification: Notification) => ({
  payload: {
    notification: {
      ...notification,
      key: notification.key || generateNotificationKey(),
    },
  },
}));

export type EnqueueNotificationAction = ReturnType<typeof enqueueNotification>;

export const removeNotification = createAction('[APP] REMOVE_NOTIFICATION', key => ({
  payload: {
    key,
  },
}));

export type RemoveNotificationAction = ReturnType<typeof removeNotification>;

export const removeAllNotifications = createAction('[APP] REMOVE_ALL_NOTIFICATIONS');
export type RemoveAllNotificationsAction = ReturnType<typeof removeAllNotifications>;

export const moreUnsavedChanges = createAction('[APP] MORE_UNSAVED_CHANGES');
export type MoreUnsavedChanges = ReturnType<typeof moreUnsavedChanges>;

export const noMoreUnsavedChanges = createAction('[APP] NO_MORE_UNSAVED_CHANGES');
export type NoMoreUnsavedChanges = ReturnType<typeof noMoreUnsavedChanges>;

export type AppActionTypes =
  | InitializePublicDataAction
  | InitializePrivateDataAction
  | LoadVersionInformationAction
  | LoadVersionInformationSucceededAction
  | LoadVersionInformationFailedAction
  | LoadFeatureOptionsAction
  | LoadFeatureOptionsSucceededAction
  | LoadFeatureOptionsFailedAction
  | EnqueueNotificationAction
  | RemoveNotificationAction
  | RemoveAllNotificationsAction
  | MoreUnsavedChanges
  | NoMoreUnsavedChanges;
