import React from 'react';
import { Sync, Forum, Mail, Group, Delete } from '@material-ui/icons';
import { Box, Card, Grid, Tooltip, IconButton, Divider, Typography } from '@material-ui/core';
import TimeAgo from 'react-timeago';
import { TimelineItem, TimelineItemType, TimelineEventBody } from '..';

interface TimelineEventProps {
  item: TimelineItem;
  onDelete: (item: TimelineItem) => void;
  issueBaseLink: string;
}

export const TimelineEvent: React.FC<TimelineEventProps> = ({ item, onDelete, issueBaseLink }) => {
  const { userName, header, createdAt, type, body, productBacklogItemChanges, storyPointChanges } = item;

  const getIcon = () => {
    switch (type) {
      case TimelineItemType.BacklogUpdate: {
        return <Sync color='primary' />;
      }
      case TimelineItemType.Conversation: {
        return <Forum color='primary' />;
      }
      case TimelineItemType.Mail: {
        return <Mail color='primary' />;
      }
      case TimelineItemType.Meeting: {
        return <Group color='primary' />;
      }
    }
  };

  return (
    <Box my={1}>
      <Card>
        <Box p={2}>
          <Grid container xs={12} direction='row' alignItems='center' justifyContent='space-between' spacing={1} item>
            <Grid item>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>{getIcon()}</Grid>
                <Grid item>
                  <Box fontFamily='fontFamily' fontWeight='fontWeightBold'>
                    {userName}
                  </Box>
                </Grid>
                <Grid item>
                  <Typography>{header}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item>
                  <Box fontFamily='fontFamily' fontWeight='fontWeightLight' fontSize={13}>
                    <TimeAgo date={createdAt.toDate()} />
                  </Box>
                </Grid>
                <Grid item>
                  {type === TimelineItemType.BacklogUpdate ? (
                    <Tooltip title='Delete not possible on backlog update'>
                      <Delete color='disabled' />
                    </Tooltip>
                  ) : (
                    <IconButton
                      size='small'
                      onClick={() => {
                        onDelete(item);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {body || productBacklogItemChanges.length > 0 || storyPointChanges.length > 0 ? (
            <>
              <Divider />
              <Box mt={1}>
                <TimelineEventBody item={item} issueBaseLink={issueBaseLink} />
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Card>
    </Box>
  );
};
