import React from 'react';
import { Alert, AlertProps } from '@material-ui/lab';

export interface AlertDisplayProps {
  content: string | JSX.Element;
  severity: AlertProps['severity'];
}

export const AlertDisplay: React.FC<AlertDisplayProps> = ({ content, severity }) => {
  return (
    <Alert icon={false} severity={severity} style={{ justifyContent: 'center', textAlign: 'center' }}>
      {content}
    </Alert>
  );
};
