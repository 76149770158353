import { green, yellow, red, grey } from '@material-ui/core/colors';
import { PbiColor } from './backlog-widget.selectors';

export interface PbiColorStyle {
  color: PbiColor;
}

export function getEpicColor(pbiEpicColor: PbiColorStyle) {
  switch (pbiEpicColor.color) {
    case PbiColor.MIN:
      return green[50];
    case PbiColor.MED:
      return yellow[100];
    case PbiColor.MAX:
      return red[50];
    case PbiColor.NO:
      return grey[100];
    default:
      break;
  }
}

export function getStoryPointColor(pbiStoryPointColor: PbiColorStyle) {
  switch (pbiStoryPointColor.color) {
    case PbiColor.MIN:
      return green[100];
    case PbiColor.MED:
      return yellow[300];
    case PbiColor.MAX:
      return red[100];
    case PbiColor.NO:
      return grey[300];
    default:
      break;
  }
}
