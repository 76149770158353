import React, { useCallback } from 'react';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
interface MovingBacklogItemMenuProps {
  index: number;
  numberOfPbis: number;
  dragPbi: (dragIndex: number, hoverIndex: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: theme.spacing(0),
  },
}));

export const MovingBacklogItemMenu: React.FC<MovingBacklogItemMenuProps> = ({
  index,
  numberOfPbis,
  dragPbi,
}: MovingBacklogItemMenuProps) => {
  const classes = useStyles();
  const maxIndex = numberOfPbis - 1;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moveItem = useCallback(
    (oldIndex: number, newIndex: number) => {
      setAnchorEl(null);
      if (oldIndex === newIndex || (oldIndex === 0 && newIndex <= oldIndex) || (oldIndex === maxIndex && newIndex >= oldIndex)) {
        return;
      }

      newIndex = newIndex < 0 ? 0 : newIndex > maxIndex ? maxIndex : newIndex;
      dragPbi(oldIndex, newIndex);
    },
    [maxIndex, dragPbi],
  );

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id='move-pbis-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => moveItem(index, 0)}>Move to top</MenuItem>
        <MenuItem onClick={() => moveItem(index, index - 10)}>Move 10 up</MenuItem>
        <Divider />
        <MenuItem onClick={() => moveItem(index, index + 10)}>Move 10 down</MenuItem>
        <MenuItem onClick={() => moveItem(index, maxIndex)}>Move to bottom</MenuItem>
      </Menu>
    </>
  );
};
