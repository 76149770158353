export class StaticInjector {
  private records: Map<any, any> = new Map();

  public register<T>(token: InjectionToken<T>, instance: any): void {
    this.records.set(token, instance);
  }

  public get<T>(token: InjectionToken<T>): T {
    return this.records.get(token);
  }
}

export const INJECTOR = new StaticInjector();

export class InjectionToken<T> {
  constructor(private desc: string) {}

  toString(): string {
    return `InjectionToken ${this.desc}`;
  }
}
