import React from 'react';
import { Typography, List, ListItem } from '@material-ui/core';

export const HowToAddARelease: React.FC = () => {
  return (
    <>
      <Typography>There are no future releases configured yet, to add one follow the steps below:</Typography>
      <List>
        <ListItem>
          <Typography>1. Select the 'Sprint Management' tab from the side bar.</Typography>
        </ListItem>
        <ListItem>
          <Typography>2. Click on the three dots icon at the end of the row of the future sprint you want to configure.</Typography>
        </ListItem>
        <ListItem>
          <Typography>3. Select 'Create Release'.</Typography>
        </ListItem>
        <ListItem>
          <Typography>4. Provide details accordingly and click 'Done'.</Typography>
        </ListItem>
        <ListItem>
          <Typography>5. Save changes.</Typography>
        </ListItem>
      </List>
    </>
  );
};
