import React, { useCallback, useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../auth/auth.selectors';
import { User, RegisterUser, UserProcessEventType } from '../../auth';
import { useGetErrorMessageCallback, useToggleState, checkEmailAddressFormat, containsLetterAndNumber, SpinnerOverlay } from '../../shared';
import { UserInfoRestriction } from './user-info-restriction.component';
import { removeAllNotifications } from '../../app.actions';
import { ignoreBackdropClick } from '../../shared/ignore-backdrop-click';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(0, 3, 2, 0),
  },
  formText: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  formTextMarginTop: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  dialogActions: {
    margin: theme.spacing(1, 2, 1, 0),
  },
}));

interface UserDialogProps {
  open: boolean;
  user?: User;
  roles: string[];
  processFinished: boolean;
  onVerifyDuplicateEmailAddress: (email?: string) => boolean;
  onSubmit: (user: User | RegisterUser, eventType: UserProcessEventType) => void;
  onClose: () => void;
}

export const UserDialog: React.FC<UserDialogProps> = ({
  open,
  user,
  roles,
  processFinished,
  onVerifyDuplicateEmailAddress,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const auth = useSelector(selectAuth);
  const [isNewUser] = useState<boolean>(!user);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [showPassword, toggleShowPassword] = useToggleState(false);

  const formOptions = {
    defaultValues: {
      email: user?.email || '',
      password: '',
    },
  };
  const formMethods = useForm(formOptions);
  const getErrorMessageForField = useGetErrorMessageCallback(formMethods.errors);

  const dispatch = useDispatch();
  const onCloseDialog = useCallback(() => {
    dispatch(removeAllNotifications());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (data: any) => {
      const email = data.email;
      const roles = [...selectedRoles];
      let formUser;
      if (isNewUser && auth.metadata.data.enableLocalLogin) {
        formUser = {
          name: email,
          email: email,
          password: data.password,
          roles: roles,
        } as RegisterUser;
      } else if (isNewUser && !auth.metadata.data.enableLocalLogin) {
        formUser = {
          name: email,
          email: email,
          roles: roles,
        } as RegisterUser;
      } else {
        formUser = {
          id: user?.id,
          name: email,
          email: email,
          roles: roles,
        } as User;
      }

      onSubmit(formUser, isNewUser ? UserProcessEventType.Create : UserProcessEventType.Update);
    },
    [user, selectedRoles, onSubmit, isNewUser],
  );

  useEffect(() => {
    if (!open) return;
    if (showPassword) {
      toggleShowPassword();
    }
    setSelectedRoles(user?.roles || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => ignoreBackdropClick(reason, onCloseDialog)}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      {React.useMemo(
        () => (
          <DialogTitle id='dialog-title'>
            <Box display='flex' justifyContent='space-between'>
              {isNewUser ? 'Create New User' : 'Update User'}
            </Box>
          </DialogTitle>
        ),
        [isNewUser],
      )}
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <DialogContent dividers>
          <DialogContentText id='dialog-description' tabIndex={-1}></DialogContentText>
          <FormContext {...formMethods}>
            {!isNewUser && (
              <Typography className={classes.formText}>
                <b>Note:</b>
                <br />
                The E-mail address of an existing user cannot be changed.
                <br />
                Delete the user and create a new one using the adjusted E-mail address.
              </Typography>
            )}
            <TextField
              name='email'
              type='text'
              label='E-Mail address *'
              variant='outlined'
              disabled={!isNewUser || !processFinished}
              className={classes.formControl}
              inputRef={formMethods.register({
                required: 'The E-Mail address is required.',
                validate: value => {
                  if (!isNewUser) {
                    return true;
                  }
                  const correctAddressFormat = checkEmailAddressFormat(value);
                  if (!correctAddressFormat) {
                    return 'Incorrect E-Mail address format. Example: test@zuehlke.com';
                  }
                  const addressAlreadyTaken = onVerifyDuplicateEmailAddress(value);
                  if (addressAlreadyTaken) {
                    return 'This E-Mail address is already taken.';
                  }
                  return true;
                },
                maxLength: {
                  value: 100,
                  message: 'E-Mail address must not be longer than 100 characters.',
                },
              })}
              InputProps={{
                endAdornment: isNewUser && (
                  <InputAdornment position='end'>
                    <UserInfoRestriction
                      title='E-mail address'
                      restrictions={{
                        Length: <>max. 100 characters</>,
                        Format: (
                          <>
                            [user]<b style={{ color: 'red' }}>@</b>[domain]<b style={{ color: 'red' }}>.</b>[tld]
                            <br />
                            Example: <i>test@zuehlke.com</i>
                          </>
                        ),
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              fullWidth
              helperText={getErrorMessageForField('email')}
              error={!!getErrorMessageForField('email')}
              autoComplete='off'
              data-cy='email'
            />
            {isNewUser && auth.metadata.data.enableLocalLogin && (
              <TextField
                name='password'
                type={showPassword ? 'text' : 'password'}
                label='Password *'
                variant='outlined'
                disabled={!processFinished}
                className={classes.formControl}
                inputRef={formMethods.register({
                  required: 'The Password is required.',
                  minLength: {
                    value: 8,
                    message: 'Password should contain at least 8 characters (letters and digits).',
                  },
                  validate: value => containsLetterAndNumber(value) || 'Password should consist of letters and digits.',
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <>
                        <IconButton aria-label='toggle password visibility' onClick={toggleShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        <UserInfoRestriction
                          title='Password'
                          restrictions={{
                            Length: (
                              <>
                                <b>min. 8</b> / max. 100 characters
                              </>
                            ),
                            Format: (
                              <>
                                must contain letters <b>and</b> digits
                              </>
                            ),
                          }}
                        />
                      </>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                helperText={getErrorMessageForField('password')}
                error={!!getErrorMessageForField('password')}
                autoComplete='new-password'
                data-cy='password'
              />
            )}
            <Typography className={classes.formTextMarginTop}>
              <b>Note:</b>
              <br />
              Users having role <i>Admin</i> can create / remove users and edit their roles.
              <br />
              Users with no role are normal application users.
            </Typography>
            <Autocomplete
              multiple={true}
              value={selectedRoles}
              id='cmbRoles'
              disabled={!processFinished}
              options={roles}
              onChange={(_event, values) => {
                setSelectedRoles(values);
              }}
              filterSelectedOptions
              noOptionsText='No role to select'
              renderInput={params => <TextField {...params} label='Roles' variant='outlined' className={classes.formControl} fullWidth />}
            />
          </FormContext>
        </DialogContent>
        {React.useMemo(
          () => (
            <DialogActions className={classes.dialogActions}>
              <Button onClick={onClose} color='default'>
                {processFinished ? 'Close' : 'Cancel'}
              </Button>
              <SpinnerOverlay
                button={
                  <Button type='submit' variant='contained' color='primary' disabled={!processFinished}>
                    {isNewUser ? 'Create' : 'Save'}
                  </Button>
                }
                show={!processFinished}
              />
            </DialogActions>
          ),
          [classes, processFinished, isNewUser, onClose],
        )}
      </form>
    </Dialog>
  );
};
