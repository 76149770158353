import { push } from 'redux-first-history';
import { AjaxResponse } from 'rxjs/ajax';
import { of, Observable } from 'rxjs';
import { switchMap, mergeMap, filter, catchError } from 'rxjs/operators';
import { Epic, combineEpics } from 'redux-observable';
import { convertTimelineItemDtoToTimelineItem } from './planning.converters';
import { removeAllNotifications } from '../app.actions';
import { SuccessNotification } from '../shared';
import { RootState } from '../app.state';
import { AppDependencies } from '../app.dependencies';
import { mapDefaultResponseToError } from '../shared';
import { enqueueNotification, initializePrivateData } from '../app.actions';
import {
  PlanningActionTypes,
  loadAllTeamsAndActiveTeam,
  loadAllTeamsAndActiveTeamSucceeded,
  loadAllTeamsAndActiveTeamFailed,
  loadSprintsForTeam,
  loadSprintsForTeamSucceeded,
  loadSprintsForTeamFailed,
  loadBacklogItemsForTeam,
  loadDataForTeam,
  loadBacklogItemsForTeamSucceeded,
  loadBacklogItemsForTeamFailed,
  loadChartViewSettingsForTeam,
  loadChartViewSettingsForTeamFailed,
  loadChartViewSettingsForTeamSucceeded,
  loadScenariosForTeam,
  loadScenariosForTeamSucceeded,
  loadScenariosForTeamFailed,
  loadTimelineItemsForTeam,
  loadTimelineItemsForTeamSucceeded,
  loadTimelineItemsForTeamFailed,
  updateBacklogForTeam,
  updateBacklogForTeamOAuth,
  updateBacklogForTeamExcel,
  updateBacklogForTeamFullJiraCsv,
  updateBacklogForTeamSucceeded,
  updateBacklogForTeamFailed,
  updateBacklogForTeamCanceled,
  createTeam,
  createTeamSucceeded,
  createTeamFailed,
  createTeamCanceled,
  deleteTeam,
  deleteTeamSucceeded,
  deleteTeamFailed,
  copyTeam,
  copyTeamSucceeded,
  copyTeamFailed,
  updateTeam,
  updateTeamSucceeded,
  updateTeamFailed,
  updateTeamCanceled,
  copyTeamCanceled,
  moveBacklogItem,
  moveBacklogItemSucceeded,
  moveBacklogItemFailed,
  updateSprintConfigurationForTeam,
  updateSprintConfigurationForTeamSucceeded,
  updateSprintConfigurationForTeamFailed,
  updateChartViewSettingsForTeam,
  updateChartViewSettingsForTeamSucceeded,
  updateChartViewSettingsForTeamFailed,
  updateDeliverySprintIdFailed,
  updateDeliverySprintId,
  updateDeliverySprintIdSucceeded,
  createTimelineItemForTeam,
  createTimelineItemForTeamFailed,
  createTimelineItemForTeamSucceeded,
  deleteTimelineItemForTeam,
  deleteTimelineItemForTeamSucceeded,
  deleteTimelineItemForTeamFailed,
  updateSelectedTeamIds,
  updateSelectedTeamIdsSucceeded,
  updateSelectedTeamIdsFailed,
  setActiveTeam,
} from './planning.actions';
import { selectActiveTeamId } from './planning.selectors';
import { ChartType } from './planning.model';
import { ChartViewSettings } from './planning.state';

const initializePlanningDataEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (action$, state$) =>
  action$.pipe(
    filter(initializePrivateData.match),
    mergeMap(() => (selectActiveTeamId(state$.value) ? [] : [loadAllTeamsAndActiveTeam()])),
  );

const setActiveTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes> = action$ =>
  action$.pipe(
    filter(setActiveTeam.match),
    mergeMap(() => [loadAllTeamsAndActiveTeam()]),
  );

const loadAllTeamsAndActiveTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadAllTeamsAndActiveTeam.match),
    switchMap(() => {
      return planningService.getTeams().pipe(
        mergeMap(response => [loadAllTeamsAndActiveTeamSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(loadAllTeamsAndActiveTeamFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

const loadDataForAllTeamsEpic: Epic<PlanningActionTypes> = action$ =>
  action$.pipe(
    filter(loadAllTeamsAndActiveTeamSucceeded.match),
    mergeMap(action => action.payload.teams.map(team => loadDataForTeam(team.id))),
  );

const loadDataForTeamEpic: Epic<PlanningActionTypes> = action$ =>
  action$.pipe(
    filter(loadDataForTeam.match),
    mergeMap(action => [
      loadSprintsForTeam(action.payload.teamId),
      loadBacklogItemsForTeam(action.payload.teamId),
      loadScenariosForTeam(action.payload.teamId),
      loadChartViewSettingsForTeam(action.payload.teamId),
      loadTimelineItemsForTeam(action.payload.teamId),
    ]),
  );

const loadSprintsForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadSprintsForTeam.match),
    mergeMap(action => {
      return planningService.getSprints(action.payload.teamId).pipe(
        mergeMap(response => [loadSprintsForTeamSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) => of(loadSprintsForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error)))),
      );
    }),
  );

const loadBacklogItemsForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadBacklogItemsForTeam.match),
    mergeMap(action => {
      return planningService.getBacklogItems(action.payload.teamId).pipe(
        mergeMap(response => [loadBacklogItemsForTeamSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) =>
          of(loadBacklogItemsForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error))),
        ),
      );
    }),
  );

const loadChartViewSettingsForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadChartViewSettingsForTeam.match),
    mergeMap(action => {
      return planningService.getChartViewSettings(action.payload.teamId).pipe(
        mergeMap(response => [loadChartViewSettingsForTeamSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) =>
          of(loadChartViewSettingsForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error))),
        ),
      );
    }),
  );

const updateChartViewSettingsForTeamEpic: Epic = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(updateChartViewSettingsForTeam.match),
    switchMap(action => {
      const { chartType, teamId } = action.payload;
      let chartViewSettings: ChartViewSettings;
      chartType === ChartType.BurnUp
        ? (chartViewSettings = state$.value.planning.burnup.data)
        : (chartViewSettings = state$.value.planning.burndown.data);
      const chartSettings = chartViewSettings;

      return planningService.updateChartViewSettings(teamId, chartSettings, chartType).pipe(
        mergeMap(() => [updateChartViewSettingsForTeamSucceeded(teamId)]),
        catchError((error: AjaxResponse<any>) => of(updateChartViewSettingsForTeamFailed(teamId, mapDefaultResponseToError(error)))),
      );
    }),
  );

const loadScenariosForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadScenariosForTeam.match),
    mergeMap(action => {
      return planningService.getScenarios(action.payload.teamId).pipe(
        mergeMap(response => [loadScenariosForTeamSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) => of(loadScenariosForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error)))),
      );
    }),
  );

const loadTimelineItemsForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(loadTimelineItemsForTeam.match),
    mergeMap(action => {
      return planningService.getTimelineItems(action.payload.teamId).pipe(
        mergeMap(response => [loadTimelineItemsForTeamSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) =>
          of(loadTimelineItemsForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error))),
        ),
      );
    }),
  );

const createTeamEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(action => createTeam.match(action) || createTeamCanceled.match(action)),
    switchMap(action => {
      if (createTeam.match(action)) {
        return planningService.createTeam(action.payload.team, action.payload.firstSprintStartDate).pipe(
          mergeMap(response => [
            createTeamSucceeded(response),
            loadSprintsForTeam(response.id),
            loadChartViewSettingsForTeam(response.id),
            loadTimelineItemsForTeam(response.id),
            push('/team-management'),
            removeAllNotifications(),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: SuccessNotification.CreateTeam,
              variant: 'success',
            }),
          ]),
          catchError((error: AjaxResponse<any>) =>
            of(
              createTeamFailed(mapDefaultResponseToError(error)),
              enqueueNotification({
                key: action.payload.notificationKey,
                message: `Creating team failed: ${Object.values(mapDefaultResponseToError(error).errors)[0]}`,
                variant: 'error',
              }),
            ),
          ),
        );
      } else {
        return [push('/team-management')];
      }
    }),
  );

const copyTeamEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(action => copyTeam.match(action) || copyTeamCanceled.match(action)),
    switchMap(action => {
      if (copyTeam.match(action)) {
        return planningService.copyTeam(action.payload.team, action.payload.name).pipe(
          mergeMap(response => [
            copyTeamSucceeded(response),
            loadSprintsForTeam(response.id),
            loadChartViewSettingsForTeam(response.id),
            loadTimelineItemsForTeam(response.id),
            push('/team-management'),
            removeAllNotifications(),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: SuccessNotification.CopyTeam,
              variant: 'success',
            }),
          ]),
          catchError((error: AjaxResponse<any>) =>
            of(
              copyTeamFailed(mapDefaultResponseToError(error)),
              enqueueNotification({
                key: action.payload.notificationKey,
                message: `Copying team failed: ${Object.values(mapDefaultResponseToError(error).errors)[0]}`,
                variant: 'error',
              }),
            ),
          ),
        );
      } else {
        return [push('/team-management')];
      }
    }),
  );

const updateTeamEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(action => updateTeam.match(action) || updateTeamCanceled.match(action)),
    switchMap(action => {
      if (updateTeam.match(action)) {
        return planningService.updateTeam(action.payload.team).pipe(
          mergeMap(response => {
            return action.payload.redirectLocation
              ? [
                  updateTeamSucceeded(response),
                  push(action.payload.redirectLocation),
                  removeAllNotifications(),
                  enqueueNotification({
                    key: action.payload.notificationKey,
                    message: SuccessNotification.UpdateTeam,
                    variant: 'success',
                  }),
                ]
              : [updateTeamSucceeded(response)];
          }),
          catchError((error: AjaxResponse<any>) =>
            of(
              updateTeamFailed(mapDefaultResponseToError(error)),
              enqueueNotification({
                key: action.payload.notificationKey,
                message: `Updating team failed: ${Object.values(mapDefaultResponseToError(error).errors)[0]}`,
                variant: 'error',
              }),
            ),
          ),
        );
      } else {
        return [push('/team-management')];
      }
    }),
  );

const deleteTeamEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(deleteTeam.match),
    switchMap(action => {
      return planningService.deleteTeam(action.payload.teamId).pipe(
        mergeMap(() => [
          deleteTeamSucceeded(action.payload.teamId),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: SuccessNotification.DeleteTeam,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            deleteTeamFailed(action.payload.teamId, mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: `Deleting team failed: ${Object.values(mapDefaultResponseToError(error).errors)[0]}`,
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const updateBacklogForTeamEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(
      action =>
        updateBacklogForTeam.match(action) ||
        updateBacklogForTeamOAuth.match(action) ||
        updateBacklogForTeamExcel.match(action) ||
        updateBacklogForTeamFullJiraCsv.match(action) ||
        updateBacklogForTeamCanceled.match(action),
    ),
    switchMap(action => {
      if (updateBacklogForTeam.match(action)) {
        return updateBacklogForTeamMatch(action, planningService.updateBacklog(action.payload.teamId, action.payload.updateBacklogOptions));
      } else if (updateBacklogForTeamOAuth.match(action)) {
        return updateBacklogForTeamMatch(
          action,
          planningService.updateBacklogOAuth(action.payload.teamId, action.payload.keepOldBacklogItems, action.payload.defaultStoryPoints),
        );
      } else if (updateBacklogForTeamExcel.match(action)) {
        return updateBacklogForTeamMatch(
          action,
          planningService.updateBacklogExcel(
            action.payload.teamId,
            action.payload.file,
            action.payload.keepOldBacklogItems,
            action.payload.defaultStoryPoints,
          ),
        );
      } else if (updateBacklogForTeamFullJiraCsv.match(action)) {
        return updateBacklogForTeamMatch(
          action,
          planningService.updateBacklogCsv(
            action.payload.teamId,
            action.payload.file,
            action.payload.keepOldBacklogItems,
            action.payload.defaultStoryPoints,
          ),
        );
      } else {
        return of<PlanningActionTypes>();
      }
    }),
  );

function updateBacklogForTeamMatch(action$: any, observable: Observable<any>): Observable<any> {
  return observable.pipe(
    mergeMap(response => [
      updateBacklogForTeamSucceeded(action$.payload.teamId),
      loadBacklogItemsForTeam(action$.payload.teamId),
      loadTimelineItemsForTeam(action$.payload.teamId),
      loadChartViewSettingsForTeam(action$.payload.teamId),
      removeAllNotifications(),
      enqueueNotification({
        key: action$.payload.notificationKey,
        message: `${SuccessNotification.BacklogUpdate} ${convertTimelineItemDtoToTimelineItem(response).body}`,
        variant: 'success',
      }),
    ]),
    catchError((error: AjaxResponse<any>) =>
      of(
        updateBacklogForTeamFailed(action$.payload.teamId, mapDefaultResponseToError(error)),
        enqueueNotification({
          key: action$.payload.notificationKey,
          message: `${Object.values(mapDefaultResponseToError(error).errors)[0]}`,
          variant: 'error',
        }),
      ),
    ),
  );
}

const moveBacklogItemEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(moveBacklogItem.match),
    switchMap(action => {
      return planningService.moveBacklogItem(action.payload.teamId, action.payload.backlog).pipe(
        mergeMap(response => [moveBacklogItemSucceeded(action.payload.teamId, response)]),
        catchError((error: AjaxResponse<any>) => of(moveBacklogItemFailed(action.payload.teamId, mapDefaultResponseToError(error)))),
      );
    }),
  );

const updateDeliverySprintIdEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(updateDeliverySprintId.match),
    switchMap(action => {
      return planningService.updateDeliverySprintId(action.payload.team).pipe(
        mergeMap(response => [updateDeliverySprintIdSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(updateDeliverySprintIdFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

const updateSelectedTeamIdsEpic: Epic<PlanningActionTypes, PlanningActionTypes, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(updateSelectedTeamIds.match),
    switchMap(action => {
      return planningService.updateSelectedTeamIds(action.payload.team).pipe(
        mergeMap(response => [updateSelectedTeamIdsSucceeded(response)]),
        catchError((error: AjaxResponse<any>) => of(updateSelectedTeamIdsFailed(mapDefaultResponseToError(error)))),
      );
    }),
  );

const updateSprintConfigurationForTeamEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (
  action$,
  state$,
  { planningService },
) =>
  action$.pipe(
    filter(updateSprintConfigurationForTeam.match),
    switchMap(action => {
      return planningService.updateSprintConfiguration(action.payload.team, action.payload.sprints).pipe(
        mergeMap(updatedSprintConfiguration => [
          updateSprintConfigurationForTeamSucceeded(action.payload.team.id, updatedSprintConfiguration),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: SuccessNotification.SprintManagement,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            updateSprintConfigurationForTeamFailed(action.payload.team.id, mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: `Error while updating sprint configuration: ${mapDefaultResponseToError(error).errors[''][0]}`,
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const createTimelineItemEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(createTimelineItemForTeam.match),
    switchMap(action => {
      return planningService.createTimelineItem(action.payload.teamId, action.payload.timelineItem).pipe(
        mergeMap(response => [
          createTimelineItemForTeamSucceeded(action.payload.teamId, response),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: SuccessNotification.AddTimelineItem,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            createTimelineItemForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: mapDefaultResponseToError(error).errors[''][0],
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

const deleteTimelineItemEpic: Epic<PlanningActionTypes, any, RootState, AppDependencies> = (action$, state$, { planningService }) =>
  action$.pipe(
    filter(deleteTimelineItemForTeam.match),
    switchMap(action => {
      return planningService.deleteTimelineItem(action.payload.teamId, action.payload.timelineItem).pipe(
        mergeMap(response => [
          deleteTimelineItemForTeamSucceeded(action.payload.teamId),
          loadTimelineItemsForTeam(action.payload.teamId),
          removeAllNotifications(),
          enqueueNotification({
            key: action.payload.notificationKey,
            message: SuccessNotification.DeleteTimelineItem,
            variant: 'success',
          }),
        ]),
        catchError((error: AjaxResponse<any>) =>
          of(
            deleteTimelineItemForTeamFailed(action.payload.teamId, mapDefaultResponseToError(error)),
            enqueueNotification({
              key: action.payload.notificationKey,
              message: mapDefaultResponseToError(error).errors[''][0],
              variant: 'error',
            }),
          ),
        ),
      );
    }),
  );

export const planningEpics = combineEpics(
  initializePlanningDataEpic,
  loadAllTeamsAndActiveTeamEpic,
  loadDataForAllTeamsEpic,
  setActiveTeamEpic,
  loadDataForTeamEpic,
  loadSprintsForTeamEpic,
  loadBacklogItemsForTeamEpic,
  loadChartViewSettingsForTeamEpic,
  loadScenariosForTeamEpic,
  updateChartViewSettingsForTeamEpic,
  loadTimelineItemsForTeamEpic,
  updateBacklogForTeamEpic,
  createTeamEpic,
  copyTeamEpic,
  updateTeamEpic,
  deleteTeamEpic,
  moveBacklogItemEpic,
  updateDeliverySprintIdEpic,
  updateSelectedTeamIdsEpic,
  updateSprintConfigurationForTeamEpic,
  createTimelineItemEpic,
  deleteTimelineItemEpic,
);
