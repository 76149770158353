import React from 'react';
import { WarningRounded } from '@material-ui/icons';
import { BacklogItemPredictedSprint } from '../planning-calc.selectors';
import { Team } from '..';

export function getPbiWarning(pbi: BacklogItemPredictedSprint, team: Team) {
  return team.config.approvedBacklogItemStatuses.includes(pbi.status) ? (
    <></>
  ) : (
    <WarningRounded style={{ verticalAlign: 'middle' }} fontSize='small' />
  );
}
