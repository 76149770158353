import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import React from 'react';
import { selectActiveTeam } from '../planning.selectors';
import { PageLayout } from '../../shared';
import { BacklogWidget } from './backlog';
import BurnupWidget from './burnup/burnup-widget.component';
import { RoadmapWidget } from './roadmap';
import { BurndownWidget } from './burndown';

const useStyles = makeStyles((theme: Theme) => ({
  widgetWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  leftHandSide: {
    flex: '1 1 640px',
    minWidth: '640px',
  },
  backlogWidget: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  rightHandSide: {
    flex: '1 1 640px',
    minWidth: '640px',
  },
  roadmapWidget: {
    margin: theme.spacing(0, 2),
  },
  chartWidget: {
    margin: theme.spacing(2, 2),
  },
}));

export const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const team = useSelector(selectActiveTeam);

  return (
    <PageLayout>
      <div className={classes.widgetWrapper}>
        <div className={classes.leftHandSide}>
          <Grid item className={classes.backlogWidget}>
            <BacklogWidget key={team.id} team={team} />
          </Grid>
        </div>
        <div className={classes.rightHandSide}>
          <Grid item className={classes.roadmapWidget}>
            <RoadmapWidget team={team} isFullRoadmap={false} />
          </Grid>
          <Grid item className={classes.chartWidget}>
            <BurnupWidget team={team} />
          </Grid>
          <Grid item className={classes.chartWidget}>
            <BurndownWidget team={team} />
          </Grid>
        </div>
      </div>
    </PageLayout>
  );
};
