import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectCopyTeamState, selectTeam, selectTeamNames } from '../planning.selectors';
import { copyTeam, copyTeamCanceled } from '../planning.actions';
import { PageLayout, useGetErrorMessageCallback, SpinnerOverlay, generateNotificationKey } from '../../shared';
import { createDefaultTeam } from './create-default-team';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
}));

export const CopyTeamPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const teamSelector = useSelector(selectTeam);
  const team = teamSelector(params.id) || createDefaultTeam();
  const teamNames = useSelector(selectTeamNames);
  const copyTeamState = useSelector(selectCopyTeamState);
  const [processFinished, setProcessFinished] = useState<boolean>(true);

  useEffect(() => {
    if (!copyTeamState || copyTeamState.isPending) return;
    if (copyTeamState.requestError && !processFinished) {
      setProcessFinished(true);
    }
  }, [copyTeamState, processFinished]);

  const formOptions = {
    defaultValues: {
      name: team.name + ' Copy',
    },
  };

  const { handleSubmit, register, errors } = useForm(formOptions);
  const getErrorMessageForField = useGetErrorMessageCallback(errors);

  const onSubmit = useCallback(
    (data: any) => {
      setProcessFinished(false);
      dispatch(copyTeam(team, data.name, generateNotificationKey()));
    },
    [team, dispatch],
  );

  const onCancel = useCallback(() => {
    setProcessFinished(true);
    dispatch(copyTeamCanceled());
  }, [dispatch]);

  return (
    <div className={classes.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageLayout
          title={`Copy Team ${team.name}`}
          actions={
            <>
              <Grid item>
                <Button onClick={onCancel}>{processFinished ? 'Close' : 'Cancel'}</Button>
              </Grid>
              <Grid item>
                <SpinnerOverlay
                  button={
                    <Button type='submit' variant='contained' color='primary' data-cy='copy-team' disabled={!processFinished}>
                      Copy
                    </Button>
                  }
                  show={copyTeamState ? copyTeamState.isPending : false}
                />
              </Grid>
            </>
          }
        >
          <Card>
            <CardContent>
              <TextField
                id='name'
                name='name'
                type='text'
                label='Copy Team Name'
                fullWidth
                variant='outlined'
                inputRef={register({
                  required: 'The Team Name is required.',
                  validate: value => !teamNames.includes(value) || 'A team with that name already exists.',
                  maxLength: {
                    value: 100,
                    message: 'Team name must not be longer than 100 characters.',
                  },
                })}
                helperText={getErrorMessageForField('name')}
                error={!!getErrorMessageForField('name')}
                data-cy='name'
              />
            </CardContent>
          </Card>
        </PageLayout>
      </form>
    </div>
  );
};
