import { Color, createTheme, responsiveFontSizes } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

const zuehlkeIndigo: Color = {
  50: '#f0e7f2',
  100: '#dac2e0',
  200: '#c39bcb',
  300: '#ab75b6',
  400: '#9a5ba6',
  500: '#894697',
  600: '#7d4190',
  700: '#6d3a86',
  800: '#5e347b',
  900: '#46296b',
  A100: '#000000',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
};
const muiTheme = createTheme({
  palette: {
    primary: zuehlkeIndigo,
    secondary: {
      main: green[500],
    },
    error: {
      main: red[300],
    },
    background: {
      default: '#eee',
      paper: 'white',
    },
  },
});

export const theme = responsiveFontSizes(muiTheme);
