import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    flex: 1,
    textAlign: 'center',
  },
  spinner: {
    margin: theme.spacing(3),
    color: theme.palette.primary.main,
  },
}));

export interface LoadingStateProps {
  text?: string;
}

export const LoadingState: React.FC<LoadingStateProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <CircularProgress className={classes.spinner} />
      </div>
      <div className={classes.wrapper}>
        <Typography variant='h5'>{text ? text.trim() : 'Loading...'}</Typography>
      </div>
    </div>
  );
};
