import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Tooltip } from '@material-ui/core';
import { ReleaseCandidate } from '../../planning.state';
import { AlertDisplay } from '../backlog';
import { ignoreBackdropClick } from '../../../shared/ignore-backdrop-click';

export interface Candidate {
  name: string;
  storyPoints: number;
  id: number;
}

interface ReleaseCandidatesModalInterface {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (candidatesList: Candidate[]) => void;
  releaseCandidatesData: ReleaseCandidate[];
}

interface ReleaseCandidateDialog extends ReleaseCandidate {
  id: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingBottom: theme.spacing(1),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
  mainDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  btnAdd: {
    float: 'right',
    marginTop: theme.spacing(-0.75),
    paddingBottom: theme.spacing(1),
  },
  btnDelete: {
    marginTop: theme.spacing(2),
  },
}));

const ReleaseCandidatesModal = ({ isOpen, handleClose, handleSave, releaseCandidatesData }: ReleaseCandidatesModalInterface) => {
  const classes = useStyles();
  const [candidatesList, setCandidatesList] = useState<ReleaseCandidateDialog[]>([]);

  const handleSettingsSave = () => {
    handleSave(candidatesList);
  };

  const handleSettingsCancel = () => {
    handleClose();
  };

  const addCandidate = () => {
    let candidates = candidatesList.map((candidate): ReleaseCandidateDialog => candidate);
    candidates.push({
      id: candidatesList.length,
      name: '',
      storyPoints: 0,
    });
    setCandidatesList(candidates);
  };

  const deleteCandidate = (id: number) => {
    let newCandidates = candidatesList.filter((candidate: ReleaseCandidateDialog) => candidate.id !== id);
    setCandidatesList(newCandidates);
  };

  const updateCandidateName = (e: any, id: number) => {
    updateCandidateProps(e, id, candidate => (candidate.name = e.target.value));
  };

  const updateCandidateSP = (e: any, id: number) => {
    const numericValue = parseFloat(e.target.value);
    if (isNaN(numericValue) || numericValue < 0) {
      e.target.value = '0';
    } else {
      e.target.value = numericValue.toString();
    }
    updateCandidateProps(e, id, candidate => (candidate.storyPoints = e.target.value));
  };

  const updateCandidateProps = (e: any, id: number, update: (candidate: ReleaseCandidateDialog) => void) => {
    e.preventDefault();
    const newCandidatesList = candidatesList.map((candidate: ReleaseCandidateDialog) => {
      if (candidate.id === id) {
        update(candidate);
      }
      return candidate;
    });
    setCandidatesList(newCandidatesList);
  };

  useEffect(() => {
    if (isOpen) {
      const releaseCandidates = releaseCandidatesData.map<ReleaseCandidateDialog>((candidate, index) => ({ ...candidate, id: index }));
      setCandidatesList(releaseCandidates);
    }
  }, [isOpen, releaseCandidatesData]);

  return (
    <Dialog
      key={'release-candidate-dialog'}
      fullWidth={true}
      disableEscapeKeyDown
      onClose={(event, reason) => ignoreBackdropClick(reason, handleSettingsCancel)}
      open={isOpen}
    >
      <DialogTitle id='release-candidates-dialog-title' className={classes.dialogTitle}>
        Release Candidates
        <Tooltip title='Add release candidate'>
          <IconButton aria-label='add-release-candidate' onClick={addCandidate} className={classes.btnAdd}>
            <Add fontSize='small' />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} id='release-candidates-dialog-content'>
        {candidatesList.length ? (
          candidatesList.map((candidate: ReleaseCandidateDialog) => {
            return (
              <div key={candidate.id} className={classes.mainDiv}>
                <TextField
                  key={`${candidate.id}-name`}
                  label='Name'
                  type='string'
                  value={candidate.name}
                  onChange={(e: any) => {
                    updateCandidateName(e, candidate.id);
                  }}
                />
                <TextField
                  key={`${candidate.id}-sp`}
                  label='SP Threshold'
                  type='number'
                  inputProps={{ min: 0, step: 1 }}
                  value={candidate.storyPoints}
                  onChange={(e: any) => {
                    updateCandidateSP(e, candidate.id);
                  }}
                />
                <Tooltip id='btnDelete' title='Delete'>
                  <IconButton onClick={() => deleteCandidate(candidate.id)} aria-label='Delete' className={classes.btnDelete}>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              </div>
            );
          })
        ) : (
          <AlertDisplay content={'No candidates, please add to view.'} severity='info' />
        )}
      </DialogContent>
      <DialogActions id='release-candidates-dialog-actions' className={classes.dialogActions}>
        <Button id='release-candidate-dialog-cancel' onClick={handleSettingsCancel}>
          Cancel
        </Button>
        <Button id='release-candidate-dialog-save' color='primary' variant='contained' onClick={handleSettingsSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseCandidatesModal;
