import React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { ErrorOutline, PeopleOutline } from '@material-ui/icons';
import { getContrastBlackOrWhite } from '../../../shared/distinct-colors';
import { RoadmapTeam } from './roadmap-widget.model';

interface RoadmapFilterItemProps {
  roadmapTeam: RoadmapTeam;
  tagProps: AutocompleteGetTagProps;
  index: number;
}

export const RoadmapFilterItem: React.FC<RoadmapFilterItemProps> = ({ roadmapTeam, tagProps, index }: RoadmapFilterItemProps) => {
  const contrastColor = getContrastBlackOrWhite(roadmapTeam.color);
  return (
    <Chip
      style={{
        padding: '1px',
        color: contrastColor,
        backgroundColor: roadmapTeam.color,
        fontWeight: roadmapTeam.active ? 'bold' : 'normal',
      }}
      icon={
        roadmapTeam.mismatch ? (
          <Tooltip title={"The sprints of this team do not match the other teams' sprints"}>
            <ErrorOutline style={{ color: contrastColor }} />
          </Tooltip>
        ) : (
          <PeopleOutline style={{ color: contrastColor }} />
        )
      }
      label={
        roadmapTeam.active ? (
          <Tooltip title={'Active team'}>
            <div>{roadmapTeam.name}</div>
          </Tooltip>
        ) : (
          <div>{roadmapTeam.name}</div>
        )
      }
      {...tagProps({ index })}
    />
  );
};
